import React from 'react';
import { IonModal } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { useNetworkStatus } from './NetworkStatusContext';

import './NetworkStatusModal.css';

const NetworkStatusModal: React.FC = () => {
  const { isOffline } = useNetworkStatus();

  const { t } = useTranslation('network');
  const offlineTitle = t('NetworkStatusModal_title', {
    defaultValue: 'Device is offline',
  });
  const offlineBody = t('NetworkStatusModal_body', {
    defaultValue: 'Please check the internet connection.',
  });

  return (
    <IonModal
      isOpen={isOffline}
      backdropDismiss={false}
      id="network-status-modal">
      <div className="wrapper">
        <h2 className="ion-text-center">{offlineTitle}</h2>
        <p>{offlineBody}</p>
      </div>
    </IonModal>
  );
};

export default NetworkStatusModal;
