import { replaceHtmlTagsInObjectProperties } from '../../../Common/Object/object';
import { recipeCards } from '../../../Content/recipeCardsContent';

export interface CardDeckRecipeCardItem {
  title: string;
  summary: string;
  imageUrl: string;
  borderColor?: string;
}

export interface CardDeckRecipeCard {
  id: number;
  title: string;
  summary: string;
  description: string;
  imageUrl: string;
  origin: string;
  items: CardDeckRecipeCardItem[];
  conclusion: string;
  walkthroughVideo: string;
  whiteboardTemplatesSubheading: string;
  miroBoardId: string;
  muralTemplateId: string;
  layoutVersion: number;
}

export const emptyRecipeCard = {
  id: 0,
  title: '',
  summary: '',
  description: '',
  imageUrl: '',
  origin: '',
  items: [],
  conclusion: '',
  walkthroughVideo: '',
  whiteboardTemplatesSubheading: '',
  miroBoardId: '',
  muralTemplateId: '',
  layoutVersion: 1,
};

const typedCards: { [key: string]: CardDeckRecipeCard[] } =
  recipeCards as unknown as { [key: string]: CardDeckRecipeCard[] };

export const getCardDeckRecipeCard = (deckId: string, cardId: number) => {
  return typedCards[deckId]?.find(card => card.id == cardId);
};

export interface CardDeckRecipeCardSearchItem extends CardDeckRecipeCard {
  deckId: number;
}

export const getCardDeckRecipeCardSearchItems = (deckIds: number[]) => {
  const recipeCardArraysFromRequestedDecks = deckIds.map(deckId => {
    return typedCards[deckId];
  });
  const rawSearchItems =
    recipeCardArraysFromRequestedDecks.flat() as unknown as CardDeckRecipeCardSearchItem[];
  const cleanedUpSearchItems = rawSearchItems.map(searchItem =>
    replaceHtmlTagsInObjectProperties(
      searchItem,
      ['title', 'summary', 'description', 'items.title', 'items.summary'],
      ' '
    )
  );
  return cleanedUpSearchItems;
};
