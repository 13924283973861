import { SolveMyProblemRoutes } from '../getSolveMyProblemRoutes';

const provideChallengeBreadcrumbProps = (
  localize: (key: string) => string,
  isLink: boolean = true
) => {
  const challengesRoute = isLink
    ? SolveMyProblemRoutes.challenge.resolve()
    : undefined;
  return {
    route: challengesRoute,
    text: localize('SolveMyProblemChallengePage_breadcrumb'),
  };
};

export default provideChallengeBreadcrumbProps;
