import { cardCategories } from '../../Content/cardCategoriesContent';

export interface CardDeckCategory {
  title: string;
  description: string;
  iconCode: string;
  color: string;
  id: number;
}

const typedCategories: { [key: string]: CardDeckCategory[] } =
  cardCategories as unknown as { [key: string]: CardDeckCategory[] };

export const getCardDeckCategories = (deckId: string) =>
  typedCategories[deckId];

export const getCardDeckCategory = (deckId: string, categoryId: number) =>
  getCardDeckCategories(deckId)?.find(category => category.id == categoryId);
