// useLogout.ts
import { useIonAlert, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import { CardDeckRoutes } from '../../cardDecksRoutes';
import { checkIfStandaloneMode } from '../../Common/Platform/StandaloneMode';
import composeResetUserIdentityWithPostHog from '../../External/PostHog/resetUserIdentity';
import composeTrackUserActionWithPostHog from '../../External/PostHog/trackUserActionWithPostHog';

import logout from './logout';

export const useLogout = () => {
  const router = useIonRouter();
  const [presentAlert] = useIonAlert();
  const { t } = useTranslation('authentication');
  const posthog = usePostHog();
  const resetUserIdentity = composeResetUserIdentityWithPostHog(posthog);
  const trackAction = composeTrackUserActionWithPostHog(posthog);

  const handleLogout = () => {
    presentAlert({
      header: t('LogOutAlert_message'),
      buttons: [
        {
          text: t('LogOutAlert_noButtonLabel'),
          role: 'cancel',
        },
        {
          text: t('LogOutAlert_yesButtonLabel'),
          role: 'confirm',
          handler: () => {
            logout().then(() => {
              trackAction('logged out');
              resetUserIdentity();
              router.push(CardDeckRoutes.root.resolve());
              if (checkIfStandaloneMode()) {
                setTimeout(() => {
                  window.location.reload();
                });
              }
            });
          },
        },
      ],
    });
  };

  return handleLogout;
};
