import { FirebaseError } from 'firebase/app';
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';

import getFirebaseApp from './FirebaseApp';

import { ListenCustomerError } from '../../CardDecks/Common/AuthorizedAccess/customerStorage';
import { Customer } from '../../CardDecks/Common/AuthorizedAccess/Customer';
import { errorResult, okResult, Result } from '../../Common/Types/Result';

export interface FirestoreCustomer {
  email: string;
  shopifyTags?: string;
}

const db = getFirestore(getFirebaseApp());

export const listenFirebaseCustomer = (
  userEmail: string,
  onNext: (customer: Result<FirestoreCustomer | null, unknown>) => void
) => {
  let unsubscribe = () => {};

  try {
    const customersRef = collection(db, 'customers');
    const customerByEmailQuery = query(
      customersRef,
      where('email', '==', userEmail)
    );
    unsubscribe = onSnapshot(
      customerByEmailQuery,
      querySnapshot => {
        if (!querySnapshot.empty) {
          const firestoreCustomerData =
            querySnapshot.docs[0].data() as FirestoreCustomer;
          //console.log('firestoreCustomerData', firestoreCustomerData);
          onNext(okResult(firestoreCustomerData));
        } else {
          //console.log('snapshot empty');
          onNext(okResult(null));
        }
      },
      error => {
        onNext(errorResult(error));
      }
    );
  } catch (error) {
    onNext(errorResult(error));
  }

  return unsubscribe;
};

export const mapFirebaseErrorToListenCustomerError = (
  error: unknown
): ListenCustomerError => {
  return ListenCustomerError.Generic;
};

export const isHandledListenCustomerFirebaseError = (
  error: unknown
): boolean => {
  return (error as FirebaseError)?.code === 'auth/network-request-failed';
};

export const mapFirestoreCustomerToCustomer = (
  firestoreCustomer: FirestoreCustomer | null
): Customer | null => {
  const customerShopifyTags = firestoreCustomer?.shopifyTags;

  if (!customerShopifyTags) {
    return null;
  }

  const shopifyTags = customerShopifyTags.split(',').map(tag => tag.trim());
  const individualDeckIdsUserCanAccess = new Set(
    shopifyTags
      .map(tag => tagToDeckIdMap[tag])
      .filter(id => id != null)
      .map(id => id as number)
  );
  const isCustomerAProMember =
    shopifyTags.filter(tag => tag === 'pro-membership').length > 0;

  const allDeckIdsUserCanAccess = isCustomerAProMember
    ? new Set([
        ...individualDeckIdsUserCanAccess,
        ...deckIdsIncludedInTheProMembership,
      ])
    : individualDeckIdsUserCanAccess;

  return {
    email: firestoreCustomer.email,
    deckIdsUserCanAccess: allDeckIdsUserCanAccess,
    isProMember: isCustomerAProMember,
  };
};

const deckIdsIncludedInTheProMembership = new Set([1, 2, 3, 5, 6, 7, 8, 9, 10]);

export const tagToDeckIdMap: { [key: string]: number | null } = {
  // IMPORTANT: when adding new decks, update the analytics mapping (TrackCustomerComp) as well
  workshoptactics: 1,
  storytellertactics: 2,
  innovationtactics: 3,
  ideatactics: 4,
  strategytactics: 5,
  teamtactics: 6,
  brandtactics: 7,
  productivitytactics: 8,
  retros: 9,
  archetypes: 10,
  negotiationtactics: 11,
  // IMPORTANT: when adding new decks, update the analytics mapping (TrackCustomerComp) as well
};

export const allDeckIds = new Set(
  Object.values(tagToDeckIdMap).filter(id => id != null)
);

// export const tagToDeckIdMap: { [key: string]: number | null } = {
//   workshoptactics: 15,
//   retros: 24,
//   archetypes: 25,
//   storytellertactics: 16,
//   // innovationtactics: 3,
//   // strategytactics: 5,
//   // teamtactics: 6,
//   brandtactics: 22,
//   // productivitytactics: 8,
// };
