import React from 'react';
import { IonIcon, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';

interface NoActionMessageCompProps {
  titleIcon: string;
  title: string;
  messageIcon: string;
  message: string;
}

const NoActionMessageComp: React.FC<NoActionMessageCompProps> = ({
  titleIcon,
  title,
  messageIcon,
  message,
}) => {
  return (
    <>
      <IonList>
        <IonItem lines="none" className="header-item">
          <IonIcon icon={titleIcon} slot="start"></IonIcon>
          <IonLabel className="ion-text-wrap">
            <h1>{title}</h1>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonIcon icon={messageIcon} slot="start"></IonIcon>
          <IonNote color="dark" className="ion-text-wrap">
            {message}
          </IonNote>
        </IonItem>
      </IonList>
    </>
  );
};

export default NoActionMessageComp;
