const isStandaloneDisplayMode = () =>
  window.matchMedia('(display-mode: standalone)').matches;
const isStandaloneiOS = () =>
  'standalone' in window.navigator && (window.navigator as any).standalone;

const checkOverride = () => {
  const isStandalone = localStorage.getItem('standaloneMode') === 'true';
  return isStandalone;
};

export const checkIfStandaloneMode = (): boolean => {
  return isStandaloneDisplayMode() || isStandaloneiOS() || checkOverride();
};
