import { useHistory } from 'react-router-dom';
import { setResetSearchPageArgument } from './searchPageArguments';
import { CardDeckRoutes } from '../../cardDecksRoutes';

function useSearchNavigator() {
  const history = useHistory();
  const searchRoute = CardDeckRoutes.cardDecksCardSearch.resolve();

  return {
    navigateToSearchRouteAndResetSearchResults: () => {
      setResetSearchPageArgument(searchRoute);
      history.push(searchRoute);
    },
  };
}

export default useSearchNavigator;
