import React, { useState } from 'react';
import { IonButton, IonItem, IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import CenteredContentContainer from '../../Common/UI/Buttons/CenteredContentContainer';
import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';

import EmailInputFormComp from './EmailInputFormComp';
import signInWithAuthBroadcastHeld from './signInWithEmailLink';

interface ConfirmEmailLoginFormProps {
  onLoggedIn: () => void;
  onLoginLinkInvalid: () => void;
  onUnknownLoginLinkError: () => void;
  onStartOver: () => void;
}

const getCurrentUrl = () => {
  return window.location.href;
};

const ConfirmEmailLoginForm: React.FC<ConfirmEmailLoginFormProps> = ({
  onLoggedIn,
  onLoginLinkInvalid,
  onUnknownLoginLinkError,
  onStartOver,
}) => {
  const [email, setEmail] = useState<string>('');
  // there needs to be a default value set,
  // otherwise the input doesn't draw a bottom border
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const { t, ready: isTReady } = useTranslation('authentication');

  const clearSubmissionResultState = () => {
    setErrorMessage(undefined);
  };
  const auth = getFirebaseAuth();

  const signIn = async () => {
    return signInWithAuthBroadcastHeld(auth, email, getCurrentUrl())
      .then(() => {
        onLoggedIn();
      })
      .catch(error => {
        console.log(error.code);
        switch (error.code) {
          case 'auth/invalid-email':
            setErrorMessage(
              t('ConfirmEmailLoginForm_invalidEmailErrorMessage')
            );
            return;
          case 'auth/invalid-action-code':
            onLoginLinkInvalid();
            return;
          default:
            onUnknownLoginLinkError();
            return;
        }
      });
  };

  const onInputChanged = (value: string) => {
    {
      setEmail(value);
      clearSubmissionResultState();
    }
  };

  return isTReady ? (
    <>
      <EmailInputFormComp
        formTitle={t('ConfirmEmailLoginForm_title')}
        isFormTitleIconVisible={true}
        formInstructions={t('ConfirmEmailLoginForm_instructions')}
        emailInputValue={undefined}
        submitButtonLabel={t('ConfirmEmailLoginForm_sendButtonLabel')}
        formErrorMessage={errorMessage}
        onInputChanged={onInputChanged}
        onEmailSubmitClicked={signIn}
      />
      {errorMessage && (
        <IonList>
          <IonItem lines="none">
            <CenteredContentContainer>
              <IonButton onClick={onStartOver} fill="clear" size="default">
                {t('ConfirmEmailLoginForm_startOverButtonLabel')}
              </IonButton>
            </CenteredContentContainer>
          </IonItem>
        </IonList>
      )}
    </>
  ) : null;
};

export default ConfirmEmailLoginForm;
