import React from 'react';
import { IonList, IonPage, useIonRouter } from '@ionic/react';

import AppContentContainer from '../../Common/AppContentContainer';
import renderPageIfModelDefinedOrNotFoundPage from '../../Common/Pages/pageIfModelDefinedOrNotFoundPage';

import AuthorizedDeckContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';

import { getCardDeck } from '../Home/cardDecks';

import { CardDeckCardListSection } from './cardDeckCardListSections';
import CardDeckCardListSectionComp from './CardDeckCardListSectionComp';

type CardDetailsRouteResolver = (deckId: number, cardId: number) => string;

const getCardListDocumentTitle = (deckId: string, cardListTitle?: string) => {
  const deckIdNumber = Number(deckId);
  const cardDeckTitle = getCardDeck(deckIdNumber)?.title;
  const suffix = cardListTitle != undefined ? `${cardListTitle} | ` : '';
  return `${suffix}${cardDeckTitle}`;
};

interface CardDeckCardListPageProps {
  deckId: string;
  breadcrumbs: React.ReactNode;
  cardListSections: CardDeckCardListSection[] | null | undefined;
  shouldShowCategorySectionTitle: boolean;
  getCardDetailsRoute: CardDetailsRouteResolver;
}

const CardDeckCardListPage: React.FC<CardDeckCardListPageProps> = ({
  deckId,
  breadcrumbs,
  cardListSections,
  shouldShowCategorySectionTitle,
  getCardDetailsRoute,
}) => {
  // console.log(`Loading card list`);
  const router = useIonRouter();
  const onListItemClick = (cardId: number) => {
    router.push(getCardDetailsRoute(Number(deckId), cardId));
  };

  return renderPageIfModelDefinedOrNotFoundPage(
    cardListSections,
    <IonPage id="card-deck-cards">
      <AppContentContainer>
        <AuthorizedDeckContentAccessContainer deckId={deckId}>
          {breadcrumbs}
          <IonList className="cards-list hotjar-adjust">
            {cardListSections?.map((category, index) => (
              <CardDeckCardListSectionComp
                key={index}
                category={category}
                onListItemClick={onListItemClick}
                shouldShowCategorySectionTitle={shouldShowCategorySectionTitle}
              />
            ))}
          </IonList>
        </AuthorizedDeckContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckCardListPage;

export { getCardListDocumentTitle };
