import {
  composeAddCardBookmarkWithRemoteErrorLogging,
  composeListenCardBookmarksWithRemoteErrorLogging,
  composeRemoveCardBookmarkWithRemoteErrorLogging,
} from '../../../CompositionRoot/CardDecks/CardDeckBookmarks/composeCardBookmarksStorageWithErrorLogging';
import AddRemoveBookmarkButton, {
  CardBookmarkProps,
} from './AddRemoveBookmarkButton';

const composeAddRemoveBookmarkButton = (
  getCardBookmarkProps: () => CardBookmarkProps,
  isOnScreen: boolean
) => {
  return (
    <AddRemoveBookmarkButton
      getCardBookmarkProps={getCardBookmarkProps}
      addCardBookmark={composeAddCardBookmarkWithRemoteErrorLogging()}
      listenCardBookmarks={composeListenCardBookmarksWithRemoteErrorLogging()}
      removeCardBookmark={composeRemoveCardBookmarkWithRemoteErrorLogging()}
      isOnScreen={isOnScreen}
    />
  );
};

export default composeAddRemoveBookmarkButton;
