import React from 'react';
import { useTranslation } from 'react-i18next';

import ContentLoadingErrorComp from '../../../Common/ContentLoading/ContentLoadingErrorComp';

interface BookmarksErrorProps {
  onRetryButtonClick: () => void;
}

const BookmarksErrorComp: React.FC<BookmarksErrorProps> = ({
  onRetryButtonClick,
}) => {
  const { t, ready: isTReady } = useTranslation('bookmarks');

  return isTReady ? (
    <ContentLoadingErrorComp
      descriptionHtml={t('BookmarksErrorComp_description')}
      buttonText={t('BookmarksErrorComp_buttonTitle')}
      onRetryButtonClick={onRetryButtonClick}
    />
  ) : null;
};

export default BookmarksErrorComp;
