import React, { useState } from 'react';
import { IonButton, IonItem, IonList, IonNote } from '@ionic/react';
import { mailOutline } from 'ionicons/icons';

import { wrapWithBTag } from '../../../Common/UI/HTML/tags';
import { stringHtmlAsReactComp } from '../../../Common/UI/HTML/HtmlToReactComp';
import { emailLinkLoginFormLocalStorage } from '../emailLinkLoginFormLocalStorage';
import { checkIfStandaloneMode } from '../../../Common/Platform/StandaloneMode';
import EmailSentHeaderItem from './EmailSentHeaderItem';
import LoginCodeItems from './LoginCodeItems';
import TroubleshootButtonItem from './TroubleshootButtonItem';
import CenteredContentContainer from '../../../Common/UI/Buttons/CenteredContentContainer';
import { useTranslation } from 'react-i18next';

interface EmailLinkSentCompProps {
  onTroubleshootClicked: () => void;
  onResendClicked: () => void;
}

const EmailLinkSentComp: React.FC<EmailLinkSentCompProps> = ({
  onTroubleshootClicked,
  onResendClicked,
}) => {
  const [isSubmitedCodeInvalid, setIsSubmitedCodeInvalid] = useState(false);
  const isStandaloneAppMode = checkIfStandaloneMode();
  const { t, ready: isTReady } = useTranslation('authentication');

  const instructions =
    isTReady &&
    (isStandaloneAppMode ? (
      <>
        <ol>
          <li>{t('EmailLinkSentComp_instructionsStandalone1')}</li>
          <li>{t('EmailLinkSentComp_instructionsStandalone2')}</li>
          <li>{t('EmailLinkSentComp_instructionsStandalone3')}</li>
        </ol>
      </>
    ) : (
      t('EmailLinkSentComp_instructionsBrowser')
    ));

  const emailSentHeaderItem = isTReady && (
    <EmailSentHeaderItem
      title={t('EmailLinkSentComp_title')}
      icon={mailOutline}
    />
  );

  const midContents = isStandaloneAppMode ? (
    <LoginCodeItems
      onCodeValidityChanged={isCodeValid =>
        setIsSubmitedCodeInvalid(isCodeValid === false)
      }
    />
  ) : null;

  return isTReady ? (
    <>
      <IonList>
        {emailSentHeaderItem}
        <IonItem lines="none">
          <IonNote color="dark" className="ion-text-wrap">
            {getLinkSentMessage(
              t,
              emailLinkLoginFormLocalStorage.getRequestedLoginLinkEmail()
            )}
          </IonNote>
        </IonItem>
        {instructions && (
          <IonItem lines="none">
            <IonNote color="dark" className="ion-text-wrap">
              {instructions}
            </IonNote>
          </IonItem>
        )}
        {midContents}
        {!isSubmitedCodeInvalid && (
          <TroubleshootButtonItem
            buttonTitle={t('EmailLinkSentComp_troubleFindingEmailMessage')}
            onButtonClicked={onTroubleshootClicked}
            className="gap"
          />
        )}
        {/* In case of the login link expiry or unknown error, the message in the browser
            points the user back to the app, so we show this button to allow the user
            to recover from this screen without a valid code */}
        {isStandaloneAppMode && (
          <IonItem lines="none">
            <CenteredContentContainer>
              <IonButton
                onClick={onResendClicked}
                fill="clear"
                size="default"
                // if TroubleshootButtonItem is hidden, then we  need a gap
                // between the filled button and the clear button
                className={isSubmitedCodeInvalid ? 'gap' : ''}>
                {t('EmailLinkSentComp_resendLinkButtonLabel')}
              </IonButton>
            </CenteredContentContainer>
          </IonItem>
        )}
      </IonList>
    </>
  ) : null;
};

export default EmailLinkSentComp;

const getLinkSentMessage = (
  t: (i18nKey: string) => string,
  email: string | null
) => {
  const message = (() => {
    if (!email) {
      return t('EmailLinkSentComp_messageWhenMissingEmail');
    } else {
      // TODO: look into templating support in i18n
      const message = t('EmailLinkSentComp_message').replace(
        '{email}',
        wrapWithBTag(email)
      );
      return message;
    }
  })();
  return stringHtmlAsReactComp(message);
};
