import { IonButton, useIonRouter } from '@ionic/react';
import LeftArrowIcon from '../../UI/Icons/LeftArrowIcon';
import './BackButton.css';

// TODO: accessibility: go back doesn't work in the standalone mode

interface BackButtonProps {
  className?: string;
}

const BackButton: React.FC<BackButtonProps> = ({ className }) => {
  const router = useIonRouter();
  return (
    <IonButton
      className={`back-button ${className}`}
      aria-label="back"
      role="button"
      onClick={e => {
        e.preventDefault();
        router.goBack();
      }}>
      <LeftArrowIcon />
    </IonButton>
  );
};

export default BackButton;
