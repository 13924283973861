import { useIonRouter } from '@ionic/react';
import React from 'react';
import './ClickableDiv.css';
import clickOnEnter from '../Events/clickOnEnter';

interface ClickableDivProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  onClickRouteTo?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  children: React.ReactNode;
}

const ClickableDiv: React.FC<ClickableDivProps> = ({
  className,
  onClickRouteTo,
  onClick,
  children,
  ...props
}) => {
  const router = useIonRouter();
  const handleClick =
    onClick || (onClickRouteTo ? () => router.push(onClickRouteTo) : undefined);

  return (
    <div
      className={`clickable ${className}`}
      tabIndex={0}
      onClick={handleClick}
      onKeyUp={clickOnEnter}
      {...props}>
      {children}
    </div>
  );
};

export default ClickableDiv;
