import { useLocation } from 'react-router';
import { useIonRouter } from '@ionic/react';

import IconButton from '../../../Common/UI/Buttons/IconButton';
import { CardDeckRoutes } from '../../../cardDecksRoutes';
import AuthorizedDecksAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDecksAccessContainer';

import bookmarksOutline from '/public/images/bookmarks/icons/bookmarks.svg';
import bookmarksFilled from '/public/images/bookmarks/icons/bookmarks-filled.svg';

const BookmarksListNavigationButton: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const savedCardsRoute = CardDeckRoutes.cardDecksSavedCards.resolve();

  let iconSrc =
    location.pathname === savedCardsRoute ? bookmarksFilled : bookmarksOutline;

  return (
    <AuthorizedDecksAccessContainer>
      <IconButton
        iconSrc={iconSrc}
        onClick={() => router.push(savedCardsRoute)}
      />
    </AuthorizedDecksAccessContainer>
  );
};

export default BookmarksListNavigationButton;
