import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from '@ionic/react';
import {
  checkmarkCircleOutline,
  globeOutline,
  personOutline,
  phonePortraitOutline,
} from 'ionicons/icons';

import { getFunctions, httpsCallable } from 'firebase/functions';

import { stringHtmlAsReactComp } from '../../Common/UI/HTML/HtmlToReactComp';
import { wrapWithBTag } from '../../Common/UI/HTML/tags';
import ButtonWithSpinner from '../../Common/UI/Buttons/ButtonWithSpinner';
import CopyToClipboardButton from '../../Common/UI/CopyToClipboardButton';
import { isSafari } from '../../Common/Platform/isSafari';

interface EmailLinkLoginSuccessCompProps {
  email: string;
  isNewLogin: boolean;
  isStandaloneLoginFlow: boolean;
  onContinueInBrowserClicked: () => void;
}

enum LoginSuccessCompModes {
  actions = 'actions',
  code = 'code',
}

const EmailLinkLoginSuccessComp: React.FC<EmailLinkLoginSuccessCompProps> = ({
  email,
  isNewLogin,
  isStandaloneLoginFlow,
  onContinueInBrowserClicked,
}) => {
  const [mode, setMode] = useState(LoginSuccessCompModes.actions);
  const [code, setCode] = useState<string | undefined>(undefined);
  const { t, ready: isTReady } = useTranslation('authentication');

  const getLoginCode = () => {
    const functions = getFunctions();
    const generateUserCode = httpsCallable(functions, 'generateUserCode');
    return generateUserCode()
      .then(result => {
        setCode((result.data as { code: string }).code);
        setMode(LoginSuccessCompModes.code);
      })
      .catch(error => {
        console.error('Error generating code', error);
      });
  };

  const title = isNewLogin
    ? t('EmailLinkLoginSuccessComp_loginSuccessTitle')
    : t('EmailLinkLoginSuccessComp_oldLoginTitle');
  const headerIcon = isNewLogin ? checkmarkCircleOutline : personOutline;
  return isTReady ? (
    <>
      <IonList>
        <IonItem lines="none" className="header-item">
          <IonIcon icon={headerIcon} slot="start"></IonIcon>
          <IonLabel className="ion-text-wrap">
            <h1>{title}</h1>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonNote color="dark" className="ion-text-wrap">
            {getLoginSuccessMessageAsReactNode(t, email)}
          </IonNote>
        </IonItem>
        {mode === LoginSuccessCompModes.actions && (
          <LoginSuccessNextActions
            isStandaloneLoginFlow={isStandaloneLoginFlow}
            onGetLoginCodeClicked={getLoginCode}
            onContinueInBrowserClicked={onContinueInBrowserClicked}
          />
        )}
        {mode === LoginSuccessCompModes.code && code && (
          <LoginCodeComp
            code={code!}
            onContinueInBrowserClicked={onContinueInBrowserClicked}
          />
        )}
      </IonList>
    </>
  ) : null;
};

export default EmailLinkLoginSuccessComp;

const ContinueInBrowserSection: React.FC<{
  shouldShowInstruction: boolean;
  onContinueInBrowserClicked: () => void;
}> = ({ shouldShowInstruction, onContinueInBrowserClicked }) => {
  const { t, ready: isTReady } = useTranslation('authentication');
  // if we are not showing the instruction,
  // the assumption is that it is the main call-to-action
  const buttonFill = shouldShowInstruction ? 'outline' : 'solid';
  return isTReady ? (
    <>
      {shouldShowInstruction && (
        <IonItem lines="none" className="gap">
          <IonIcon icon={globeOutline} slot="start"></IonIcon>
          <IonNote color="dark" className="ion-text-wrap">
            {t('EmailLinkLoginSuccessComp_continueInBrowserInstructions')}
          </IonNote>
        </IonItem>
      )}
      <IonButton
        expand="block"
        className={`action${!shouldShowInstruction && ' gap'}`}
        onClick={onContinueInBrowserClicked}
        fill={buttonFill}
        size="default">
        {t('EmailLinkLoginSuccessComp_continueInBrowserButtonLabel')}
      </IonButton>
    </>
  ) : null;
};

const LoginSuccessNextActions: React.FC<{
  isStandaloneLoginFlow: boolean;
  onGetLoginCodeClicked: () => Promise<void>;
  onContinueInBrowserClicked: () => void;
}> = ({
  isStandaloneLoginFlow,
  onGetLoginCodeClicked,
  onContinueInBrowserClicked,
}) => {
  const { t, ready: isTReady } = useTranslation('authentication');
  return isTReady ? (
    <>
      {isStandaloneLoginFlow && (
        <>
          <IonItem lines="none" className="gap">
            <IonIcon icon={phonePortraitOutline} slot="start"></IonIcon>
            <IonNote color="dark" className="ion-text-wrap">
              {t('EmailLinkLoginSuccessComp_getCodeInstructions')}
            </IonNote>
          </IonItem>
          <ButtonWithSpinner
            expand="block"
            title={t('EmailLinkLoginSuccessComp_getCodeButtonLabel')}
            className="action"
            onClick={onGetLoginCodeClicked}
          />
        </>
      )}
      <ContinueInBrowserSection
        shouldShowInstruction={isStandaloneLoginFlow}
        onContinueInBrowserClicked={onContinueInBrowserClicked}
      />
    </>
  ) : null;
};

const LoginCodeComp: React.FC<{
  code: string;
  onContinueInBrowserClicked: () => void;
}> = ({ code, onContinueInBrowserClicked }) => {
  const { t, ready: isTReady } = useTranslation('authentication');
  const copyToClipboardButtonRef = useRef<HTMLIonButtonElement>(null);

  useEffect(() => {
    // on Safari it is only allowed to write to clipboard on user interaction
    // and not on the initial load
    // https://webkit.org/blog/10247/new-webkit-features-in-safari-13-1/
    if (isTReady && copyToClipboardButtonRef.current && !isSafari()) {
      copyToClipboardButtonRef.current.click();
    }
  }, [isTReady]);

  return isTReady ? (
    <>
      <>
        <IonItem lines="none" className="gap">
          <IonIcon icon={phonePortraitOutline} slot="start"></IonIcon>
          <IonNote color="dark" className="ion-text-wrap">
            {t('EmailLinkLoginSuccessComp_copyCodeInstructions')}
          </IonNote>
        </IonItem>
        <CopyToClipboardButton
          expand="block"
          ref={copyToClipboardButtonRef}
          clipboardContent={code}
          successToastMessage={t(
            'EmailLinkLoginSuccessComp_copyCodeSuccessMessage'
          )}
          errorToastMessage={t(
            'EmailLinkLoginSuccessComp_copyCodeErrorMessage'
          )}
          className="action"
          fill="solid"
          size="default">
          {code}
        </CopyToClipboardButton>
      </>
      <ContinueInBrowserSection
        shouldShowInstruction={true}
        onContinueInBrowserClicked={onContinueInBrowserClicked}
      />
    </>
  ) : null;
};

const getLoginSuccessMessageAsReactNode = (
  t: (i18nKey: string) => string,
  email: string
) => {
  const message = t('EmailLinkLoginSuccessComp_loginSuccessMessage').replace(
    '{email}',
    wrapWithBTag(email)
  );
  const renderedMessage = stringHtmlAsReactComp(message);
  return renderedMessage;
};
