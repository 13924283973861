import React from 'react';
import './Breadcrumbs.css';

interface BreadcrumbsProps {
  children: React.ReactNode;
  className?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ children, className }) => {
  const baseClassName = 'breadcrumbs';
  const classNames =
    className != undefined ? className + ' ' + baseClassName : baseClassName;
  return <nav className={classNames}>{children}</nav>;
};

export default Breadcrumbs;
