import { signInWithEmailLink } from 'firebase/auth';

import {
  holdAuthBroadcast,
  releaseAuthBroadcast,
} from './authStateChangeObserverWithManualRelease';

const signInWithAuthBroadcastHeld = async (
  auth: any,
  email: string,
  link: string
) => {
  holdAuthBroadcast();

  // return new Promise((resolve, reject) => {
  //     reject(new Error('Not implemented'));
  //   })
  return signInWithEmailLink(auth, email, link)
    .then(result => {
      // console.log(result);
    })
    .catch(error => {
      releaseAuthBroadcast();
      throw error;
    });
};

export default signInWithAuthBroadcastHeld;
