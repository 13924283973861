import { useEffect, useState } from 'react';
import {
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import '../Common/SolveMyProblemFlow.css';

import SolveMyProblemContent from '../Content/SolveMyProblemContent';

import { isDataLoadingResultSuccess } from '../../../Common/Types/DataLoadingResult';
import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import AppContentContainer from '../../../Common/AppContentContainer';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import { SolveMyProblemRoutes } from '../Common/getSolveMyProblemRoutes';
import { useAuthenticatedCustomer } from '../../Common/AuthorizedAccess/AuthenticatedCustomerContext';
import { getDeckIdsUserCanAccess } from '../../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import AuthorizedDecksContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDecksContentAccessContainer';
import provideChallengeBreadcrumbProps from '../Common/Breadcrumbs/provideChallengeBreadcrumbProps';
import SolveMyProblemListItem from '../Common/SolveMyProblemListItem/SolveMyProblemListItem';
import navigateToMissingSolutionForm, {
  MissingSolutionFormNavigationSource,
} from '../Common/MissingSolutionFeedback/navigateToMissingSolutionForm';
import composeTrackMissingSolutionActionWithPostHog, {
  TrackMissingSolutionActionPage,
} from '../Common/MissingSolutionFeedback/trackMissingSolutionAction';

type ChallengeListItem = {
  title: string;
  index: number;
  route: string;
};

const SolveMyProblemChallengePage: React.FC = () => {
  const [challenges, setChallenges] = useState<
    ChallengeListItem[] | null | undefined
  >([]);
  const title = SolveMyProblemContent.title;
  const customer = useAuthenticatedCustomer();
  const { t, ready: isTReady } = useTranslation('solve-my-problem');
  const router = useIonRouter();
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);
  const trackMissingSolutionAction =
    composeTrackMissingSolutionActionWithPostHog(postHog);

  const setDocumentTitle = () => {
    document.title = t('SolveMyProblemChallengePage_documentTitle');
  };

  function loadAndDisplayData(deckIdsUserCanAccess: Set<number>) {
    const challenges = SolveMyProblemContent.deckOptions
      .map((deckOption, deckOptionIndex) => {
        return {
          title: deckOption.deckLink,
          index: deckOptionIndex,
          deckId: Number(deckOption.deckId),
        };
      })
      .filter(challenge => deckIdsUserCanAccess.has(challenge.deckId))
      .map(challenge => {
        return {
          title: challenge.title,
          index: challenge.index,
          route: SolveMyProblemRoutes.focus.resolve(challenge.index.toString()),
        };
      });
    setChallenges(challenges);
  }

  useEffect(() => {
    if (!isTReady) {
      return;
    }

    const deckIdsUserCanAccess = getDeckIdsUserCanAccess(customer);
    if (deckIdsUserCanAccess) {
      loadAndDisplayData(deckIdsUserCanAccess);
    }
    // else either still loading or error handled by AuthorizedDecksContentAccessContainer
  }, [customer, isTReady]);

  useIonViewWillEnter(() => {
    setDocumentTitle();
  });

  return isTReady ? (
    <IonPage
      id="solve-my-problem-challenge"
      className="solve-my-problem-flow-container"
    >
      <AppContentContainer>
        <AuthorizedDecksContentAccessContainer>
          <Breadcrumbs>
            <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
            <TextBreadcrumb {...provideChallengeBreadcrumbProps(t, false)} />
          </Breadcrumbs>
          <IonList className="hotjar-adjust">
            <IonListHeader>
              <IonLabel>{title}</IonLabel>
            </IonListHeader>
            {challenges?.map((challenge, _) => (
              <SolveMyProblemListItem
                key={challenge.index}
                onClick={() => {
                  trackAction('select challenge in Solve My Problem', {
                    challengeTitle: challenge.title,
                  });
                  router.push(challenge.route);
                }}
                button
              >
                {challenge.title}
              </SolveMyProblemListItem>
            ))}
            <SolveMyProblemListItem
              key="1001"
              onClick={() => {
                trackMissingSolutionAction(
                  TrackMissingSolutionActionPage.Challenge
                );
                const customerEmail = isDataLoadingResultSuccess(customer)
                  ? customer.data?.email
                  : undefined;
                navigateToMissingSolutionForm(
                  MissingSolutionFormNavigationSource.Challenge,
                  customerEmail
                );
              }}
              button
            >
              {t('SolveMyProblemChallengePage_missingSolutionButtonTitle')}
            </SolveMyProblemListItem>
          </IonList>
        </AuthorizedDecksContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  ) : null;
};

export default SolveMyProblemChallengePage;
