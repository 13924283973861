import { PostHog } from 'posthog-js';
import {
  TrackUserIdentity,
  TrackedUserIdentity,
} from '../../Analytics/UserInfo/TrackUserIdentity';

const composeTrackUserIdentityWithPostHog = (
  postHog: PostHog
): TrackUserIdentity => {
  return (userIdentity: TrackedUserIdentity | null) => {
    if (userIdentity) {
      // because we identify invited users on PostHog with their email even before they sign in,
      // we are using their email as their identity
      const email = userIdentity.email;
      if (email) {
        postHog.identify(email, { email: userIdentity.email });
      }
      // we'd like to be able to track user by their id, because they might want to change their email.
      // given that the only way to change their email is for us to do it in the backend manually,
      // next time they login Posthog will treat them as a new user and reject their id as an alias :/
      // but in the future we can allow them to change their email through the app and at that point,
      // we can create an alias with with their new email.
      postHog.alias(userIdentity.id);
    }
  };
};

export default composeTrackUserIdentityWithPostHog;
