import { useIonToast } from '@ionic/react';
import { alertCircle, checkmarkCircleOutline } from 'ionicons/icons';
import './toast.css';

const useToast = () => {
  const [presentToast, dismissToast] = useIonToast();

  const presentToastWithContent = (
    message: string,
    style: 'error' | 'success',
    ref?: string | HTMLElement,
    position?: 'top' | 'bottom' | 'middle' | undefined
  ) => {
    const isError = style === 'error';

    return dismissToast()
      .then(() => delay(150))
      .then(() =>
        presentToast({
          message: message,
          duration: isError ? 3000 : 2000,
          position: position,
          positionAnchor: ref,
          icon: isError ? alertCircle : checkmarkCircleOutline,
          cssClass: isError ? 'error' : '',
        })
      );
  };

  return [presentToastWithContent, dismissToast] as const;
};

export default useToast;

const delay = (delayInMiliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, delayInMiliseconds));
