import { ReactNode } from 'react';
import { useSolveMyProblemAccess } from './SolveMyProblemAccessContext';

interface SolveMyProblemAccessContainerProps {
  children: ReactNode;
}

export const SolveMyProblemAccessContainer: React.FC<
  SolveMyProblemAccessContainerProps
> = ({ children }) => {
  const { canAccessSolveMyProblem } = useSolveMyProblemAccess();

  if (canAccessSolveMyProblem) {
    return <>{children}</>;
  }

  return null;
};
