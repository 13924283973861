import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { ScrollDetail, useIonViewWillLeave } from '@ionic/react';
import ScrolledToTopContent from './Navigation/ScrolledToTopContent';
import { checkIfStandaloneMode } from './Platform/StandaloneMode';
import PipLogoHeader from '../Headers/PipLogoHeader';
import BackButton from './Navigation/BackButton/BackButton';
import BackUpButton from './Navigation/BackUpButton/BackUpButton';

interface AppContentContainerProps {
  children: ReactNode;
  onContentScroll?: (
    ev: CustomEvent<ScrollDetail>,
    content: React.RefObject<HTMLIonContentElement>
  ) => void;
}

const AppContentContainer: React.FC<AppContentContainerProps> = ({
  children,
  onContentScroll,
}) => {
  const modeClassName = checkIfStandaloneMode()
    ? 'standalone-mode'
    : 'browser-mode';

  const contentRef = useRef<HTMLIonContentElement>(null);
  const upButtonRef = useRef<HTMLIonButtonElement>(null);
  const [isUpButtonVisible, seUpButtonVisible] = useState(false);

  const onContentScrollUpdateUpButtonVisibility = (
    ev: CustomEvent<ScrollDetail>
  ) => {
    if (contentRef.current) {
      const contentContainerHeight = contentRef.current.clientHeight;
      const scrollPositionFromTop = ev.detail.scrollTop;
      const isPastHalfScreenHeight =
        scrollPositionFromTop > contentContainerHeight / 2;
      seUpButtonVisible(isPastHalfScreenHeight);
    }
  };

  const onContentScrollComposition = (ev: CustomEvent<ScrollDetail>) => {
    onContentScrollUpdateUpButtonVisibility(ev);
    if (onContentScroll) {
      onContentScroll(ev, contentRef);
    }
  };

  useEffect(() => {
    if (!upButtonRef.current) {
      return;
    }

    const upButtonClassList = upButtonRef.current.classList;

    if (
      !isUpButtonVisible &&
      !upButtonClassList.contains('visible') &&
      !upButtonClassList.contains('hidden')
    ) {
      // prevent the unwanted initial hiding animation on page load
      // by not assigning classes until the button becomes visible
      // for the first time
      return;
    }

    if (isUpButtonVisible) {
      upButtonClassList.remove('hidden');
      upButtonClassList.add('visible');
    } else {
      upButtonClassList.add('hidden');
      upButtonClassList.remove('visible');
    }
  }, [isUpButtonVisible]);

  useIonViewWillLeave(() => {
    /* Ionic switches between pages by switching between display none & block
    so if the upButton was hidden on a previously rendered page which is now
    hidden, and we go back to this page, Ionic sets the page's display to block
    and this triggers the hidding animation on the upButton. The solution
    is to remove both classes from the button so there are no animations
    defined on it. */
    const upButtonClassList = upButtonRef.current?.classList;
    upButtonClassList?.remove('hidden', 'visible');
  });

  return (
    <>
      <ScrolledToTopContent
        ref={contentRef}
        onScroll={onContentScrollComposition}>
        <div className={modeClassName}>
          <PipLogoHeader />
          <div className="app-width">{children}</div>
        </div>
      </ScrolledToTopContent>
      <div className={modeClassName}>
        <BackButton className="floating-button circle-button bottom-button" />
        <BackUpButton
          ref={upButtonRef}
          className="floating-button circle-button bottom-button"
          contentRef={contentRef}
        />
      </div>
    </>
  );
};

export default AppContentContainer;
