import React from 'react';
import imgSrcSetOf from '../../../Common/UI/images/imgSrcSetOf';
import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';

import './HomeImageTitleCell.css';

interface HomeImageTitleCellProps {
  imageSource: string;
  title: string;
  onClick: () => void;
}

const HomeImageTitleCell: React.FC<HomeImageTitleCellProps> = ({
  imageSource,
  title,
  onClick,
}) => {
  return (
    <ClickableDiv className="home-image-title-cell" onClick={onClick}>
      <img
        src={imageSource}
        srcSet={imgSrcSetOf(imageSource)}
        className="image"
        aria-hidden="true"
      />
      <h3 className="title">{title}</h3>
    </ClickableDiv>
  );
};

export default HomeImageTitleCell;
