import { useEffect, useRef, useState } from 'react';
import { IonItem, IonLabel, IonList, IonListHeader } from '@ionic/react';
import CardDeckCategoryFinderPromptComp from './CardDeckCategoryFinderPromptComp';
import { CardDeckSystemCard } from './cardDeckSystemCards';
import CardDeckCategoryFinderCategoryAnswerComp from './CardDeckCategoryFinderCategoryAnswerComp';
import './CardDeckCategoryFinderComp.css';

export interface CardDeckCategoryFinderGoToCategoryButtonContent {
  title: string;
  iconCode: string;
  iconColor: string;
}

interface CardDeckCategoryFinderCompProps {
  systemCard: CardDeckSystemCard;
  getGoToCategoryButtonContent: (
    visiblePromptIndex: number,
    selectedButtonPerPrompt: (string | undefined)[]
  ) => CardDeckCategoryFinderGoToCategoryButtonContent | undefined;
  onGoToCategoryButtonTap: (
    visiblePromptIndex: number,
    selectedButtonPerPrompt: (string | undefined)[]
  ) => void;
}

const CardDeckCategoryFinderComp: React.FC<CardDeckCategoryFinderCompProps> = ({
  systemCard,
  getGoToCategoryButtonContent,
  onGoToCategoryButtonTap,
}) => {
  const [visiblePromptIndex, setVisiblePromptIndex] = useState(0);

  const [goToCategoryButtonTitle, setGoToCategoryButtonTitle] = useState('');
  const [goToCategoryButtonIconCode, setGoToCategoryButtonIconCode] =
    useState('');
  const [goToCategoryButtonIconColor, setGoToCategoryButtonIconColor] =
    useState('');
  const [isGoToCategoryButtonVisible, setGoToCategoryButtonVisible] =
    useState(false);

  const [selectedButtonPerPrompt, setSelectedButtonPerPrompt] = useState<
    (string | undefined)[]
  >([]);

  const prompts = systemCard.prompts;

  const isYesButtonSelected = (index: number) => {
    return selectedButtonPerPrompt[index] == 'yes';
  };

  const onYesButtonTap = (index: number) => {
    selectedButtonPerPrompt[index] = 'yes';
    setSelectedButtonPerPrompt(selectedButtonPerPrompt);
    if (shouldShowNextPromptOnYesButtonTap(index)) {
      setVisiblePromptIndex(index + 1);
    }
    const shouldShowCategoryButton =
      shouldShowCategoryButtonOnYesButtonTap(index);
    if (shouldShowCategoryButton) {
      updateGoToCategoryButtonContent();
    }
    setGoToCategoryButtonVisible(shouldShowCategoryButton);
  };

  const shouldShowNextPromptOnYesButtonTap = (promptIndex: number) => {
    return (
      promptIndex < prompts.length - 1 &&
      prompts[promptIndex + 1].prompt != undefined
    );
  };

  const shouldShowCategoryButtonOnYesButtonTap = (promptIndex: number) => {
    return (
      promptIndex == prompts.length - 2 &&
      prompts[promptIndex + 1].prompt == undefined &&
      prompts[promptIndex + 1].categoryId != undefined
    );
  };

  const isNoButtonSelected = (index: number) => {
    return selectedButtonPerPrompt[index] == 'no';
  };

  const onNoButtonTap = (index: number) => {
    updateSelectedButtonPerPromptOnNoButtonTap(index);
    if (index < prompts.length - 1) {
      setVisiblePromptIndex(index); // and subsequent rows will be hidden
    }
    updateGoToCategoryButtonContent();
    setGoToCategoryButtonVisible(true);
  };

  const updateSelectedButtonPerPromptOnNoButtonTap = (index: number) => {
    selectedButtonPerPrompt[index] = 'no';
    for (let i = index + 1; i < prompts.length; i++) {
      selectedButtonPerPrompt[i] = undefined;
    }
    setSelectedButtonPerPrompt(selectedButtonPerPrompt);
  };

  const updateGoToCategoryButtonContent = () => {
    const buttonProps = getGoToCategoryButtonContent(
      visiblePromptIndex,
      selectedButtonPerPrompt
    );
    if (buttonProps != undefined) {
      setGoToCategoryButtonTitle(buttonProps!.title);
      setGoToCategoryButtonIconCode(buttonProps.iconCode);
      setGoToCategoryButtonIconColor(buttonProps.iconColor);
    }
  };

  const resetState = () => {
    setVisiblePromptIndex(0);
    setSelectedButtonPerPrompt([]);
    setGoToCategoryButtonVisible(false);
  };
  useEffect(() => {
    updateGoToCategoryButtonContent();
  }, [visiblePromptIndex, isGoToCategoryButtonVisible]);

  useEffect(() => resetState, [systemCard]);

  const scrollIntoView = (element: HTMLElement | null) => {
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const listItemsRefs = useRef<{ [key: number]: HTMLElement | null }>({});
  useEffect(() => {
    if (visiblePromptIndex == 0) {
      return;
      // otherwise may cause unexpected scroll on page appear
      // because of the resetState() call
    }
    scrollIntoView(listItemsRefs.current[visiblePromptIndex]);
  }, [visiblePromptIndex]);

  let categoryButtonRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (isGoToCategoryButtonVisible) {
      scrollIntoView(categoryButtonRef.current);
    }
  }, [isGoToCategoryButtonVisible]);

  return (
    <div className="prompts-container bottom-buttons-margin-adjust responsive-centered-container white-background">
      {/* highlight buttons as selected 
      - all revealed list items but the last one should have the Yes button .selected */}
      {prompts.map((item, index) => {
        if (item.prompt === undefined) {
          return '';
        }
        return (
          <CardDeckCategoryFinderPromptComp
            key={index}
            index={index}
            text={item.prompt!}
            isItemVisible={() => index <= visiblePromptIndex}
            areButtonsVisible={() =>
              item.categoryId != undefined && item.prompt != undefined
            }
            isYesButtonSelected={() => isYesButtonSelected(index)}
            onYesButtonTap={onYesButtonTap}
            isNoButtonSelected={() => isNoButtonSelected(index)}
            onNoButtonTap={onNoButtonTap}
            setListItemElementRef={(el: HTMLElement | null) =>
              (listItemsRefs.current[index] = el)
            }
          />
        );
      })}
      <CardDeckCategoryFinderCategoryAnswerComp
        title={goToCategoryButtonTitle}
        iconCode={goToCategoryButtonIconCode}
        iconColor={goToCategoryButtonIconColor}
        isVisible={isGoToCategoryButtonVisible}
        onTap={() =>
          onGoToCategoryButtonTap(visiblePromptIndex, selectedButtonPerPrompt)
        }
        setRef={element => (categoryButtonRef.current = element)}
      />
    </div>
  );
};

export default CardDeckCategoryFinderComp;
