import React from 'react';

import './CategoryCell.css';

import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';
import FontIconComp from '../../../Common/UI/Icons/FontIconComp';

interface CategoryCellProps {
  title: string;
  iconColor?: string;
  iconCode: string;
  onClick: () => void;
}

const CategoryCell: React.FC<CategoryCellProps> = ({
  title,
  iconColor,
  iconCode,
  onClick,
}) => {
  return (
    <ClickableDiv className="category-cell" onClick={onClick}>
      <FontIconComp
        className="pip-icon image"
        color={iconColor}
        iconCode={iconCode}
      />
      <h3 className="title">{title}</h3>
    </ClickableDiv>
  );
};

export default CategoryCell;
