/**
 * 'ion-valid' and 'ion-invalid' are used to indicate if the input is valid or not
 * by coloring the input field green or red, respectively. But the coloring appears
 * only when the `ion-touched` is present, too.
 */
const getIonInputClasses = (
  isValid: boolean | undefined,
  isTouched: boolean
) => {
  return `${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${
    isTouched && 'ion-touched'
  }`;
};

/**
 * Use this function instead of 'getIonInputClasses()' when you want the input field
 * to be colored only when it is invalid.
 */
const getInvalidOnlyIonInputClasses = (
  isValid: boolean | undefined,
  isTouched: boolean
) => {
  return `${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`;
};

export { getIonInputClasses, getInvalidOnlyIonInputClasses };
