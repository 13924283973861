import React, { createContext, useContext, useEffect, useState } from 'react';

interface NetworkStatusContextProps {
  isOffline: boolean;
}

const NetworkStatusContext = createContext<NetworkStatusContextProps>({
  isOffline: false,
});

interface NetworkStatusProviderProps {
  children: React.ReactNode;
  getInitialOnlineStatus?: () => boolean;
  addOnlineStatusListeners?: (
    onStatusOnline: () => void,
    onStatusOffline: () => void
  ) => () => void;
}

const NetworkStatusProvider: React.FC<NetworkStatusProviderProps> = ({
  children,
  getInitialOnlineStatus = () => true,
  addOnlineStatusListeners = () => () => {},
}) => {
  const [isOffline, setIsOffline] = useState<boolean>(
    !getInitialOnlineStatus()
  );

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    const removeListeners = addOnlineStatusListeners(
      handleOnline,
      handleOffline
    );

    return removeListeners;
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOffline }}>
      {children}
    </NetworkStatusContext.Provider>
  );
};

const useNetworkStatus = () => useContext(NetworkStatusContext);

export { NetworkStatusContext, NetworkStatusProvider, useNetworkStatus };
