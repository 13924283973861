export function replaceHtmlTagsInObjectProperties(
  obj: any,
  keyPaths: string[],
  replaceValue: string = ''
): any {
  const clonedObj = deepClone(obj);

  for (const keyPath of keyPaths) {
    const keyPathParts = keyPath.split('.');
    replaceHtmlTagsAtKeyPath(clonedObj, keyPathParts, replaceValue);
  }

  return clonedObj;
}

function replaceHtmlTagsAtKeyPath(
  obj: any,
  keyPathParts: string[],
  replaceValue: string
): void {
  const key = keyPathParts[0];

  if (!obj || !obj.hasOwnProperty(key)) return;

  if (Array.isArray(obj[key])) {
    for (const item of obj[key]) {
      replaceHtmlTagsAtKeyPath(item, keyPathParts.slice(1), replaceValue);
    }
  } else if (typeof obj[key] === 'object' && obj[key] !== null) {
    replaceHtmlTagsAtKeyPath(obj[key], keyPathParts.slice(1), replaceValue);
  } else if (typeof obj[key] === 'string') {
    obj[key] = obj[key].replace(/<[^>]+>/g, replaceValue);
  }
}

function deepClone(obj: any): any {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepClone);
  }

  const clonedObj: { [key: string]: any } = {};
  for (const key in obj) {
    clonedObj[key] = deepClone(obj[key]);
  }

  return clonedObj;
}
