import React from 'react';
import ListItemChevron from '../../Icons/ListItemChevron';
import './LabelChevronView.css';

type LabelChevronProps = {
  label: string;
  alignment?: 'start' | 'center';
};

/**
 * Displays a label with a chevron icon at the end. If the label contains multiple words
 * and the label wraps into multiple lines, the chevron will stick with the last word.
 */
const LabelChevronView: React.FC<LabelChevronProps> = ({
  label,
  alignment,
}) => {
  const words = label.split(/[\s]/);

  return (
    <span
      className="label-chevron-view"
      style={{ justifyContent: alignment ?? 'start' }}>
      {
        <>
          {words.map((word, index) => {
            const isLastWord = index === words.length - 1;
            return isLastWord ? (
              wrapInSpanAndAppendChevron(word)
            ) : (
              <span key={index}>
                {word}
                {breakingSpaceCharacter}
              </span>
            );
          })}
        </>
      }
    </span>
  );
};

export default LabelChevronView;

const breakingSpaceCharacter = '\u00A0';

const wrapInSpanAndAppendChevron = (word: string) => {
  return (
    <span className="last-word" key="10000">
      {word}
      <span className="icon">
        <ListItemChevron />
      </span>
    </span>
  );
};
