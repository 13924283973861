import { IonList } from '@ionic/react';
import { CardDeckRecipeCardItem } from './cardDeckRecipeCard';
import CardDeckRecipeCardLinkedCardsListItemComp from './CardDeckRecipeCardLinkedCardsListItemComp';
import { CardDeckRecipeCardLinkedCardsListItemTitleStyle } from './CardDeckRecipeCardPage';

interface CardDeckRecipeCardLinkedCardsListCompProps {
  cards: CardDeckRecipeCardItem[];
  listItemTitleStyle: CardDeckRecipeCardLinkedCardsListItemTitleStyle;
}

const CardDeckRecipeCardLinkedCardsListComp: React.FC<
  CardDeckRecipeCardLinkedCardsListCompProps
> = ({ cards, listItemTitleStyle }) => {
  return (
    <IonList lines="none">
      {cards.map((card, index) => (
        <CardDeckRecipeCardLinkedCardsListItemComp
          key={index}
          card={card}
          index={index}
          listItemTitleStyle={listItemTitleStyle}
        />
      ))}
    </IonList>
  );
};

export default CardDeckRecipeCardLinkedCardsListComp;
