import React from 'react';
import { IonButton } from '@ionic/react';
import LabelChevronView from '../Views/LabelChevronView/LabelChevronView';
import './PageSectionHeaderComp.css';

interface PageSectionHeaderCompButtonProps {
  title: string;
  onClick: () => void;
  // false if undefined
  isVisibleOnMobileOnly?: boolean;
}

interface PageSectionHeaderCompProps {
  title: React.ReactNode;
  buttonProps?: PageSectionHeaderCompButtonProps;
}

const PageSectionHeaderComp: React.FC<PageSectionHeaderCompProps> = ({
  title,
  buttonProps,
}) => {
  const buttonClassName = buttonProps?.isVisibleOnMobileOnly
    ? 'mobile-only'
    : '';
  return (
    <div className="section-header">
      <h2 className="title">{title}</h2>
      {buttonProps && (
        <IonButton onClick={buttonProps.onClick} className={buttonClassName}>
          <LabelChevronView label={buttonProps.title}></LabelChevronView>
        </IonButton>
      )}
    </div>
  );
};

export default PageSectionHeaderComp;
