import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  IonPage,
  useIonViewWillEnter,
  useIonRouter,
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { flash } from 'ionicons/icons';

import './CardDeckHomePage.css';

import AppContentContainer from '../../Common/AppContentContainer';
import Breadcrumb from '../../Common/Navigation/Breadcrumbs/Breadcrumb';
import Breadcrumbs from '../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import PageSectionHeaderComp from '../../Common/UI/Sections/PageSectionHeaderComp';
import LabelWithHelpPopover from '../../Common/UI/Views/LabelWithHelpPopover/LabelWithHelpPopover';
import renderPageIfModelDefinedOrNotFoundPage from '../../Common/Pages/pageIfModelDefinedOrNotFoundPage';
import { CardDeckRoutes } from '../../cardDecksRoutes';

import HomeBreadcrumb from '../Common/Breadcrumbs/PipLogoBreadcrumb';
import provideHomeBreadcrumbProps from '../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import AuthorizedDeckContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';
import CardCell from '../Common/CardCell/CardCell';
import DownloadsSection from '../Common/Downloads/DownloadsSection';
import { getCardDeckByIdString } from '../Home/cardDecks';
import {
  CardDeckCardListItem,
  getCardDeckCategoriesWithCards,
  getCardDeckRecipes,
} from '../CardDeckCardList/cardDeckCardListSections';

import {
  CardDeckCategory,
  getCardDeckCategories,
} from '../CardDeckCategories/cardDeckCategories';

import CategoryCell from './Components/CategoryCell';
import CardDeckHomeIconTextNavItem from './Components/CardDeckHomeIconTextNavItem';
import LinkCell from './Components/LinkCell';

const getCardDeckTitle = (deckId: string) => {
  return getCardDeckByIdString(deckId)?.title;
};

const setDocumentTitle = (deckId: string) => {
  const documentTitle = getCardDeckTitle(deckId);
  if (documentTitle) {
    document.title = documentTitle;
  }
};

const cardGridColumnSizeSm = '4';
const cardGridColumnSizeXs = '12';

const CardDeckHomePage: React.FC = () => {
  // console.log('Navigation page start');
  const { deckId } = useParams<{ deckId: string }>();
  const deckIdNumber = Number(deckId);
  const deck = getCardDeckByIdString(deckId) ?? null;
  const [categories, setCategories] = useState<
    CardDeckCategory[] | null | undefined
  >(undefined);
  const [recipes, setRecipes] = useState<
    CardDeckCardListItem[] | null | undefined
  >(undefined);
  const [tactics, setTactics] = useState<
    CardDeckCardListItem[] | null | undefined
  >(undefined);
  const router = useIonRouter();
  const { t, ready: isTReady } = useTranslation('decks');

  const tacticsRoute =
    CardDeckRoutes.cardDeckTacticCardList.resolve(deckIdNumber);
  const categoryFinderRoute =
    CardDeckRoutes.cardDeckCategoryFinder.resolve(deckIdNumber);

  const getCategoryRoute = (deckId: number, categoryId: number) => {
    return CardDeckRoutes.cardDeckCategoryTacticCardList.resolve(
      deckId,
      categoryId
    );
  };
  const onRecipeCardClick = (recipeId: number) => {
    router.push(
      CardDeckRoutes.cardDeckRecipeCard.resolve(deckIdNumber, recipeId)
    );
  };
  const onTacticCardClick = (tacticId: number) => {
    router.push(
      CardDeckRoutes.cardDeckTacticCard.resolve(deckIdNumber, tacticId)
    );
  };
  const loadAndDisplayData = (deckId: string) => {
    const categories = getCardDeckCategories(deckId) ?? null;
    setCategories(categories);
    const recipes = getCardDeckRecipes(deckId)?.[0].cards ?? null;
    setRecipes(recipes);
    const tactics =
      getCardDeckCategoriesWithCards(deckId)?.flatMap(
        section => section.cards
      ) ?? null;
    const tacticsSubset = tactics?.slice(0, 5);
    setTactics(tacticsSubset);
  };

  useIonViewWillEnter(() => {
    loadAndDisplayData(deckId);
    setDocumentTitle(deckId);
  }, [deckId]);

  return renderPageIfModelDefinedOrNotFoundPage(
    deck,
    isTReady ? (
      <IonPage id="card-deck-home">
        <AppContentContainer>
          <AuthorizedDeckContentAccessContainer deckId={deckId}>
            <Breadcrumbs>
              <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
              <Breadcrumb>{getCardDeckTitle(deckId)}</Breadcrumb>
            </Breadcrumbs>
            <PageSectionHeaderComp
              title={t('CardDeckHomePage_CategoriesSectionTitle')}
            />
            <IonGrid className="categories-grid">
              <IonRow>
                <IonCol size="12" sizeMd="6">
                  <CardDeckHomeIconTextNavItem
                    icon={<IonIcon icon={flash} />}
                    text={t('CardDeckHomePage_CategoryFinderButtonTitle')}
                    onClick={() => router.push(categoryFinderRoute)}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                {categories?.map(category => (
                  <IonCol size="6" sizeMd="3" key={category.id}>
                    <CategoryCell
                      title={category.title}
                      iconCode={category.iconCode}
                      iconColor={category.color}
                      onClick={() =>
                        router.push(getCategoryRoute(deckIdNumber, category.id))
                      }
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
            <PageSectionHeaderComp
              title={
                <LabelWithHelpPopover
                  id="recipes-popover"
                  label={t('CardDeckHomePage_RecipesSectionTitle')}
                  popoverText={t('CardDeckHomePage_RecipesDescription')}
                />
              }
            />
            <IonGrid className="cards-grid">
              <IonRow className="scrollable-row">
                {recipes?.map(recipe => (
                  <IonCol
                    sizeSm={cardGridColumnSizeSm}
                    sizeXs={cardGridColumnSizeXs}
                    className="scrollable-column"
                    key={recipe.id}
                  >
                    <CardCell
                      title={recipe.title}
                      imageSource={recipe.imageUrl}
                      description={recipe.description}
                      onClick={() => onRecipeCardClick(recipe.id)}
                      categoryColor="var(--colour-brand-black)"
                    />
                  </IonCol>
                ))}
              </IonRow>
            </IonGrid>
            <PageSectionHeaderComp
              title={
                <LabelWithHelpPopover
                  id="tactics-popover"
                  label={t('CardDeckHomePage_TacticsSectionTitle')}
                  popoverText={t('CardDeckHomePage_TacticsDescription')}
                />
              }
              buttonProps={{
                title: t('CardDeckHomePage_TacticsSectionViewAllButtonTitle'),
                onClick: () => router.push(tacticsRoute),
                isVisibleOnMobileOnly: true,
              }}
            />
            <IonGrid className="cards-grid">
              <IonRow className="scrollable-row">
                {tactics?.map(tactic => (
                  <IonCol
                    sizeSm={cardGridColumnSizeSm}
                    sizeXs={cardGridColumnSizeXs}
                    className="scrollable-column"
                    key={tactic.id}
                  >
                    <CardCell
                      title={tactic.title}
                      imageSource={tactic.imageUrl}
                      description={tactic.description}
                      onClick={() => onTacticCardClick(tactic.id)}
                      categoryColor={tactic.style.borderColor}
                    />
                  </IonCol>
                ))}
                <IonCol
                  sizeSm={cardGridColumnSizeSm}
                  sizeXs={cardGridColumnSizeXs}
                  className="scrollable-column"
                >
                  <LinkCell
                    imageSource="/images/deck-home/illustrations/pip-library-shelf.png"
                    title={t('CardDeckHomePage_TacticsViewAllButtonTitle')}
                    route={tacticsRoute}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
            {deck && (
              <DownloadsSection
                deckTitle={deck.title}
                deckPdfUrl={deck.pdfDownloadUrl}
                deckImagesArchiveUrl={deck.imagesArchiveDownloadUrl}
              />
            )}
            <div className="bottom-buttons-margin-adjust"></div>
          </AuthorizedDeckContentAccessContainer>
        </AppContentContainer>
      </IonPage>
    ) : (
      <></>
    )
  );
};

export default CardDeckHomePage;
