import { useTranslation } from 'react-i18next';

import { getCardDeck } from '../../../Home/cardDecks';

import UnauthorizedAccessCallToActionComp from './UnauthorizedAccessCallToActionComp';

interface PurchaseDeckAccessCallToActionProps {
  deckId: string;
}

const PurchaseDeckAccessCallToAction: React.FC<
  PurchaseDeckAccessCallToActionProps
> = ({ deckId }) => {
  const { t, ready: isTReady } = useTranslation('content-access');

  const deck = getCardDeck(Number(deckId));
  const deckTitle = deck?.title;
  const deckShopUrl = deck?.shopUrl;

  return deck && isTReady ? (
    <UnauthorizedAccessCallToActionComp
      imageSrc={deck.imageUrl}
      imageWidth="140px"
      title={t('PurchaseDeckAccessCallToAction_Title', {
        deckTitle: deckTitle,
      })}
      body={t('PurchaseDeckAccessCallToAction_Body')}
      buttonTitle={t('PurchaseDeckAccessCallToAction_ShopButton')}
      onButtonClick={() => {
        window.open(deckShopUrl, '_blank');
      }}
    />
  ) : null;
};

export default PurchaseDeckAccessCallToAction;
