import { onAuthStateChanged } from 'firebase/auth';
import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';
import { User } from '../User';

let currentUser: User | null = null;
let onCurrentUserChanged: ((user: User | null) => void) | null;
let isCurrentUserBroadcastBlocked = false;

const addAuthStateChangeObserverWithManualRelease = (
  onUserChanged: (user: User | null) => void
): (() => void) => {
  onCurrentUserChanged = onUserChanged;
  const auth = getFirebaseAuth();
  const unsubscribe = onAuthStateChanged(auth, user => {
    // console.log('auth changed', user);
    // console.log('isCurrentUserBroadcastBlocked', isCurrentUserBroadcastBlocked);
    currentUser = (user && { id: user.uid, email: user.email }) || null;
    if (!isCurrentUserBroadcastBlocked) {
      onUserChanged(currentUser);
    }
  });

  return () => {
    currentUser = null;
    onCurrentUserChanged = null;
    unsubscribe();
  };
};

const holdAuthBroadcast = () => {
  isCurrentUserBroadcastBlocked = true;
};

const releaseAuthBroadcast = () => {
  isCurrentUserBroadcastBlocked = false;
  onCurrentUserChanged && onCurrentUserChanged!(currentUser);
};

export {
  addAuthStateChangeObserverWithManualRelease,
  holdAuthBroadcast,
  releaseAuthBroadcast,
};
