import React from 'react';
import { useTranslation } from 'react-i18next';

import TrackedAnchor from '../../../../Analytics/UserActivity/TrackedAnchor';
import EnhancedHtmlToReactComp from '../../../../Common/EnhancedHtmlToReactComp';
import { GuideLink } from '../GuideLink';

import './CardDeckCardResourcesComp.css';

type WhiteboardTemplatesContent = {
  subheading?: string;
  miroBoardId?: string;
  muralTemplateId?: string;
};

interface CardDeckCardResourcesProps {
  videoHtml?: string;
  whiteboardTemplatesContent?: WhiteboardTemplatesContent;
  guides?: GuideLink[];
  cardTrackingDescription: string;
}

const CardDeckCardResources: React.FC<CardDeckCardResourcesProps> = ({
  videoHtml,
  whiteboardTemplatesContent,
  guides,
  cardTrackingDescription,
}) => {
  const { t, ready: isTReady } = useTranslation('decks');
  if (!isTReady) {
    return null;
  }

  const trimmedVideoHtml = videoHtml && videoHtml.trim();
  const videoBlock =
    trimmedVideoHtml && trimmedVideoHtml.length > 0 ? (
      <Resource title={t('CardDeckCardResources_VideoWalktrhough_title')}>
        <EnhancedHtmlToReactComp
          html={videoHtml!}
          shouldOpenImagesInFullScreen={false}
        />
      </Resource>
    ) : null;

  const miroBoardId = whiteboardTemplatesContent?.miroBoardId;
  const doesHaveAMiroBoardBlock = miroBoardId && miroBoardId.length > 0;
  const miroBoardBlock = doesHaveAMiroBoardBlock ? (
    <WhiteboardComp
      title={t('CardDeckCardResources_Whiteboards_navigateButtonTitle', {
        serviceName: 'Miro',
      })}
      iconPath="/images/whiteboard-templates/miro-logo.png"
      url={`https://miro.com/app/board/${miroBoardId}/`}
      trackingDescription={`${cardTrackingDescription} - Miro`}
    />
  ) : null;

  const muralTemplateId = whiteboardTemplatesContent?.muralTemplateId;
  const doesHaveAMuralTemplateBlock =
    muralTemplateId && muralTemplateId.length > 0;
  const muralTemplateBlock = doesHaveAMuralTemplateBlock ? (
    <WhiteboardComp
      title={t('CardDeckCardResources_Whiteboards_navigateButtonTitle', {
        serviceName: 'Mural',
      })}
      iconPath="/images/whiteboard-templates/mural-logo.png"
      url={`https://app.mural.co/template/${muralTemplateId}`}
      trackingDescription={`${cardTrackingDescription} - Mural`}
    />
  ) : null;

  const doesHaveAnyWhiteboards =
    doesHaveAMiroBoardBlock || doesHaveAMuralTemplateBlock;

  const whiteboardsSubheading =
    whiteboardTemplatesContent?.subheading ??
    t('CardDeckCardResources_Whiteboards_headline');
  const whiteboardsBlock = doesHaveAnyWhiteboards ? (
    <Resource title={t('CardDeckCardResources_Whiteboards_title')}>
      <p>{whiteboardsSubheading}</p>
      <div className="whiteboard-templates">
        {miroBoardBlock}
        {muralTemplateBlock}
      </div>
    </Resource>
  ) : null;

  const guidesBlock =
    guides && guides.length > 0 ? (
      <Resource title={t('CardDeckCardResources_RecipeGuides_title')}>
        <p>{t('CardDeckCardResources_RecipeGuides_headline')}</p>
        <ul className="guides-list">
          {guides.map((guide, index) => (
            <li key={index} className="guides-list-item">
              <img src={guide.imageUrl} className="guides-list-item-image" />
              <div className="guides-list-item-content">
                <img
                  src={guide.imageUrl}
                  className="guides-list-item-inline-image"
                />
                <span className="guides-list-item-title">
                  <EnhancedHtmlToReactComp
                    html={guide.titleHtml}
                    shouldOpenImagesInFullScreen={false}
                  />
                </span>
                <div className="guides-list-item-excerpt">
                  <EnhancedHtmlToReactComp
                    html={guide.excerptHtml}
                    shouldOpenImagesInFullScreen={false}
                  />
                </div>
                <TrackedAnchor
                  linkDescription={getGuideLinkTrackingDescription(
                    cardTrackingDescription,
                    guide
                  )}
                  href={guide.link}
                  className="content-link">
                  {t('CardDeckCardResources_RecipeGuides_navigateButtonTitle')}
                </TrackedAnchor>
              </div>
            </li>
          ))}
        </ul>
      </Resource>
    ) : null;

  return (
    (guidesBlock || videoBlock || whiteboardsBlock) && (
      <div className="card-resources">
        <h2>{t('CardDeckCardResources_title')}</h2>
        {videoBlock}
        {whiteboardsBlock}
        {guidesBlock}
      </div>
    )
  );
};

const getGuideLinkTrackingDescription = (
  cardTrackingDescription: string,
  guide: GuideLink
) => {
  const strippedGuideTitle = guide.titleHtml.replace(/(<([^>]+)>)/gi, '');
  return `${cardTrackingDescription} - Guide: ${strippedGuideTitle}`;
};

export default CardDeckCardResources;

interface ResourceProps {
  title: string;
  children: React.ReactNode;
}

const Resource: React.FC<ResourceProps> = ({ title, children }) => {
  return (
    <div className="card-resource">
      <h3>{title}</h3>
      {children}
    </div>
  );
};

interface WhiteboardCompProps {
  title: string;
  iconPath: string;
  url: string;
  trackingDescription?: string;
}

const WhiteboardComp: React.FC<WhiteboardCompProps> = ({
  title,
  iconPath,
  url,
  trackingDescription,
}) => {
  return (
    <div className="whiteboard-template">
      <TrackedAnchor
        linkDescription={trackingDescription}
        href={url}
        target="_blank"
        className="content-link">
        <img src={iconPath} className="logo" />
        <div>{title}</div>
      </TrackedAnchor>
    </div>
  );
};
