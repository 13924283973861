import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentLoadingErrorComp from '../../../../Common/ContentLoading/ContentLoadingErrorComp';

interface AuthenticatedCustomerLoadingErrorCompProps {
  onRetryButtonClick: () => void;
}

const AuthenticatedCustomerLoadingErrorComp: React.FC<
  AuthenticatedCustomerLoadingErrorCompProps
> = ({ onRetryButtonClick }) => {
  const { t, ready: isTReady } = useTranslation('content-access');

  return isTReady ? (
    <ContentLoadingErrorComp
      descriptionHtml={t('AuthenticatedCustomerLoadingErrorComp_description')}
      buttonText={t('AuthenticatedCustomerLoadingErrorComp_buttonTitle')}
      onRetryButtonClick={onRetryButtonClick}
    />
  ) : null;
};

export default AuthenticatedCustomerLoadingErrorComp;
