import { ReactNode } from 'react';
import HtmlToReactComp, { DOMNode } from './UI/HTML/HtmlToReactComp';
import VimeoVideoPlayer from './Vimeo/VimeoVideoPlayer';
import RouterLink from './Navigation/Link/RouterLink';

interface EnhancedHtmlToReactCompProps {
  html: string;
  shouldOpenImagesInFullScreen: boolean;
}

const EnhancedHtmlToReactComp: React.FC<EnhancedHtmlToReactCompProps> = ({
  html,
  shouldOpenImagesInFullScreen,
}) => {
  const preprocessingInstructions = [
    // force external links to open in a new tab
    {
      shouldPreprocessNode: (node: DOMNode) =>
        node.name === 'a' &&
        !node.attribs.href.startsWith('/') &&
        !node.attribs.target,
      preprocessNode: (node: DOMNode) => {
        node.attribs.target = 'blank';
      },
    },
    // vary the tip icon
    {
      shouldPreprocessNode: (node: DOMNode) => node.name === 'p',
      preprocessNode: (node: DOMNode) => {
        if (
          node.attribs &&
          node.attribs.class &&
          node.attribs.class.includes('tip')
        ) {
          node.attribs.class += ` ${randomTipImageClass()}`;
        }
      },
    },
  ];

  let processingInstructions = [
    // transform internal links into router links
    {
      shouldProcessNode: (node: DOMNode) =>
        node.name === 'a' && node.attribs.href.startsWith('/'),

      processNode: (node: DOMNode, children: ReactNode[], index: number) => {
        return (
          <RouterLink
            key={index}
            href={node.attribs.href}
            className="contentLink">
            {children}
          </RouterLink>
        );
      },
    },
    // transform vimeo tags into vimeo components
    {
      shouldProcessNode: (node: DOMNode) =>
        node.name === 'vimeo-video' &&
        node.attribs.id != undefined &&
        node.attribs.id.trim().length > 0,

      processNode: (node: DOMNode, children: ReactNode[], index: number) => {
        const vimeoVideoId = node.attribs.id;
        const vimeoVideoPrivacyHash = (node.attribs as any)['hash'];
        return (
          <VimeoVideoPlayer
            videoId={vimeoVideoId}
            videoPrivacyHash={vimeoVideoPrivacyHash}
            key={vimeoVideoId}
          />
        );
      },
    },
  ];

  // wrap img elements in links to open the image in a new tab ("full screen mode")
  // but exclude images marked with the "illustration" class
  const openImageInFullScreenProcessingInstruction = {
    shouldProcessNode: (node: DOMNode) =>
      node.name === 'img' &&
      node.attribs.src != undefined &&
      (node.attribs.class == undefined ||
        !node.attribs.class.split(' ').includes('illustration')),

    processNode: (node: DOMNode, children: ReactNode[], index: number) => {
      return (
        <a href={node.attribs.src} target="_blank">
          <img
            src={node.attribs.src}
            style={styleStringToObject(node.attribs.style)}
          />
        </a>
      );
    },
  };

  if (shouldOpenImagesInFullScreen) {
    processingInstructions.push(openImageInFullScreenProcessingInstruction);
  }

  return (
    <HtmlToReactComp
      html={html}
      processingInstructions={processingInstructions}
      preprocessingInstructions={preprocessingInstructions}
    />
  );
};

export default EnhancedHtmlToReactComp;

const styleStringToObject = (styleString?: string): React.CSSProperties => {
  if (!styleString) return {};

  return styleString
    .split(';')
    .map(s => s.trim())
    .filter(s => s)
    .reduce<React.CSSProperties>((acc, curr) => {
      const [key, value] = curr.split(':').map(s => s.trim());
      const camelCaseKey = key.replace(/-([a-z])/g, g => g[1].toUpperCase());
      (acc as any)[camelCaseKey] = value;
      return acc;
    }, {});
};

const randomTipImageClass = () => {
  const tipImageClasses = ['tip-frodge', 'tip-pip', 'tip-blob'];
  const randomIndex = Math.floor(Math.random() * tipImageClasses.length);
  return tipImageClasses[randomIndex];
};
