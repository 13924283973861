import React from 'react';

const ChevronIcon: React.FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="chevron-icon"
    stroke="currentcolor"
    viewBox="0 0 512 512">
    <path
      style={{ fill: 'none' }}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="48"
      d="M184 112l144 144-144 144"></path>
  </svg>
);

export default ChevronIcon;
