import { FirebaseError } from 'firebase/app';
import {
  AddCardBookmark,
  Bookmark,
  ListenBookmarksError,
  ListenCardBookmarks,
  RemoveCardBookmark,
} from '../../../CardDecks/CardDeckBookmarks/bookmarksStorage';
import { mapArrayResult, Result } from '../../../Common/Types/Result';
import {
  composeListenWithErrorLogging,
  composePromiseReturningFuncWithErrorLogging,
} from '../../../ErrorHandling/composeWithErrorLogging';
import {
  addCardBookmark,
  FirestoreBookmark,
  isHandledAddBookmarkFirebaseError,
  isHandledListenBookmarksFirebaseError,
  isHandledRemoveBookmarkFirebaseError,
  listenFirebaseCardBookmarks,
  mapFirebaseErrorToListenBookmarksError,
  mapFirestoreBookmarkToBookmark,
  removeCardBookmark,
} from '../../../External/Firebase/FirebaseBookmarksStorage';
import logErrorWithSentry from '../../../External/Sentry/logErrorWithSentry';

const mapFirebaseResultToBookmarksResult = (
  result: Result<[string, FirestoreBookmark][] | null, unknown>
): Result<Bookmark[] | null, ListenBookmarksError> => {
  return mapArrayResult(
    result,
    mapFirestoreBookmarkToBookmark,
    mapFirebaseErrorToListenBookmarksError
  );
};

export const composeListenCardBookmarksWithRemoteErrorLogging =
  (): ListenCardBookmarks => {
    return composeListenWithErrorLogging(
      listenFirebaseCardBookmarks,
      logErrorWithSentry,
      isHandledListenBookmarksFirebaseError,
      mapFirebaseResultToBookmarksResult
      // new FirebaseError('auth/network-request-failed', 'Network request failed')
    );
  };

export const composeAddCardBookmarkWithRemoteErrorLogging =
  (): AddCardBookmark => {
    return composePromiseReturningFuncWithErrorLogging(
      addCardBookmark,
      logErrorWithSentry,
      isHandledAddBookmarkFirebaseError
      // new FirebaseError('auth/network-request-failed', 'Network request failed')
    );
  };

export const composeRemoveCardBookmarkWithRemoteErrorLogging =
  (): RemoveCardBookmark => {
    return composePromiseReturningFuncWithErrorLogging(
      removeCardBookmark,
      logErrorWithSentry,
      isHandledRemoveBookmarkFirebaseError
      // new FirebaseError(
      //   'auth/network-request-faileds',
      //   'Network request failed'
      // )
    );
  };
