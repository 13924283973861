import React from 'react';
import { useTranslation } from 'react-i18next';

import ExitIcon from '/public/images/icons/exit.svg';

import IconButton from '../../Common/UI/Buttons/IconButton';
import { useAuthenticatedUser } from '../AuthenticatedUserContext';
import { useLogout } from './useLogout';

type LogOutButtonProps = {
  size?: 'small' | 'default';
};

const LogOutButton: React.FC<LogOutButtonProps> = ({
  size = 'default',
}): JSX.Element => {
  const user = useAuthenticatedUser();
  const { t, ready: isTReady } = useTranslation('authentication');
  const handleLogout = useLogout();

  return isTReady && user ? (
    <IconButton
      id="logout-button"
      iconSrc={ExitIcon}
      className="logout-button"
      size={size}
      onClick={handleLogout}
    />
  ) : (
    <div></div>
  );
};

export default LogOutButton;
