import { cartOutline } from 'ionicons/icons';
import { usePostHog } from 'posthog-js/react';

import IconButton from '../Common/UI/Buttons/IconButton';
import composeTrackUserActionWithPostHog from '../External/PostHog/trackUserActionWithPostHog';

import './ShopButton.css';

const ShopButton: React.FC = () => {
  const posthog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(posthog);

  return (
    <IconButton
      className="shop-button"
      iconSrc={cartOutline}
      onClick={() => {
        trackAction('navigate to shop from header');
        window.open('https://pipdecks.com/pages/shop', '_blank');
      }}
    />
  );
};

export default ShopButton;
