import {
  consumePageArguments,
  setPageArguments,
} from '../../Common/Navigation/pageArguments';

export const setResetSearchPageArgument = (searchPageRoute: string) => {
  setPageArguments(searchPageRoute, { reset: true });
};

export const consumeResetSearchPageArgument = (searchPageRoute: string) => {
  return consumePageArguments(searchPageRoute)?.reset;
};
