import React from 'react';
import { IonChip } from '@ionic/react';

import './CardCell.css';

import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';
import LabelChevronView from '../../../Common/UI/Views/LabelChevronView/LabelChevronView';
import { hexToRgba } from '../../../Common/UI/color';
import PipCategoryLabel from '../PipCategoryLabel';

interface CardCellProps {
  imageSource: string;
  title: string;
  description: string;
  onClick: () => void;
  deckTitle?: string;
  categoryTitle?: string;
  categoryColor?: string;
  categoryIcon?: string;
}

const cardGridColumnSizeSm = '4';
const cardGridColumnSizeXs = '8';

const CardCell: React.FC<CardCellProps> = ({
  imageSource,
  title,
  description,
  onClick,
  deckTitle,
  categoryTitle,
  categoryColor,
  categoryIcon,
}) => {
  return (
    <ClickableDiv
      className="card-cell"
      onClick={onClick}
      style={{ borderColor: categoryColor }}>
      <div className="card">
        <img src={imageSource} className="image" aria-hidden="true" />
        <h3 className="title" aria-label={title}>
          <LabelChevronView label={title} />
        </h3>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
      <div className="metadata">
        {categoryTitle && categoryIcon && (
          <IonChip
            style={{
              backgroundColor: hexToRgba(categoryColor!, 0.15),
            }}>
            <PipCategoryLabel
              title={categoryTitle}
              iconCode={categoryIcon}
              iconColor={categoryColor}
            />
          </IonChip>
        )}
        {deckTitle && (
          <IonChip
            style={{
              backgroundColor: '#eee',
            }}>
            {deckTitle}
          </IonChip>
        )}
      </div>
    </ClickableDiv>
  );
};

export default CardCell;
export { cardGridColumnSizeSm, cardGridColumnSizeXs };
