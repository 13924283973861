import React from 'react';
import { useLocation } from 'react-router';

import searchIcon from '/public/images/icons/search.svg';
import searchIconFilled from '/public/images/icons/search-filled.svg';

import { CardDeckRoutes } from '../../cardDecksRoutes';
import IconButton from '../../Common/UI/Buttons/IconButton';
import AuthorizedDecksAccessContainer from '../Common/AuthorizedAccess/AuthorizedDecksAccessContainer';

import useSearchNavigator from './useSearchNavigator';

const SearchButton: React.FC = () => {
  const location = useLocation();
  const searchRoute = CardDeckRoutes.cardDecksCardSearch.resolve();

  let iconSrc =
    location.pathname === searchRoute ? searchIconFilled : searchIcon;

  const searchNavigator = useSearchNavigator();

  return (
    <AuthorizedDecksAccessContainer>
      <IconButton
        aria-label="search"
        iconSrc={iconSrc}
        className="search-button"
        onClick={() => {
          searchNavigator.navigateToSearchRouteAndResetSearchResults();
        }}
      />
    </AuthorizedDecksAccessContainer>
  );
};

export default SearchButton;
