import React from 'react';
import { useTranslation } from 'react-i18next';
import NoActionMessageComp from './NoActionMessageComp';
import { phonePortraitOutline, refreshOutline } from 'ionicons/icons';

const StartOverRequestNewLoginLinkInAppComp: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('authentication');

  return isTReady ? (
    <NoActionMessageComp
      titleIcon={refreshOutline}
      title={t('StartOverRequestNewLoginLinkInAppComp_title')}
      messageIcon={phonePortraitOutline}
      message={t('RequestNewLoginLinkInApp_instructions')}
    />
  ) : null;
};

export default StartOverRequestNewLoginLinkInAppComp;
