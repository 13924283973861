import { PostHog } from 'posthog-js';
import AddPropertiesToAllTrackedEventsInSession from '../../Analytics/UserInfo/AddPropertiesToAllTrackedEventsInSession';

const composeAddPropertiesToAllTrackedEventsInSessionWithPostHog = (
  postHog: PostHog
): AddPropertiesToAllTrackedEventsInSession => {
  return (properties: { [key: string]: any }) => {
    postHog.register_for_session(properties);
  };
};

export default composeAddPropertiesToAllTrackedEventsInSessionWithPostHog;
