import React, { useRef, useState } from 'react';
import { IonContent, IonIcon, IonPopover } from '@ionic/react';
import { helpCircleOutline } from 'ionicons/icons';
import './LabelWithHelpPopover.css';
import clickOnEnter from '../../Events/clickOnEnter';

type LabelWithHelpPopoverProps = {
  id: string;
  label: string;
  popoverText: string;
};

const LabelWithHelpPopover: React.FC<LabelWithHelpPopoverProps> = ({
  id,
  label,
  popoverText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <span className="label-with-help-popover">
      {label}
      <IonIcon
        icon={helpCircleOutline}
        id={id}
        role="button"
        aria-label={`Explain ${label}`}
        aria-expanded={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        onKeyUp={clickOnEnter}
        tabIndex={0}
      />
      <IonPopover
        arrow={true}
        trigger={id}
        triggerAction="click"
        dismissOnSelect={true}
        isOpen={isOpen}
        onDidDismiss={() => setIsOpen(false)}
        // showBackdrop={false}
        reference="trigger">
        <IonContent className="ion-padding">{popoverText}</IonContent>
      </IonPopover>
    </span>
  );
};
export default LabelWithHelpPopover;
