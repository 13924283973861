import { cardDecks } from '../../Content/cardDecksContent';

export interface CardDeck {
  title: string;
  description: string;
  imageUrl: string;
  id: number;
  type: string; //'full' | 'expansion';
  shopUrl: string;
  pdfDownloadUrl: string;
  imagesArchiveDownloadUrl: string;
}

export const getCardDecks = (): CardDeck[] => cardDecks;

export const getCardDeck = (id: number) =>
  cardDecks.find(deck => deck.id === id);

export const getCardDeckByIdString = (idString: string) => {
  const id = Number(idString);
  return getCardDeck(id);
};
