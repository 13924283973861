import React from 'react';
import { IonButton, IonItem, IonLabel, IonList, IonNote } from '@ionic/react';

import '../../Common/Style/card.css';
import '../../Common/Style/empty-card.css';

import HtmlToReactComp from '../UI/HTML/HtmlToReactComp';
import CenteredContentContainer from '../UI/Buttons/CenteredContentContainer';

const EmptyContentComp: React.FC<{
  illustrationPath: string;
  title?: string;
  descriptionHtml: string;
  buttonText: string;
  onButtonClick: () => void;
}> = ({
  illustrationPath,
  title,
  descriptionHtml,
  buttonText,
  onButtonClick,
}) => {
  return (
    <div className="card-container empty-card bottom-buttons-margin-adjust">
      <div className="card-background clear-button-at-the-bottom">
        <IonList>
          <IonItem lines="none" className="image-item">
            <img src={illustrationPath} style={{ margin: '0 auto' }} />
          </IonItem>
          {title && (
            <IonItem lines="none" className="header-item">
              <IonLabel className="ion-text-wrap ion-text-center">
                <h1>{title}</h1>
              </IonLabel>
            </IonItem>
          )}
          <IonItem lines="none">
            <IonNote color="dark" className="ion-text-wrap ion-text-center">
              <HtmlToReactComp html={descriptionHtml} />
            </IonNote>
          </IonItem>
          <IonItem lines="none">
            <CenteredContentContainer>
              <IonButton
                size="default"
                onClick={onButtonClick}
                fill="clear"
                strong>
                {buttonText}
              </IonButton>
            </CenteredContentContainer>
          </IonItem>
        </IonList>
      </div>
    </div>
  );
};

export default EmptyContentComp;
