import { useTranslation } from 'react-i18next';
import UnauthorizedAccessCallToActionComp from './UnauthorizedAccessCallToActionComp';

const NewCustomerCallToActionComp = () => {
  const { t, ready: isTReady } = useTranslation('content-access');

  return isTReady ? (
    <UnauthorizedAccessCallToActionComp
      imageSrc="/images/not-authorized/decks.png"
      imageWidth="300px"
      title={t('NewCustomerCallToAction_Title')}
      body={t('NewCustomerCallToAction_Body')}
      buttonTitle={t('NewCustomerCallToAction_ShopButton')}
      onButtonClick={() => {
        window.open('https://pipdecks.com/pages/shop', '_blank');
      }}
      showAlreadyCustomerComp={true}
    />
  ) : null;
};

export default NewCustomerCallToActionComp;
