import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyContentComp from '../../../Common/ContentLoading/EmptyContentComp';

interface EmptyBookmarksCompProps {
  onButtonClick: () => void;
}

const EmptyBookmarksComp: React.FC<EmptyBookmarksCompProps> = ({
  onButtonClick,
}) => {
  const { t, ready: isTReady } = useTranslation('bookmarks');

  return isTReady ? (
    <EmptyContentComp
      illustrationPath="images/bookmarks/illustrations/card-empty.svg"
      descriptionHtml={t('EmptyBookmarksComp_description')}
      buttonText={t('EmptyBookmarksComp_buttonTitle')}
      onButtonClick={onButtonClick}
    />
  ) : null;
};

export default EmptyBookmarksComp;
