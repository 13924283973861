import React, { useEffect, useRef, useState } from 'react';
import { mapToCallbacks, Result } from '../Types/Result';
import ContentLoadingIndicatorComp from './ContentLoadingIndicatorComp';

enum ContentLoadingState {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

function isContentEmpty(content: any): boolean {
  if (content === null || content === undefined) return true;
  if (Array.isArray(content)) return content.length === 0;
  return false;
}

export interface RetryableContentLoadingCompProps<DataType> {
  isViewReadyForContentLoad: boolean;
  loadContent: (
    onResult: (result: Result<DataType, any>) => void
  ) => () => void;
  renderContent: (content: DataType) => React.ReactNode;
  renderEmptyState?: () => React.ReactNode;
  renderErrorState: (retryContentLoad: () => void) => React.ReactNode;
  onDataLoaded?: (content: DataType) => void;
  onError?: (error: any) => void;
  onEmptyDataLoaded?: () => void;
  fullScreen?: boolean;
}

const RetryableContentLoadingComp: React.FC<
  RetryableContentLoadingCompProps<any>
> = <DataType,>({
  isViewReadyForContentLoad,
  loadContent,
  renderContent,
  renderEmptyState,
  renderErrorState,
  onDataLoaded,
  onError,
  onEmptyDataLoaded,
  fullScreen = true,
}: RetryableContentLoadingCompProps<DataType>): React.ReactElement => {
  const [content, setContent] = useState<DataType>();
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const unsubscribeRef = useRef(() => {});
  const [loadRetryCount, setLoadRetryCount] = useState(-1);

  const handleContentLoaded = (loadedContent: DataType) => {
    if (isContentEmpty(loadedContent)) {
      onEmptyDataLoaded?.();
    } else {
      onDataLoaded?.(loadedContent);
    }
    setContent(loadedContent);
    setLoadingState(ContentLoadingState.Loaded);
  };

  const handleError = (error: Error) => {
    onError?.(error);
    setLoadingState(ContentLoadingState.Error);
  };

  const handleResult = (result: Result<DataType, any>) => {
    mapToCallbacks(result, handleContentLoaded, handleError);
  };

  useEffect(() => {
    if (!isViewReadyForContentLoad) {
      return;
    }

    setLoadingState(ContentLoadingState.Loading);
    const timeoutId = setTimeout(() => {
      // console.log(`Retrying content load: iteration ${loadRetryCount}`);
      unsubscribeRef.current();
      unsubscribeRef.current = loadContent(handleResult);
    }, Math.pow(2, loadRetryCount) * 1000);

    return () => {
      clearTimeout(timeoutId);
      unsubscribeRef.current();
    };
  }, [isViewReadyForContentLoad, loadRetryCount]);

  switch (loadingState) {
    case ContentLoadingState.Loading:
      return (
        <ContentLoadingIndicatorComp
          className={fullScreen ? undefined : 'compact'}
        />
      );
    case ContentLoadingState.Loaded:
      if (!content) {
        return <></>;
      }
      return (
        <>
          {isContentEmpty(content)
            ? renderEmptyState?.() || <></>
            : renderContent(content)}
        </>
      );
    case ContentLoadingState.Error:
      return (
        <>{renderErrorState(() => setLoadRetryCount(count => count + 1))}</>
      );
    default:
      return <></>;
  }
};

export default RetryableContentLoadingComp;
