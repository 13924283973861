import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonIcon, IonItem, IonList, IonNote } from '@ionic/react';
import { lockOpenOutline } from 'ionicons/icons';
import { usePostHog } from 'posthog-js/react';

import { sendSignInLinkToEmail } from 'firebase/auth';

import { checkIfStandaloneMode } from '../../Common/Platform/StandaloneMode';
import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';
import composeTrackUserActionWithPostHog from '../../External/PostHog/trackUserActionWithPostHog';
import logErrorWithSentry from '../../External/Sentry/logErrorWithSentry';

import EmailInputFormComp from './EmailInputFormComp';
import { emailLinkLoginFormLocalStorage } from './emailLinkLoginFormLocalStorage';
import { appendStandaloneLoginFlowQueryParamTo } from './loginLink';
import LegalNoteComp from './LegalNoteComp';

interface EmailLogInFormProps {
  onEmailSent: () => void;
}

const EmailLogInForm: React.FC<EmailLogInFormProps> = ({ onEmailSent }) => {
  const [email, setEmail] = useState<string>(
    emailLinkLoginFormLocalStorage.getLastKnownLoginEmail() ?? ''
  );
  // there needs to be a default value set,
  // otherwise the input doesn't draw a bottom border
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const { t, ready: isTReady } = useTranslation('authentication');
  const posthog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(posthog);

  const clearSubmissionResultState = () => {
    setErrorMessage(undefined);
  };
  const auth = getFirebaseAuth();

  const sendEmail = async () => {
    setErrorMessage(undefined);

    const isStandaloneAppMode = checkIfStandaloneMode();
    const redirectUrlString = window.location.toString();
    const redirectUrl = new URL(redirectUrlString);
    if (isStandaloneAppMode) {
      appendStandaloneLoginFlowQueryParamTo(redirectUrl);
    }

    const actionCodeSettings = {
      url: redirectUrl.toString(),
      handleCodeInApp: true,
    };

    emailLinkLoginFormLocalStorage.setRequestedLoginLinkEmail(email);
    emailLinkLoginFormLocalStorage.setLastKnownLoginEmail(email);

    // await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      // throw new Error('test');
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      trackAction('login link requested', { email: email });
      onEmailSent();
    } catch (error) {
      // TODO: auth/invalid-email when the email is not a valid email address
      logErrorWithSentry(error);
      setErrorMessage(t('EmailLogInForm_sendErrorMessage'));
    }
  };

  const onInputChanged = (value: string) => {
    {
      setEmail(value);
      clearSubmissionResultState();
      emailLinkLoginFormLocalStorage.removeRequestedLoginLinkEmail();
    }
  };

  return (
    (isTReady && (
      <>
        <EmailInputFormComp
          formTitle={t('EmailLogInForm_title')}
          // icon hidden because of the longer title and to reduce visual clutter
          // of the login form
          isFormTitleIconVisible={false}
          formInstructions={t('EmailLogInForm_usePurchaseEmailReminder')}
          emailInputValue={
            emailLinkLoginFormLocalStorage.getLastKnownLoginEmail() ?? undefined
          }
          submitButtonLabel={t('EmailLogInForm_sendButtonLabel')}
          formErrorMessage={errorMessage}
          onInputChanged={onInputChanged}
          onEmailSubmitClicked={sendEmail}
        />
        <LegalNoteComp />
        <IonList style={{ marginTop: '2em' }}>
          <IonItem lines="none">
            <IonIcon icon={lockOpenOutline} slot="start"></IonIcon>
            <IonNote color="dark" className="ion-text-wrap">
              {'New here? Unlock digital Pip Decks in the '}
              <a
                href="https://pipdecks.com/pages/shop"
                target="_blank"
                className="content-link thin-link">
                Shop
              </a>
            </IonNote>
          </IonItem>
        </IonList>
      </>
    )) ||
    null
  );
};

export default EmailLogInForm;
