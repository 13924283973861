import ChevronIcon from '../../../Common/UI/Icons/ChevronIcon';
import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';
import './CardDeckHomeIconTextNavItem.css';

interface CardDeckHomeIconTextNavItemProps {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
}

const CardDeckHomeIconTextNavItem: React.FC<
  CardDeckHomeIconTextNavItemProps
> = ({ icon, text, onClick }) => {
  return (
    <ClickableDiv
      className="card-deck-home-icon-text-nav-item"
      onClick={onClick}>
      <div className="left-icon" aria-hidden="true">
        {icon}
      </div>
      <h3>{text}</h3>
      {/* decided to hide the chevron for this element for now, 
          it might change in the future designs */}
      <div
        className="right-icon"
        aria-hidden="true"
        style={{ display: 'none' }}>
        <ChevronIcon />
      </div>
    </ClickableDiv>
  );
};

export default CardDeckHomeIconTextNavItem;
