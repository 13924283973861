import React from 'react';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';

interface HomeBreadcrumbProps {
  route: string;
}

const HomeBreadcrumb: React.FC<HomeBreadcrumbProps> = ({ route }) => {
  return (
    <Breadcrumb route={route} hideChevron={true} className="home">
      <span>{'Home'}</span>
    </Breadcrumb>
  );
};

export default HomeBreadcrumb;
