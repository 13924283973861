import { PostHog } from 'posthog-js';
import composeTrackUserActionWithPostHog from '../../../../External/PostHog/trackUserActionWithPostHog';

enum TrackMissingSolutionActionPage {
  Challenge = 'challenge',
  Focus = 'focus',
}

const composeTrackMissingSolutionActionWithPostHog = (postHog: PostHog) => {
  const trackAction = composeTrackUserActionWithPostHog(postHog);
  return (page: TrackMissingSolutionActionPage, challenge?: string) => {
    trackAction('missing solution in Solve My Problem', {
      sourcePage: page,
      selectedChallenge: challenge,
    });
  };
};

export default composeTrackMissingSolutionActionWithPostHog;
export { TrackMissingSolutionActionPage };
