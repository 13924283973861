enum MissingSolutionFormNavigationSource {
  Challenge = 'challenge',
  Focus = 'focus',
}

const navigateToMissingSolutionForm = (
  sourcePage: MissingSolutionFormNavigationSource,
  customerEmail?: string,
  selectedChallenge?: string
) => {
  const uriEncodedEmail = customerEmail && encodeURIComponent(customerEmail);
  window.location.href = `https://form.typeform.com/to/tRlHL7WW#page=${sourcePage}&challenge=${selectedChallenge}&email=${uriEncodedEmail}`;
};

export default navigateToMissingSolutionForm;
export { MissingSolutionFormNavigationSource };
