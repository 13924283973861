import {
  DataLoadingResult,
  isDataLoadingResultSuccess,
  mapDataLoadingResult,
} from '../../../Common/Types/DataLoadingResult';
import { CardDeck } from '../../Home/cardDecks';
import { CustomerResult } from './AuthenticatedCustomerContext';

type DeckIdsUserAccessResult = DataLoadingResult<Set<number> | null, unknown>;

export const getDeckIdsUserCanAccessResult = (
  customer: CustomerResult
): DeckIdsUserAccessResult => {
  // return successContentLoadingResult(null);
  return mapDataLoadingResult(customer, customer => {
    if ((customer?.deckIdsUserCanAccess.size ?? 0) > 0) {
      return customer!.deckIdsUserCanAccess;
    } else {
      return null;
    }
  });
};

export const getDeckIdsUserCanAccess = (
  customer: CustomerResult
): Set<number> | null => {
  const result = getDeckIdsUserCanAccessResult(customer);
  return isDataLoadingResultSuccess(result) ? result.data : null;
};

export const getDecksUserCanAccess = (
  deckIdsUserCanAcccess: Set<number>,
  allDecks: CardDeck[]
) => {
  return allDecks.filter(deck => deckIdsUserCanAcccess.has(deck.id));
};

export const getDecksUserCannotAccess = (
  deckIdsUserCanAcccess: Set<number>,
  allDecks: CardDeck[]
) => {
  return allDecks.filter(deck => !deckIdsUserCanAcccess.has(deck.id));
};
