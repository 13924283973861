export interface RecoverableHookError<E> {
  cause: E;
  retry: () => void;
}

export type DataLoadingResult<T, E> =
  | { status: 'loading' }
  | { status: 'success'; data: T | null }
  | { status: 'error'; error: RecoverableHookError<E> };

export const loadingDataLoadingResult = <T, E>(): DataLoadingResult<T, E> => ({
  status: 'loading',
});
export const successDataLoadingResult = <T, E>(
  data: T | null
): DataLoadingResult<T, E> => ({
  status: 'success',
  data,
});
export const errorDataLoadingResult = <T, E>(
  error: E,
  retry: () => void
): DataLoadingResult<T, E> => ({
  status: 'error',
  error: { cause: error, retry },
});

export const isDataLoadingResultLoading = <T, E>(
  result: DataLoadingResult<T, E>
): result is { status: 'loading' } => result.status === 'loading';

export const isDataLoadingResultSuccess = <T, E>(
  result: DataLoadingResult<T, E>
): result is { status: 'success'; data: T | null } =>
  result.status === 'success';

export const isDataLoadingResultError = <T, E>(
  result: DataLoadingResult<T, E>
): result is { status: 'error'; error: RecoverableHookError<E> } =>
  result.status === 'error';

export const mapDataLoadingResult = <T1, T2, E>(
  result: DataLoadingResult<T1, E>,
  transform: (data: T1 | null) => T2 | null
): DataLoadingResult<T2, E> => {
  if (isDataLoadingResultLoading(result)) {
    return loadingDataLoadingResult();
  }

  if (isDataLoadingResultError(result)) {
    return errorDataLoadingResult(result.error.cause, result.error.retry);
  }

  return successDataLoadingResult(transform(result.data));
};
