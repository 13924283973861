import React, { ReactNode } from 'react';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';

// this is not the fully typed interface, but just enough to support our needs
export interface DOMNode {
  type: string;
  name: string;
  attribs: {
    href: string;
    target: string;
    src: string;
    class: string;
    style: string;
    id: string;
  };
  children?: DOMNode[];
}

interface PreprocessingInstruction {
  shouldPreprocessNode: (node: DOMNode) => boolean;
  preprocessNode: (node: DOMNode) => void;
}

interface ProcessingInstruction {
  shouldProcessNode: (node: DOMNode) => boolean | string;
  processNode: (
    node: DOMNode,
    children: ReactNode[],
    index: number
  ) => JSX.Element;
}

interface HtmlToReactCompProps {
  html: string;
  isValidNode?: () => boolean;
  processingInstructions?: ProcessingInstruction[];
  preprocessingInstructions?: PreprocessingInstruction[];
}

const isValidNodeYes = () => true;

const processNodeDefinitions = new ProcessNodeDefinitions(React);

const HtmlToReactComp: React.FC<HtmlToReactCompProps> = ({
  html,
  isValidNode,
  processingInstructions,
  preprocessingInstructions,
}) => {
  const htmlToReactParser = new Parser();
  const allProcessingInstructions = [
    ...(processingInstructions ?? []),
    ...[
      {
        shouldProcessNode: () => true,
        processNode: processNodeDefinitions.processDefaultNode,
      } as unknown as ProcessingInstruction,
    ],
  ];
  return htmlToReactParser.parseWithInstructions(
    html,
    isValidNode ?? isValidNodeYes,
    allProcessingInstructions,
    preprocessingInstructions
  );
};

const stringHtmlAsReactComp = (str: string) => {
  return <HtmlToReactComp html={str} />;
};

export default HtmlToReactComp;
export { stringHtmlAsReactComp };
