import React from 'react';
import { useState } from 'react';
import {
  IonLabel,
  IonPage,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from '@ionic/react';
import { useParams } from 'react-router';

import '../Common/CardDeckCardDetails.css';
import './CardDeckRecipeCardPage.css';
import '../../../Common/Style/card.css';

import CardDeckRecipeCardLinkedCardsListComp from './CardDeckRecipeCardLinkedCardsListComp';

import CardDeckCardResources from '../Common/CardDeckCardResourcesComp/CardDeckCardResourcesComp';

import { getCardDeckByIdString } from '../../Home/cardDecks';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import PipCategoryLabelBreadcrumb from '../../Common/Breadcrumbs/PipCategoryLabelBreadcrumb';
import provideDeckBreadcrumbProps from '../../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import provideRecipesBreadcrumbProps from '../../Common/Breadcrumbs/provideRecipesBreadcrumbProps';
import AuthorizedDeckContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';

import EnhancedHtmlToReactComp from '../../../Common/EnhancedHtmlToReactComp';
import AppContentContainer from '../../../Common/AppContentContainer';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import renderPageIfModelDefinedOrNotFoundPage from '../../../Common/Pages/pageIfModelDefinedOrNotFoundPage';

import {
  CardDeckRecipeCard,
  emptyRecipeCard,
  getCardDeckRecipeCard,
} from './cardDeckRecipeCard';
import composeAddRemoveBookmarkButton from '../../CardDeckBookmarks/AddRemoveBookmarkButton/composeAddRemoveBookmarkButton';

export enum CardDeckRecipeCardLinkedCardsListItemTitleStyle {
  numbered = 'numbered',
  unstyled = 'unstyled',
}

const getListItemTitleStyle = (cardLayout: number) => {
  switch (cardLayout) {
    case 1:
      return CardDeckRecipeCardLinkedCardsListItemTitleStyle.numbered;
    case 2:
      return CardDeckRecipeCardLinkedCardsListItemTitleStyle.unstyled;
    default:
      return CardDeckRecipeCardLinkedCardsListItemTitleStyle.numbered;
  }
};

const getCardStyle = (cardColor: string) => {
  return {
    borderColor: cardColor,
  };
};

const getCardDeckTitle = (deckId: string) => {
  const cardDeckTitle = getCardDeckByIdString(deckId)?.title;
  return cardDeckTitle;
};

const getDocumentTitle = (deckTitle?: string, cardTitle?: string) => {
  return `${cardTitle} | ${deckTitle}`;
};

const setDocumentTitle = (deckTitle?: string, cardTitle?: string) => {
  document.title = getDocumentTitle(deckTitle, cardTitle);
};

const CardDeckRecipeCardPage: React.FC = () => {
  const [card, setCard] = useState<CardDeckRecipeCard | null | undefined>(
    emptyRecipeCard
  );
  const [deckTitle, setDeckTitle] = useState<string | undefined>(undefined);
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);
  const { deckId, cardId } = useParams<{ deckId: string; cardId: string }>();

  useIonViewWillEnter(() => {
    const cardIdNumber = Number(cardId);
    if (isNaN(cardIdNumber)) {
      return;
    }
    const card = getCardDeckRecipeCard(deckId, cardIdNumber) ?? null;
    setCard(card);

    const deckTitle = getCardDeckTitle(deckId);
    setDeckTitle(deckTitle);

    setDocumentTitle(deckTitle, card?.title);

    setIsOnScreen(true);
  }, [deckId, cardId]);

  useIonViewDidLeave(() => {
    setIsOnScreen(false);
  });

  return renderPageIfModelDefinedOrNotFoundPage(
    card,
    <IonPage id="card-deck-recipe-details">
      <AppContentContainer>
        <AuthorizedDeckContentAccessContainer deckId={deckId}>
          {card && (
            <div>
              <Breadcrumbs>
                <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
                <TextBreadcrumb {...provideDeckBreadcrumbProps(deckId)} />
                {/* <PipCategoryLabelBreadcrumb
                  {...provideRecipesBreadcrumbProps(deckId)}
                /> */}
                <Breadcrumb>{card?.title}</Breadcrumb>
              </Breadcrumbs>
              {composeAddRemoveBookmarkButton(() => {
                return { cardId: card.id, cardType: 'recipe' };
              }, isOnScreen)}
              <div className="card-container ion-padding bottom-buttons-margin-adjust">
                <div
                  className="card-details card-background"
                  style={getCardStyle('black')}
                >
                  <img src={card.imageUrl} className="lead-image" />
                  <h1>{card.title}</h1>
                  <p className="summary">
                    <EnhancedHtmlToReactComp
                      html={card.summary}
                      shouldOpenImagesInFullScreen={false}
                    />
                  </p>
                  <div className="description">
                    <EnhancedHtmlToReactComp
                      html={card.description}
                      // because the description may contain images with small details
                      // although that hasn't been the case so far
                      shouldOpenImagesInFullScreen={true}
                    />
                  </div>
                  <CardDeckRecipeCardLinkedCardsListComp
                    cards={card.items}
                    listItemTitleStyle={getListItemTitleStyle(
                      card.layoutVersion
                    )}
                  />
                  <div className="conclusion">
                    <IonLabel>
                      <h2>End result</h2>
                      <p>
                        <EnhancedHtmlToReactComp
                          html={card.conclusion}
                          shouldOpenImagesInFullScreen={false}
                        />
                      </p>
                    </IonLabel>
                  </div>

                  {card.origin ? (
                    <p className="card-origin">Origin: {card.origin}</p>
                  ) : (
                    ''
                  )}
                </div>
                <CardDeckCardResources
                  videoHtml={card.walkthroughVideo}
                  whiteboardTemplatesContent={{
                    subheading: card.whiteboardTemplatesSubheading,
                    miroBoardId: card.miroBoardId,
                    muralTemplateId: card.muralTemplateId,
                  }}
                  cardTrackingDescription={`${deckTitle} - ${card.title}`}
                />
              </div>
            </div>
          )}
        </AuthorizedDeckContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckRecipeCardPage;
