import './ContentLoadingErrorComp.css';
import EmptyContentComp from './EmptyContentComp';

interface ContentLoadingErrorCompProps {
  illustrationPath?: string;
  descriptionHtml: string;
  buttonText: string;
  onRetryButtonClick: () => void;
}

const ContentLoadingErrorComp: React.FC<ContentLoadingErrorCompProps> = ({
  illustrationPath = 'images/content-loading/pip-team-upset.svg',
  descriptionHtml,
  buttonText,
  onRetryButtonClick,
}) => {
  return (
    <EmptyContentComp
      illustrationPath={illustrationPath}
      descriptionHtml={descriptionHtml}
      buttonText={buttonText}
      onButtonClick={onRetryButtonClick}
    />
  );
};

export default ContentLoadingErrorComp;
