import { Listen } from '../../Common/Types/Listen';

export interface Bookmark {
  id: string;
  cardId: number;
  cardType: string;
  timestamp: string;
}

export enum ListenBookmarksError {
  Unknown = 'Unknown',
}

export type ListenCardBookmarks = Listen<
  [userId: string],
  Bookmark[],
  ListenBookmarksError
>;

export enum AddBookmarksError {
  Unknown = 'Unknown',
}

export type AddCardBookmark = (
  cardId: number,
  cardType: string,
  userId: string
) => Promise<string>;

export enum RemoveBookmarksError {
  Unknown = 'Unknown',
}

export type RemoveCardBookmark = (
  bookmarkId: string,
  userId: string
) => Promise<void>;
