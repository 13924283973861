import React, { forwardRef } from 'react';
import { IonButton } from '@ionic/react';
import LeftArrowIcon from '../../UI/Icons/LeftArrowIcon';
import './BackUpButton.css';

// TODO: accessibility: go up doesn't work in the standalone mode?

interface UpButtonProps {
  className?: string;
  contentRef: React.RefObject<HTMLIonContentElement>;
}

const BackUpButton = forwardRef<HTMLIonButtonElement, UpButtonProps>(
  ({ className, contentRef }, ref) => {
    return (
      <IonButton
        ref={ref}
        className={`up-button ${className}`}
        aria-label="up"
        role="button"
        onClick={e => {
          e.preventDefault();
          contentRef.current?.scrollToTop(350);
        }}>
        <LeftArrowIcon rotationDegree={90} />
      </IonButton>
    );
  }
);

export default BackUpButton;
