import React from 'react';
import { useState } from 'react';
import { IonPage, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { useParams } from 'react-router';

import './CardDeckTacticCardPage.css';
import '../Common/CardDeckCardDetails.css';
import '../../../Common/Style/card.css';

import CardDeckCardResources from '../Common/CardDeckCardResourcesComp/CardDeckCardResourcesComp';
import { getCardDeckByIdString } from '../../Home/cardDecks';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import provideDeckBreadcrumbProps from '../../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import provideCategoryBreadcrumbProps from '../../Common/Breadcrumbs/provideCategoryBreadcrumbProps';
import AuthorizedDeckContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';
import PipCategoryLabelBreadcrumb from '../../Common/Breadcrumbs/PipCategoryLabelBreadcrumb';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import EnhancedHtmlToReactComp from '../../../Common/EnhancedHtmlToReactComp';
import AppContentContainer from '../../../Common/AppContentContainer';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import renderPageIfModelDefinedOrNotFoundPage from '../../../Common/Pages/pageIfModelDefinedOrNotFoundPage';

import {
  getCardDeckTacticCard,
  CardDeckTacticCard,
} from './cardDeckTacticCard';
import composeAddRemoveBookmarkButton from '../../CardDeckBookmarks/AddRemoveBookmarkButton/composeAddRemoveBookmarkButton';

const getCardStyle = (cardColor: string) => {
  return {
    borderColor: cardColor,
  };
};

const getCardDeckTitle = (deckId: string) => {
  return getCardDeckByIdString(deckId)?.title;
};

const getDocumentTitle = (deckTitle?: string, cardTitle?: string) => {
  return `${cardTitle} | ${deckTitle}`;
};

const setDocumentTitle = (deckTitle?: string, cardTitle?: string) => {
  document.title = getDocumentTitle(deckTitle, cardTitle);
};

const CardDeckTacticCardPage: React.FC = () => {
  const { deckId, cardId } = useParams<{ deckId: string; cardId: string }>();
  const [card, setCard] = useState<CardDeckTacticCard | null | undefined>(
    undefined
  );
  const [deckTitle, setDeckTitle] = useState<string | undefined>(undefined);
  const [isOnScreen, setIsOnScreen] = useState<boolean>(false);

  useIonViewWillEnter(() => {
    const cardIdNumber = Number(cardId);
    if (isNaN(cardIdNumber)) {
      setCard(null);
      return;
    }

    const card = getCardDeckTacticCard(deckId, cardIdNumber) ?? null;
    setCard(card);

    const deckTitle = getCardDeckTitle(deckId);
    setDeckTitle(deckTitle);

    setDocumentTitle(deckTitle, card?.title);
    setIsOnScreen(true);
  }, [deckId, cardId]);

  useIonViewDidLeave(() => {
    setIsOnScreen(false);
  });

  return renderPageIfModelDefinedOrNotFoundPage(
    card,
    <IonPage id="card-deck-tactic-details">
      <AppContentContainer>
        <AuthorizedDeckContentAccessContainer deckId={deckId}>
          {card && (
            <div>
              <Breadcrumbs>
                <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
                <TextBreadcrumb {...provideDeckBreadcrumbProps(deckId)} />
                <PipCategoryLabelBreadcrumb
                  {...provideCategoryBreadcrumbProps(
                    deckId,
                    card?.categoryId + '',
                    true //getCardDeck(Number(deckId))?.type == 'full'
                  )}
                />
                <Breadcrumb>{card?.title}</Breadcrumb>
              </Breadcrumbs>
              {composeAddRemoveBookmarkButton(() => {
                return { cardId: card.id, cardType: 'tactic' };
              }, isOnScreen)}
              <div className="card-container ion-padding bottom-buttons-margin-adjust">
                <div
                  className="card-details card-background"
                  style={getCardStyle(card.color)}
                >
                  <img src={card.imageUrl} className="lead-image" />
                  <h1>{card.title}</h1>
                  <p className="summary">
                    <EnhancedHtmlToReactComp
                      html={card.summary}
                      shouldOpenImagesInFullScreen={false}
                    />
                  </p>
                  <div className="description">
                    <EnhancedHtmlToReactComp
                      html={card.description}
                      // because the description may contain images with small details
                      shouldOpenImagesInFullScreen={true}
                    />
                  </div>
                  {card.origin ? (
                    <p className="card-origin">Origin: {card.origin}</p>
                  ) : (
                    ''
                  )}
                </div>
                <CardDeckCardResources
                  videoHtml={card.walkthroughVideo}
                  whiteboardTemplatesContent={{
                    subheading: card.whiteboardTemplatesSubheading,
                    miroBoardId: card.miroBoardId,
                    muralTemplateId: card.muralTemplateId,
                  }}
                  guides={card.guidesLinks}
                  cardTrackingDescription={`${deckTitle} - ${card.title}`}
                />
              </div>
            </div>
          )}
        </AuthorizedDeckContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckTacticCardPage;
