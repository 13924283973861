export interface ListItemChevronProps {
  color?: string;
  marginLeft?: string;
}

const ListItemChevron: React.FC<ListItemChevronProps> = ({
  color = '#6F6F6E',
  marginLeft = '8px',
}) => {
  return (
    <svg
      style={{ marginLeft: marginLeft }}
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9L5 5L1 1" stroke={color} strokeWidth="1.5" />
    </svg>
  );
};

export default ListItemChevron;
