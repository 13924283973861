import { createContext, useContext, useEffect, useState } from 'react';

import { getQueryParam } from '../Common/WebContext/queryParams';
import { User } from './User';

const AuthenticatedUserContext = createContext<User | null | undefined>(
  undefined
);

const useAuthenticatedUser = () => useContext(AuthenticatedUserContext);

type AuthenticatedUserProviderProps = {
  children: React.ReactNode;
  listenUser?: (onNext: (user: User | null) => void) => () => void;
};

const AuthenticatedUserProvider: React.FC<AuthenticatedUserProviderProps> = ({
  children,
  listenUser,
}) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    // fakes a user object on an internal build
    // NOTE: make sure .env.internal is sourced in the console before running 'npm run build:internal'
    if (import.meta.env.VITE_INTERNAL_BUILD_AUTH_CHECK === 'true') {
      const token = getQueryParam('token');
      if (token) {
        localStorage.setItem('token', token);
      }
      if (
        localStorage.getItem('token') ===
        import.meta.env.VITE_INTERNAL_ACCESS_TOKEN
      ) {
        if (user === undefined) {
          setUser({ email: 'internal@pipdecks.com' } as User);
        }
        return;
      }
    }

    // production build
    if (listenUser) {
      return listenUser(setUser);
    }
  }, [listenUser]);

  return (
    <AuthenticatedUserContext.Provider value={user}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

export {
  AuthenticatedUserContext,
  AuthenticatedUserProvider,
  useAuthenticatedUser,
};
