import React from 'react';

interface CenteredContentContainerProps {
  children: React.ReactNode;
}
const CenteredContentContainer: React.FC<CenteredContentContainerProps> = ({
  children,
}) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      {children}
    </div>
  );
};

export default CenteredContentContainer;
