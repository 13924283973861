import { FirebaseApp, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyBoWfTVi7Q-vz3ELcrmbBZzDCSy2vchMs0',
  authDomain: 'pipdecksapp.firebaseapp.com',
  projectId: 'pipdecksapp',
  storageBucket: 'pipdecksapp.appspot.com',
  messagingSenderId: '1017883462921',
  appId: '1:1017883462921:web:ef6c9e78de1a47d19897d8',
};

let firebaseApp: FirebaseApp;

const getFirebaseApp = () => {
  if (firebaseApp === undefined) {
    firebaseApp = initializeApp(firebaseConfig);
  }
  return firebaseApp;
};

export default getFirebaseApp;
