import React, { forwardRef, useRef } from 'react';
import { IonButton } from '@ionic/react';
import useToast from './Toast/useToast';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface CopyToClipboardButtonProps extends IonButtonProps {
  clipboardContent: string;
  successToastMessage: string;
  errorToastMessage: string;
}

const CopyToClipboardButton = forwardRef<
  HTMLIonButtonElement,
  CopyToClipboardButtonProps
>(
  (
    {
      clipboardContent,
      successToastMessage,
      errorToastMessage,
      children,
      ...rest
    },
    ref
  ) => {
    const [presentToast, dismissToast] = useToast();
    const buttondIdRef = useRef(
      `copy-to-clipboard-button-${generateRandomNumber(3)}`
    );

    const handleCopyToClipboard = () => {
      navigator.clipboard
        .writeText(clipboardContent)
        .then(() => {
          // if (Math.random() < 0.5) {
          //   throw new Error();
          // }
          presentToast(
            successToastMessage,
            'success',
            buttondIdRef.current,
            'bottom'
          );
        })
        .catch(err => {
          // console.error('Failed to copy code to clipboard', err);
          presentToast(
            errorToastMessage,
            'error',
            buttondIdRef.current,
            'bottom'
          );
        });
    };

    return (
      <>
        <IonButton
          id={buttondIdRef.current}
          ref={ref}
          onClick={handleCopyToClipboard}
          {...rest}>
          {children}
        </IonButton>
      </>
    );
  }
);

const generateRandomNumber = (length: number) =>
  Math.floor(Math.random() * Math.pow(10, length - 1))
    .toString()
    .padStart(length, '0');

const delay = (delayInMiliseconds: number) =>
  new Promise(resolve => setTimeout(resolve, delayInMiliseconds));

export default CopyToClipboardButton;
