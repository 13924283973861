import { ReactElement } from 'react';
import NotFoundPage from './NotFoundPage';

const renderPageIfModelDefinedOrNotFoundPage = (
  model: any | null,
  page: ReactElement<any, any>
): ReactElement<any, any> | null => {
  if (model === null) {
    return <NotFoundPage />;
  } else {
    return page;
  }
};

export default renderPageIfModelDefinedOrNotFoundPage;
