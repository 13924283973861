// Usage example
// const hexColor = "#ff0000"; // Red color
// const opacity = 0.5;
// const rgbaColor = hexToRgba(hexColor, opacity);

// <div style={{ backgroundColor: rgbaColor }}>
//   This div has a semi-transparent red background.
// </div>
export function hexToRgba(hex: string, opacity: number): string {
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
