/**
 * getPageArguments() and setPageArguments() are used as a way to pass
 * one-time arguments across pages without having the arguments persisted
 * in the URL or re-appearing when navigating forward from the destination
 * page and then going back to the destination page, or reloading the
 * destinatation page.
 */
const pageArguments: { [key: string]: any } = {};

export const setPageArguments = (pageRoute: string, value: any) => {
  pageArguments[pageRoute] = value;
};

export const consumePageArguments = (pageRoute: string) => {
  const pageArgumentsForPath = pageArguments[pageRoute];
  if (pageArgumentsForPath) {
    delete pageArguments[pageRoute];
  }
  return pageArgumentsForPath;
};
