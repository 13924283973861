import React from 'react';
import ContentLoadingIndicatorComp from './ContentLoadingIndicatorComp';

enum ContentLoadingState {
  Loading = 'Loading',
  Loaded = 'Loaded',
  Error = 'Error',
}

interface ContentLoadingCompProps {
  loadingState: ContentLoadingState;
  loadedContent: React.ReactNode;
  errorContent?: React.ReactNode;
  fullScreen?: boolean;
}

const ContentLoadingComp: React.FC<ContentLoadingCompProps> = ({
  loadingState,
  loadedContent,
  errorContent,
  fullScreen = true,
}) => {
  switch (loadingState) {
    case ContentLoadingState.Loading:
      return (
        <ContentLoadingIndicatorComp
          className={fullScreen ? undefined : 'compact'}
        />
      );
    case ContentLoadingState.Loaded:
      return <>{loadedContent}</>;
    case ContentLoadingState.Error:
      return <>{errorContent ?? 'Oops. Looks like there is nothing here.'}</>;
  }
};

export default ContentLoadingComp;
export { ContentLoadingState };
