import React, { ReactNode, useEffect, useState, useRef } from 'react';
import { usePostHog } from 'posthog-js/react';

import ContentLoadingPage from '../Common/ContentLoading/ContentLoadingPage';
import composeTrackUserActionWithPostHog from '../External/PostHog/trackUserActionWithPostHog';
import EmailLinkLogInPage from './EmailLinkLogin/EmailLinkLogInPage';
import {
  isEmailLoginLink,
  isLoginLinkWithStandaloneFlow,
} from './EmailLinkLogin/loginLink';
import { useAuthenticatedUser } from './AuthenticatedUserContext';
import { User } from './User';
import { once } from '../Common/Functions/functions';
import TrackUserAction from '../Analytics/UserActivity/TrackUserAction';

interface ProtectedPageContainerProps {
  children: ReactNode;
}

enum ProtectedPageContainerState {
  Loading = 'Loading',
  Login = 'Login',
  Content = 'Content',
}

const trackAuthUserLoadStarted = once((trackAction: TrackUserAction) => {
  trackAction('auth user load started');
});

const trackAuthUserLoadCompleted = once(
  (trackAction: TrackUserAction, state: ProtectedPageContainerState) => {
    trackAction('auth user load completed', {
      state: state,
    });
  }
);

const determineProtectedPageContainerState = (
  user: User | null | undefined,
  currentUrl: string,
  didFinishLoginFlow: boolean
) => {
  if (user === undefined) {
    return ProtectedPageContainerState.Loading;
  }

  const isLoginLink = isEmailLoginLink(currentUrl);
  const isStandaloneModeLoginLink =
    isLoginLink && isLoginLinkWithStandaloneFlow(currentUrl);

  const isLoggedInAndOpeningLoginLink = user && isLoginLink;
  const isBrowserModeLoginFlow = !isStandaloneModeLoginLink;

  // console.log('determineProtectedPageContainerState');
  // console.log('currentUrl', currentUrl);
  // console.log('user', user);
  // console.log('isLoginLink', isLoginLink);
  // console.log('isStandaloneLoginLink', isStandaloneModeLoginLink);
  // console.log('didFinishLoginFlow', didFinishLoginFlow);

  // once the user object has been broadcasted, we only go to the login page if:
  // 1. it is a standalone mode flow, to show the get-code page
  // 2. the user is already logged in, and is opening a login link,
  //    to show the already-logged-in page
  // 3. user logged out and user object became 'null', to show the login page
  // When user leaves the success page or the already-logged-in page,
  // we use `didFinishLoginFlow` to re-render this component.
  if (
    user &&
    (isBrowserModeLoginFlow || didFinishLoginFlow) &&
    !isLoggedInAndOpeningLoginLink
  ) {
    return ProtectedPageContainerState.Content;
  }

  return ProtectedPageContainerState.Login;
};

const ProtectedPageContainer: React.FC<ProtectedPageContainerProps> = ({
  children,
}) => {
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);
  const [state, setState] = useState<ProtectedPageContainerState>(
    ProtectedPageContainerState.Loading
  );
  const user = useAuthenticatedUser();
  const [didContinueToApp, setDidContinueToApp] = useState<boolean>(false);

  useEffect(() => {
    // console.log('user', user);
    // console.log('didContinueToApp', didContinueToApp);
    // setContentState(ProtectedPageContainerState.Login);
    // return;
    const state = determineProtectedPageContainerState(
      user,
      window.location.toString(),
      didContinueToApp
    );

    setState(state);
  }, [user, didContinueToApp]);

  useEffect(() => {
    if (state === ProtectedPageContainerState.Loading) {
      trackAuthUserLoadStarted(trackAction);
    }

    if (state !== ProtectedPageContainerState.Loading) {
      trackAuthUserLoadCompleted(trackAction, state);
    }
  }, [state]);

  return (() => {
    switch (state) {
      case ProtectedPageContainerState.Loading:
        return <ContentLoadingPage />;
      case ProtectedPageContainerState.Login:
        return (
          <EmailLinkLogInPage
            onContinueToApp={() => setDidContinueToApp(true)}
          />
        );
      case ProtectedPageContainerState.Content:
        return <>{children}</>;
    }
  })();
};

export default ProtectedPageContainer;
