import { IonButton } from '@ionic/react';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface TroubleshootButtonItemProps extends IonButtonProps {
  buttonTitle: string;
  onButtonClicked: () => void;
}

const TroubleshootButtonItem: React.FC<TroubleshootButtonItemProps> = ({
  buttonTitle,
  onButtonClicked,
  ...rest
}) => {
  return (
    <IonButton onClick={onButtonClicked} fill="clear" size="default" {...rest}>
      {buttonTitle}
    </IonButton>
  );
};

export default TroubleshootButtonItem;
