import React from 'react';
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonNote,
  IonPage,
  IonRow,
  useIonRouter,
} from '@ionic/react';

import AppContentContainer from '../../Common/AppContentContainer';
import renderPageIfModelDefinedOrNotFoundPage from '../../Common/Pages/pageIfModelDefinedOrNotFoundPage';
import AuthorizedDeckContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';
import PipCategoryLabel from '../Common/PipCategoryLabel';
import { getCardDeck } from '../Home/cardDecks';
import PageSectionHeaderComp from '../../Common/UI/Sections/PageSectionHeaderComp';
import CardCell, {
  cardGridColumnSizeSm,
  cardGridColumnSizeXs,
} from '../Common/CardCell/CardCell';

import { CardDeckCardListSection } from './cardDeckCardListSections';
import './CardDeckCardsGridPage.css';

type CardDetailsRouteResolver = (deckId: number, cardId: number) => string;

const getCardListDocumentTitle = (deckId: string, cardListTitle?: string) => {
  const deckIdNumber = Number(deckId);
  const cardDeckTitle = getCardDeck(deckIdNumber)?.title;
  const suffix = cardListTitle != undefined ? `${cardListTitle} | ` : '';
  return `${suffix}${cardDeckTitle}`;
};

interface CardDeckCardsGridPageProps {
  deckId: string;
  breadcrumbs: React.ReactNode;
  cardListSections: CardDeckCardListSection[] | null | undefined;
  shouldShowCategorySectionTitle: boolean;
  getCardDetailsRoute: CardDetailsRouteResolver;
  bottomSection?: React.ReactNode;
}

const CardDeckCardsGridPage: React.FC<CardDeckCardsGridPageProps> = ({
  deckId,
  breadcrumbs,
  cardListSections,
  shouldShowCategorySectionTitle,
  getCardDetailsRoute,
  bottomSection,
}) => {
  // console.log(`Loading card list`);
  const router = useIonRouter();
  const onCardClick = (cardId: number) => {
    router.push(getCardDetailsRoute(Number(deckId), cardId));
  };

  return renderPageIfModelDefinedOrNotFoundPage(
    cardListSections,
    <IonPage id="card-deck-cards">
      <AppContentContainer>
        <AuthorizedDeckContentAccessContainer deckId={deckId}>
          {breadcrumbs}
          {cardListSections?.map((cardListSection, index) => (
            <React.Fragment key={index}>
              {shouldShowCategorySectionTitle && (
                <PageSectionHeaderComp
                  title={
                    shouldShowCategorySectionTitle ? (
                      <PipCategoryLabel
                        title={cardListSection.title}
                        iconCode={cardListSection.iconCode}
                        iconColor={cardListSection.color}
                      />
                    ) : null
                  }
                  key={cardListSection.title}
                />
              )}
              {cardListSection.description && (
                <div className="section-description">
                  {cardListSection.description}
                </div>
              )}
              <IonGrid className="cards-grid">
                <IonRow>
                  {cardListSection.cards.map(tactic => (
                    <IonCol
                      sizeSm={cardGridColumnSizeSm}
                      sizeXs={cardGridColumnSizeXs}
                      offsetSm="0"
                      offsetXs="2"
                      key={tactic.id}
                    >
                      <CardCell
                        title={tactic.title}
                        imageSource={tactic.imageUrl}
                        description={tactic.description}
                        onClick={() => onCardClick(tactic.id)}
                        categoryColor={tactic.style.borderColor}
                      />
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            </React.Fragment>
          ))}
          {bottomSection}
          <div className="bottom-buttons-margin-adjust"></div>
        </AuthorizedDeckContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckCardsGridPage;

export { getCardListDocumentTitle };
