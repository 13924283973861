import {
  getRecipeCardListItemAndSectionByCardId,
  getTacticCardListItemAndSectionByCardId,
} from '../CardDeckCardList/cardDeckCardListSections';
import { CardDeckRoutes } from '../../cardDecksRoutes';

export const getCardDescriptionByIdAndType = (
  cardId: number,
  cardType: string
) => {
  switch (cardType) {
    case 'tactic':
      const { deckId: tacticCardDeckId } =
        getTacticCardListItemAndSectionByCardId(cardId);
      const tacticCardDescription = CardDeckRoutes.cardDeckTacticCard.describe(
        tacticCardDeckId.toString(),
        cardId.toString()
      );
      return tacticCardDescription;
    case 'recipe':
      const { deckId: recipeCardDeckId } =
        getRecipeCardListItemAndSectionByCardId(cardId);
      const recipeCardDescription = CardDeckRoutes.cardDeckRecipeCard.describe(
        recipeCardDeckId.toString(),
        cardId.toString()
      );
      return recipeCardDescription;
  }
};
