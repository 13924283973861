import { PostHog } from 'posthog-js';
import { ResetUserIdentity } from '../../Analytics/UserInfo/ResetUserIdentity';

const composeResetUserIdentityWithPostHog = (
  postHog: PostHog
): ResetUserIdentity => {
  return () => {
    // console.log('resetUserIdentity');
    postHog.reset(true);
  };
};

export default composeResetUserIdentityWithPostHog;
