import React from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';

const options: Partial<PostHogConfig> = {
  api_host: 'https://eu.posthog.com',
  capture_pageview: false,
  person_profiles: 'identified_only',
};

const PostHogAnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <PostHogProvider
    apiKey={'phc_4I57wQRLeJ3Q2IgKB7RM2jDwF8Fkw4O0tWX5iv0CxZ1'}
    options={options}>
    {children}
  </PostHogProvider>
);

export default PostHogAnalyticsProvider;
