import { IonItem, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import './CardDeckRecipeCardLinkedCardsListItemComp.css';
import EnhancedHtmlToReactComp from '../../../Common/EnhancedHtmlToReactComp';
import { CardDeckRecipeCardItem } from './cardDeckRecipeCard';
import { CardDeckRecipeCardLinkedCardsListItemTitleStyle } from './CardDeckRecipeCardPage';

interface CardDeckRecipeCardLinkedCardsListItemCompProps {
  card: CardDeckRecipeCardItem;
  index: number;
  listItemTitleStyle: CardDeckRecipeCardLinkedCardsListItemTitleStyle;
}

const CardDeckRecipeCardLinkedCardsListItemComp: React.FC<
  CardDeckRecipeCardLinkedCardsListItemCompProps
> = ({ card, index, listItemTitleStyle }) => {
  const { t, ready: isTReady } = useTranslation('decks');

  const borderColor = card.borderColor;
  const borderStyle =
    borderColor != undefined
      ? {
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: borderColor,
        }
      : {};
  const cardImageUrl = card.imageUrl;
  const currentItemIndex = index + 1;
  const titlePrefix =
    listItemTitleStyle ===
    CardDeckRecipeCardLinkedCardsListItemTitleStyle.numbered
      ? `${currentItemIndex}. `
      : '';
  const title =
    card.title ||
    t('CardDeckRecipeCardPage_ListItemTitle', {
      index: currentItemIndex,
    });

  return isTReady ? (
    <IonItem detail={false} className="list-item">
      <IonLabel className="ion-text-wrap">
        <h2>
          {titlePrefix}
          <EnhancedHtmlToReactComp
            html={title}
            shouldOpenImagesInFullScreen={false}
          />
        </h2>
        <p>
          <EnhancedHtmlToReactComp
            html={card.summary}
            // because summaries may contain images of linked cards
            // which we don't want to open on tap
            shouldOpenImagesInFullScreen={false}
          />
        </p>
        {cardImageUrl ? <img src={cardImageUrl} style={borderStyle} /> : null}
      </IonLabel>
    </IonItem>
  ) : null;
};

export default CardDeckRecipeCardLinkedCardsListItemComp;
