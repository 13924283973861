import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { usePostHog } from 'posthog-js/react';
import { PostHog } from 'posthog-js';

import { CardDeckRoutes } from '../cardDecksRoutes';

const PageViewTracker: React.FC = () => {
  const location = useLocation();
  const posthog = usePostHog();

  useEffect(() => {
    trackPageView(location.pathname + location.search, posthog);
  }, [location]);

  return null;
};

export default PageViewTracker;

type RouteDescriptionFunction = (...args: any[]) => string;

const trackPageView = (path: string, posthog: PostHog) => {
  const description = getRouteDescription(path);
  // console.log(`Page view tracked: ${description}`);

  posthog.capture('$pageview', {
    description: description,
  });
};

const getRouteDescription = (urlPath: string) => {
  const match = matchRouteAndParseParams(urlPath);
  if (match) {
    const { routeKey, params } = match;
    if (routeKey && params) {
      const routes: { [key: string]: { describe: RouteDescriptionFunction } } =
        CardDeckRoutes;
      const description = routes[routeKey].describe(...params);
      return description;
    }
  }
};

interface RouteMatch {
  routeKey?: string;
  params?: [string];
}

const matchRouteAndParseParams = (path: string): RouteMatch | null => {
  for (const routeKey of Object.keys(CardDeckRoutes) as Array<
    keyof typeof CardDeckRoutes
  >) {
    const route = CardDeckRoutes[routeKey];
    const match = matchPath<{
      deckId: string;
      cardId: string;
      categoryId: string;
    }>(path, {
      path: route.path,
      exact: true,
      strict: false,
    });

    if (match) {
      const params = Object.keys(match.params).reduce(
        (paramsArray, paramKey) => {
          const param = (match.params as any)[paramKey];
          const cleanedParam = param.split('?')[0];
          paramsArray.push(cleanedParam);
          return paramsArray;
        },
        [] as unknown as [string]
      );

      return { routeKey: routeKey, params: params };
    }
  }
  return null;
};
