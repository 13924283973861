import React from 'react';
import { useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import { SolveMyProblemRoutes } from '../../SolveMyProblem/Common/getSolveMyProblemRoutes';

import HomeImageTitleCell from './HomeImageTitleCell';

const HomeSolveMyProblemCell: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('solve-my-problem');
  const router = useIonRouter();
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);

  const goToSolveMyProblem = () => {
    router.push(SolveMyProblemRoutes.challenge.resolve());
  };

  const onClick = () => {
    trackAction('navigate to Solve My Problem');
    goToSolveMyProblem();
  };

  return isTReady ? (
    <HomeImageTitleCell
      imageSource="/images/home/illustrations/map-icon.png"
      title={t('HomeSolveMyProblemCell_title')}
      onClick={onClick}
    />
  ) : null;
};

export default HomeSolveMyProblemCell;
