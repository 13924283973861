import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from '@ionic/react';
import { mailOpenOutline } from 'ionicons/icons';

import CenteredContentContainer from '../../Common/UI/Buttons/CenteredContentContainer';
import { stringHtmlAsReactComp } from '../../Common/UI/HTML/HtmlToReactComp';

interface EmailLinkTroubleshootCompProps {
  onResendClicked: () => void;
  onBackClicked: () => void;
}

const EmailLinkTroubleshootComp: React.FC<EmailLinkTroubleshootCompProps> = ({
  onResendClicked,
  onBackClicked,
}) => {
  const { t, ready: isTReady } = useTranslation('authentication');
  return isTReady ? (
    <>
      <IonList>
        <IonItem lines="none" className="header-item">
          <IonIcon icon={mailOpenOutline} slot="start"></IonIcon>
          <IonLabel className="ion-text-wrap">
            <h1>{t('EmailLinkTroubleshootComp_troubleshootingTitle')}</h1>
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonNote color="dark" className="ion-text-wrap">
            {stringHtmlAsReactComp(
              t('EmailLinkTroubleshootComp_troubleshootingInstructions')
            )}
          </IonNote>
        </IonItem>
        <IonButton
          expand="block"
          onClick={onBackClicked}
          fill="solid"
          className="action large-gap"
          size="default">
          {t('EmailLinkTroubleshootComp_backButtonLabel')}
        </IonButton>
        <IonItem lines="none">
          <CenteredContentContainer>
            <IonButton
              onClick={onResendClicked}
              fill="clear"
              size="default"
              className="gap">
              {t('EmailLinkTroubleshootComp_resendLinkButtonLabel')}
            </IonButton>
          </CenteredContentContainer>
        </IonItem>
      </IonList>
    </>
  ) : null;
};

export default EmailLinkTroubleshootComp;
