import { Listen } from '../../../Common/Types/Listen';
import { Customer } from './Customer';

export enum ListenCustomerError {
  Generic = 'Generic',
}

export type ListenCustomer = Listen<
  [userEmail: string],
  Customer,
  ListenCustomerError
>;
