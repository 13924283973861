import React from 'react';
import RouterLink from '../Link/RouterLink';
import ChevronIcon from '../../UI/Icons/ChevronIcon';
import './Breadcrumb.css';

interface BreadcrumbProps {
  route?: string;
  hideChevron?: boolean;
  children: React.ReactNode;
  className?: string;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  route,
  hideChevron = false,
  children,
  className,
}) => {
  const baseClassName = `breadcrumb ${route ? 'link' : ''}`;
  const classNames =
    className != undefined ? className + ' ' + baseClassName : baseClassName;
  return (
    <div className={classNames}>
      {!hideChevron && <Separator />}
      {route ? (
        <RouterLink href={route} className="title" style={{ display: 'flex' }}>
          {children}
        </RouterLink>
      ) : (
        <span className="title">{children}</span>
      )}
    </div>
  );
};

const Separator = () => (
  <div className="separator">
    <ChevronIcon />
  </div>
);

export default Breadcrumb;
