import { useEffect, useState } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { signInWithCustomToken } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import getFirebaseAuth from '../../../External/Firebase/FirebaseAuth';

import { getIonInputClasses } from '../../../Common/UI/Ionic/IonInputExtensions';
import ButtonWithSpinner from '../../../Common/UI/Buttons/ButtonWithSpinner';

import { emailLinkLoginFormLocalStorage } from '../emailLinkLoginFormLocalStorage';

type LoginCodeItemsProps = {
  // this allows us to modify alternative actions based on remote code validation
  onCodeValidityChanged: (isCodeValid: boolean | undefined) => void;
};

const LoginCodeItems: React.FC<LoginCodeItemsProps> = ({
  onCodeValidityChanged,
}) => {
  const [code, setCode] = useState<string>('');
  // there needs to be a default value set,
  // otherwise the input doesn't draw a bottom border
  const [inputErrorMessage, setInputErrorMessage] = useState<string>('error');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isInputFieldValueValid, setInputFieldValueValid] = useState<
    boolean | undefined
  >(undefined);
  const [isCodeInvalid, setIsCodeInvalid] = useState<boolean | undefined>(
    undefined
  );
  const { t, ready: isTReady } = useTranslation('authentication');

  useEffect(() => {
    onCodeValidityChanged(
      isCodeInvalid == undefined ? undefined : !isCodeInvalid
    );
  }, [isCodeInvalid]);

  const onSubmitButtonClicked = async () => {
    if (!code) {
      setInputErrorMessage(
        t('EmailLinkSentComp_LoginCodeItems_emptyCodeError')
      );
      setInputFieldValueValid(false);
      return;
    }

    //await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      // throw new Error('test');
      const functions = getFunctions();
      const requestTokenWithLoginCode = httpsCallable(
        functions,
        'requestTokenWithLoginCode'
      );
      const email = emailLinkLoginFormLocalStorage.getRequestedLoginLinkEmail();
      const result = await requestTokenWithLoginCode({
        email: email,
        code: code,
      });

      const customToken = (result.data as { token: string }).token;
      const auth = getFirebaseAuth();
      return signInWithCustomToken(auth, customToken).then(userCredential => {
        // ProtectedPageContainer will take care of the redirect
        // console.log('User signed in with custom token', userCredential.user);
      });
    } catch (error) {
      // console.error('Error signing in with custom token', error);
      const errorCode = (error as { code: string }).code;
      if (errorCode === 'functions/invalid-argument') {
        setIsCodeInvalid(true);
      }
      const message = mapCodeSubmissionErrorToMessage(t, errorCode);
      setInputErrorMessage(message);
      setInputFieldValueValid(false);
    }
  };
  const submitButtonClicked = async () => {
    setIsTouched(true);
    await onSubmitButtonClicked();
  };

  return isTReady ? (
    <>
      <IonItem lines="none">
        <IonInput
          type="text"
          clearInput={true}
          className={getIonInputClasses(isInputFieldValueValid, isTouched)}
          label={t('EmailLinkSentComp_LoginCodeItems_inputLabel')}
          labelPlacement="stacked"
          placeholder={t('EmailLinkSentComp_LoginCodeItems_inputPlaceholder')}
          errorText={inputErrorMessage}
          onIonInput={e => {
            // console.log('onIonInput');
            const code = e.detail.value;
            setCode(code!);
          }}
          onFocus={() => {
            // console.log('onFocus');
            setInputFieldValueValid(undefined);
            setIsCodeInvalid(undefined);
          }}
          onIonBlur={() => {
            // console.log('onIonBlur');
          }}
        />
      </IonItem>
      <ButtonWithSpinner
        expand="block"
        title={t('EmailLinkSentComp_LoginCodeItems_buttonLabel')}
        className="action gap"
        onClick={submitButtonClicked}
      />
    </>
  ) : null;
};

const mapCodeSubmissionErrorToMessage = (
  t: (i18nKey: string) => string,
  errorCode: string
) => {
  switch (errorCode) {
    case 'functions/unknown':
      return t('EmailLinkSentComp_LoginCodeItems_unknownCodeError');
    case 'functions/invalid-argument':
      return t('EmailLinkSentComp_LoginCodeItems_invalidCodeError');
    default:
      return t('EmailLinkSentComp_LoginCodeItems_unknownCodeError');
  }
};

export default LoginCodeItems;
