import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';

import HomeImageTitleCell from './HomeImageTitleCell';

const HomeGuidesCell: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('guides');

  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);

  const goToGuides = () =>
    (window.location.href = 'https://guide.pipdecks.com/');

  const onClick = () => {
    trackAction('navigate to guides from decks list');
    goToGuides();
  };

  return isTReady ? (
    <HomeImageTitleCell
      imageSource="/images/home/illustrations/compass-icon.png"
      title={t('HomeGuidesCell_title')}
      onClick={onClick}
    />
  ) : null;
};

export default HomeGuidesCell;
