import { CardDeckRoutes } from '../../../cardDecksRoutes';
import { getCardDeckCategory } from '../../CardDeckCategories/cardDeckCategories';

const provideCategoryBreadcrumbProps = (
  deckId: string,
  categoryId: string,
  isLinking: boolean
) => {
  const deckIdNumber = Number(deckId);
  const categoryIdNumber = Number(categoryId);
  const category = getCardDeckCategory(deckId, categoryIdNumber);
  const route = isLinking
    ? CardDeckRoutes.cardDeckCategoryTacticCardList.resolve(
        deckIdNumber,
        categoryIdNumber
      )
    : undefined;
  return {
    route: route,
    text: category?.title ?? '',
    iconCode: category?.iconCode ?? '',
    iconColor: category?.color,
  };
};

export default provideCategoryBreadcrumbProps;
