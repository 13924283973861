import React, { useEffect, useState } from 'react';

import ContentLoadingComp, {
  ContentLoadingState,
} from '../../../Common/ContentLoading/ContentLoadingComp';
import {
  isDataLoadingResultSuccess,
  isDataLoadingResultError,
} from '../../../Common/Types/DataLoadingResult';
import { useAuthenticatedCustomer } from './AuthenticatedCustomerContext';
import { getDeckIdsUserCanAccessResult } from './cardDeckAuthorizedAccessFunctions';
import NewCustomerCallToActionComp from './UnauthorizedAccess/NewCustomerCallToActionComp';
import AuthenticatedCustomerLoadingErrorComp from './UnauthorizedAccess/AuthenticatedCustomerLoadingErrorComp';

interface AuthorizedDecksContentAccessContainerProps {
  children: React.ReactNode;
}

const AuthorizedDecksContentAccessContainer: React.FC<
  AuthorizedDecksContentAccessContainerProps
> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const [errorContent, setErrorContent] = useState<React.ReactNode>(null);

  const customer = useAuthenticatedCustomer();

  useEffect(() => {
    const deckIdsResult = getDeckIdsUserCanAccessResult(customer);

    if (isDataLoadingResultSuccess(deckIdsResult)) {
      if (deckIdsResult.data) {
        setLoadingState(ContentLoadingState.Loaded);
      } else {
        setErrorContent(<NewCustomerCallToActionComp />);
        setLoadingState(ContentLoadingState.Error);
      }
    } else if (isDataLoadingResultError(deckIdsResult)) {
      setErrorContent(
        <AuthenticatedCustomerLoadingErrorComp
          onRetryButtonClick={() => {
            setLoadingState(ContentLoadingState.Loading);
            deckIdsResult.error!.retry();
          }}
        />
      );
      setLoadingState(ContentLoadingState.Error);
    }
    // else still loading
  }, [customer]);

  return (
    <ContentLoadingComp
      loadingState={loadingState}
      loadedContent={children}
      errorContent={errorContent}
    />
  );
};

export default AuthorizedDecksContentAccessContainer;
