import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { IonButton, IonItem, IonList, IonNote } from '@ionic/react';

import './AddRemoveBookmarkButton.css';
import addBookmarkIcon from '/images/bookmarks/icons/add-bookmark.svg';
import savedBookmarkIcon from '/images/bookmarks/icons/add-bookmark-filled.svg';

import ContentLoadingComp, {
  ContentLoadingState,
} from '../../../Common/ContentLoading/ContentLoadingComp';
import HtmlToReactComp from '../../../Common/UI/HTML/HtmlToReactComp';
import CenteredContentContainer from '../../../Common/UI/Buttons/CenteredContentContainer';
import { isResultError, isResultOk } from '../../../Common/Types/Result';
import { useAuthenticatedUser } from '../../../Authentication/AuthenticatedUserContext';
import ButtonWithSpinner from '../../../Common/UI/Buttons/ButtonWithSpinner';
import useToast from '../../../Common/UI/Toast/useToast';
import {
  AddCardBookmark,
  ListenCardBookmarks,
  RemoveCardBookmark,
} from '../bookmarksStorage';
import { getCardDescriptionByIdAndType } from '../getCardDescriptionByIdAndType';

export interface CardBookmarkProps {
  cardId: number;
  cardType: string;
}

interface AddRemoveBookmarkButtonProps {
  getCardBookmarkProps: () => CardBookmarkProps;
  addCardBookmark: AddCardBookmark;
  listenCardBookmarks: ListenCardBookmarks;
  removeCardBookmark: RemoveCardBookmark;
  isOnScreen: boolean;
}

const AddRemoveBookmarkButton: React.FC<AddRemoveBookmarkButtonProps> = ({
  getCardBookmarkProps,
  addCardBookmark,
  listenCardBookmarks,
  removeCardBookmark,
  isOnScreen,
}) => {
  const [bookmarkId, setBookmarkId] = useState<string | null | undefined>(
    undefined
  );
  const buttonRef = useRef<HTMLIonButtonElement>(null);
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const [reloadKey, setReloadKey] = useState<number>(0);

  const user = useAuthenticatedUser();
  const posthog = usePostHog();
  const [presentToast, dismissToast] = useToast();
  const { t, ready: isTReady } = useTranslation('bookmarks');

  // ensures that the toast is dismissed when user navigates away from
  // the page that shows this button and the toast
  !isOnScreen && dismissToast();

  const getBookmarkButtonTitle = (bookmarkId: string | null | undefined) => {
    if (bookmarkId) {
      return t('AddRemoveBookmarkButton_removeTitle');
    } else {
      return t('AddRemoveBookmarkButton_addTitle');
    }
  };

  const onBookmarkButtonTap = async () => {
    if (!user) {
      return;
    }

    dismissToast();

    if (bookmarkId) {
      try {
        await removeCardBookmark(bookmarkId, user.id);
        setBookmarkId(null);
      } catch (e) {
        presentRemoveBookmarkError();
        return;
      }
    } else {
      const { cardId, cardType } = getCardBookmarkProps();
      try {
        const bookmarkId = await addCardBookmark(cardId, cardType, user.id);
        setBookmarkId(bookmarkId);
      } catch (e) {
        presetAddBookmarkError();
        return;
      }
    }

    trackBookmarkChangeEvent(bookmarkId);
  };

  const presetAddBookmarkError = () => {
    presentErrorToast(t('AddRemoveBookmarkButton_addErrorTitle'));
  };

  const presentRemoveBookmarkError = () => {
    presentErrorToast(t('AddRemoveBookmarkButton_removeErrorTitle'));
  };

  const presentErrorToast = (message: string) => {
    presentToast(message, 'error', buttonRef.current ?? undefined, 'bottom');
  };

  const trackBookmarkChangeEvent = (bookmarkId: string | null | undefined) => {
    var eventName: string = bookmarkId ? 'remove bookmark' : 'add bookmark';
    const { cardId, cardType } = getCardBookmarkProps();
    const eventProps = {
      cardId: cardId,
      cardType: cardType,
      description: getCardDescriptionByIdAndType(cardId, cardType),
    };
    posthog?.capture(eventName, eventProps);
  };

  useEffect(() => {
    if (!isTReady) {
      return;
    }

    if (user) {
      const cardBookmarkProps = getCardBookmarkProps();
      return listenCardBookmarks(user.id, bookmarksResult => {
        if (isResultError(bookmarksResult)) {
          setLoadingState(ContentLoadingState.Error);
        } else if (isResultOk(bookmarksResult)) {
          const bookmark = bookmarksResult.data.find(
            bookmark =>
              bookmark.cardId === cardBookmarkProps.cardId &&
              bookmark.cardType === cardBookmarkProps.cardType
          );
          setBookmarkId(bookmark?.id ?? null);
          setLoadingState(ContentLoadingState.Loaded);
        }
      });
    }
  }, [isTReady, user, getCardBookmarkProps, reloadKey]);

  const buttonNode = (
    <ButtonWithSpinner
      ref={buttonRef}
      title={getBookmarkButtonTitle(bookmarkId)}
      loadingTitle={t(
        bookmarkId
          ? 'AddRemoveBookmarkButton_removeActivityTitle'
          : 'AddRemoveBookmarkButton_addActivityTitle'
      )}
      icon={bookmarkId ? savedBookmarkIcon : addBookmarkIcon}
      fill="outline"
      expand="block"
      className="secondary bookmark-button"
      containerClassName="bookmark-button-container"
      onClick={onBookmarkButtonTap}
    />
  );

  const onRetryLoad = () => {
    setLoadingState(ContentLoadingState.Loading);
    setTimeout(() => {
      setReloadKey(key => key + 1);
    }, Math.pow(2, reloadKey) * 1000);
  };

  const errorNode = (
    <div className="card-container empty-card">
      {/* reduce bottom padding because the clear button has a lot of whitespace at the bottom */}
      <div className="card-background small clear-button-at-the-bottom">
        <IonList>
          <IonItem lines="none" className="content-hugging-item">
            <IonNote color="dark" className="ion-text-wrap ion-text-center">
              <HtmlToReactComp
                html={t('AddRemoveBookmarkButton_listenErrorDescription')}
              />
            </IonNote>
          </IonItem>
          <IonItem lines="none">
            <CenteredContentContainer>
              <IonButton
                size="default"
                onClick={onRetryLoad}
                fill="clear"
                strong
              >
                {t('AddRemoveBookmarkButton_listenErrorButtonTitle')}
              </IonButton>
            </CenteredContentContainer>
          </IonItem>
        </IonList>
      </div>
    </div>
  );

  return (
    <ContentLoadingComp
      loadingState={loadingState}
      loadedContent={buttonNode}
      errorContent={errorNode}
      fullScreen={false}
    />
  );
};

export default AddRemoveBookmarkButton;
