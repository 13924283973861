export type Result<T, E> =
  | { ok: true; data: T | null }
  | { ok: false; error: E };

export const okResult = <T, E>(data: T | null): Result<T, E> => ({
  ok: true,
  data: data,
});

export const errorResult = <T, E>(error: E): Result<T, E> => ({
  ok: false,
  error: error,
});

export const isResultOk = <T, E>(
  result: Result<T, E>
): result is {
  ok: true;
  data: T;
} => result.ok;

export const isResultError = <T, E>(
  result: Result<T, E>
): result is {
  ok: false;
  error: E;
} => !result.ok;

export const mapToCallbacks = <T, E>(
  result: Result<T, E>,
  onSuccess: (data: T) => void,
  onError: (error: E) => void
) => {
  if (isResultOk(result)) {
    onSuccess(result.data);
  } else if (isResultError(result)) {
    onError(result.error);
  }
};

export const mapErrorResult = <T, E1, E2>(
  result: Result<T, E1>,
  transform: (error: E1) => E2
): Result<T, E2> => {
  if (isResultError(result)) {
    return errorResult(transform(result.error));
  }
  return result;
};

export const mapResult = <T1, T2, E1, E2>(
  result: Result<T1, E1>,
  transformData: (data: T1) => T2,
  transformError: (error: E1) => E2
): Result<T2, E2> => {
  if (isResultError(result)) {
    return errorResult(transformError(result.error));
  } else if (isResultOk(result)) {
    return okResult(transformData(result.data));
  }
  throw new Error('Unreachable');
};

export const mapArrayResult = <T1, T2, E1, E2>(
  result: Result<T1[], E1>,
  transformData: (data: T1) => T2,
  transformError: (error: E1) => E2
): Result<T2[], E2> => {
  if (isResultError(result)) {
    return errorResult(transformError(result.error));
  } else if (isResultOk(result)) {
    return okResult(result.data.map(transformData));
  }
  throw new Error('Unreachable');
};
