import { IonButton } from '@ionic/react';

import HtmlToReactComp from '../../../../Common/UI/HTML/HtmlToReactComp';

import './UnauthorizedAccessCallToActionComp.css';
import AlreadyCustomerComp from './AlreadyCustomerComp';

interface UnauthorizedAccessCallToActionCompProps {
  imageSrc: string;
  imageWidth?: string;
  title: string;
  body: string;
  buttonTitle: string;
  onButtonClick: () => void;
  showAlreadyCustomerComp?: boolean;
}

const UnauthorizedAccessCallToActionComp = ({
  imageSrc,
  imageWidth,
  title,
  body,
  buttonTitle,
  onButtonClick,
  showAlreadyCustomerComp = false,
}: UnauthorizedAccessCallToActionCompProps) => {
  return (
    <div className="card-container empty-card bottom-buttons-margin-adjust">
      <div className="card-background">
        <div className="unauthorized-access-content">
          <img src={imageSrc} style={{ maxWidth: imageWidth }} />
          <h1>{title}</h1>
          <HtmlToReactComp html={body} />
          <IonButton
            size="default"
            onClick={onButtonClick}
            className="action shop-button">
            {buttonTitle}
          </IonButton>
          {showAlreadyCustomerComp && <AlreadyCustomerComp />}
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedAccessCallToActionComp;
