import React from 'react';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import PipCategoryLabel from '../PipCategoryLabel';

interface PipCategoryLabelBreadcrumbProps {
  route?: string;
  text: string;
  iconCode: string;
  iconColor?: string;
}

const PipCategoryLabelBreadcrumb: React.FC<PipCategoryLabelBreadcrumbProps> = ({
  route,
  text,
  iconCode,
  iconColor,
}) => {
  return (
    <Breadcrumb route={route}>
      <PipCategoryLabel
        title={text}
        iconCode={iconCode}
        iconColor={iconColor}
      />
    </Breadcrumb>
  );
};

export default PipCategoryLabelBreadcrumb;
