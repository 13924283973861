import React, { useEffect, useState } from 'react';
import {
  IonPage,
  useIonViewWillEnter,
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import './HomePage.css';

import AppContentContainer from '../../Common/AppContentContainer';

import { getDeckIdsUserCanAccess } from '../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import { useAuthenticatedCustomer } from '../Common/AuthorizedAccess/AuthenticatedCustomerContext';
import AuthorizedDecksContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDecksContentAccessContainer';
import { resolveCardDeckLink } from '../Common/resolveCardDeckLink';

import { CardDeck } from './cardDecks';
import {
  getLockedCardDecks,
  getUnlockedCardDecks,
} from './authorizedCardDecks';
import HomeDeckCell from './Components/HomeDeckCell';
import PageSectionHeaderComp from '../../Common/UI/Sections/PageSectionHeaderComp';
import HomeSolveMyProblemCell from './Components/HomeSolveMyProblemCell';
import HomeGuidesCell from './Components/HomeGuidesCell';
import { SolveMyProblemAccessProvider } from '../SolveMyProblem/Common/AccessAuthorization/SolveMyProblemAccessContext';
import { SolveMyProblemAccessContainer } from '../SolveMyProblem/Common/AccessAuthorization/SolveMyProblemAccessContainer';

const shouldShowDecksSection = (cardDecks: CardDeck[] | null) => {
  return cardDecks && cardDecks.length > 0;
};

const shouldShowExpansionPacksSection = (expansionPacks: CardDeck[] | null) => {
  return expansionPacks && expansionPacks.length > 0;
};

const HomePage: React.FC = () => {
  // console.log('Home page start');
  const [unlockedDecks, setUnlockedDecks] = useState<CardDeck[] | null>(null);
  const [lockedDecks, setLockedDecks] = useState<CardDeck[] | null>(null);
  const [readyToLoadContent, setReadyToLoadContent] = useState<boolean>(false);
  const customer = useAuthenticatedCustomer();
  const { t, ready: isTReady } = useTranslation('home');

  function loadAndDisplayDecks(deckIdsUserCanAccess: Set<number>) {
    const unlockedDecks = getUnlockedCardDecks(deckIdsUserCanAccess);
    const expansionDecks = getLockedCardDecks(deckIdsUserCanAccess);
    setUnlockedDecks(unlockedDecks);
    setLockedDecks(expansionDecks);
  }

  useEffect(() => {
    if (readyToLoadContent) {
      const deckIdsUserCanAccess = getDeckIdsUserCanAccess(customer);
      if (deckIdsUserCanAccess) {
        loadAndDisplayDecks(deckIdsUserCanAccess);
      } else {
        // should be handled by AuthorizedDecksContentAccessContainer
      }
    }
  }, [customer, readyToLoadContent]);

  useIonViewWillEnter(() => {
    setReadyToLoadContent(true);
  });

  const setDocumentTitle = () => {
    document.title = t('HomePage_documentTitle');
  };
  useEffect(() => {
    setDocumentTitle();
  }, [isTReady]);

  const decksContent = isTReady && (
    <>
      <>
        <PageSectionHeaderComp title={t('HomePage_getStartedSectionTitle')} />
        <IonGrid>
          <IonRow>
            <SolveMyProblemAccessProvider>
              <SolveMyProblemAccessContainer>
                <IonCol>
                  <HomeSolveMyProblemCell />
                </IonCol>
              </SolveMyProblemAccessContainer>
            </SolveMyProblemAccessProvider>
            <IonCol>
              <HomeGuidesCell />
            </IonCol>
          </IonRow>
        </IonGrid>
      </>
      {shouldShowDecksSection(unlockedDecks) && (
        <>
          <PageSectionHeaderComp
            title={t('HomePage_unlockedDecksSectionTitle')}
          />
          <IonGrid>
            <IonRow>
              {unlockedDecks?.map((deck, index) => (
                <IonCol size-md="3" size-sm="4" size-xs="6" key={index}>
                  <HomeDeckCell
                    imageSource={deck.imageUrl}
                    title={forceBreakLineForDeckTitle(deck.title)}
                    description={deck.description}
                    deckRoute={resolveCardDeckLink(deck)}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
      {shouldShowExpansionPacksSection(lockedDecks) && (
        <>
          <PageSectionHeaderComp
            title={t('HomePage_lockedDecksSectionTitle')}
          />
          <IonGrid>
            <IonRow>
              {lockedDecks?.map((deck, index) => (
                <IonCol size-md="3" size-sm="4" size-xs="6" key={index}>
                  <HomeDeckCell
                    imageSource={deck.imageUrl}
                    title={forceBreakLineForDeckTitle(deck.title)}
                    description={deck.description}
                    deckRoute={resolveCardDeckLink(deck)}
                    onUnlockClick={() => {
                      window.open(deck.shopUrl, '_blank');
                    }}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </>
      )}
      <div className="bottom-buttons-margin-adjust"></div>
    </>
  );

  return (
    <IonPage id="card-decks">
      <AppContentContainer>
        <AuthorizedDecksContentAccessContainer>
          {decksContent}
        </AuthorizedDecksContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default HomePage;

const forceBreakLineForDeckTitle = (deckTitle: string) => {
  return deckTitle.split(' ').join('<br/>');
};
