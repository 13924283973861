function once<T extends (...args: any[]) => void>(fn: T): T {
  let called = false;

  return function(...args: any[]) {
    if (!called) {
      called = true;
      fn(...args);
    }
  } as T;
}

export { once };