import { systemCards } from '../../../Content/systemCards';

export interface CardDeckSystemItem {
  prompt?: string;
  categoryId?: number;
}

export interface CardDeckSystemCard {
  title: string;
  description?: string;
  prompts: CardDeckSystemItem[];
}

const typedSystemCard: { [key: string]: CardDeckSystemCard } =
  systemCards as unknown as { [key: string]: CardDeckSystemCard };

export const getCardDeckSystemCard = (deckId: string) =>
  typedSystemCard[deckId];
