import React, { useEffect, forwardRef, ReactNode } from 'react';
import { IonContent, ScrollDetail, useIonRouter } from '@ionic/react';

interface ScrolledToTopContentProps {
  children: ReactNode;
  onScroll: (ev: CustomEvent<ScrollDetail>) => void;
}

const ScrolledToTopContent = forwardRef<
  HTMLIonContentElement,
  ScrolledToTopContentProps
>(({ children, onScroll }, ref) => {
  const router = useIonRouter();

  useEffect(() => {
    // Cast ref to React.RefObject
    const refObject = ref as React.RefObject<HTMLIonContentElement>;
    refObject.current?.scrollToTop();
  }, [router.routeInfo, ref]);

  return (
    <IonContent ref={ref} scrollEvents={true} onIonScroll={onScroll}>
      {children}
    </IonContent>
  );
});

export default ScrolledToTopContent;
