import { PostHog } from 'posthog-js';
import TrackUserAction from '../../Analytics/UserActivity/TrackUserAction';

const composeTrackUserActionWithPostHog = (
  postHog: PostHog
): TrackUserAction => {
  return (actionLabel: string, actionProperties?: { [key: string]: any }) => {
    postHog.capture(actionLabel, actionProperties);
  };
};

export default composeTrackUserActionWithPostHog;
