export const systemCards = {
  "1": {
    "title": "Workshop Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you know clearly what your team is working towards?",
        "categoryId": 27
      },
      {
        "prompt": "Do you know the biggest problem you face?",
        "categoryId": 23
      },
      {
        "prompt": "Are you able to articulate your problem clearly?",
        "categoryId": 28
      },
      {
        "prompt": "Do you have a range of ideas on how to solve it?",
        "categoryId": 29
      },
      {
        "prompt": "Have you assessed if your ideas are robust?",
        "categoryId": 24
      },
      {
        "prompt": "Have you decided which ideas to test?",
        "categoryId": 26
      },
      {
        "prompt": "Make a prototype and test it!"
      }
    ]
  },
  "2": {
    "title": "Story Building System",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you know why you need a story?",
        "categoryId": 32
      },
      {
        "prompt": "Do you know where to find your story?",
        "categoryId": 31
      },
      {
        "prompt": "Do you know your role in the story?",
        "categoryId": 33
      },
      {
        "prompt": "Do you know what your story needs to do?",
        "categoryId": 37
      },
      {
        "prompt": "Do you know how to plan your story?",
        "categoryId": 36
      },
      {
        "prompt": "Do you know how to tell your story?",
        "categoryId": 35
      },
      {
        "prompt": "Do you know how to share your story?",
        "categoryId": 34
      },
      {
        "prompt": "Great, you are ready to tell your story to the world!"
      }
    ]
  },
  "3": {
    "title": "Innovation Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you know the struggle you want to help people make progress with?",
        "categoryId": 72
      },
      {
        "prompt": "Do you have ideas for innovative ways to help them make progress?",
        "categoryId": 71
      },
      {
        "prompt": "Do you know the most important aspect of an idea to test?",
        "categoryId": 67
      },
      {
        "prompt": "Do you know which signals in the world will tell you if an idea works?",
        "categoryId": 68
      },
      {
        "prompt": "Do you know how to poke the world to get those signals?",
        "categoryId": 66
      },
      {
        "prompt": "Do you know how to interpret the signals to decide what to do next?",
        "categoryId": 70
      },
      {
        "prompt": "Ship it! (Or repeat earlier steps until you can.)"
      }
    ]
  },
  "4": {
    "title": "Idea Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Is your creative challenge crystal clear?",
        "categoryId": 137
      },
      {
        "prompt": "Have you got material to inspire you?",
        "categoryId": 138
      },
      {
        "prompt": "Do you already have some ideas?",
        "categoryId": 139
      },
      {
        "prompt": "Do any of them excite or surprise you?",
        "categoryId": 140
      },
      {
        "prompt": "Have you described your most interesting ideas in detail?",
        "categoryId": 141
      },
      {
        "prompt": "Do you know what to prioritise?",
        "categoryId": 142
      },
      {
        "prompt": "Make something real and get feedback"
      }
    ]
  },
  "5": {
    "title": "Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you have a clear idea of what you should be working on, and why?",
        "categoryId": 129
      },
      {
        "prompt": "Are you aware of all the moving parts of what you’re working on?",
        "categoryId": 130
      },
      {
        "prompt": "Do you know exactly how the parts combine to create value?",
        "categoryId": 131
      },
      {
        "prompt": "Do you know where things are headed by default?",
        "categoryId": 132
      },
      {
        "prompt": "Do you know how to adapt and thrive?",
        "categoryId": 133
      },
      {
        "prompt": "Do you know how to win?",
        "categoryId": 134
      },
      {
        "prompt": "Does everyone know what to do?",
        "categoryId": 135
      },
      {
        "prompt": "Take action, learn from what happens and go again!"
      }
    ]
  },
  "6": {
    "title": "Team Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Have you defined the roles and environment for your team?",
        "categoryId": 144
      },
      {
        "prompt": "Does your team know its direction?",
        "categoryId": 145
      },
      {
        "prompt": "Does your team have the support it needs?",
        "categoryId": 146
      },
      {
        "prompt": "Are you able to measure your team’s health?",
        "categoryId": 147
      },
      {
        "prompt": "Are you helping your team work better together?",
        "categoryId": 148
      },
      {
        "prompt": "Do your team know how to share their work clearly?",
        "categoryId": 149
      },
      {
        "categoryId": 150
      }
    ]
  },
  "7": {
    "title": "Brand Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you understand the dynamics of your market?",
        "categoryId": 152
      },
      {
        "prompt": "Do you understand the advantages of your business?",
        "categoryId": 153
      },
      {
        "prompt": "Does your business have a unique strategy?",
        "categoryId": 154
      },
      {
        "prompt": "Has your strategy been proven to work?",
        "categoryId": 155
      },
      {
        "prompt": "Do you know how to execute?",
        "categoryId": 156
      },
      {
        "prompt": "Are you taking it as far as you can?",
        "categoryId": 157
      },
      {
        "prompt": "Get to work! But revisit this system often to stay on track."
      }
    ]
  },
  "8": {
    "title": "Productivity Strategy System",
    "description": null,
    "prompts": [
      {
        "prompt": "Are you ready to get to work?",
        "categoryId": 158
      },
      {
        "prompt": "Do you know what you’re working towards?",
        "categoryId": 159
      },
      {
        "prompt": "Do you know what to do next?",
        "categoryId": 160
      },
      {
        "prompt": "Do you have a system for getting it done?",
        "categoryId": 161
      },
      {
        "prompt": "Have you improved it recently?",
        "categoryId": 162
      },
      {
        "prompt": "Do you have plenty of time?",
        "categoryId": 163
      },
      {
        "prompt": "Are you feeling energised?",
        "categoryId": 164
      },
      {
        "prompt": "Prepare for your next project or task"
      }
    ]
  },
  "9": null,
  "10": null,
  "11": {
    "title": "Negotiation Category Finder",
    "description": null,
    "prompts": [
      {
        "prompt": "Do you understand the basics of negotiation?",
        "categoryId": 177
      },
      {
        "prompt": "Have you honed your listening skills?",
        "categoryId": 178
      },
      {
        "prompt": "Have you gathered all the information you need?",
        "categoryId": 179
      },
      {
        "prompt": "Have you worked out your approach?",
        "categoryId": 180
      },
      {
        "prompt": "Have you built a relationship with your counterpart?",
        "categoryId": 181
      },
      {
        "prompt": "Have you encountered any challenges?",
        "categoryId": 182
      },
      {
        "categoryId": 183
      }
    ]
  }
};