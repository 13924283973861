import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';
import { emailLinkLoginFormLocalStorage } from '../EmailLinkLogin/emailLinkLoginFormLocalStorage';

const logout = () => {
  if (import.meta.env.VITE_INTERNAL_BUILD_AUTH_CHECK === 'true') {
    localStorage.removeItem('token');
    window.location.reload(); // to clear the user object
  }
  emailLinkLoginFormLocalStorage.removeRequestedLoginLinkEmail();
  return getFirebaseAuth().signOut();
};

export default logout;
