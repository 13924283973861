import React, { useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonPage,
  IonRow,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import './CardDeckCardBookmarksPage.css';

import logErrorWithSentry from '../../../External/Sentry/logErrorWithSentry';
import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import AppContentContainer from '../../../Common/AppContentContainer';
import RetryableContentLoadingComp from '../../../Common/ContentLoading/RetryableContentLoadingComp';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import PipLogoBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import providePipLogoBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import { useAuthenticatedUser } from '../../../Authentication/AuthenticatedUserContext';
import { CardDeckRoutes } from '../../../cardDecksRoutes';
import { composeListenCardBookmarksWithRemoteErrorLogging } from '../../../CompositionRoot/CardDecks/CardDeckBookmarks/composeCardBookmarksStorageWithErrorLogging';
import { Result } from '../../../Common/Types/Result';

import CardCell, {
  cardGridColumnSizeSm,
  cardGridColumnSizeXs,
} from '../../Common/CardCell/CardCell';
import AuthorizedDecksContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDecksContentAccessContainer';
import {
  CardDeckCardListItem,
  CardDeckCardListSection,
  getRecipeCardListItemAndSectionByCardId,
  getTacticCardListItemAndSectionByCardId,
} from '../../CardDeckCardList/cardDeckCardListSections';
import useSearchNavigator from '../../CardDeckCardsSearch/useSearchNavigator';
import { getCardDeck } from '../../Home/cardDecks';

import { Bookmark, ListenBookmarksError } from '../bookmarksStorage';
import { getCardDescriptionByIdAndType } from '../getCardDescriptionByIdAndType';
import EmptyBookmarksComp from './EmptyBookmarksComp';
import BookmarksErrorComp from './BookmarksErrorComp';

type CardDetailsRouteResolver = (deckId: number, cardId: number) => string;

const getRouteResolver = (
  cardType: string
): CardDetailsRouteResolver | null => {
  switch (cardType) {
    case 'tactic':
      return CardDeckRoutes.cardDeckTacticCard.resolve;
    case 'recipe':
      return CardDeckRoutes.cardDeckRecipeCard.resolve;
    default:
      return null;
  }
};

type BookmarkListItem = {
  deckId: number;
  cardType: string;
  section: CardDeckCardListSection;
  card: CardDeckCardListItem;
};

const setDocumentTitle = () => {
  document.title = 'Bookmarks';
};

const CardDeckCardBookmarksPage: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('bookmarks');
  const user = useAuthenticatedUser();
  const router = useIonRouter();
  const posthog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(posthog);

  const [readyToLoadData, setReadyToLoadData] = useState<boolean>(false);
  const isViewReadyForContentLoad =
    (user && isTReady && readyToLoadData) || false;

  const listenCardBookmarksWithErrorLogging =
    composeListenCardBookmarksWithRemoteErrorLogging();

  const loadContent = (
    onResult: (result: Result<Bookmark[], ListenBookmarksError>) => void
  ) => {
    const loadedUserId = user?.id;
    if (loadedUserId) {
      return listenCardBookmarksWithErrorLogging(loadedUserId, onResult);
    } else {
      return () => {};
    }
  };

  const breadcrumbs = (
    <Breadcrumbs>
      <PipLogoBreadcrumb {...providePipLogoBreadcrumbProps()} />
      <Breadcrumb>{t('CardDeckCardBookmarksPage_breadcrumb')}</Breadcrumb>
    </Breadcrumbs>
  );

  const getRoute = (cardId: number, cardType: string, deckId: number) => {
    const resolveRoute = getRouteResolver(cardType);
    if (!resolveRoute) {
      logErrorWithSentry(
        new Error(`No route resolver for card type ${cardType}`)
      );
      return '/not-found';
    }
    return resolveRoute(deckId, cardId);
  };

  const mapBookmarksToBookmarkListItems = (bookmarks: Bookmark[]) => {
    return bookmarks
      .map(bookmark => {
        const getCardListItemSectionItemAndDeckIdByCardId = (() => {
          switch (bookmark.cardType) {
            case 'tactic':
              return getTacticCardListItemAndSectionByCardId;
            case 'recipe':
              return getRecipeCardListItemAndSectionByCardId;
          }
        })();
        if (getCardListItemSectionItemAndDeckIdByCardId) {
          const cardListItemSectionItemAndDeckId =
            getCardListItemSectionItemAndDeckIdByCardId(bookmark.cardId);
          return {
            deckId: cardListItemSectionItemAndDeckId.deckId,
            section: cardListItemSectionItemAndDeckId.cardListSection,
            cardType: bookmark.cardType,
            card: cardListItemSectionItemAndDeckId.cardListItem,
          };
        }
      })
      .filter(item => item !== undefined) as BookmarkListItem[];
  };

  const renderBookmarkListItems = (bookmarkListItems: BookmarkListItem[]) => {
    return (
      <IonGrid className="cards-grid">
        <IonRow>
          {bookmarkListItems?.map(bookmarkListItem => (
            <IonCol
              sizeSm={cardGridColumnSizeSm}
              sizeXs={cardGridColumnSizeXs}
              offsetSm="0"
              offsetXs="2"
              key={bookmarkListItem.card.id}
            >
              <CardCell
                title={bookmarkListItem.card.title}
                imageSource={bookmarkListItem.card.imageUrl}
                description={bookmarkListItem.card.description}
                onClick={() => {
                  const eventName = 'navigate to bookmark';
                  const cardId = bookmarkListItem.card.id;
                  const cardType = bookmarkListItem.cardType;
                  const eventProps = {
                    cardId: cardId,
                    cardType: cardType,
                    description: getCardDescriptionByIdAndType(
                      cardId,
                      cardType
                    ),
                  };
                  trackAction(eventName, eventProps);

                  router.push(
                    getRoute(
                      bookmarkListItem.card.id,
                      bookmarkListItem.cardType,
                      bookmarkListItem.deckId
                    )
                  );
                }}
                categoryColor={bookmarkListItem.card.style.borderColor}
                deckTitle={getCardDeck(bookmarkListItem.deckId)?.title}
                categoryTitle={bookmarkListItem.section.title}
                categoryIcon={bookmarkListItem.section.iconCode}
              />
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    );
  };

  const renderContent = (bookmarks: Bookmark[]): React.ReactNode => {
    const bookmarkListItems = mapBookmarksToBookmarkListItems(bookmarks);
    const bookmarkListItemsNode = renderBookmarkListItems(bookmarkListItems);
    return (
      <>
        {breadcrumbs}
        {bookmarkListItemsNode}
        <div className="bottom-buttons-margin-adjust"></div>
      </>
    );
  };

  const renderErrorContent = (retryDataLoad: () => void) => (
    <BookmarksErrorComp onRetryButtonClick={retryDataLoad} />
  );

  const searchNavigator = useSearchNavigator();
  const navigateToSearch = () => {
    searchNavigator.navigateToSearchRouteAndResetSearchResults();
  };
  const emptyNode = <EmptyBookmarksComp onButtonClick={navigateToSearch} />;

  const renderEmptyContent = () => (
    <>
      {breadcrumbs}
      {emptyNode}
    </>
  );

  useIonViewWillEnter(() => {
    setDocumentTitle();
    setReadyToLoadData(true);
  });

  return (
    <IonPage id="card-bookmarks">
      <AppContentContainer>
        <AuthorizedDecksContentAccessContainer>
          <RetryableContentLoadingComp
            isViewReadyForContentLoad={isViewReadyForContentLoad}
            loadContent={loadContent}
            renderContent={renderContent}
            renderEmptyState={renderEmptyContent}
            renderErrorState={renderErrorContent}
          />
        </AuthorizedDecksContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckCardBookmarksPage;
