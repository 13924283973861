import { Route } from 'react-router-dom';
import SolveMyProblemChallengePage from '../Challenge/SolveMyProblemChallengePage';
import SolveMyProblemFocusPage from '../Focus/SolveMyProblemFocusPage';
import SolveMyProblemTacticsPage from '../Tactics/SolveMyProblemTacticsPage';
import ProtectedPageContainer from '../../../Authentication/ProtectedPageContainer';
import SolveMyProblemContent from '../Content/SolveMyProblemContent';
import CheckSolveMyProblemContentPage from '../Content/CheckSolveMyProblemContentPage';
import { SolveMyProblemAccessProvider } from './AccessAuthorization/SolveMyProblemAccessContext';
import SolveMyProblemPageAccessContainer from './AccessAuthorization/SolveMyProblemPageAccessContainer';

const getSolveMyProblemRoutes = () => {
  return [
    <Route
      path={SolveMyProblemRoutes.challenge.path}
      key={SolveMyProblemRoutes.challenge.path}
      exact={true}>
      <ProtectedPageContainer>
        <SolveMyProblemAccessProvider>
          <SolveMyProblemPageAccessContainer>
            <SolveMyProblemChallengePage />
          </SolveMyProblemPageAccessContainer>
        </SolveMyProblemAccessProvider>
      </ProtectedPageContainer>
    </Route>,
    <Route
      path={SolveMyProblemRoutes.focus.path}
      key={SolveMyProblemRoutes.focus.path}
      exact={true}>
      <ProtectedPageContainer>
        <SolveMyProblemAccessProvider>
          <SolveMyProblemPageAccessContainer>
            <SolveMyProblemFocusPage />
          </SolveMyProblemPageAccessContainer>
        </SolveMyProblemAccessProvider>
      </ProtectedPageContainer>
    </Route>,
    <Route
      path={SolveMyProblemRoutes.tactics.path}
      key={SolveMyProblemRoutes.tactics.path}
      exact={true}>
      <ProtectedPageContainer>
        <SolveMyProblemAccessProvider>
          <SolveMyProblemPageAccessContainer>
            <SolveMyProblemTacticsPage />
          </SolveMyProblemPageAccessContainer>
        </SolveMyProblemAccessProvider>
      </ProtectedPageContainer>
    </Route>,
    // <Route
    //   path={SolveMyProblemRoutes.check.path}
    //   key={SolveMyProblemRoutes.check.path}
    //   exact={true}>
    //   <ProtectedPageContainer>
    //     <CheckSolveMyProblemContentPage />
    //   </ProtectedPageContainer>
    // </Route>,
  ];
};

export const SolveMyProblemRoutes = {
  challenge: {
    path: '/challenge',
    resolve: () => '/challenge',
    describe: () => 'Challenges List',
  },
  focus: {
    path: '/challenge/:challengeIndex/focus',
    resolve: (challengeIndex: string) => `/challenge/${challengeIndex}/focus`,
    describe: (challengeIndex: string) => {
      const challengeIndexNumber = Number(challengeIndex);
      if (challengeIndexNumber) {
        const deckDetails =
          SolveMyProblemContent.deckOptions[challengeIndexNumber];
        if (deckDetails) {
          return `Challenge: ${deckDetails.deckFocusPrompt}`;
        } else {
          return `Challenge not found for index ${challengeIndexNumber}`;
        }
      } else {
        return 'Unknown Challenge';
      }
    },
  },
  tactics: {
    path: '/challenge/:challengeIndex/focus/:focusIndex/tactics',
    resolve: (challengeIndex: string, focusIndex: string) =>
      `/challenge/${challengeIndex}/focus/${focusIndex}/tactics`,
    describe: (challengeIndex: string, focusIndex: string) => {
      const challengeIndexNumber = Number(challengeIndex);
      const focusIndexNumber = Number(focusIndex);
      if (challengeIndexNumber && focusIndexNumber) {
        return `Focus: ${
          SolveMyProblemContent.deckOptions[challengeIndexNumber].focusOptions[
            Number(focusIndex)!
          ].title
        }`;
      } else {
        return 'Unknown Focus';
      }
    },
  },
  check: {
    path: '/challenge-content-check',
    resolve: () => {},
    describe: () => {},
  },
};

export default getSolveMyProblemRoutes;
