import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { usePostHog } from 'posthog-js/react';

import PageSectionHeaderComp from '../../../Common/UI/Sections/PageSectionHeaderComp';
import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import DownloadButton from './DownloadButton';

export interface DownloadsSectionProps {
  deckTitle: string;
  deckPdfUrl: string;
  deckImagesArchiveUrl: string;
}

const DownloadsSection: React.FC<DownloadsSectionProps> = ({
  deckTitle,
  deckPdfUrl,
  deckImagesArchiveUrl,
}) => {
  const { t, ready: isTReady } = useTranslation('decks');
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);
  const trackPDFDownload = () => {
    trackAction('download PDF', {
      deck: deckTitle,
    });
  };
  const downloadPDF = composeDownloadAction({
    document: document,
    url: deckPdfUrl,
    fileName: `${deckTitle}.pdf`,
  });
  const trackImagesDownload = () => {
    trackAction('download images', {
      deck: deckTitle,
    });
  };
  const downloadImages = composeDownloadAction({
    document: document,
    url: deckImagesArchiveUrl,
    fileName: `${deckTitle} - images.zip`,
  });
  const trackedDonwloadPDF = () => {
    trackPDFDownload();
    downloadPDF();
  };
  const trackedDonwloadImages = () => {
    trackImagesDownload();
    downloadImages();
  };

  return isTReady ? (
    <>
      <PageSectionHeaderComp
        title={t('CardDeckHomePage_DownloadsSectionTitle')}
      />
      <IonGrid className="cards-grid">
        <IonRow>
          <IonCol sizeSm="6" sizeXs="12" key="pdf">
            <DownloadButton
              title={t('CardDeckHomePage_DownloadPDFButtonTitle')}
              onClick={trackedDonwloadPDF}
            />
          </IonCol>
          <IonCol sizeSm="6" sizeXs="12" key="images">
            <DownloadButton
              title={t('CardDeckHomePage_DownloadCardImagesButtonTitle')}
              onClick={trackedDonwloadImages}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  ) : null;
};

export default DownloadsSection;

type DownloadOptions = {
  document: Document;
  url: string;
  fileName: string;
};

const composeDownloadAction = ({
  document,
  url,
  fileName,
}: DownloadOptions) => {
  return () => {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
};
