import { getCardDeckRecipeCard } from './CardDecks/CardDeckCard/RecipeCard/cardDeckRecipeCard';
import { getCardDeckTacticCard } from './CardDecks/CardDeckCard/TacticCard/cardDeckTacticCard';
import { getCardDeck } from './CardDecks/Home/cardDecks';
import { getCardDeckCategory } from './CardDecks/CardDeckCategories/cardDeckCategories';

// TODO: move describe(s) to tracking related file and map it based on the routeKey
export const CardDeckRoutes = {
  cardDecks: {
    path: '/card-decks',
    resolve: () => '/card-decks',
    describe: () => 'Old Root',
  },
  root: {
    path: '/',
    resolve: () => '/',
    describe: () => 'Root',
  },
  home: {
    path: '/home',
    resolve: () => '/home',
    describe: () => 'Home',
  },
  cardDeck: {
    path: '/card-decks/:deckId',
    resolve: (deckId: number) => `/card-decks/${deckId}`,
    describe: (deckId: string) => `${getCardDeck(Number(deckId))?.title}`,
  },
  cardDeckCategoryFinder: {
    path: '/card-decks/:deckId/category-finder',
    resolve: (deckId: number) => `/card-decks/${deckId}/category-finder`,
    describe: (deckId: string) =>
      `${getCardDeck(Number(deckId))?.title}: Category finder`,
  },
  // Note: this path is duplicated in the ContentImport/routeResolvers.js
  cardDeckCategoryTacticCardList: {
    path: '/card-decks/:deckId/categories/:categoryId/cards',
    resolve: (deckId: number, categoryId: number) =>
      `/card-decks/${deckId}/categories/${categoryId}/cards`,
    describe: (deckId: string, categoryId: string) =>
      `${getCardDeck(Number(deckId))?.title}: ${
        getCardDeckCategory(deckId, Number(categoryId))?.title
      } category`,
  },
  cardDeckTacticCardList: {
    path: '/card-decks/:deckId/cards/tactics',
    resolve: (deckId: number) => `/card-decks/${deckId}/cards/tactics`,
    describe: (deckId: string) =>
      `${getCardDeck(Number(deckId))?.title}: Tactics`,
  },
  cardDeckRecipeCardList: {
    path: '/card-decks/:deckId/cards/recipes',
    resolve: (deckId: number) => `/card-decks/${deckId}/cards/recipes`,
    describe: (deckId: string) =>
      `${getCardDeck(Number(deckId))?.title}: Recipes`,
  },
  // Note: this path is duplicated in the ContentImport/routeResolvers.js
  cardDeckTacticCard: {
    path: '/card-decks/:deckId/cards/tactics/:cardId',
    resolve: (deckId: number, cardId: number) =>
      `/card-decks/${deckId}/cards/tactics/${cardId}`,
    describe: (deckId: string, cardId: string) =>
      `${getCardDeck(Number(deckId))?.title}: ${
        getCardDeckTacticCard(deckId, Number(cardId))?.title
      } tactic`,
  },
  // Note: this path is duplicated in the ContentImport/routeResolvers.js
  cardDeckRecipeCard: {
    path: '/card-decks/:deckId/cards/recipes/:cardId',
    resolve: (deckId: number, cardId: number) =>
      `/card-decks/${deckId}/cards/recipes/${cardId}`,
    describe: (deckId: string, cardId: string) =>
      `${getCardDeck(Number(deckId))?.title}: ${
        getCardDeckRecipeCard(deckId, Number(cardId))?.title
      } recipe`,
  },
  cardDecksCardSearch: {
    path: '/cards-search',
    resolve: () => '/cards-search',
    describe: () => 'Cards search',
  },
  cardDecksSavedCards: {
    path: '/saved-cards',
    resolve: () => '/saved-cards',
    describe: () => 'Saved Cards',
  },
};
