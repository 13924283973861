import { useEffect, useState } from 'react';
import ContentLoadingPage from '../../../../Common/ContentLoading/ContentLoadingPage';
import { ContentLoadingState } from '../../../../Common/ContentLoading/ContentLoadingComp';
import NotFoundPage from '../../../../Common/Pages/NotFoundPage';

import { useSolveMyProblemAccess } from './SolveMyProblemAccessContext';

interface SolveMyProblemPageAccessContainerProps {
  children: React.ReactNode;
}
const SolveMyProblemPageAccessContainer: React.FC<
  SolveMyProblemPageAccessContainerProps
> = ({ children }) => {
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const { canAccessSolveMyProblem } = useSolveMyProblemAccess();

  useEffect(() => {
    if (canAccessSolveMyProblem === undefined) {
      // not loaded yet
      return;
    }

    if (canAccessSolveMyProblem === false) {
      setLoadingState(ContentLoadingState.Error);
    }

    if (canAccessSolveMyProblem) {
      setLoadingState(ContentLoadingState.Loaded);
    }
  }, [canAccessSolveMyProblem]);

  switch (loadingState) {
    case ContentLoadingState.Loading:
      return <ContentLoadingPage />;
    case ContentLoadingState.Loaded:
      return <>{children}</>;
    case ContentLoadingState.Error:
      return <NotFoundPage />;
    default:
      return <></>;
  }
};

export default SolveMyProblemPageAccessContainer;
