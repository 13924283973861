import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IonPage, useIonViewWillEnter, useIonRouter } from '@ionic/react';

import './CadDeckCategoryFinderPage.css';

import AppContentContainer from '../../Common/AppContentContainer';
import Breadcrumb from '../../Common/Navigation/Breadcrumbs/Breadcrumb';
import Breadcrumbs from '../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import HomeBreadcrumb from '../Common/Breadcrumbs/PipLogoBreadcrumb';
import provideHomeBreadcrumbProps from '../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import AuthorizedDeckContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';

import { CardDeckRoutes } from '../../cardDecksRoutes';

import { getCardDeckByIdString } from '../Home/cardDecks';
import { getCardDeckCategory } from '../CardDeckCategories/cardDeckCategories';

import {
  CardDeckSystemCard,
  getCardDeckSystemCard,
} from './CardDeckCategoryFinderComp/cardDeckSystemCards';
import CardDeckCategoryFinderComp from './CardDeckCategoryFinderComp/CardDeckCategoryFinderComp';
import renderPageIfModelDefinedOrNotFoundPage from '../../Common/Pages/pageIfModelDefinedOrNotFoundPage';
import provideDeckBreadcrumbProps from '../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import TextBreadcrumb from '../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import { useTranslation } from 'react-i18next';

const getCardDeckTitle = (deckId: string) => {
  return getCardDeckByIdString(deckId)?.title;
};

const CadDeckCategoryFinderPage: React.FC = () => {
  // console.log('Category finder page start');
  const { deckId } = useParams<{ deckId: string }>();
  const deckIdNumber = Number(deckId);
  const [systemCard, setSystemCard] = useState<
    CardDeckSystemCard | undefined | null
  >(undefined);
  const router = useIonRouter();
  const { t, ready: isTReady } = useTranslation('decks');

  const getCategoryToGoTo = (itemIndex: number) => {
    const visibleSystemItem = systemCard?.prompts[itemIndex];
    const categoryIdToGoTo = visibleSystemItem?.categoryId;
    if (categoryIdToGoTo != undefined) {
      return getCardDeckCategory(deckId, categoryIdToGoTo);
    }
    return undefined;
  };

  const determineCategoryToGoTo = (
    systemCard: CardDeckSystemCard,
    visibleItemIndex: number,
    selectedButtonPerRow: (string | undefined)[]
  ) => {
    const systemItems = systemCard.prompts;
    if (systemItems.length == 0) {
      return undefined;
    }
    let relevantItemIndex = visibleItemIndex;
    const lastSystemItem = systemItems[systemItems.length - 1];
    const isLastSystemItemACategoryPointer =
      lastSystemItem.prompt == undefined &&
      lastSystemItem.categoryId != undefined;
    if (
      isLastSystemItemACategoryPointer &&
      visibleItemIndex == systemItems.length - 2 &&
      selectedButtonPerRow[visibleItemIndex] == 'yes'
    ) {
      // point to the last system item containing only the category
      relevantItemIndex += 1;
    }
    const categoryToGoTo = getCategoryToGoTo(relevantItemIndex);
    return categoryToGoTo;
  };

  const getGoToCategoryButtonContent = (
    systemCard: CardDeckSystemCard,
    visibleItemIndex: number,
    selectedButtonPerRow: (string | undefined)[]
  ) => {
    const categoryToGoTo = determineCategoryToGoTo(
      systemCard,
      visibleItemIndex,
      selectedButtonPerRow
    );
    if (categoryToGoTo != undefined) {
      return {
        title: categoryToGoTo!.title,
        iconCode: categoryToGoTo!.iconCode,
        iconColor: categoryToGoTo!.color,
      };
    }
    return undefined;
  };

  const onGoToCategoryButtonTap = (
    systemCard: CardDeckSystemCard,
    visibleItemIndex: number,
    selectedButtonPerRow: (string | undefined)[]
  ) => {
    const categoryToGoTo = determineCategoryToGoTo(
      systemCard,
      visibleItemIndex,
      selectedButtonPerRow
    );
    if (categoryToGoTo != undefined) {
      router.push(
        CardDeckRoutes.cardDeckCategoryTacticCardList.resolve(
          deckIdNumber,
          categoryToGoTo.id
        )
      );
    }
  };

  const loadAndDisplayData = (deckId: string) => {
    const systemCard = getCardDeckSystemCard(deckId) ?? null;
    setSystemCard(systemCard);
  };

  useIonViewWillEnter(() => {
    loadAndDisplayData(deckId);
  }, [deckId]);

  const setDocumentTitle = (deckId: string) => {
    const deckTitle = getCardDeckTitle(deckId);
    if (deckTitle) {
      document.title = t('CadDeckCategoryFinderPage_documentTitle', {
        deckTitle: deckTitle,
      });
    }
  };

  useEffect(() => {
    if (isTReady) {
      setDocumentTitle(deckId);
    }
  }, [isTReady]);

  return renderPageIfModelDefinedOrNotFoundPage(
    systemCard,
    isTReady ? (
      <IonPage id="card-deck-category-finder">
        <AppContentContainer>
          <AuthorizedDeckContentAccessContainer deckId={deckId}>
            <Breadcrumbs>
              <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
              <TextBreadcrumb {...provideDeckBreadcrumbProps(deckId)} />
              <Breadcrumb>
                {t('CadDeckCategoryFinderPage_breadcrumb')}
              </Breadcrumb>
            </Breadcrumbs>
            {systemCard && (
              <CardDeckCategoryFinderComp
                systemCard={systemCard}
                getGoToCategoryButtonContent={(
                  visibleItemIndex,
                  selectedButtonPerRow
                ) =>
                  getGoToCategoryButtonContent(
                    systemCard,
                    visibleItemIndex,
                    selectedButtonPerRow
                  )
                }
                onGoToCategoryButtonTap={(
                  visibleItemIndex,
                  selectedButtonPerRow
                ) =>
                  onGoToCategoryButtonTap(
                    systemCard,
                    visibleItemIndex,
                    selectedButtonPerRow
                  )
                }
              />
            )}
          </AuthorizedDeckContentAccessContainer>
        </AppContentContainer>
      </IonPage>
    ) : (
      <></>
    )
  );
};

export default CadDeckCategoryFinderPage;
