import React from 'react';
import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';
import './LinkCell.css';
import LabelChevronView from '../../../Common/UI/Views/LabelChevronView/LabelChevronView';

interface LinkCellProps {
  imageSource: string;
  title: string;
  route: string;
}

const LinkCell: React.FC<LinkCellProps> = ({ imageSource, title, route }) => {
  return (
    <ClickableDiv className="link-cell" onClickRouteTo={route}>
      <img src={imageSource} className="image" aria-hidden="true" />
      <h3 className="title">
        <LabelChevronView label={title} alignment="center" />
      </h3>
    </ClickableDiv>
  );
};

export default LinkCell;
