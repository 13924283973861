import { usePostHog } from 'posthog-js/react';

interface TrackedAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  linkDescription?: string;
}

const TrackedAnchor: React.FC<TrackedAnchorProps> = ({
  linkDescription = undefined,
  children,
  ...props
}) => {
  const postHog = usePostHog();
  const trackAnchorClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const href = event.currentTarget.getAttribute('href');
    if (href) {
      postHog.capture('clicked link', {
        href: href,
        linkDescription: linkDescription,
      });
    }
  };
  return (
    <a {...props} onClick={trackAnchorClick}>
      {children}
    </a>
  );
};

export default TrackedAnchor;
