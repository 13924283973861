import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { IonButton, IonIcon, IonSpinner } from '@ionic/react';

import './ButtonWithSpinner.css';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface ButtonWithSpinnerProps extends IonButtonProps {
  icon?: string;
  title: string;
  loadingTitle?: string;
  shouldStartWithLoadingState?: boolean;
  containerClassName?: string;
  minLoadingDurationWhenLoadingTitleSet?: number;
  onClick: () => Promise<void>;
}

const ButtonWithSpinner = forwardRef<
  HTMLIonButtonElement,
  ButtonWithSpinnerProps
>(
  (
    {
      icon,
      title,
      loadingTitle,
      shouldStartWithLoadingState = false,
      containerClassName,
      minLoadingDurationWhenLoadingTitleSet = 300,
      // minLoadingDurationWhenLoadingTitleSet = 1500,
      onClick,
      ...rest
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(shouldStartWithLoadingState);
    // The complexity introduced with internalLoadingTitle and loadingTitleRef is necessary
    // to prevent the updated loadingTitle from being rendered before the loading state is set to false.
    // Without internalLoadingTitle and loadingTitleRef the renders happen in the wrong order:
    // 1. title set before operation is rendered
    // 2. when operation starts, loadingTitle set before the operation is rendered
    // 3. when the operation ends, loadingTitle set after the operation is rendered
    // 4. then title set after the operation is rendered
    // We don't want to see step 3 happening, because the updated loadingTitle should be used
    // next time the button is clicked. To prevent step 3 from happening, we need to ensure
    // that the internalLoadingTitle is set only once isLoading is false.
    const [internalLoadingTitle, setInternalLoadingTitle] =
      useState(loadingTitle);
    const loadingTitleRef = useRef(loadingTitle);

    useEffect(() => {
      loadingTitleRef.current = loadingTitle;
    }, [loadingTitle]);

    const handleClick = async (
      e: React.MouseEvent<HTMLIonButtonElement, MouseEvent>
    ) => {
      e.preventDefault();

      setIsLoading(true);
      const startTime = Date.now();
      // await new Promise(resolve => setTimeout(resolve, 2000));
      try {
        await onClick();
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
      await delayButtonOperationIfNecessary(startTime);
      setIsLoading(false);
      setInternalLoadingTitle(loadingTitleRef.current);
    };

    const delayButtonOperationIfNecessary = async (startTime: number) => {
      // we want the delay to happen only if there is a title during the operation,
      // as it's a bit jarring to see the title switching two times quickly for a quick operation
      if (!internalLoadingTitle) {
        // but if the title doesn't change during the activity,
        // a quick transition through before > loader > after is not that bad to justify delaying
        return;
      }

      const endTime = Date.now();
      let delayToMakeTransitionThroughStatesEasyOnTheEyes =
        minLoadingDurationWhenLoadingTitleSet - (endTime - startTime);
      if (delayToMakeTransitionThroughStatesEasyOnTheEyes > 0) {
        await new Promise(resolve =>
          setTimeout(resolve, delayToMakeTransitionThroughStatesEasyOnTheEyes)
        );
      }
    };

    const resolvedContainerClassName = `button-with-spinner${
      containerClassName ? ` ${containerClassName}` : ''
    }`;

    return (
      <div className={resolvedContainerClassName}>
        <IonButton
          ref={ref}
          onClick={handleClick}
          strong
          size="default"
          {...rest}
          // since we are using rest.disabled,
          // {...rest} must go before disabled={isLoading || rest.disabled}
          disabled={isLoading || rest.disabled}>
          {isLoading ? (
            <>
              <IonSpinner
                slot="start"
                className={(!internalLoadingTitle && 'centered') || ''}
              />
              {internalLoadingTitle}
              {/* hidden text to keep the same button width
                  while the spinner is shown */}
              {!internalLoadingTitle && (
                <span className="title" style={{ visibility: 'hidden' }}>
                  {title}
                </span>
              )}
            </>
          ) : (
            <>
              {icon && <IonIcon slot="start" icon={icon} />}
              <span className="title">{title}</span>
            </>
          )}
        </IonButton>
      </div>
    );
  }
);
export default ButtonWithSpinner;
