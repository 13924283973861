import React from 'react';
import { IonItem } from '@ionic/react';
import ListItemChevron from '../../../../Common/UI/Icons/ListItemChevron';

import './SolveMyProblemListItem.css';

type IonItemProps = React.ComponentProps<typeof IonItem>;

interface SolveMyProblemListItemProps extends IonItemProps {
  children: React.ReactNode;
}

const SolveMyProblemListItem: React.FC<SolveMyProblemListItemProps> = ({
  children,
  ...rest
}) => {
  return (
    <IonItem lines="none" detail={false} className="card-container" {...rest}>
      {children}
      <div slot="end">
        <ListItemChevron />
      </div>
    </IonItem>
  );
};

export default SolveMyProblemListItem;
