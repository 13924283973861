import { LogError } from './LogError';
import { Listen } from '../Common/Types/Listen';
import {
  errorResult,
  isResultError,
  okResult,
  Result,
} from '../Common/Types/Result';

export const composePromiseReturningFuncWithErrorLogging = <
  ResultType,
  T extends (...args: any[]) => Promise<ResultType>
>(
  fn: T,
  logErrorRemotely: LogError,
  isKnownError: (error: any) => boolean,
  mockError?: any,
  mockResult?: ResultType
): T => {
  return ((...args: Parameters<T>) => {
    const onError:
      | ((reason: any) => PromiseLike<never>)
      | null
      | undefined = error => {
      if (!isKnownError(error)) {
        logErrorRemotely(error);
      }
      throw error;
    };

    if (mockError && Math.random() < 0.6) {
      return Promise.reject(mockError).catch(onError);
    }

    if (mockResult !== undefined) {
      return Promise.resolve(mockResult).catch(onError);
    }

    return fn(...args).catch(onError);
  }) as T;
};

export const composeListenWithErrorLogging = <
  InitArgs extends any[],
  T,
  E,
  TInfra
>(
  fn: Listen<InitArgs, TInfra, any>,
  logErrorRemotely: LogError,
  isKnownError: (error: E) => boolean,
  mapResult: (result: Result<TInfra, any>) => Result<T, E>,
  mockError?: any,
  mockResult?: TInfra | null
): Listen<InitArgs, T, E> => {
  return (...args: [...InitArgs, (result: Result<T, E>) => void]) => {
    const initArgs = args.slice(0, -1) as InitArgs;
    const [onResult] = args.slice(-1) as [(result: Result<T, E>) => void];

    const handleResult = (result: Result<TInfra, any>) => {
      if (isResultError(result) && !isKnownError(result.error)) {
        logErrorRemotely(result.error);
      }
      const mappedResult = mapResult(result);
      onResult(mappedResult);
    };

    if (mockError && Math.random() < 0.6) {
      setTimeout(() => {
        handleResult(errorResult(mockError));
      }, 0);
      return () => {};
    }

    if (mockResult !== undefined) {
      setTimeout(() => {
        handleResult(okResult(mockResult));
      }, 0);
      return () => {};
    }

    return fn(...initArgs, handleResult);
  };
};
