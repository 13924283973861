export const cardListSections = {
  "1": [
    {
      "id": 27,
      "title": "Goals",
      "description": "Agree on a desired end-state to work towards, so daily activity is moving you in the right direction.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "cards": [
        {
          "id": 101,
          "title": "Sailboat",
          "description": "Find out what your goals really are, the reasons behind them and what’s stopping you from achieving them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Sail_Boat_e280db8a90.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 120,
          "title": "Newspaper Headline",
          "description": "Think about your company or project’s future by predicting what the press might say on their front pages.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Newspaper_Headlines_7f7562a146.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 111,
          "title": "Sticky Steps",
          "description": "Start with your goal and work backwards to give you a clear path to get there.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Sticky_Steps_72ecea9aaa.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 105,
          "title": "G.R.O.W",
          "description": "Create a plan for how to achieve a specific goal.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/G_R_O_W_8692308837.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 97,
          "title": "Objectives & Key Results",
          "description": "Create radical focus for your team by agreeing on an objective and actively measuring progress towards it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/OKR_3d399c9cbc.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 23,
      "title": "Understand",
      "description": "Unpack problems and discover insights with critical and analytical thinking exercises.",
      "iconCode": "&#xE802",
      "color": "#EB986C",
      "cards": [
        {
          "id": 106,
          "title": "Five Whys",
          "description": "When faced with a problem that is affecting a project, channel your inner inquisitive toddler and ask ‘why?’ five times to get to the root cause.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Five_Whys_5413865cd6.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 118,
          "title": "Hopes & Fears",
          "description": "Understand your team’s expectations and hesitations so you’re all on the same page before you take flight on a new journey.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hopes_and_Fears_28f2a889da.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 99,
          "title": "Assumption Collecting",
          "description": "Collect assumptions that reflect what you and your team think might be true about the project.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Assumption_Collection_5177e92dd8.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 119,
          "title": "Journey Map",
          "description": "Identify areas of improvement in a user’s experience by creating a map of it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Journey_Map_ffc70775c4.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 130,
          "title": "Service Map",
          "description": "Create a map of a service to understand it better, and identify opportunities for improving processes.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Service_Mapping_3c4491d0d9.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 122,
          "title": "Empathy Map",
          "description": "Empathise with your audience so you can make better design decisions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/empathy_mapping_7ae0eadf65.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 121,
          "title": "Prototype Persona",
          "description": "Try to predict who is using (or will use) your product and why, so you can start testing with the right people.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Persona_Prototype_23988e388d.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 126,
          "title": "Stakeholder Map",
          "description": "Identify a project’s stakeholders. Anyone who can significantly impact a decision, or who may be impacted by it, needs to be considered.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stakeholder_Map_62146fc044.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 123,
          "title": "Roles & Responsibilities",
          "description": "Understand each other’s roles better, and learn who is responsible for what.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Roles_and_Responsibilities_1a70f39562.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 110,
          "title": "Skills Market",
          "description": "Learn about each other’s skills, abilities and ambitions, so you can understand how to help each other grow.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Skills_Market_0bc58e39e1.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 28,
      "title": "Frame",
      "description": "Articulate your problem or experiment in a clear way, making it easier to start solving or testing.",
      "iconCode": "&#xE805",
      "color": "#F5C466",
      "cards": [
        {
          "id": 98,
          "title": "How Might We...",
          "description": "Transform a problem or observation into a solvable question.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/how_might_we_493f32956c.png",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 113,
          "title": "Problem Statement",
          "description": "A statement that captures the project’s goals, problems, and measurable success criteria.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Problem_Statement_5173fb3013.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 96,
          "title": "Hypothesis Statement",
          "description": "Treat your assumptions as experiments by turning them into testable hypotheses.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hypothesis_Statement_50431b3587.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 102,
          "title": "Value Proposition",
          "description": "Clearly articulate the point of your service or product, who it’s for and what makes it unique.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Value_Proposition_521074f017.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 29,
      "title": "Ideas",
      "description": "Encourage people to come up with lots of ideas, then refine and develop the best.",
      "iconCode": "&#xE803",
      "color": "#9CC992",
      "cards": [
        {
          "id": 108,
          "title": "Mind Map",
          "description": "Turn all your thoughts into an interconnected map so you have stimulus for idea generation.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Mind_Mapping_062a3c6c5b.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 94,
          "title": "Idea Eight",
          "description": "<span style=\"font-weight: normal;\"><i>This tactic was formerly known as Crazy Eights.</i></span><br/><br/>Generate a lot of ideas, quickly. Eight ‘out there’ ideas in eight minutes!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Crazy_Eights_ced5e02ccb.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 109,
          "title": "Round Robin",
          "description": "Evolve each other’s ideas – quickly.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Round_Robin_819fc801a5.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 107,
          "title": "Storyboard",
          "description": "Draw your idea as a sequence of moments and real-world interactions to better understand how it works.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Storyboard_ba198716d4.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 112,
          "title": "Reverse Brainstorm",
          "description": "Think of the worst, most despicable ideas possible, then reverse them to find a solution.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Reverse_Brainstorming_c10757fc79.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 24,
      "title": "Evaluate",
      "description": "Assess problems or ideas against certain criteria to understand them better.",
      "iconCode": "&#xE806",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 95,
          "title": "Premortem",
          "description": "Think about all the ways something might go wrong, so you can effectively plan to stop it from happening.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pre_Mortem_41bcdefef1.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 132,
          "title": "SWOT Analysis",
          "description": "Identify strengths, weaknesses, opportunities and threats for a desired end-state.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/SWOT_d90ccb115b.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 114,
          "title": "Rose, Thorn, Bud",
          "description": "Evaluate an idea, project or process, identifying positives, negatives and opportunities.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rose_Thorn_Bud_c8807cb51e.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 133,
          "title": "Idea Beetle",
          "description": "Evaluate if your idea is strong and has ‘legs’, or if it needs more thought.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ideas_Beetle_c5ce0ca33b.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 104,
          "title": "Heart, Head, Hand",
          "description": "Evaluate the usefulness, emotional resonance and business value of an idea.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Heart_Head_Hand_0cdea5587c.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 26,
      "title": "Decide",
      "description": "Prioritise ideas or problems based on your needs in order to decide what to focus on.",
      "iconCode": "&#xE808",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 138,
          "title": "Priority Map",
          "description": "Find out where to focus based on criteria important to the group.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Prioritisation_Matrix_222f234057.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 135,
          "title": "Impact Effort Map",
          "description": "Group together ideas by how much effort they require to create, and by how much impact they’ll have on your goal.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Impact_effort_map_f943e32ab6.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 134,
          "title": "Assumption Map",
          "description": "Prioritise your assumptions based on certainty and risk.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Assumption_Map_7ff9d1824c.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 137,
          "title": "Secret Vote",
          "description": "<span style=\"font-weight: normal;\"><i>This tactic was formerly known as Blind Vote.</i></span><br/><br/>Democratically make decisions as a group. Eliminate all but one thing, or find the top three things.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Blind_Dot_Voting_e5eb57b93e.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 139,
          "title": "$100",
          "description": "Prioritise which ideas or features to focus on by deciding where to invest $100.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/100_9b292e5467.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 131,
          "title": "Who, What, When",
          "description": "Leave a session with a clear plan of goals and deadlines.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Who_What_When_ff9fab2ac4.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 25,
      "title": "Discuss",
      "description": "Facilitate discussion to reach a shared understanding so you can move towards your goals.",
      "iconCode": "&#xE807",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 125,
          "title": "Start, Stop, Continue",
          "description": "Get balanced, actionable feedback from a group on a topic, project or team.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Start_Stop_Continue_3c2ccb6cb2.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 129,
          "title": "Mad, Sad, Glad",
          "description": "Get a sense of a team’s feelings towards a current or previous project.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Mad_Sad_Glad_800081e61a.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 127,
          "title": "Three Little Pigs",
          "description": "Reflect on what’s going well and what could be improved, with the help of a childhood fable.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/three_little_pigs_454480e1bc.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 128,
          "title": "Democratic Discussion",
          "description": "Build a discussion agenda together and keep track of what’s been discussed, and what needs to be discussed.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Democratic_Discussion_9d6aeb3e67.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 136,
          "title": "Fishbowl",
          "description": "A self-facilitating, inclusive discussion with a large group of people.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Fishbowl_Discussion_87c1228aa0.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    },
    {
      "id": 30,
      "title": "Technique",
      "description": "Core techniques for planning and facilitating successful workshops.",
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "cards": [
        {
          "id": 100,
          "title": "Theme Sort",
          "description": "Make sense of large amounts of information by clustering similar things together to find the theme.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Affinity_Sort_cddd9172dd.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 124,
          "title": "T-Bar Format",
          "description": "Put your ideas into a format that can explain itself.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/T_Bar_9a994a08f3.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 116,
          "title": "Session Principles",
          "description": "Creating principles for a meeting or workshop is a 5–10 minute investment resulting in a more engaged and effective group of people.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Principles_e19950394b.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 117,
          "title": "Meeting Wizard",
          "description": "Set a clear purpose and structure for your meeting.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Meeting_Wizard_52956079a0.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 103,
          "title": "Ice Breakers",
          "description": "Warm up the room so people feel more comfortable contributing to the session you are about to run.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ice_Breakers_3995c10ee7.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 140,
          "title": "Parking Lot",
          "description": "Keep workshops on track by ‘parking’ irrelevant questions or topics.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Parking_Lot_bbbefdca71.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 115,
          "title": "Workshop Checklist",
          "description": "Remind yourself what you need to do before, during and after a workshop.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Time_Keeping_c0c13f0f05.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        }
      ]
    }
  ],
  "2": [
    {
      "id": 32,
      "title": "Concept",
      "description": "Stories shape how we see ourselves and the world around us. Frame your work as an epic adventure.",
      "iconCode": "&#xE803",
      "color": "#D16461",
      "cards": [
        {
          "id": 169,
          "title": "The Dragon & The City",
          "description": "Explain your project as if it’s an epic adventure. Get people excited about your plan of action.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Dragon_and_The_City_fec78a19b8.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 177,
          "title": "Three Great Conflicts",
          "description": "Show how your work helps people with life’s great battles. Conflict drives your story forward, because we want to know how it resolves.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Three_Great_Conflicts_ed26087a5f.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 154,
          "title": "Order & Chaos",
          "description": "Show how your project can impose order on a chaotic mess – or disrupt a system that’s too rigid.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Order_and_Chaos_114cbbee7f.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 152,
          "title": "Secrets & Puzzles",
          "description": "Make your story stick in our minds: promise a secret waiting to be revealed or a puzzle waiting to be solved.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Secrets_and_Puzzles_357e3244d9.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 141,
          "title": "Rules, Cheats & Rebels",
          "description": "Tell us a rule, then show someone breaking it. Now you’ve got our attention: will they be punished or vindicated?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rules_Cheats_and_Rebels_461242021e.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 147,
          "title": "Good & Evil",
          "description": "Tap into strong feelings of right and wrong in your audience. Bring a moral conflict into your story.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Good_and_Evil_4c57644706.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 150,
          "title": "Universal Stories",
          "description": "Build your story on solid foundations. Use elements that everyone will recognise.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Universal_Stories_7424a0c37a.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 163,
          "title": "Curious Tales",
          "description": "Tell me what grabs your attention and you’re halfway to grabbing my attention too.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Curious_Tales_3ca3370918.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 176,
          "title": "Circle Of Life",
          "description": "Help us recognise the characters, conflicts and changes in your story. Relate them to universal life stages.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Circle_of_Life_453f01ea76.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 157,
          "title": "Shock Of The Old",
          "description": "We can get so hooked on imagining the future that we forget to look for inspiration in stories from the past.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Shock_of_The_Old_a22cda637d.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 31,
      "title": "Explore",
      "description": "Stories help us navigate confusing, unknown and changing situations. Make a map for your way ahead.",
      "iconCode": "&#xE908",
      "color": "#EB986C",
      "cards": [
        {
          "id": 146,
          "title": "Story Listening",
          "description": "Listen to other people’s stories when you need their wisdom or support. Don’t just talk at them!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Story_Listening_6810dbd41c.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 174,
          "title": "Emotional Dashboard",
          "description": "Look for strong emotions when you need to find a story. What made you feel that way and what happened next?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Emotional_Dashboard_cb56220eee.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 151,
          "title": "Data Detectives",
          "description": "Wrap your key facts up in a story if you want people to remember them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Data_Detectives_99edb18525.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 153,
          "title": "Thoughtful Failures",
          "description": "Every time you fail there’s a story to tell-and plenty of people ready to listen.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Thoughtful_Failures_0c15d68ab2.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 144,
          "title": "That’s Funny",
          "description": "You can tell a great story about your project by finding “That’s funny...” moments in your work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/That_s_Funny_5221d6e762.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 186,
          "title": "Abstractions",
          "description": "Don’t just ask questions, observe people in action. We often understand much more than we can explain.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Abstractions_e0c781bfdd.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 148,
          "title": "Social Proof",
          "description": "You’re in a busy room. Suddenly a fire alarm goes off. What do you do?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Social_Proofs_38839fbcce.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 33,
      "title": "Character",
      "description": "Stories connect us to other people. Show us why we can trust you.",
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "cards": [
        {
          "id": 170,
          "title": "Trust Me, I’m An Expert",
          "description": "Win my trust. Tell me a story that shows your character and values <b><i>in action</i></b>.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Trust_Me_I_m_an_Expert_6c0c181d22.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 143,
          "title": "What’s My Motivation?",
          "description": "How will your user or customer respond to your idea? It helps if you know what’s driving them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Whats_My_Motivation_44ba6eb5a3.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 165,
          "title": "Drive Stories",
          "description": "Why do you work? For the money, yes. But what really drives you? These stories build a better team culture.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Drive_Stories_fd9c17e484.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 149,
          "title": "Hero & Guide",
          "description": "Stop talking about yourself. Make your user the Hero of your story. You play a supporting role: the <b><i>Expert Guide</i></b>.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hero_and_Guide_84441ae6ff.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 159,
          "title": "What’s It About?",
          "description": "Me: “So what are you working on?”<br/>You: “Umm... well... er...” Don’t be that person.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/What_s_It_About_57b0112fa2.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 142,
          "title": "Cut To The Chase",
          "description": "Oh no, you’re losing your audience! Their minds are wandering. Quick, get their attention back on you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Cut_to_The_Chase_3eb09870f3.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 37,
      "title": "Function",
      "description": "Stories are more effective than facts or opinions by themselves. Put your stories to work.",
      "iconCode": "&#xE90A",
      "color": "#9CC992",
      "cards": [
        {
          "id": 179,
          "title": "Pitch Perfect",
          "description": "Convince me to back your idea. Show me: what have you got that I need? And why should I trust you to deliver?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pitch_Perfect_c72d4d9838.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 156,
          "title": "Simple Sales Stories",
          "description": "Convince new customers to buy into your idea by telling stories about existing customers or users.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/simple_sales_stories_5bfbeac28c.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 167,
          "title": "Story-ish Conversations",
          "description": "Find the insights buried beneath everyday conversa­tions with your colleagues, customers or users.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Story_ish_Conversations_05d5d578d3.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 155,
          "title": "Icebreaker Stories",
          "description": "Get your team thinking in a story-ish way.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Icebreaker_Stories_df7a4a6c3e.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 36,
      "title": "Structure",
      "description": "Stories carry us along thanks to a few basic patterns. Make your ideas flow in a story-ish way.",
      "iconCode": "&#xE90B",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 182,
          "title": "Man In A Hole",
          "description": "This is the story of your first day at school or your first job. It’s every fear you faced and every lesson you’ve learned.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Man_in_a_hole_cda652aa5a.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 173,
          "title": "Rags To Riches",
          "description": "Cinderella, Rocky or Steve Jobs – it’s the same story. The rise to success from humble beginnings.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rags_to_Riches_8b54ff8e0e.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 181,
          "title": "No Easy Way",
          "description": "Nothing good is ever easy. If something was good and easy, we’d be doing it already.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/No_Easy_Way_f58edd7b07.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 175,
          "title": "Pride & Fall",
          "description": "This is one of the oldest stories we know. Icarus, Frankenstein and Titanic all follow its arc.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pride_and_Fall_ca5b114b28.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 183,
          "title": "Downfall",
          "description": "Macbeth, Lance Armstrong and Nokia: they all had a hidden flaw that brought them down.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Downfall_2efe2bfd96.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 161,
          "title": "Epic Fail",
          "description": "When your plans go wrong, you can play the blame game. Or you can make sense of the failure with a story.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Epic_Fail_978f5e7a05.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 180,
          "title": "Innovation Curve",
          "description": "Make your bold new idea seem less risky.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Innovation_Curve_ba01214433.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 185,
          "title": "Voyage & Return",
          "description": "Put adventure and the ties of home at the heart of your story. This will keep us hooked til the end.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Voyage_and_Return_9ff776c529.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 171,
          "title": "Happy Ever Afters",
          "description": "We’re suckers for a happy ending. Life is no fairy tale, but you can always give your story a satisfying end.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Happy_Ever_Afters_2322b36aa6.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 166,
          "title": "Five Ts",
          "description": "Keep these Ts in your back pocket in case someone puts you on the spot and you need to tell a story <b><i>right now</i></b>!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Five_Ts_d891f8f246.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 35,
      "title": "Style",
      "description": "Stories carry useful information. Make sure people remember yours.",
      "iconCode": "&#xE90C",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 172,
          "title": "Movie Time",
          "description": "Tell me a story and a little movie starts playing in my head. I literally “see” what you mean.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Movie_Time_19914bc352.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 145,
          "title": "Story Hooks",
          "description": "Craft a compelling hook at the start of your story. It will keep us listening til the end.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Story_Hooks_1ea3c7b4c1.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 162,
          "title": "Three Is The Magic Number",
          "description": "Choose the most important parts of your story, then use these tricks to fix them in your audience’s memory.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Three_is_the_Magic_Number_f3fdc1f203.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 164,
          "title": "Rolls Royce Moment",
          "description": "“Cruising at 60mph, the loudest noise inside the Rolls Royce comes from the electric clock.”",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rolls_Royce_Moment_3aa42a15b7.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 158,
          "title": "Leave It Out!",
          "description": "Tell us you’ve left something out and we will work hard to fill in the gap. And then we feel like it’s our story too.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Leave_it_out_9d5b32c4fb.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 178,
          "title": "Show & Tell",
          "description": "Keep people’s attention <b><i>on you</i></b> while you make a presentation. Make your <b><i>Show</i></b> and <b><i>Tell</i></b> work together.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Show_and_Tell_d657510fae.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 34,
      "title": "Organise",
      "description": "Stories work, so long as you use them well. Plan how you should tell yours.",
      "iconCode": "&#xE90D",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 168,
          "title": "Story Bank",
          "description": "Keep track of your stories – and the information behind them – if you don’t want to lose our trust.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Story_Bank_24f1d178b9.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 184,
          "title": "Big, Small, Inside, Outside",
          "description": "You might have a Big Story to tell the world. But you’ll spend more time telling small stories to colleagues.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Big_Small_Inside_Outside_0c7a99e9d8.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 160,
          "title": "Audience Profile",
          "description": "Know your audience. You can’t tell the right story if you don’t know what your audience needs.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Audience_Profile_a9b19b374c.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    }
  ],
  "3": [
    {
      "id": 72,
      "title": "Situation",
      "description": "Understand the reality of the world you’re dealing with and the people within it, so you can spot opportunities for innovation.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "cards": [
        {
          "id": 234,
          "title": "Chasm Crossing",
          "description": "Sense when to experiment and when to scale by matching your efforts to where you are in the innovation cycle.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/chasm_crossing_065bc8c158.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 196,
          "title": "Real Needs",
          "description": "Detect people’s most pressing needs, so that you don’t waste time trying to fix the endless long tail of needs that they’re secretly happy to keep putting up with.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/real_needs_final_5f5bb188ee.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 187,
          "title": "Documentary Conversation",
          "description": "Surface the real reasons behind a customer’s behaviour by asking questions as if you were planning to make a documentary about their life.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/documentary_conversation_final_ac9e6a1086.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 189,
          "title": "Diagnostic ‘Shop Along’",
          "description": "Understand what an experience was really like for someone by recreating it live. You’ll see details they couldn’t tell you from memory alone.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/diagnostic_shop_a_long_final_7ea1940118.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 190,
          "title": "Solution Aikido",
          "description": "Find the real opportunity behind a suggested solution so that you don’t waste time building the wrong thing.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/solution_Aikido_9f0739ac7f.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 193,
          "title": "Observation",
          "description": "Watch people go about their day so you can spot the opportunities in struggles they can’t tell you about.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/observation_final_eadf2b5115.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 195,
          "title": "Watering Holes",
          "description": "Go to where your audience naturally hangs out, so you can find out what truly matters to them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/watering_hole_final_195e12e792.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 224,
          "title": "Human Story Map",
          "description": "Tell the story of how your user is currently meeting needs in their life, so you can spot opportunities for innovation.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/human_story_map_final_df65646cd2.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 230,
          "title": "Task Landscape",
          "description": "Visualise all the ways people are already trying to make progress with their struggles, so that you can spot opportunities.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/task_landscape_0b2b655225.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 232,
          "title": "Pain X-Ray",
          "description": "Read between the lines of the words and phrases your audience use, so you can uncover their hidden pain – showing you genuine opportunities to innovate.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/pain_x_ray_final_1354d881a5.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 233,
          "title": "Switch Timeline",
          "description": "Understand your market’s real needs by looking at the common patterns behind big buying decisions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/switch_timeline_final_755feb7fc5.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 71,
      "title": "Ideas",
      "description": "Come up with surprising new ideas for products, services or experiences, using exercises that stimulate your creativity.",
      "iconCode": "&#xE803",
      "color": "#EB986C",
      "cards": [
        {
          "id": 188,
          "title": "Snowmobiling",
          "description": "Remix elements from existing ideas so you can create never-seen-before innovations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/snowmobiling_final_c4cab016f3.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 191,
          "title": "Radical Repurposing",
          "description": "Reuse what already exists in ways they weren’t designed for so you can make unexpected innovative leaps.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/radical_repurposing_final_6bab6567af.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 221,
          "title": "10-Star Experience",
          "description": "Tell the story of your customer having an impossibly good experience, so that you can use it to uncover ideas that could actually be possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/10_star_experience_final_3a5664e873.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 201,
          "title": "Forced Mutation",
          "description": "Use the power of guesswork between teams to help you come up with “never knew I wanted it till I saw it” kind of innovations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/forced_mutation_final_6f11eb562d.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 223,
          "title": "Force 25",
          "description": "Force out 25 ideas in 25 minutes so you can get past the obvious and surprise yourself with concepts you didn’t know were within you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/force_25_final_55540ea33c.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 211,
          "title": "Shaping Trios",
          "description": "Know how to make progress with an opportunity by assessing the range of approaches a team proposes.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/shaping_trios_final_d31457efb6.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 199,
          "title": "Box Clever",
          "description": "Clarify new ideas quickly by getting people to design the packaging that an innovation could be sold in.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/box_clever_final_5a00c1c761.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 194,
          "title": "Stretch Prompts",
          "description": "Ask questions that push you to see your situation from a different angle, so you can come up with new ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/stretch_promps_final_88384e1158.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 67,
      "title": "Focus",
      "description": "Enable yourself to start with the riskiest aspect of an idea. Then, if you need to adapt, you’ll have the leeway to take more ‘shots’ and find success.",
      "iconCode": "&#xE806",
      "color": "#F5C466",
      "cards": [
        {
          "id": 214,
          "title": "Disconfirmation Bias",
          "description": "Embrace the possibility that your innovation will fail, so you can gain the courage to find out if it really will.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/disconformation_bias_final_5460a5b9ea.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 213,
          "title": "Seeds vs Soil",
          "description": "Identify the best way to make all the decisions that matter, so you avoid a futile hunt for certainty.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/seeds_vs_soil_prep_final_6b517c1f47.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 192,
          "title": "Time Machine",
          "description": "Predict how an idea might fail – so that you can avoid that future – by taking a trip in an imaginary time machine.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/time_machine_final_72a94773e8.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 222,
          "title": "Solve For Distribution",
          "description": "Tell two simple stories so that you ensure people can first choose and then use your innovation.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/solve_for_distribution_final_eac9c00159.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 203,
          "title": "Hard Test, Easy Life",
          "description": "First deliver an incomplete, rough version of your idea, so that you learn how to do the hardest parts. Then you can confidently build the right complete, polished version.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/hard_test_easy_life_final_0cf71347ad.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 216,
          "title": "Complexity Heatmap",
          "description": "Create a heatmap that pinpoints your ‘unknown unknowns’. Those are the high-risk parts of your innovation. Take control of them, and the rest is easy.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/complexity_heat_map_final_6aa86db296.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 229,
          "title": "Multiverse Map",
          "description": "In a potential customer’s shoes, tell the story of what they’ll see and do with your innovation in the best and worst universes. Then you can spot – and amplify – the behaviours that are critical to success.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/multiverse_map_final_b31ebc9fce.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 68,
      "title": "Signals",
      "description": "Identify which type(s) of signals you’d need to see to feel confident that your innovation will succeed.",
      "iconCode": "&#xE800",
      "color": "#9CC992",
      "cards": [
        {
          "id": 202,
          "title": "Pivot Triggers",
          "description": "Before you start the work, agree what you’ll need to see from the world for you to feel confident about investing in your idea.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/pivot_triggers_final_20bf2178d1.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 217,
          "title": "Want It Enough?",
          "description": "Probe for signals that people are willing to spend money (or time, effort, risk) on your innovation, so that you know for sure whether anyone truly wants it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/want_it_enough_signals_final_01afdb5fe4.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 197,
          "title": "Get It?",
          "description": "Before people can want your innovation, they need to be able to understand it. Probe for signals of confusion so that you can fix your pitch.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/get_it_signals_final_e5d4c4edeb.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 212,
          "title": "Worth It?",
          "description": "Probe for signals that help you understand what it will take to build and operate an innovation, so you can be confident that it will be profitable.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/worth_it_signals_final_fe970f36fe.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 66,
      "title": "Probe",
      "description": "Poke the world for the signals you need. How have (or haven’t) real humans changed their behaviour as a result of something you put out there?",
      "iconCode": "&#xE911",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 218,
          "title": "Be The Algorithm",
          "description": "Operate your innovation by hand first, so that you can build it better, faster.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/be_the_algorithim_final_08fc16049e.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 208,
          "title": "Walking Skeleton",
          "description": "When building software, first join up just enough of the bones to make one tiny function work from end to end. That way you’ll find nasty surprises early.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/walking_skeleton_final_0a4476ce12.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 207,
          "title": "Language Market Fit",
          "description": "Find the words that resonate with your potential customers so that you know your innovation will actually connect with them before you build it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/language_market_fit_final_0a374e9346.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 205,
          "title": "Recruitment Probe",
          "description": "Get early signals that people could be interested in solving a frustration by inviting them to chat about it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/recruitment_probe_final_aefd987ce5.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 200,
          "title": "Behavioural Probe",
          "description": "Sell a tiny, pretend or alternative version of your idea before you actually build it, so you know if it has legs.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/behavioural_probe_final_58e7244113.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 210,
          "title": "‘Huh?’ Test",
          "description": "Get someone’s gut reaction to your work to see if they understand it – so you know what needs improving.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/get_it_test_final_1f92553b79.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 227,
          "title": "Pitch Provocations",
          "description": "See how potential customers respond to a range of teaser adverts for ideas that don’t exist yet, so that you can decide which of them to invest your effort into.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/pitch_provocation_6ba3a3f47e.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 198,
          "title": "Punchy Prototypes",
          "description": "Prototype to learn instead of to validate, so you can understand the unavoidable tradeoffs and choose from a range of possibilities – without getting wedded to one idea.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/punchy_prototypes_final_1e06edcaa6.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 70,
      "title": "Sense",
      "description": "Generate actionable insights from the signals you gathered. Then you can either adapt your plans, or move forward with confidence and momentum.",
      "iconCode": "&#xE802",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 226,
          "title": "Speedy Synthesis",
          "description": "To get the most out of every session with a customer or potential customer, summarise your observations immediately afterwards.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/speedy_synthesis_final_c3a5dfab57.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 225,
          "title": "Anatomy Of An Insight",
          "description": "Tell more than one story about the situation you’re observing so you can decide on your best next action.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/anatomy_of_an_insight_final_d33ba3cb17.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 231,
          "title": "Four Forces",
          "description": "Learn how to influence your customers’ decisions by finding out what drives their behaviour.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/four_forces_final_ae83a67495.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 215,
          "title": "CSI Pinboard",
          "description": "Unlock insights by making use of your brain’s natural ability to spot patterns across lots of observations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/CSI_pinboard_9903c5d73b.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 206,
          "title": "Universal Translator",
          "description": "Know what potential customers are trying to tell you when they offer opinions or excuses, so you can understand and fix the real problems.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/universal_translator_final_6aee706b5e.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 228,
          "title": "2D Comparison",
          "description": "Get a potential customer or a team to compare possibilities, so you learn what they prefer – and what lies behind their subjective choices.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/2_D_comparison_final_6415318e44.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 69,
      "title": "Support",
      "description": "Core methods for innovation.",
      "iconCode": "&#xE900",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 220,
          "title": "Incentives",
          "description": "Speak with people who don’t naturally want to speak with you by paying them in some way.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/incentives_final_0b57c74512.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 219,
          "title": "User Session Outline",
          "description": "Follow a simple outline when you talk with customers, so you avoid wasting precious time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/user_session_final_4cf60fba28.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 204,
          "title": "Screeners",
          "description": "Make every research session count by filtering to find the people you really want to speak with.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/screeners_final_f86661a215.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 209,
          "title": "Question Cheat Sheet",
          "description": "To get valuable answers, frame your questions so people are willing and able to answer truthfully.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/question_cheat_sheet_7e70d4444d.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    }
  ],
  "4": [
    {
      "id": 136,
      "title": "Prime",
      "description": "Ready your body and mind to make creative leaps.",
      "iconCode": "&#xE912",
      "color": "#D16461",
      "cards": [
        {
          "id": 281,
          "title": "Imperfect Portraits",
          "description": "Loosen people up by getting them to draw each other.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/imperfect_portraits_81efd68b6f.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 282,
          "title": "Mood Check-In",
          "description": "Embrace emotions and practise listening to each other with attention and empathy.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/mood_check_in_65fef58c81.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 283,
          "title": "Zen Spiral",
          "description": "Calm your mind and make sketching seem less scary.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/zen_spiral_13ed70a690.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 284,
          "title": "Thunks",
          "description": "Promote opinionated but flexible thinking using questions with more than one possible response –questions that make your brain go ‘ouch!’.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/thunks_521b591f41.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 285,
          "title": "Mind-Body Dissonance",
          "description": "Get everyone energised and ready to embrace uncommon ideas by playing with facial expressions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/mind_body_dissonance_c301d6189c.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 286,
          "title": "Haiku",
          "description": "The room is ready<br/>But our minds feel kinda dull<br/>We sharpen with words",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/haiku_5abe15874c.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 287,
          "title": "Dream Sketch",
          "description": "Go from zero to weird by drawing dreams.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/dream_sketch_bdf4c5851f.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 288,
          "title": "Draw Your Job",
          "description": "Get people to sketch a picture that describes their job or their role on a particular project.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/draw_your_job_2b718ae285.png",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 289,
          "title": "Invent An Animal",
          "description": "Connect with your imagination – create a new animal and give it a name.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/invent_an_animal_31c48afcd1.png",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 137,
      "title": "Frame",
      "description": "Describe your challenge and draw the boundaries of your creative playground.",
      "iconCode": "&#xE805",
      "color": "#EB986C",
      "cards": [
        {
          "id": 290,
          "title": "Fence The Playground",
          "description": "Foster freedom by setting some rules.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/fence_the_playground_5d38000c1c.png",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 291,
          "title": "Normstorming",
          "description": "Pinpoint stale clichés so you can avoid doing the same thing as everyone else.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/normstorming_cac84acd8f.png",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 292,
          "title": "Perform The Problem",
          "description": "Act out a problem to help people understand it and make it feel real.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/perform_the_problem_7860926899.png",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 293,
          "title": "Who, What, Where, Why",
          "description": "Summarise existing knowledge before you start coming up with ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/who_what_where_why_8d96175933.png",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 294,
          "title": "How Might We?",
          "description": "Craft a question that gives you the freedom to explore many solutions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/FRAME_05_a594b6d4ee.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 138,
      "title": "Explore",
      "description": "See existing insights with fresh, bright, wide-open eyes, and create artefacts to inspire ideas.",
      "iconCode": "&#xE908",
      "color": "#F5C466",
      "cards": [
        {
          "id": 295,
          "title": "Mind Map",
          "description": "Visualise your idea-space and draw connections between different pieces.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/mind_map_78bbc33461.png",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 296,
          "title": "Combinaboards",
          "description": "Collect inspiration in rough-and-ready moodboard format to help you make novel connections later on.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/combinaboards_3a7abf9188.png",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 297,
          "title": "Make A Meme",
          "description": "Customise or invent a meme to make your context easier to grasp.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/make_a_meme_6fbb97c019.png",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 298,
          "title": "Fame Vs Shame",
          "description": "Gather innovation examples and analyse what makes them strong or weak.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/fame_vs_shame_07cf2865c9.png",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 299,
          "title": "Observe/Infer",
          "description": "Engage more deeply with research by separating observation and interpretation.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/observe_infer_57e9207923.png",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 139,
      "title": "Diverge",
      "description": "Go wide – approach your challenge from different angles and generate lots of potential directions.",
      "iconCode": "&#xE911",
      "color": "#9CC992",
      "cards": [
        {
          "id": 300,
          "title": "Eight-Box Blitz",
          "description": "<span style=\"font-weight: normal;\"><i>This tactic was formerly known as Crazy Eights.</i></span><br/><br/>Quickly capture eight ideas or options in response to any question.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/eight_box_blitz_346c370642.png",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 301,
          "title": "What Would X Do?",
          "description": "Imagine how famous figures, fictional characters or iconic brands might respond to your creative challenge.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/what_would_i_do_4faa89ab81.png",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 302,
          "title": "Lotus Blossoms",
          "description": "Rapidly generate and evolve ideas over 3 rounds.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/lotus_blossoms_5af082fc72.png",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 303,
          "title": "6-3-5",
          "description": "Get to over 100 ideas in 30 minutes.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/6_3_5_f6b8328990.png",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 304,
          "title": "Mash-Up",
          "description": "Make novel connections to spark fresh ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/mash_up_7ed617b8b3.png",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 305,
          "title": "Worst Idea Race",
          "description": "Get weak ideas out of the way so the good ones are easier to find.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/worst_idearace_087a248085.png",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 140,
      "title": "Stretch",
      "description": "Push your ideas further without losing sight of what matters.",
      "iconCode": "&#xE90F",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 306,
          "title": "Analogous Inspiration",
          "description": "Get away from your desk and find fresh perspective to unlock breakthrough ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/analogous_inspiration_8bb90da5ce.png",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 307,
          "title": "ProvocaPrompts",
          "description": "Strengthen your ideas with a set of provocative questions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/provocaprompts_6d91616e02.png",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 308,
          "title": "Yes, And...",
          "description": "Build on each other’s ideas using a mindset borrowed from improv theatre.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/yes_and_27f38c085f.png",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 309,
          "title": "Human Truths",
          "description": "Enrich your ideas by contemplating your audience’s deepest and most unchanging needs.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/human_truths_d0a5e4b39b.png",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 310,
          "title": "Peak-End Rule",
          "description": "Elevate key moments within your idea, spot potential pain points and give a satisfying ending.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/peak_end_rule_30f417abba.png",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 141,
      "title": "Refine",
      "description": "Add detail and depth to your strongest ideas.",
      "iconCode": "&#xE910",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 311,
          "title": "Storyboard",
          "description": "Sketch the key stages in a person’s experience of your idea.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/storyboard_cc29b65c2a.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 312,
          "title": "T-Bar",
          "description": "Capture ideas in a format that anyone can quickly understand.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/t_bar_36a1171dce.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 313,
          "title": "Big Beats",
          "description": "Describe why your idea matters using a simple but powerful story structure.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/big_beats_3c9d1099ba.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 314,
          "title": "Like/Not Like",
          "description": "Explore what properties your idea shares with other things, and what makes your idea stand apart, to make your thinking clearer.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/like_not_like_f8b47ce85d.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 315,
          "title": "Mega Memo",
          "description": "Write an internal press release for your idea that centres the user and helps you get buy-in.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/mega_memo_1662eb9630.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 316,
          "title": "Back & Forth",
          "description": "Think through an idea as if it’s a conversation to better understand how it should flow.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/back_forth_6339d4942a.png",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 142,
      "title": "Review",
      "description": "Get meaningful feedback, identify the most compelling elements of your ideas, and decide which ideas to pursue.",
      "iconCode": "&#xE90E",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 317,
          "title": "PrioriTree",
          "description": "Break down the various elements of an idea and decide where to begin when prototyping it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/prioritree_f693a1e22f.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 318,
          "title": "Invest Your PipCoins",
          "description": "Rank multiple ideas by thinking in bets.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/invest_your_pipcoins_1f6aea8d8a.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 319,
          "title": "Like, Wish, Wonder",
          "description": "Get feedback that focusses on positives and possibilities to unlock bigger and better ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/like_not_like_f8b47ce85d.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 320,
          "title": "Concerns, Confusions, Conflicts",
          "description": "Stress-test your ideas through structured critique.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/concerns_confusions_conflicts_a539c07add.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 321,
          "title": "Angels & Demons",
          "description": "Shine a light on any issues that might hold your idea back and identify what will improve its chances of success.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/angels_demons_3731d0c901.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 322,
          "title": "Deep Impact",
          "description": "Make greater positive change with your idea by considering different perspectives.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/deep_impact_fc0ebf270c.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 323,
          "title": "SICFAM",
          "description": "Prioritise ideas by scoring them on six key criteria.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/sicfam_8084c3528b.png",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    },
    {
      "id": 143,
      "title": "Cheatsheet",
      "description": "Get more out of your creative process with mindful planning, preparation and facilitation.",
      "iconCode": "&#xE800",
      "color": "#23232F",
      "cards": [
        {
          "id": 324,
          "title": "Prep Like A Pro",
          "description": "Maximise your chances of coming up with fresh, relevant, effective ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/prep_like_a_pro_8e37bebd6c.png",
          "style": {
            "borderColor": "#23232F"
          }
        },
        {
          "id": 325,
          "title": "Invaluable Inputs",
          "description": "Gather information that will help you generate high-value ideas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/invaluable_inputs_9a5da75d88.png",
          "style": {
            "borderColor": "#23232F"
          }
        },
        {
          "id": 326,
          "title": "Facilitation Tips",
          "description": "Create the right atmosphere for free thinking, expression and collaboration.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/facilitation_tips_0f3f1558f2.png",
          "style": {
            "borderColor": "#23232F"
          }
        },
        {
          "id": 327,
          "title": "Boss Mode",
          "description": "Keep activities and processes feeling fresh with next-level tips and provocations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/boss_mode_66edc2ac0b.png",
          "style": {
            "borderColor": "#23232F"
          }
        }
      ]
    }
  ],
  "5": [
    {
      "id": 129,
      "title": "Purpose",
      "description": "Find out why you should even bother, then decide what you want to accomplish.",
      "iconCode": "&#xE804",
      "color": "#D16461",
      "cards": [
        {
          "id": 235,
          "title": "Close Up Shop",
          "description": "Confront the hard truth about your impact on the world, so you can discover a compelling reason to keep going.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/CLOSE_UP_SHOP_67299d9b75.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 236,
          "title": "Default Disaster",
          "description": "Imagine the catastrophe that will happen if nothing changes, and then make a plan to avoid it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/DEFAULT_DISASTER_c71f751c29.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 237,
          "title": "Better Now",
          "description": "Aim for the best possible version of today to unlock the many amazing possibilities of tomorrow.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BETTER_NOW_1c333f23b9.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 238,
          "title": "Feasible Futures",
          "description": "What would we build if we had a blank slate?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/FEASIBLE_FUTURES_4bcbd1cc85.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 239,
          "title": "Complaint Department",
          "description": "Turn individual complaints into shared challenges to make change possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/COMPLAINT_DEPARTMENT_4b3aca16e7.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 240,
          "title": "Plainly Put",
          "description": "Settle the differences between your words and your actions to find a purpose you can live up to every single day.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/PLAINLY_PUT_6e6f10930c.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 241,
          "title": "Name Your Game",
          "description": "Account for your everyday actions and goals, so you can build your capacity to take on bigger and better challenges.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/NAME_YOUR_GAME_4ffbbd7295.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 130,
      "title": "Identify",
      "description": "Make any situation discussable by breaking it down into clear whos and whats.",
      "iconCode": "&#xE806",
      "color": "#EB986C",
      "cards": [
        {
          "id": 242,
          "title": "Mystery Machine",
          "description": "Make a model of a confusing or broken system to figure out how it works, identify what’s wrong, and fix it!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/MYSTERY_MACHINE_4fa629d225.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 243,
          "title": "Human Network",
          "description": "Uncover your extended network of stakeholders, so their needs never catch you off guard.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/HUMAN_NETWORK_31e8ce5fb8.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 244,
          "title": "Seeking Specifics",
          "description": "Identify all the different parts of your organization, so you can figure out what hurts and get help to make it better.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/SEEKING_SPECIFICS_652ad477c8.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 245,
          "title": "Promise Proposal",
          "description": "Understand what needs people expect you to meet, so you make promises you can keep.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/PROMISE_PROPOSAL_78c4d2d4a3.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 246,
          "title": "Buzzword Breakup",
          "description": "Replace false understanding with true agreement by driving empty words out of your conversations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BUZZWORD_BREAKUP_c521308626.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 131,
      "title": "Connect",
      "description": "Find out how all the pieces fit together to learn how the whole thing works.",
      "iconCode": "&#xE90D",
      "color": "#F5C466",
      "cards": [
        {
          "id": 247,
          "title": "Five Whats",
          "description": "Find the risks deep in your supply chain by asking “...and what does that depend on?” at least five times.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/FIVE_WHATS_b71088d762.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 248,
          "title": "Value Chain",
          "description": "Sketch out a system to prove to yourself that you understand how it works.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/VALUE_CHAIN_122721fc2a.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 249,
          "title": "Fog Of Work",
          "description": "Make your decisions more strategic by widening your field of view beyond what’s right in front of you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/FOG_OF_WORK_7894ff12a6.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 132,
      "title": "Evolve",
      "description": "Embrace market movement, so you can go with the flow – instead of crashing into it.",
      "iconCode": "&#xE913",
      "color": "#9CC992",
      "cards": [
        {
          "id": 250,
          "title": "Building Blocks",
          "description": "Evaluate the core parts of your business to make sure you’re building on solid ground.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BUILDING_BLOCK_d838a16499.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 251,
          "title": "Fast-Forward Futures",
          "description": "Follow the predictable path of <card-link>Market Evolution</card-link> to anticipate tomorrow’s innovations based on today’s market.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/FAST_FORWARD_FUTURE_256cbf6290.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 252,
          "title": "Market Perspectives",
          "description": "Get aligned on the type of market challenge you’re facing to get everyone moving in the same direction.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/MARKET_PERSPECTIVES_19a6c470ed.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 253,
          "title": "Market Evolution",
          "description": "Spot the market forces in action around you, so you can ride change like a wave – instead of getting smashed by it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/MARKET_EVOLUTION_8a647ca7dd.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 133,
      "title": "Adapt",
      "description": "Change your behavior so you can thrive within a challenging, competitive environment.",
      "iconCode": "&#xE912",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 254,
          "title": "Embrace The Change",
          "description": "Overcome resistance to change by recognizing why change can be painful and how to move forward.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/EMBRACE_THE_CHANGE_b1b5794eae.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 255,
          "title": "Big Frog, Little Pond",
          "description": "Focus on the smallest, simplest way to get things done, to protect yourself from nimbler competitors.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BIG_FROG_LITTLE_POND_38268babd0.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 256,
          "title": "Why DIY?",
          "description": "Decide which parts of your system to build, buy, and outsource with a simple points-based market assessment.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/WHY_DIY_c20c9dfcbd.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 257,
          "title": "Vendor Disassembler",
          "description": "Make clear-eyed purchasing choices by matching supplier offerings to specific needs in your design.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/VENDOR_DISASSEMBLER_f1a1561816.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 258,
          "title": "Buyer’s Remorse",
          "description": "Avoid unpleasant surprises in purchasing and contracting by setting realistic terms.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BUYERS_REMORSE_b8319368ab.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 259,
          "title": "Experiment Methods",
          "description": "Design the right kind of experiment for every situation, so you learn as much as you can, every time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/EXPERIMENT_METHODS_67a1cbf3e5.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 260,
          "title": "Disruption Readiness",
          "description": "Prepare a competitive response to disruption, so you can overcome the threat of future irrelevance.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/DISRUPTION_READINESS_94904fc426.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 261,
          "title": "Legacy Trap",
          "description": "Manage the decline of your legacy systems, so you can make the most of the past without compromising your future.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/LEGACY_TRAP_9c9572c3e5.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 262,
          "title": "Adjustable Attitudes",
          "description": "Help teams find the right blend of approaches, so that what they bring to the table matches exactly what the work needs.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/ADJUSTABLE_ATTITUDES_198d34e733.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 263,
          "title": "Shift Gears",
          "description": "Match your way of working to what the situation needs, so you don’t stall out or run out of gas.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/SHIFT_GEARS_dabeb503eb.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 264,
          "title": "Changing Ways",
          "description": "Get the full benefits of switching to something new by switching your habits, too.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/CHANGING_WAYS_8a9bf5621b.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 265,
          "title": "Open-Source Intelligence",
          "description": "Use publicly available information in order to find opportunities hidden in plain sight.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/OPEN_SOURCE_INTELLIGENCE_6935e77b1a.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 134,
      "title": "Plays",
      "description": "Change the environment itself until it meets your needs.",
      "iconCode": "&#xE911",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 266,
          "title": "Bundle Up",
          "description": "Create new market possibilities by putting things together or taking them apart.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BUNDLE_UP_9ea5d66c86.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 267,
          "title": "Active Waiting",
          "description": "Do nothing, so you can conserve your energy for a more favorable moment.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/ACTIVE_WAITING_88173b6b01.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 268,
          "title": "Be First Or Be Fast",
          "description": "Know when to blaze a new trail and when to follow in others’ footsteps, so you always have the advantage.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BE_FIRST_OR_BE_FAST_279af7bba9.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 269,
          "title": "Compete Different",
          "description": "Consider all available ways to compete so you can make the best investment of your limited resources.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/COMPETE_DIFFERENT_512aef6bd7.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 270,
          "title": "Idea Garden",
          "description": "Share useful building blocks, then watch how people use them, so you can be among the first to benefit from any new innovations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/IDEA_GARDEN_dd99e28aab.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 271,
          "title": "Open Approaches",
          "description": "Give a product or idea away for free to speed up adoption, then invite people to give back to speed up improvement.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/OPEN_APPROACHES_4fc71a3015.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 272,
          "title": "Building Bridges",
          "description": "Invest in your alliances, so you’ll always have a helping hand when you need it most.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BUILDING_BRIDGES_87149b7b4a.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 273,
          "title": "Positive Influence",
          "description": "Educate the people who matter to you, so they make better decisions for themselves (and for you).",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/POSITIVE_INFLUENCE_d7d98926bd.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 135,
      "title": "Lead",
      "description": "Be clear, do the right thing and help others contribute their best.",
      "iconCode": "&#xE908",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 274,
          "title": "Backbrief",
          "description": "Rehearse the plan before work begins, so you eliminate unpleasant surprises early.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/BACKBRIEF_4f45970ba6.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 275,
          "title": "Take Responsibility",
          "description": "Hold the space for doing what must be done, in order to lead the people in your care through difficult, no-win situations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/TAKE_RESPONSIBILITY_a2f945680f.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 276,
          "title": "Decision Diary",
          "description": "Improve your decision-making skills by comparing what you think will happen to what actually happens.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/DECISION_DIARY_47b8640e99.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 277,
          "title": "Measure Twice",
          "description": "Catch missed opportunities and mistakes early by sharing your thinking with a group for feedback.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/MEASURE_TWICE_14aa0b48f5.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 278,
          "title": "Give Me A Reason",
          "description": "Speak like a leader so that everyone stays connected to the purpose.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/GIVE_ME_A_REASON_c7315f17dd.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 279,
          "title": "Challenge Statement",
          "description": "Eliminate miscommunication by crafting a crystal clear objective.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/CHALLENGE_STATEMENT_5026a9ec8d.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 280,
          "title": "Tripwires",
          "description": "Set up an early warning system to find out when change is on its way so you can buy time to make a plan.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/TRIPWIRES_0c5a6ed4ae.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    }
  ],
  "6": [
    {
      "id": 144,
      "title": "Environment",
      "description": "Get into the teamwork mindset so you are all setting off together.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "cards": [
        {
          "id": 328,
          "title": "Team Modelling",
          "description": "Model your teams on customer experience to remove silos that lead to poor services and products.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_modelling_21243afcad.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 329,
          "title": "Roles & Responsibilities",
          "description": "Understand each other’s roles better, and learn who is responsible for what.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/roles_and_responsibilities_c82d112959.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 330,
          "title": "Team Charter",
          "description": "Set your ground rules to bond the team and build shared understanding and commitment.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_charter_46d49916ab.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 331,
          "title": "Team Circles",
          "description": "Create ‘teams within teams’ to break down silos, build rapport and share responsibility.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_circles_6e1a9db105.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 145,
      "title": "Direction",
      "description": "Decide on a clear vision and work out the values and principles that will get you there, together.",
      "iconCode": "&#xE905",
      "color": "#EB986C",
      "cards": [
        {
          "id": 332,
          "title": "Design Principles",
          "description": "Empower your team to make design decisions with clear principles to apply in their everyday work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/design_principles_6feb673a0f.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 333,
          "title": "Decision Stack",
          "description": "Connect the dots between vision and execution to keep your team aligned.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/decision_stack_944dfaa4dd.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 334,
          "title": "Team Vision",
          "description": "Create a shared vision to inspire and give high-level guidance to your teams.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/design_vision_6c6c25a76a.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 335,
          "title": "Frame The Problem",
          "description": "Create clarity around your work so your team takes the correct action.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/frame_the_problem_cefd10261e.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 336,
          "title": "Lean Wardley Map",
          "description": "Evolve your team’s practice by finding the most valuable parts to focus your work on.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/lean_wardley_mapping_4447946061.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 337,
          "title": "Force-Field Analysis",
          "description": "Make balanced decisions together by ranking the fors and againsts.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/force_feild_analysis_58600a5ee7.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 338,
          "title": "Team Values",
          "description": "While your team vision gives your employees a destination, your team values help them forge the path to get there.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_values_763836afad.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 339,
          "title": "Start With Why",
          "description": "Inspire action by finding and communicating your team’s ‘why’.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/start_with_why_b932bf9531.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 340,
          "title": "Team Strategy",
          "description": "Help your team make decisions, and give them the purpose and autonomy needed to get stuff done.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_strategy_9dd350a01a.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 146,
      "title": "Support",
      "description": "Make sure your team doesn’t get lost along the way by giving them the support they need to succeed.",
      "iconCode": "&#xE900",
      "color": "#F5C466",
      "cards": [
        {
          "id": 341,
          "title": "One-To-One",
          "description": "Guide your team members to set goals they are motivated to achieve. Understand where they are now and where they want to be.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/one_to_one_7759fe2c7b.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 342,
          "title": "My User Manual",
          "description": "Help your team understand how they can best work with you and each other.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/my_user_manual_7fb7e491ac.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 343,
          "title": "Sphere Of Influence",
          "description": "Focus your energy and attention where it counts.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/circle_of_influence_e8c17f64bd.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 344,
          "title": "Maker Time",
          "description": "Help your team spend time practicing their craft.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/maker_time_2b837a7417.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 345,
          "title": "Ritual Reset",
          "description": "Reflect on and re-evaluate your team meetings and processes to create more space for what matters.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/ritual_reset_5b10cb840a.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 147,
      "title": "Health",
      "description": "Monitor how your team is doing so you can see clearly when your help is needed the most.",
      "iconCode": "&#xE903",
      "color": "#9CC992",
      "cards": [
        {
          "id": 346,
          "title": "Health Monitor",
          "description": "Keep track of your teams members’ wellbeing to learn when and where they need support.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_health_monitor_f692328bd9.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 347,
          "title": "Onboarding Retros",
          "description": "Monitor onboarding to improve it over time and help people feel part of your team from day one.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/onboarding_retros_1ee130c9c3.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 348,
          "title": "Attrition Rate",
          "description": "Anticipate turnover so you can act to retain key employees and recruit new ones ahead of time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/attrition_rate_f1f3c8edf8.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 349,
          "title": "Design Maturity",
          "description": "Understand what your team’s design maturity level is today, and where you want to go in the short and long-term.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/design_maturity_e86ba7eaf4.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 148,
      "title": "Collaborate",
      "description": "Encourage team members to work together to help them design better products and services.",
      "iconCode": "&#xE902",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 350,
          "title": "Community Of Practice",
          "description": "Break down silos by creating a safe space for people who share a common interest and passion.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/community_practice_628a4ec5eb.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 351,
          "title": "Daily Sharing",
          "description": "Form habits of sharing work early and often without eating into your team’s precious time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/daily_work_sharing_435485009f.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 352,
          "title": "Shift Left Lenses",
          "description": "Create multidisciplinary collaboration early to avoid duplication and help your team deliver better quality work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/shift_left_leses_20bab32332.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 353,
          "title": "Lean Updates",
          "description": "Discuss what matters across your different teams and disciplines without it becoming a chore or slowing you down.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/lean_updates_10ba328696.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 354,
          "title": "Top Tasks",
          "description": "Understand and improve the things that matter most to your team and customers.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/top_tasks_2fb463d524.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 355,
          "title": "Productivity Blueprint",
          "description": "Spend more time on high-value work by identifying inefficiencies and opportunities to improve workflow.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/design_productivity_blueprint_3b7a43cb78.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 356,
          "title": "Task Modelling",
          "description": "Gain clarity about the steps people go through and the decisions they make to accomplish a specific task.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/task_modeling_9358a80c47.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 357,
          "title": "Retros",
          "description": "Look back on successes and failures in order to learn and improve for next time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/retros_aba84977c2.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 358,
          "title": "Design Crits",
          "description": "Improve your designs by gaining different perspectives while increasing collaboration across disciplines.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/design_crits_d63e387192.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 359,
          "title": "Get Buy-In",
          "description": "Talk to individuals first and involve them deeply in the problem – so they get on board with your idea.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/get_buy_in_daa3f1d0a0.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 149,
      "title": "Communicate",
      "description": "Encourage people to talk about their work, sharing it early and with clarity to foster trust.",
      "iconCode": "&#xE807",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 360,
          "title": "Write In Plain English",
          "description": "Get your message understood the first time its read (you might not get a second chance).",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/write_plain_english_69597472ad.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 361,
          "title": "Inclusive Meeting Playbook",
          "description": "Make people feel part of their meetings.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/inclusive_meeting_playbook_5668f37491.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 362,
          "title": "Say What You Mean",
          "description": "Explain your feelings and needs clearly while creating empathy in your conversations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/say_what_you_mean_002088bee3.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 363,
          "title": "Communication Matrix",
          "description": "Be intentional with your communications, keep people informed and build trust.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/communication_matrix_ecf77b4a85.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 364,
          "title": "Accountability Dial",
          "description": "Hold your team to account without falling into the micromanagement trap.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/accountability_dial_4f9452204d.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 365,
          "title": "Agile Comms",
          "description": "Help your team communicate clearly and creatively about their work in progress.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/agile_comms_final_4ff9132130.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 366,
          "title": "Lean Presentations",
          "description": "Choose the most important parts of your work, then use common structures to communicate them clearly so they stick in your audience’s memory.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/lean_presentation_480cb0a0e1.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 150,
      "title": "Recognition",
      "description": "Forster a culture of appreciation by rewarding and recognising your team’s efforts.",
      "iconCode": "&#xE901",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 367,
          "title": "Team Appreciation",
          "description": "Put a smile on everyone’s face by sharing good vibes.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/team_appretiation_da96917422.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 368,
          "title": "Appreciation Playbook",
          "description": "Be consistent in letting people know their efforts are recognised and valued.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/appreciation_playbook_747f280f4e.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 369,
          "title": "Goal, Signal, Metric",
          "description": "Let your team know the impact they are having.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/goal_signal_metric_39e766bff7.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    },
    {
      "id": 151,
      "title": "Technique",
      "description": "Core tactics to get organised, make decisions, ask good questions and set metrics.",
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "cards": [
        {
          "id": 370,
          "title": "Lean Survey",
          "description": "Get answers in numbers to help you make decisions.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/lean_survey_81e95b71e4.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 371,
          "title": "Research Questions",
          "description": "Ask questions that help you challenge your own unavoidable biases.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/research_questions_9a912b16eb.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 372,
          "title": "Private Vote",
          "description": "Democratically make decisions as a group. Eliminate all but one thing, or find the top three things.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/blind_vote_b6fcc307c1.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 373,
          "title": "Objectives & Key Results",
          "description": "Create radical focus for your team by agreeing on an objective and actively measuring progress towards it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/objective_key_results_0856073352.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 374,
          "title": "Who, What, When",
          "description": "Come away from a session with clear goals and deadlines.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/who_what_when_7cb00da142.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 375,
          "title": "Theme Sort",
          "description": "Make sense of large amounts of information by clustering similar things together to find the theme.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/theme_sort_15f635551b.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        },
        {
          "id": 376,
          "title": "Impact Effort Map",
          "description": "Group together ideas by how much effort they require to create, and by how much impact they’ll have on your goal.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/impact_effort_map_12d5dfec42.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        }
      ]
    }
  ],
  "7": [
    {
      "id": 152,
      "title": "Context",
      "description": "All brands are understood in relation to the world around them. Find the gaps and opportunities you could exploit.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "cards": [
        {
          "id": 377,
          "title": "The True Competitor",
          "description": "Discover the hidden alternatives to your brand so that you understand what you’re <b><i>really</i></b> up against.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_True_Competitor_3760070ad3.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 378,
          "title": "Category Clichés",
          "description": "Plot the conventional battlegrounds of your category, so you can fight where there’s less competition.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Category_Cliches_43b34e7bfd.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 379,
          "title": "No-Go Zones",
          "description": "Understand the territories where your competitors excel to avoid fighting them at their own game.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_No_Go_Zones_2b8ad3a465.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 380,
          "title": "Find The Fear",
          "description": "Uncover the hidden fear and anxiety that drives your category – then provide the antidote.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Find_The_Fear_a50c4fca22.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 381,
          "title": "Invisible Rules",
          "description": "Shine a light on the unspoken assumptions that shape your category, and uncover hidden stupidity ripe for attack.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Invisible_Rules_1aed229300.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 382,
          "title": "So You Think You’re Special?",
          "description": "See how real people compare you to your competitors to find out just how different (or not) you really are.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_So_You_Think_Youre_Special_28cbd74d03.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 153,
      "title": "Introspection",
      "description": "Every business has hidden advantages and disadvantages. Discover yours so you know what you’re working with.",
      "iconCode": "&#xE913",
      "color": "#EB986C",
      "cards": [
        {
          "id": 383,
          "title": "Weird Or Normal?",
          "description": "Decide if your brand’s job is to be ‘dangerous’ or ‘safe’ to know what tone you need to strike.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Weirdor_Normal_25077ff89d.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 384,
          "title": "Only Is Better Than Best",
          "description": "Tease out your true competitive advantage by stating what only you do.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Only_Is_Better_Than_Best_d7fccb8584.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 385,
          "title": "We Suck!",
          "description": "Reveal opportunities to bring something new to the market by embracing your weaknesses.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_We_Suck_380ecc41ad.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 386,
          "title": "Superfan Secrets",
          "description": "Identify what it is about your brand that really gets your fans’ hearts racing – so you can amplify it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Superfan_Forum_3cf758422f.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 387,
          "title": "The Interesting Stranger",
          "description": "Your brand isn’t what you think it is, it’s what <b><i>they</i></b> think it is. Get some outside perspective to uncover what people really think you’re all about.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Interesting_Stranger_78e1886d5c.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 388,
          "title": "The Dolly Score",
          "description": "Analyse your successes and failures to determine what the market really wants from you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Dolly_Score_625e710363.JPG",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 154,
      "title": "Strategy",
      "description": "Great brands offer something to the world nobody else does. Figure out what that thing is for you.",
      "iconCode": "&#xE90D",
      "color": "#F5C466",
      "cards": [
        {
          "id": 389,
          "title": "The Strategic Hierarchy",
          "description": "Summarise your business in a simple framework that reveals if you even have a strategy – or not!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Strategic_Hierachy_81c54d9546.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 390,
          "title": "The Real Product",
          "description": "Interrogate what people are really buying from you to change your perspective from product to value.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Real_Product_f90e16ca39.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 391,
          "title": "Value Mining",
          "description": "Compare your brand directly to a variety of competitors to discover big hidden advantages.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Value_Mining_9356dd1a26.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 392,
          "title": "Contrarian Value",
          "description": "Choose a divine line that all other brands in the category refuse to cross... and cross it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Contrarian_Value_aa702d93a2.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 393,
          "title": "Cultivate A Weakness",
          "description": "Surrender something you already suck at and free yourself to do something new.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Cultivate_A_Weakness_9a90ddb814.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 394,
          "title": "The Outsider",
          "description": "Bring popular offerings from other categories into yours to generate new value.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Outsider_dff214c372.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 395,
          "title": "The Targeting Paradox",
          "description": "Trigger desire by tailoring your offering to the tiniest audience possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Targeting_Paradox_4a973d7854.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 396,
          "title": "The Silent Market",
          "description": "Massively expand your potential market by attracting people who aren’t shopping for what you’re selling (yet…).",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Silent_Market_b5b92be7d1.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 397,
          "title": "Clear The Pipes",
          "description": "Generate surprising new insights and angles for your strategy by writing a letter to yourself.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Clear_The_Pipes_9aa4abc283.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 398,
          "title": "Between You & Me",
          "description": "Get the juices flowing by taking things off the record, allowing you to interrogate your strategic thoughts without holding back.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Between_You_And_Me_13f5381ec0.JPG",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 155,
      "title": "Judgement",
      "description": "Insights are great, but you’ve got to make them usable and effective. Transform those raw ideas into clear strategic direction.",
      "iconCode": "&#xE806",
      "color": "#9CC992",
      "cards": [
        {
          "id": 399,
          "title": "First Goal Of Strategy",
          "description": "Know instantly if your strategy is viable by asking if it forces you to change from what you’re doing now.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_First_Goal_Of_Strategy_12e703f3f9.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 400,
          "title": "The Toilet Paper Rule",
          "description": "Match your offering to your product’s place in consumers’ lives.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Toilet_Paper_Rule_8f996459ac.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 401,
          "title": "Simple. Easy. Fun.",
          "description": "Judge an idea with your gut – rather than your brain – to get a deeper read on its suitability.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Simple_Easy_Fun_277531c3bb.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 402,
          "title": "Subjectivity Test",
          "description": "Write your strategy with no subjective language and see what’s left – so you can be sure it’s not just hot air.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Subjectivity_Test_5d3cb0e6e1.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 403,
          "title": "The Challenger Trap",
          "description": "Test your offering against your competitors’ to see if it’s truly unique.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Challenger_Trap_e6012a4be1.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 404,
          "title": "Opposites Game",
          "description": "Reverse your strategy to see if the opposite also makes sense – if it doesn’t, then neither does yours.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Opposites_Game_8c4a3a7b7f.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 405,
          "title": "How Cascade",
          "description": "Distinguish between a goal and a strategy by asking ‘how?’ until you hit a solution.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_How_Cascade_6854be6d75.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 406,
          "title": "Pub Language",
          "description": "Explain your strategy to someone who knows nothing about your business to ensure it makes sense to everyone who uses it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Pub_Language_0263741dd8.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 407,
          "title": "Ditch The Deck",
          "description": "Write your strategy as a mini essay to expose muddled thinking.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Ditch_The_Deck_266509c5f6.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 408,
          "title": "What’s The Gist?",
          "description": "Ask people to describe something in their own words to find out if they really do ‘get it’.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Whats_The_Gist_9223113458.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 409,
          "title": "Idea Fishing",
          "description": "Ask random people to pull ideas out of your strategy to test its creative potential.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Idea_Fishing_f586535ea9.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 410,
          "title": "Strategy Story",
          "description": "Lay out your strategy using this format for maximum clarity and excitement.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Strategy_Story_85da6c2f2d.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 156,
      "title": "Execution",
      "description": "Creative execution is so much more than a logo and some ads. Bring your strategy to life across the whole of your business.",
      "iconCode": "&#xE90C",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 411,
          "title": "Chief Value Officer",
          "description": "Structure your team in a way that ensures the strategy won’t be forgotten.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Chief_Value_Officer_8bb4649ea4.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 412,
          "title": "The ‘Why’",
          "description": "Motivate the troops by underpinning the strategy with a bigger mission.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Why_682a72f742.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 413,
          "title": "Minimum Viable Strategy",
          "description": "Get the ball rolling by identifying the minimum actions your strategy needs to exist.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Minimum_Viable_Strategy_84fa9b7b27.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 414,
          "title": "Kill List",
          "description": "Root out the things you need to stop doing so you don’t blunt your brand by contradicting yourself.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Kill_List_45a2ba20b8.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 415,
          "title": "Power Platform",
          "description": "Translate your strategy into a consumer-facing text that explains your brand in the most exciting way possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Power_Platform_09f31fe7d1.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 416,
          "title": "The Philosopher",
          "description": "Extract a bigger philosophical belief from your strategy, allowing your brand to talk creatively and movingly about a wide variety of topics.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_The_Philosopher_1e18ed53f9.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 417,
          "title": "Copy Playbook",
          "description": "Create a suite of copy you can use to present your brand again and again, so you don’t have to keep rewriting it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Copy_Playbook_9d68ca0b91.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 418,
          "title": "The Creative Canvas",
          "description": "Turn your business into a living ad by mapping all the opportunities you have for creative expression.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_The_Creative_Canvas_cad17b2d55.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 419,
          "title": "Cross-Category Copying",
          "description": "Find creative inspiration in categories far from your own, then exploit their hard work to make yourself better understood.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Cross_Category_Copying_9046359b2f.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 420,
          "title": "Look At Me!",
          "description": "Help your customers tell stories about themselves using your brand.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Look_At_Me_b65d22ca20.JPG",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 157,
      "title": "Double Down",
      "description": "Iconic brands keep things fresh by pushing deeper and further every year. Explore just how far you can take it.",
      "iconCode": "&#xE901",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 421,
          "title": "Paper Boss",
          "description": "Judge ongoing ideas on whether they are brand positive, negative or neutral.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Paper_Boss_77d65313d9.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 422,
          "title": "Brand-Led Growth",
          "description": "Use your strategy to create new products that grow your brand – and your bottom line.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Brand_Led_Growth_918b5edb87.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 423,
          "title": "One Stupid Thing",
          "description": "Make sure nobody can copy you ever again by using your strategy to do something nobody else would dare.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_One_Stupid_Thing_10564e5b13.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 424,
          "title": "Hamster Wheel",
          "description": "Protect your brand’s position by re-evaluating your strategy in a changing world.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Hamster_Wheel_4bad8b6247.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 425,
          "title": "Word On The Street",
          "description": "Hold yourself to account by seeing if outsiders are able to understand your brand intuitively.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Word_On_The_Street_72616066be.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 426,
          "title": "Brand Stretch",
          "description": "Prove you really mean what you say by exploring the wider potential of your brand offering away from your core business operations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pip_Deck_Brand_Tactics_Brand_Stretch_74b12908c2.JPG",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    }
  ],
  "8": [
    {
      "id": 158,
      "title": "Prepare",
      "description": "Get yourself in the right headspace to nail your goals, every time.",
      "iconCode": "&#xE912",
      "color": "#D16461",
      "cards": [
        {
          "id": 427,
          "title": "Mindful Mojo",
          "description": "Pause... pay attention to the present moment to gain focus, reduce stress and enhance productivity.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Mindful_Mojo_ba253e38c9.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 428,
          "title": "Sunrise Scribblings",
          "description": "Write every morning in a freestyle fashion to offload mental clutter and spark creativity.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Sunrise_Scribblings_43a9816f20.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 429,
          "title": "Focus Fortress",
          "description": "Create a calm work space and cut out interruptions so you can power through high value work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Focus_Fortress_d553e6f007.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 430,
          "title": "One-Minute Breathing",
          "description": "Inhale clarity with a one-minute breathing exercise to boost blood oxygen and increase mental performance.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/One_Minute_Breathing_2a23247a49.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 431,
          "title": "Hard Truths",
          "description": "Soak up hard-won productivity wisdom so you can make better choices about your work and live a happier life.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hard_Truths_06e4705912.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 432,
          "title": "Wonder Beam",
          "description": "Publicly signal your passions to connect with like-minded people, find helpful new ideas and open up exciting opportunities.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Wonder_Beam_5fdebdd6d7.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 433,
          "title": "Laugh Out Loud",
          "description": "Laugh on purpose to boost your mood and be your most focussed, creative and productive self.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Laugh_Out_Loud_7e87b4c3d1.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 434,
          "title": "Nimble Eyes, Nimble Mind",
          "description": "Exercise your eyes with quick focus-switching to prevent eye strain and boost productivity.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Nimble_Eyes_Nimble_Mind_c03af3ad00.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 159,
      "title": "Plan",
      "description": "Streamline your workflow and keep track of everything you’re working on.",
      "iconCode": "&#xE90D",
      "color": "#EB986C",
      "cards": [
        {
          "id": 435,
          "title": "Kanban",
          "description": "Visualise what’s happening, what’s next, and what’s done to streamline your workflow and stay focussed.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Kanban_6ae378a6a7.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 436,
          "title": "SMART Goals",
          "description": "Set effective goals so ideal outcomes and deadlines are crystal clear.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Smart_Goals_e854abf557.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 437,
          "title": "Weekly Focus",
          "description": "Set your intention for the week ahead and describe what you want to achieve – this helps you ignore anything irrelevant.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Weekly_Focus_3053fd07a5.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 438,
          "title": "Communication Canvas",
          "description": "Align with your team on communication tools, expectations and boundaries, so you can keep work flowing without losing focus.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Communication_Canvas_8ef1c1206a.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 439,
          "title": "Time Oasis",
          "description": "Carve out ‘slack time’ in your work week to ignite new ideas and keep burnout at bay.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Time_Oasis_e2801048e8.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 440,
          "title": "Yes, Maybe, BTW",
          "description": "Capture clear next steps, future options to return to, and key supporting references to tame any project.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Yes_Maybe_Btw_afeac420bc.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 441,
          "title": "Time Blocking",
          "description": "Block out time in your calendar for important tasks to maximise focus.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Time_Blocking_9cf55d8482.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 442,
          "title": "3–3–3",
          "description": "Divide each day into three distinct blocks to slay your to-do list and make progress on the big stuff.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/3_3_3_4f866c9dd0.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 160,
      "title": "Prioritise",
      "description": "Spend your precious energy wisely with these tactics for ruthless prioritisation.",
      "iconCode": "&#xE808",
      "color": "#F5C466",
      "cards": [
        {
          "id": 443,
          "title": "One In, One Out",
          "description": "Swap tasks to stop your workload getting out of control.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/One_In_One_Out_6a4526373c.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 444,
          "title": "Seinfeld System",
          "description": "Commit to a key daily action and build an unbreakable streak to achieve great things.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Seinfeld_System_9362949b9c.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 445,
          "title": "Eisenhower Matrix",
          "description": "Decide which tasks to do and which to delete, delegate or delay, so you can make progress on the stuff that really matters.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Eisenhower_Matrix_88073a896a.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 161,
      "title": "Do",
      "description": "The time for talk is over; get in the zone, obliterate distractions and power through your to-do list.",
      "iconCode": "&#xE908",
      "color": "#9CC992",
      "cards": [
        {
          "id": 446,
          "title": "Single-Task Superhero",
          "description": "Tackle one task at a time to force focus and keep your dopamine flowing.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Single_Task_Superhero_c2f2feec26.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 447,
          "title": "Tiny Rewards",
          "description": "Reward yourself with small treats after completing urgent or important tasks to stay motivated and maintain momentum.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Tiny_Rewards_edeb11f296.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 448,
          "title": "Out Of Order",
          "description": "Dive straight into the middle of your task to kick-start your creative flow.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Out_Of_Order_1aa9de6ddc.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 449,
          "title": "Pomodoro",
          "description": "Split your day into focussed 25-minute bursts of work, followed by short breaks, so that you maintain peak mental performance and get more done in less time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pomodoro_9ee4c8447d.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 450,
          "title": "Productive Procrastination",
          "description": "Use time well when you’re struggling to focus so you don’t end up in a self-defeating spiral of guilt.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Productive_Procrastination_70a4b957da.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 451,
          "title": "Spark File",
          "description": "Capture fleeting thoughts and build a treasure trove of inspiration.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Spark_File_4511043edb.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 452,
          "title": "Synapse Safari",
          "description": "Capture ideas and organise them visually with a branching, tree-like structure so you can make sense of your thoughts and tasks.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Synaps_Safari_7e5ce097ad.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 453,
          "title": "Decision Dash",
          "description": "Make speedy group decisions in a 45-minute workshop so that your projects don’t get blocked.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Decision_Dash_3491e40946.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 454,
          "title": "Single Step",
          "description": "Tackle the smallest possible piece of a daunting task to stop it overwhelming you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Single_Step_98d54f1408.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 455,
          "title": "Daydream Station",
          "description": "Schedule daydreaming sessions to unlock new ideas and refresh your brain.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Daydream_Station_03b8c33b8a.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 456,
          "title": "Two-Minute Taskmaster",
          "description": "Complete tasks that take two minutes or less immediately to stop them distracting you.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Two_Minute_Taskmaster_23f1b4fd86.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 162,
      "title": "Reflect",
      "description": "Avoid getting stuck in a rut by regularly reflecting on and refining your processes.",
      "iconCode": "&#xE802",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 457,
          "title": "Power Hours",
          "description": "Find your peak productivity times so you can schedule tasks mindfully and achieve more.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Power_Hours_fca9d2c24b.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 458,
          "title": "Winventory",
          "description": "List and celebrate your wins to stay motivated.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Winventory_2045c97111.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 459,
          "title": "Get Feedback",
          "description": "Get feedback from your peers to improve your work and grow yourself.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Get_Feedback_9bb6798f3e.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 460,
          "title": "Energy Audit",
          "description": "Reflect on recent tasks to identify energy patterns and fine-tune your schedule.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Energy_Audit_4a9c3fcab3.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 461,
          "title": "Ritual Recalibration",
          "description": "Check into your daily rituals to see what’s working and what needs to change.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ritual_Recalibration_baedde0e8b.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 462,
          "title": "Captain Hindsight",
          "description": "Reflect on the effectiveness of your work, and the systems supporting it, so you can continually improve.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Retrospective_Captain_Hindsight_20efaf9eef.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 463,
          "title": "Progress Check",
          "description": "Review your progress to stay motivated when you’re doing well and make changes when you’re behind.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Progress_Check_8d5d65fd60.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 464,
          "title": "Weeknotes",
          "description": "Document your weekly experiences to deepen your learning and share insights with others.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Weeknotes_ea2a733fc4.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 163,
      "title": "Reduce",
      "description": "Even at maximum productivity, there’s only so much you can do. Prune the dead wood from your schedule and watch your impact double.",
      "iconCode": "&#xE800",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 465,
          "title": "Open Loops",
          "description": "Complete half-finished tasks and low-effort to-dos so you can focus on the big stuff.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Open_Loops_f600631f52.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 466,
          "title": "Kill It With Fire",
          "description": "Ruthlessly remove non-essential tasks from your schedule to free up time and headspace for your real priorities.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Kill_It_With_Fire_d32f64c26f.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 467,
          "title": "Meeting Mincer",
          "description": "Minimise meeting madness to reclaim valuable time.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Meeting_Mincer_072e92f930.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 164,
      "title": "Reset",
      "description": "If hard work just ain’t working, take things back to basics with a ‘reset’ ritual.",
      "iconCode": "&#xE913",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 468,
          "title": "Ikigai",
          "description": "Uncover your Ikigai to align your life and work with your true passions and purpose.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ikigai_c3c0671a85.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 469,
          "title": "S.T.O.P",
          "description": "Slow down for a brief mindful pause to re-energise yourself and do your best work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stop_c61d972b60.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 470,
          "title": "Productivity Principles",
          "description": "Learn 11 key principles that will help you stay productive.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Productivity_Principles_07baed5942.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 471,
          "title": "Canary In The Coalmine",
          "description": "Identify your personal burnout signs so you know when to slow down or simplify.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Canary_In_The_Coalmine_3ee9914a83.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 472,
          "title": "Wellbeing 101",
          "description": "Check into your health and wellbeing basics so that you can stay balanced.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Wellbeing_101_fccb2d58c4.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 473,
          "title": "Mindful Disruption",
          "description": "Inject some novelty into your day to keep your thinking fresh.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Mindful_Disruption_55a6b58a4a.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 474,
          "title": "Monk Mode",
          "description": "Step away from the whirlwind of modern life to break unhelpful habits and recharge your mind.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Monk_Mode_59ba78d92f.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    }
  ],
  "9": [
    {
      "id": 166,
      "title": "Start",
      "description": null,
      "iconCode": "&#xe807",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 475,
          "title": "Retros Introduction",
          "description": "Make the most of your teams’ shared experiences.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/02_Meeting_After_8bc661ed78.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    },
    {
      "id": 165,
      "title": "Discuss",
      "description": null,
      "iconCode": "&#xE807",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 476,
          "title": "Four Ls",
          "description": "Transform your team’s loves, longings and learnings into concrete actions that propel them forward.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Four_Ls_d16f393cc4.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 477,
          "title": "Starfish",
          "description": "Combine your team members’ priorities to create shared understanding and a cohesive path forward.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Starfish_63fbc006e2.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 478,
          "title": "Superhero",
          "description": "Turn your team into superheroes to showcase the unique powers they possess that will help them safely deliver on their goals.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Superheroes_b872b958b4.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 479,
          "title": "Fishbone",
          "description": "Get to the heart of your team’s biggest problem, so you can understand what exactly needs to be solved.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Fishbone_da4b7f175c.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 480,
          "title": "Balloon Ride",
          "description": "Use an engaging visual metaphor to find out what weighed your down and what helped you reach new heights in your last period of work.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hot_Air_Balloon_cf1377e1a5.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 481,
          "title": "What? So What? Now What?",
          "description": "Turn learnings into actions by working out how the ‘What’ and ‘Why’ of your recent work could change ‘How’ you reach your goals.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/What_So_What_Now_What_308624c37b.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 482,
          "title": "What To Drop?",
          "description": "Turn complicated emotions into plain-talking statements that help your team drop inefficient processes and do more of what works.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/What_to_Drop_9f69b42038.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 483,
          "title": "Wishes & Puzzles",
          "description": "Turn playful ‘what if’ thinking and a little gratitude into a powerful force to free your team from all those annoying little problems.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Puzzles_and_Wishes_1401b58964.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 484,
          "title": "Awards Night",
          "description": "Turn your retro into an awards ceremony so your team’s mistakes become bloopers – and their achievements get the recognition they deserve.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Awards_Night_2ebe27d18f.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 485,
          "title": "Speedcar",
          "description": "Turn a stack of topics into two simple lists that help you pinpoint which actions speed your team up, and how to encourage them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Speedcar_2992a8355a.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 486,
          "title": "Rollercoaster",
          "description": "Turn your team’s thoughts and feelings into a literal rollercoaster to show you what needs to be done to get them all flying high (and moving forward).",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rollercoaster_0d61109d68.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 487,
          "title": "Keep, Problem, Try",
          "description": "Turn your experiences into fresh new ideas to help you achieve more in the next period.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Keep_Problem_Try_320d3a21d8.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 488,
          "title": "Team Radar",
          "description": "Gather data on five aspects of your team’s work to help you prioritise your effort for the next period.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Team_Radar_75ad6dc880.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 489,
          "title": "Timeline",
          "description": "Use a simple timeline structure to understand how past events affected productivity, so you can learn from them and improve.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Timeline_482fbea30e.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 490,
          "title": "Team Values",
          "description": "Turn your team or company values into a lens for examining your progress to ensure you’re on the right path.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Team_Values_eca872336b.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    },
    {
      "id": 170,
      "title": "Decide",
      "description": null,
      "iconCode": "&#xE808",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 507,
          "title": "Secret Vote",
          "description": "Democratically make decisions as a group. Eliminate all but one thing, or find the top three things.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Blind_Dot_Voting_6763f90a0b.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 509,
          "title": "Who, What, When",
          "description": "Leave a session with a clear plan of goals and deadlines.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Who_What_When_e6d07a5cb8.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 171,
      "title": "Technique",
      "description": null,
      "iconCode": "&#xE800",
      "color": "#5A4574",
      "cards": [
        {
          "id": 508,
          "title": "Theme Sort",
          "description": "Make sense of large amounts of information by clustering similar things together to find the theme.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Affinity_Sort_6af4b0cab1.jpg",
          "style": {
            "borderColor": "#5A4574"
          }
        }
      ]
    },
    {
      "id": 172,
      "title": "Frame",
      "description": null,
      "iconCode": "&#xE805",
      "color": "#F5C466",
      "cards": [
        {
          "id": 510,
          "title": "Problem Statement",
          "description": "A statement that captures the project’s goals, problems, and measurable success criteria.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Problem_Statement_9e418406a8.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    }
  ],
  "10": [
    {
      "id": 167,
      "title": "Start",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "cards": [
        {
          "id": 491,
          "title": "Archetypes Introduction",
          "description": "Connect with your audience on a deeper level; use characters they already know and love.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Meet_The_Family_75fc260a1c.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 168,
      "title": "Recipes",
      "description": null,
      "iconCode": "&#xe907",
      "color": "#000",
      "cards": [
        {
          "id": 492,
          "title": "The Way Ahead",
          "description": "Form a strong bond with your customer or user. What do they want to become? How can you help?",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/With_you_every_step_of_the_way_1f296b6211.jpg",
          "style": {
            "borderColor": "#000"
          }
        },
        {
          "id": 493,
          "title": "Raise The Stakes",
          "description": "Make us root for the ‘good guys’ in your story. Give them a great ‘bad guy’ to contend with.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Raise_The_Stakes_ea26a99b24.jpg",
          "style": {
            "borderColor": "#000"
          }
        },
        {
          "id": 494,
          "title": "Build Your Brand",
          "description": "Tell consistent stories that build trust with colleagues and customers.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Brand_Building_1661409275.jpg",
          "style": {
            "borderColor": "#000"
          }
        }
      ]
    },
    {
      "id": 169,
      "title": "Archetypes",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "cards": [
        {
          "id": 495,
          "title": "The Ruler",
          "description": "Take the lead. Be the commanding presence. Lay down the laws. Protect fragile order.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Ruler_606af23dd3.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 496,
          "title": "The Caregiver",
          "description": "Protect and nourish. Be the supportive arm around our shoulder. Allow others to thrive.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Caregiver_3_8d3c2f8dbc.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 497,
          "title": "The Innocent",
          "description": "Born anew. See the world with fresh eyes. Vulnerable but wondrous, full of potential.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Innocent_a9c0cc6f79.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 498,
          "title": "The Jester",
          "description": "Let the games begin. Roll the dice and take a chance. There’s always a funny side to life.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Jester_93cbc313d3.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 499,
          "title": "The Rebel",
          "description": "Demand more. The status quo is not good enough. Some rules are meant to be broken.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Rebel_2b5ba60d0a.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 500,
          "title": "The Warrior",
          "description": "Fight the good fight. Actions speak louder than words. Rally others to your cause.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Warrior_f47e0d3fe4.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 501,
          "title": "The Lover",
          "description": "Seek beauty and pleasure. We’re here for a good time, not a long time. Live in the moment.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Lover_6dd122d658.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 515,
          "title": "The Companion",
          "description": "It’s good to belong. We stand together, equals in a circle of friends. You’re one of us.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Companion_5b18f32e5e.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 502,
          "title": "The Explorer",
          "description": "Discover by doing. Keep on moving. Imagine what you’ll find over the next horizon.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Explorer_27c3e18fa6.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 503,
          "title": "The Artist",
          "description": "Find patterns in the chaos. Free your mind and the rest will follow. Show us a new way.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Artist_3f39bf24f6.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 504,
          "title": "The Magician",
          "description": "Attempt the impossible. Dream up things that don’t exist. Transform the mundane world.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Magician_b5412ce1b2.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 505,
          "title": "The Sage",
          "description": "The truth will set you free. See it first, tell it straight. Gain wisdom and understanding.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/The_Sage_518223e622.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 173,
      "title": "Character",
      "description": null,
      "iconCode": "&#xE909",
      "color": "#F5C466",
      "cards": [
        {
          "id": 506,
          "title": "Hero & Guide",
          "description": "Stop talking about yourself. Make your user the Hero of your story. You play a supporting role: the <b><i>Expert Guide</i></b>.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Hero_and_Guide_293b641850.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 174,
      "title": "Concept",
      "description": null,
      "iconCode": "&#xE803",
      "color": "#D16461",
      "cards": [
        {
          "id": 512,
          "title": "Good & Evil",
          "description": "Tap into strong feelings of right and wrong in your audience. Bring a moral conflict into your story.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Good_and_Evil_71b4af646b.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 175,
      "title": "Structure",
      "description": null,
      "iconCode": "&#xE90B",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 513,
          "title": "Man In A Hole",
          "description": "This is the story of your first day at school or your first job. It’s every fear you faced and every lesson you’ve learned.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Man_in_a_hole_e45f52f0ae.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 176,
      "title": "Style",
      "description": null,
      "iconCode": "&#xE90C",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 514,
          "title": "Movie Time",
          "description": "Tell me a story and a little movie starts playing in my head. I literally “see” what you mean.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Movie_Time_c0d2de01d2.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    }
  ],
  "11": [
    {
      "id": 177,
      "title": "Principles",
      "description": "Learn the timeless principles of ethical and effective negotiation.",
      "iconCode": "&#xE904",
      "color": "#D16461",
      "cards": [
        {
          "id": 516,
          "title": "Ethical Negotiation",
          "description": "Do business you can be proud of, maintain your reputation – and reap the rewards.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ethical_Negotiation_855077bdb7.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 517,
          "title": "7 Principles Of Influence",
          "description": "Learn the art of connecting with others, so you’re better able to influence their decisions",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/7_principles_of_influence_2f6a6e1380.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 518,
          "title": "Friends & Foes",
          "description": "Don’t let your liking or loathing of your negotiation partner shape your decision.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Friends_and_Foes_4e3e8036c1.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 519,
          "title": "Stairway Of Influence",
          "description": "Learn the fundamental steps you must take to change your counterpart’s behaviour in your favour.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stairway_of_Influence_72508517cd.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 520,
          "title": "Golden Rules",
          "description": "Turn simple behaviour changes into powerful signals that you can be trusted.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Golden_Rules_b7fe361e9e.jpg",
          "style": {
            "borderColor": "#D16461"
          }
        },
        {
          "id": 521,
          "title": "Negotiation No-Goes",
          "description": "Avoid these bad behaviours to ensure both parties walk away feeling good about the negotiation and themselves.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/No_Gos_e7754996f6.JPG",
          "style": {
            "borderColor": "#D16461"
          }
        }
      ]
    },
    {
      "id": 178,
      "title": "Active Listening",
      "description": "Practise listening skills that make you likeable and help you better understand your counterpart.",
      "iconCode": "&#xE909",
      "color": "#EB986C",
      "cards": [
        {
          "id": 522,
          "title": "Judgement-Free Zone",
          "description": "Learn how to focus on what really matters without getting sidetracked by your judgements.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Judgement_Free_Zone_d7c11dc9de.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 523,
          "title": "Open Questions",
          "description": "When you’re not listening, you’re not learning. Turn every question into a powerful learning opportunity.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Open_Question_952e05d782.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 524,
          "title": "Affirmative Attention",
          "description": "Use these communication tricks to show your counterpart you’re truly listening.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Affirmative_Attention_e9a1d17e67.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 525,
          "title": "Fully Present",
          "description": "Create a foundation of mutual respect by mastering basic etiquette that makes people feel heard.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Fully_Present_760b4aceb1.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        },
        {
          "id": 526,
          "title": "Body Talk",
          "description": "Make sure the story your body language tells supports the words coming out of your mouth.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Body_Talk_f10739113f.jpg",
          "style": {
            "borderColor": "#EB986C"
          }
        }
      ]
    },
    {
      "id": 179,
      "title": "Research",
      "description": "Dig deep to discover all kinds of information that you can use to your advantage.",
      "iconCode": "&#xE802",
      "color": "#F5C466",
      "cards": [
        {
          "id": 527,
          "title": "Bias Hunting",
          "description": "Your personal biases (we all have them) can sabotage negotiations. Learn to spot and neutralise them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Bias_Hunting_90f627fe24.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 528,
          "title": "Stakeholder Network Map",
          "description": "Turn complex human needs into a visual map that adds context to your counterpart’s moves.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stakeholder_Network_Map_e9522b49f4.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 529,
          "title": "Context Clues",
          "description": "Turn seemingly inconsequential information into hints at how to get more from the discussion.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Context_Clues_791b41b18a.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 530,
          "title": "Send In The Experts",
          "description": "Bring in outside expertise to help you make an informed decision.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Send_in_The_Experts_133cf88a08.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 531,
          "title": "Friends In High Places",
          "description": "Identify people with the knowledge, influence and power to make a big impact if they support your cause.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Friends_in_high_places_d5f5369773.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 532,
          "title": "A Whole New World (View)",
          "description": "Turn your one-dimensional world view into a more objective picture of what’s at play.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/A_whole_new_world_view_beee440b21.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 533,
          "title": "Cultural Exploration",
          "description": "Acknowledge, explore and respect cultural differences to build a strong relationship with your counterpart.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Cultural_Exploration_ace1924ec7.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        },
        {
          "id": 534,
          "title": "Crank The Levers",
          "description": "There is always more to a negotiation than just goods, money or getting your own way.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Crank_The_levers_5fd511600d.jpg",
          "style": {
            "borderColor": "#F5C466"
          }
        }
      ]
    },
    {
      "id": 180,
      "title": "Plan",
      "description": "Create a solid plan and put all the information you gathered to good use.",
      "iconCode": "&#xE902",
      "color": "#9CC992",
      "cards": [
        {
          "id": 535,
          "title": "Ahead Of The Game",
          "description": "Gather and arrange all the available information, so you feel confident and prepared.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ahead_of_the_game_2dd3f50953.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 536,
          "title": "Cockpit Environment",
          "description": "Negotiating as a team? Don’t wing it. Prepare in advance or you’ll miss out on the team advantage.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Cockpit_Environment_38c2d1cce2.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 537,
          "title": "ZOPA",
          "description": "Turn endless possible outcomes into a clearly defined playing field, so you know what’s possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/ZOPA_b44c2c1025.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 538,
          "title": "Concessions",
          "description": "Prepare an array of concessions you can use to increase your influence and add value in negotiations.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Concessions_c02f8ddb45.JPG",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 539,
          "title": "Say What?",
          "description": "Keep a close eye on the message you’re sending, how it’ll be received and who by (even unintentionally).",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Say_What_85d0b18161.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 540,
          "title": "Casino Environment",
          "description": "Create the kind of environment that makes your counterpart want to give you the world.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Casino_Environment_b5d06341b3.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        },
        {
          "id": 541,
          "title": "Fallback Plan",
          "description": "Your fallback plan is a surprisingly powerful tool – even when you don’t need to use it.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Fallback_Plan_b398821037.jpg",
          "style": {
            "borderColor": "#9CC992"
          }
        }
      ]
    },
    {
      "id": 181,
      "title": "Build A Relationship",
      "description": "Start building trust and developing the foundations of a mutually beneficial relationship.",
      "iconCode": "&#xE903",
      "color": "#87A6CD",
      "cards": [
        {
          "id": 542,
          "title": "Empathy Amplifier",
          "description": "Learn this powerful approach for connecting with others to elevate all your relationships.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Empathy_Amplifier_ec2ef71f42.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 543,
          "title": "Four-Part Apology",
          "description": "Turn your mistakes into an opportunity to showcase your strength of character and bounce back stronger.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Four_part_Apology_20ac467e21.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 544,
          "title": "Horns Or Halo?",
          "description": "Prepare to make a first impression that will set you up for a successful negotiation.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Horns_or_Halo_2311661c3e.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 545,
          "title": "Like & Be Liked",
          "description": "Give genuine, appropriate praise and compliments to enhance your relationships.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Like_and_Be_liked_0fc64fe356.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 546,
          "title": "Rapport Reps",
          "description": "Rapport transforms a relationship into a close and mutually beneficial partnership.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Rapport_Reps_84fee979a0.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        },
        {
          "id": 547,
          "title": "Trust Tree",
          "description": "Nurture trust at all times. When your counterpart trusts and values you, negotiations flow more easily.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Trust_Tree_fadb2cdd23.jpg",
          "style": {
            "borderColor": "#87A6CD"
          }
        }
      ]
    },
    {
      "id": 182,
      "title": "Negotiate",
      "description": "Move from simply communicating to actively influencing the situation in your favour.",
      "iconCode": "&#xE807",
      "color": "#E4ABC9",
      "cards": [
        {
          "id": 548,
          "title": "Zest Of Compromise",
          "description": "Anticipate and plan for compromise so you’re ready for it if it happens.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Zest_of_compromise_57dda40611.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 549,
          "title": "Dynamic Silence",
          "description": "Patience is not just a virtue – it can be a weapon. Use a confident, patient silence to your advantage.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Dynamic_Silence_b3d40d87f7.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 550,
          "title": "Micro Objectives",
          "description": "Plan how every interaction with your counterpart will bring you a little closer to your overall goal.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Micro_Objectives_f9088e89f0.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 551,
          "title": "Drop The Anchor",
          "description": "Start negotiations in your favour by making the first proposal – that all others will be compared against.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Drop_The_Anchor_50e07646da.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 552,
          "title": "SUCCESs",
          "description": "Use the SUCCESs storytelling framework to state your case as a meaningful, memorable story.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/V_Succes_9604cfa2f8.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 553,
          "title": "Indicators Of Progress",
          "description": "Identify hints that things are going well so you can decide whether to keep going or change your approach.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Indicators_Of_Progress_c423cc7abc.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 554,
          "title": "Crunch Time",
          "description": "Before you make a decision, analyse every aspect of it carefully to remove as much risk as possible.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Crunch_Time_f239be59c1.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        },
        {
          "id": 555,
          "title": "Stand & Deliver",
          "description": "A deal isn’t done until it’s been delivered on, so plan to make that happen as soon as you get to ‘yes’.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stand_and_Deliver_ff15dabc4c.jpg",
          "style": {
            "borderColor": "#E4ABC9"
          }
        }
      ]
    },
    {
      "id": 183,
      "title": "Troubleshoot",
      "description": "Identify and overcome common barriers to negotiation – and know when to walk away.",
      "iconCode": "&#xE900",
      "color": "#A96DA7",
      "cards": [
        {
          "id": 556,
          "title": "Emotional Detachment",
          "description": "Turn strong negative feelings on the issue at hand into a bag of luggage you can leave at the door.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Emotional_Detachment_818b44752f.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 557,
          "title": "Red Flags",
          "description": "Learn to spot dirty tricks, so you know where you stand and can proceed with caution.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Red_Flags_d8ec84c72b.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 558,
          "title": "Pitfalls",
          "description": "Identify why your negotiation is crashing down around you, so you can remedy it – fast!",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Pitfalls_900770633e.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 559,
          "title": "Tear Down The Barriers",
          "description": "Learn the most common sources of friction in a negotiation – and how to overcome them.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/tear_down_the_wall_1445aa4432.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 560,
          "title": "Stalemate",
          "description": "If it looks like your negotiation is failing, team up with your counterpart to get it back on track.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Stalemate_5bda92dc93.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 561,
          "title": "Ride The De-Escalator",
          "description": "If your counterpart’s emotions are running high, guide them to a calmer state.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ride_The_De_Escalator_e3ebdbd2a3.jpg",
          "style": {
            "borderColor": "#A96DA7"
          }
        },
        {
          "id": 562,
          "title": "Ultimatum",
          "description": "Ultimatums are powerful and should be given sparingly and responded to carefully.",
          "imageUrl": "https://divergent-smoggy-farm.media.strapiapp.com/Ultimatum_becba84915.JPG",
          "style": {
            "borderColor": "#A96DA7"
          }
        }
      ]
    }
  ]
};