import React from 'react';
import { IonItem, IonLabel, IonNote } from '@ionic/react';

import HtmlToReactComp from '../../Common/UI/HTML/HtmlToReactComp';
import { CardDeckTacticCardSearchItem } from '../CardDeckCard/TacticCard/cardDeckTacticCard';
import { CardDeckRecipeCardSearchItem } from '../CardDeckCard/RecipeCard/cardDeckRecipeCard';
import { CardDeckRoutes } from '../../cardDecksRoutes';
import { CardDeckCardSearchResult } from './CardDeckCardSearchModels';
import { getCardDeck } from '../Home/cardDecks';

import './SearchResultListItem.css';

interface SearchResultListItemProps {
  searchResult: CardDeckCardSearchResult;
  searchTerm: string;
}

const SearchResultListItem: React.FC<SearchResultListItemProps> = ({
  searchResult,
  searchTerm,
}) => {
  const getRouteForSearchResult = (item: any) => {
    if ('categoryId' in item) {
      const tacticCard = item as CardDeckTacticCardSearchItem;
      return CardDeckRoutes.cardDeckTacticCard.resolve(
        tacticCard.deckId,
        tacticCard.id
      );
    } else {
      const recipeCard = item as CardDeckRecipeCardSearchItem;
      return CardDeckRoutes.cardDeckRecipeCard.resolve(
        recipeCard.deckId,
        recipeCard.id
      );
    }
  };

  return (
    <IonItem
      className="search-result-item"
      routerLink={getRouteForSearchResult(searchResult.item)}
      // onClick={() => navigator.clipboard.writeText(searchResult.item.id + '')}
    >
      <IonLabel>
        <span className="card-title">
          <HtmlToReactComp
            html={getResultTitleAndHighlightItIfMatches(
              searchResult,
              searchTerm
            )}
          />
        </span>
        &nbsp;&nbsp;
        <span className="deck-title">
          {getCardDeck(searchResult.item.deckId)?.title}
        </span>
        <IonNote className="ion-text-wrap">
          {searchResult.matches
            .filter(match => match.key != 'title')
            .map((match, index) => {
              const optionalSeparator =
                index < searchResult.matches.length - 1 ? '<br/>' : '';
              const highlightedText = highlightText(
                match.value,
                match.indices,
                searchTerm
              );
              const matchContent =
                highlightedText.length > 0
                  ? highlightedText + optionalSeparator
                  : null;
              return matchContent ? (
                <HtmlToReactComp key={index} html={matchContent} />
              ) : null;
            })}
        </IonNote>
      </IonLabel>
    </IonItem>
  );
};

export default SearchResultListItem;

const getResultTitleAndHighlightItIfMatches = (
  searchResult: CardDeckCardSearchResult,
  searchTerm: string
): string => {
  const match = searchResult.matches.find(
    match =>
      match.key == 'title' &&
      match.value.toLowerCase().includes(searchTerm.toLowerCase())
  );
  if (match) {
    return highlightText(match.value, match.indices, searchTerm);
  }
  return searchResult.item.title;
};

const highlightText = (
  text: string,
  indices: number[][],
  searchTerm: string
): string => {
  const exactMatchIndices = filterAlmostExactMatchIndices(
    indices,
    text,
    searchTerm
  );

  let resultText = '';
  let lastProcessedCharacterIndex = 0;

  exactMatchIndices.forEach(
    ([currentMatchStartIndex, currentMatchEndIndex], currentMatchIndex) => {
      const precedingText = text.substring(
        lastProcessedCharacterIndex,
        currentMatchStartIndex
      );
      const truncatedPrecedingText = getFiveWords(precedingText, true);
      let truncatedPrecedingTextStartIndex = text.indexOf(
        truncatedPrecedingText
      );
      const beforeHighlightedText = (() => {
        if (lastProcessedCharacterIndex >= truncatedPrecedingTextStartIndex) {
          // the following text from the previous match is overlapping
          // with the preceding text of the current match
          const bridgingText = text.substring(
            lastProcessedCharacterIndex,
            currentMatchStartIndex
          );
          return bridgingText;
        } else {
          const prefix = truncatedPrecedingTextStartIndex == 0 ? '' : ' ... ';
          return prefix + truncatedPrecedingText;
        }
      })();

      const highlightedText = wrapTextWithHighlightingTags(
        text.substring(currentMatchStartIndex, currentMatchEndIndex + 1)
      );

      const followingTextStartIndex = currentMatchEndIndex + 1;
      let wordsFollowing = text
        .substring(followingTextStartIndex)
        .split(' ', 6); // why not 5 and why the .slice(0, 5) in the next line?
      let truncatedFollowingText = wordsFollowing.slice(0, 5).join(' ');

      const truncatedFollowingTextSearchTermStartIndex = truncatedFollowingText
        .toLowerCase()
        .indexOf(searchTerm.toLowerCase());
      if (truncatedFollowingTextSearchTermStartIndex != -1) {
        truncatedFollowingText = truncatedFollowingText.substring(
          0,
          truncatedFollowingTextSearchTermStartIndex
        );
      }
      const afterHighlightedText = (() => {
        const hasMoreWordsFollowing = wordsFollowing.length > 5;
        const suffix =
          hasMoreWordsFollowing &&
          exactMatchIndices.length == currentMatchIndex + 1
            ? ' ...'
            : ''; // next iteration will append ... if needed
        return truncatedFollowingText + suffix;
      })();

      resultText +=
        beforeHighlightedText + highlightedText + afterHighlightedText;

      lastProcessedCharacterIndex =
        followingTextStartIndex + truncatedFollowingText.length;
    }
  );

  return resultText;
};

/**
 * Returns only indices where the match contains the search term.
 * Filters out matches which similar to the search term but do not
 * include the search term.
 */
function filterAlmostExactMatchIndices(
  indices: number[][],
  text: string,
  searchTerm: string
) {
  return indices.filter(([startIndex, endIndex]) => {
    const substring = text.substring(startIndex, endIndex + 1).toLowerCase();
    return substring.includes(searchTerm.toLowerCase());
  });
}

const getFiveWords = (substring: string, isPreceding: boolean) => {
  const words = substring.split(' ');
  return isPreceding ? words.slice(-5).join(' ') : words.slice(0, 5).join(' ');
};

const wrapTextWithHighlightingTags = (text: string) => {
  return `<span class="highlight">${text}</span>`;
};
