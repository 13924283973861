import { FirebaseError } from 'firebase/app';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore';

import getFirebaseDb from '../../External/Firebase/FirebaseDb';
import {
  AddBookmarksError,
  AddCardBookmark,
  Bookmark,
  ListenBookmarksError,
  RemoveBookmarksError,
  RemoveCardBookmark,
} from '../../CardDecks/CardDeckBookmarks/bookmarksStorage';
import { errorResult, okResult, Result } from '../../Common/Types/Result';

export interface FirestoreBookmark {
  cardId: number;
  cardType: string;
  timestamp: string;
}

export const addCardBookmark: AddCardBookmark = async (
  cardId: number,
  cardType: string,
  userId: string
) => {
  const db = getFirebaseDb();
  const bookmarksRef = collection(db, `users/${userId}/bookmarks`);
  const newBookmark = await addDoc(bookmarksRef, {
    cardId: cardId,
    cardType: cardType,
    timestamp: new Date().toISOString(),
  });
  return newBookmark.id;
};

export const isHandledAddBookmarkFirebaseError = (error: unknown): boolean => {
  return (error as FirebaseError)?.code === 'auth/network-request-failed';
};

export const mapFirebaseErrorToAddBookmarkError = (
  error: unknown
): AddBookmarksError => {
  return AddBookmarksError.Unknown;
};

export const removeCardBookmark: RemoveCardBookmark = async (
  bookmarkId: string,
  userId: string
) => {
  const db = getFirebaseDb();
  const bookmarkDoc = doc(db, `users/${userId}/bookmarks/${bookmarkId}`);
  await deleteDoc(bookmarkDoc);
};

export const isHandledRemoveBookmarkFirebaseError = (
  error: unknown
): boolean => {
  return (error as FirebaseError)?.code === 'auth/network-request-failed';
};

export const mapFirebaseErrorToRemoveBookmarkError = (
  error: unknown
): RemoveBookmarksError => {
  return RemoveBookmarksError.Unknown;
};

export const listenFirebaseCardBookmarks = (
  userId: string,
  onResult: (
    result: Result<Array<[string, FirestoreBookmark]>, unknown>
  ) => void
) => {
  const db = getFirebaseDb();
  const bookmarksRef = collection(db, `users/${userId}/bookmarks`);
  const q = query(bookmarksRef, orderBy('timestamp', 'desc'));
  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const bookmarks = querySnapshot.docs.map(
        doc =>
          [doc.id, doc.data() as FirestoreBookmark] as [
            string,
            FirestoreBookmark
          ]
      );
      onResult(okResult(bookmarks));
    },
    error => {
      onResult(errorResult(error));
    }
  );
  return unsubscribe;
};

export const mapFirestoreBookmarkToBookmark = (
  firestoreBookmark: [string, FirestoreBookmark]
): Bookmark => {
  return {
    id: firestoreBookmark[0],
    cardId: firestoreBookmark[1].cardId,
    cardType: firestoreBookmark[1].cardType,
    timestamp: firestoreBookmark[1].timestamp,
  };
};

export const isHandledListenBookmarksFirebaseError = (
  error: unknown
): boolean => {
  return (error as FirebaseError)?.code === 'auth/network-request-failed';
};

export const mapFirebaseErrorToListenBookmarksError = (
  error: unknown
): ListenBookmarksError => {
  return ListenBookmarksError.Unknown;
};
