import { cardListSections } from '../../Content/cardListSectionsContent';
import { recipeCards } from '../../Content/recipeCardsContent';
import { CardDeckRecipeCard } from '../CardDeckCard/RecipeCard/cardDeckRecipeCard';

export interface CardDeckCardListItemStyle {
  borderColor: string;
}

export interface CardDeckCardListItem {
  title: string;
  description: string;
  imageUrl: string;
  id: number;
  style: CardDeckCardListItemStyle;
}

export interface CardDeckCardListSection {
  title: string;
  description?: string;
  iconCode: string;
  color?: string;
  id: number;
  cards: CardDeckCardListItem[];
}

const typedListSections: { [key: string]: CardDeckCardListSection[] } =
  cardListSections as unknown as { [key: string]: CardDeckCardListSection[] };

export const getCardDeckCategoriesWithCards = (deckId: string) =>
  typedListSections[deckId];
export const getCardDeckCategoryWithCards = (
  deckId: string,
  categoryId: number
) => typedListSections[deckId]?.find(category => category.id == categoryId);

const typedRecipeCards: { [key: string]: CardDeckRecipeCard[] } =
  recipeCards as unknown as { [key: string]: CardDeckRecipeCard[] };

export const recipeCategoryAttr = {
  title: 'Recipes',
  iconCode: '&#xe907;',
  borderColor: 'black',
};

let recipeSection: CardDeckCardListSection = {
  title: recipeCategoryAttr.title,
  description: 'Combine Tactics to tackle complex problems.',
  iconCode: recipeCategoryAttr.iconCode,
  color: undefined,
  id: -1,
  cards: [],
};

const recipeCardsSectionByDeckIdMap = Object.entries(typedRecipeCards).reduce(
  (acc, [deckId, recipeCards]) => {
    const newRecipeSection = { ...recipeSection };
    newRecipeSection.cards = recipeCards?.map(recipeCard => {
      return {
        title: recipeCard.title,
        description: recipeCard.summary,
        imageUrl: recipeCard.imageUrl,
        id: recipeCard.id,
        style: {
          borderColor: recipeCategoryAttr.borderColor,
        },
      };
    });
    acc[deckId] = newRecipeSection;
    return acc;
  },
  {} as { [key: string]: CardDeckCardListSection }
);

export const getCardDeckRecipes = (deckId: string) => {
  const recipeCardsSection = recipeCardsSectionByDeckIdMap[deckId];
  if (recipeCardsSection) {
    return [recipeCardsSection];
  } else {
    return null;
  }
};

type DeckIdCardListSectionAndCardListItem = {
  deckId: number;
  cardListSection: CardDeckCardListSection;
  cardListItem: CardDeckCardListItem;
};

export const getTacticCardListItemAndSectionByCardId = (() => {
  const cardDeckIdByCardIdMap = Object.entries(typedListSections).reduce(
    (acc, [deckId, sections]) => {
      sections?.forEach(section => {
        section.cards.forEach(card => {
          acc[card.id] = {
            deckId: Number(deckId),
            cardListSection: section,
            cardListItem: card,
          };
        });
      });
      return acc;
    },
    {} as { [key: number]: DeckIdCardListSectionAndCardListItem }
  );

  return (cardId: number): DeckIdCardListSectionAndCardListItem => {
    return cardDeckIdByCardIdMap[cardId];
  };
})();

export const getTacticCardListItemAndSectionByCardTitleAndDeckId = (() => {
  const cardByDeckIdByCardTitleMap = Object.entries(typedListSections).reduce(
    (acc, [deckId, sections]) => {
      if (!acc[deckId]) {
        acc[deckId] = {};
      }
      sections?.forEach(section => {
        section.cards.forEach(card => {
          acc[deckId][card.title.toLowerCase()] = {
            deckId: Number(deckId),
            cardListSection: section,
            cardListItem: card,
          };
        });
      });
      return acc;
    },
    {} as {
      [key: string]: { [key: string]: DeckIdCardListSectionAndCardListItem };
    }
  );

  return (
    cardTitle: string,
    deckId: string
  ): DeckIdCardListSectionAndCardListItem | null => {
    const deckMap = cardByDeckIdByCardTitleMap[deckId];
    if (!deckMap) {
      return null;
    }
    return deckMap[cardTitle.toLowerCase()];
  };
})();

export const getRecipeCardListItemAndSectionByCardId = (() => {
  const cardDeckIdByCardIdMap = Object.entries(
    recipeCardsSectionByDeckIdMap
  ).reduce((acc, [deckId, section]) => {
    section.cards.forEach(card => {
      acc[card.id] = {
        deckId: Number(deckId),
        cardListSection: section,
        cardListItem: card,
      };
    });
    return acc;
  }, {} as { [key: number]: DeckIdCardListSectionAndCardListItem });

  return (cardId: number): DeckIdCardListSectionAndCardListItem => {
    return cardDeckIdByCardIdMap[cardId];
  };
})();
