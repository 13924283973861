import React, { createContext, useContext, ReactNode } from 'react';

import { useAuthenticatedCustomer } from '../../../Common/AuthorizedAccess/AuthenticatedCustomerContext';
import { getDecksUserCanAccess } from '../../../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import { getCardDecks } from '../../../Home/cardDecks';
import { isDataLoadingResultSuccess } from '../../../../Common/Types/DataLoadingResult';

interface SolveMyProblemAccessContextType {
  canAccessSolveMyProblem?: boolean;
}

const SolveMyProblemAccessContext = createContext<
  SolveMyProblemAccessContextType | undefined
>(undefined);

interface SolveMyProblemAccessProviderProps {
  children: ReactNode;
}

/* 
   The whole SolveMyProblem feature is only available
   if the user has access to at least 2 full decks.
*/
export const SolveMyProblemAccessProvider: React.FC<
  SolveMyProblemAccessProviderProps
> = ({ children }) => {
  const customer = useAuthenticatedCustomer();
  const canAccessSolveMyProblem = (() => {
    if (!isDataLoadingResultSuccess(customer)) {
      return undefined;
    }
    if (!customer.data || !customer.data.deckIdsUserCanAccess) {
      return false;
    }
    const decks = getCardDecks();
    const decksCustomerCanAccess = getDecksUserCanAccess(
      customer.data.deckIdsUserCanAccess,
      decks
    );
    const numberOfFullDecks = decksCustomerCanAccess.filter(
      deck => deck.type === 'full'
    ).length;
    return numberOfFullDecks > 1;
  })();

  return (
    <SolveMyProblemAccessContext.Provider value={{ canAccessSolveMyProblem }}>
      {children}
    </SolveMyProblemAccessContext.Provider>
  );
};

export const useSolveMyProblemAccess = (): SolveMyProblemAccessContextType => {
  const context = useContext(SolveMyProblemAccessContext);
  if (!context) {
    throw new Error(
      'useSolveMyProblemAccess must be used within a SolveMyProblemAccessProvider'
    );
  }
  return context;
};
