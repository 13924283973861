import React from 'react';
import { IonButton } from '@ionic/react';
import { cloudDownloadOutline } from 'ionicons/icons';

import ButtonWithSpinner from '../../../Common/UI/Buttons/ButtonWithSpinner';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface DownloadButtonProps extends IonButtonProps {
  title: string;
  onClick: () => void;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  title,
  onClick,
  ...rest
}) => {
  return (
    <ButtonWithSpinner
      icon={cloudDownloadOutline}
      title={title}
      fill="outline"
      style={{ width: '100%' }}
      onClick={() => Promise.resolve(onClick())}
      className="secondary"
      {...rest}
    />
  );
};

export default DownloadButton;
