import { useEffect } from 'react';
import { PostHog } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';

import { once } from '../../Common/Functions/functions';
import { useAuthenticatedCustomer } from '../../CardDecks/Common/AuthorizedAccess/AuthenticatedCustomerContext';
import { isDataLoadingResultSuccess } from '../../Common/Types/DataLoadingResult';
import { getDeckIdsUserCanAccessResult } from '../../CardDecks/Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';

const trackEmailAuthenticatedUserAppAccessOnce = once(
  (postHog: PostHog, decksAccess: string[] | undefined) => {
    postHog.capture('access with email auth', {
      decksAccess: decksAccess,
    });
  }
);

const trackCustomerProfile = (
  postHog: PostHog,
  decksAccess: string[],
  subscriptionPlan: string | null
) => {
  postHog.capture('$set', {
    $set: {
      decksAccess: decksAccess,
      subscriptionPlan: subscriptionPlan,
    },
  });
};

const TrackCustomerComp: React.FC = () => {
  const customer = useAuthenticatedCustomer();
  const postHog = usePostHog();

  useEffect(() => {
    const deckIdsResult = getDeckIdsUserCanAccessResult(customer);

    if (
      isDataLoadingResultSuccess(deckIdsResult) &&
      // this one is needed because we check if they are a subscriber below
      // but since we got deckIdsResult, the customer should also be loaded already
      isDataLoadingResultSuccess(customer)
    ) {
      if (!deckIdsResult.data) return;

      const decksAccess = Array.from(deckIdsResult.data).map(
        deckId => deckIdToTitleMap[deckId]
      );

      // TODO: should we be recording a an access with email auth here?
      // We do it only because we want to know which decks the user has access to
      // but do we have enough info here to know if the user is even authenticated?
      // I guess so, CustomerProvider shouldn't be triggered if the user is not authenticated
      // but that's only if the CustomerProvider is used within UserProvider
      // DECISION: track 'access with email auth' on user auth and 'customer access' with deck list in a
      // property on customer data loaded. this will require reviewing of the Posthog dashbaords
      // cause a lot of them are based on 'access with email auth'
      trackEmailAuthenticatedUserAppAccessOnce(postHog, decksAccess);

      const decksAccessList = !!decksAccess ? decksAccess : [];
      const subscriptionPlan =
        (customer.data?.isProMember && 'pro-membership') || null;
      trackCustomerProfile(postHog, decksAccessList, subscriptionPlan);
    }
  }, [customer]);

  return null;
};

export default TrackCustomerComp;

const deckIdToTitleMap: { [key: number]: string } = {
  1: 'Workshop Tactics',
  2: 'Storyteller Tactics',
  3: 'Innovation Tactics',
  4: 'Idea Tactics',
  5: 'Strategy Tactics',
  6: 'Team Tactics',
  7: 'Brand Tactics',
  8: 'Productivity Tactics',
  9: 'Retros',
  10: 'Archetypes',
  11: 'Negotiation Tactics',
};
