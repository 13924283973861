import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';

import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import { CardDeckRoutes } from '../../../cardDecksRoutes';

import provideDeckBreadcrumbProps from '../../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import { getCardDeckByIdString } from '../../Home/cardDecks';
import DownloadsSection, {
  DownloadsSectionProps,
} from '../../Common/Downloads/DownloadsSection';

import {
  CardDeckCardListSection,
  getCardDeckCategoriesWithCards,
} from '../cardDeckCardListSections';

import { getCardListDocumentTitle } from '../CardDeckCardListPage';
import CardDeckCardsGridPage from '../CardDeckCardsGridPage';

const setDocumentTitle = (deckId: string, isExpansionDeck: boolean) => {
  const documentTitleSegment = isExpansionDeck ? undefined : 'Tactics';
  document.title = getCardListDocumentTitle(deckId, documentTitleSegment);
};

const CardDeckTacticsGridPage: React.FC = () => {
  // console.log(`Loading card list`);
  const [sections, setSections] = useState<
    CardDeckCardListSection[] | null | undefined
  >(undefined);
  const [isExpansionDeck, setIsExpansionDeck] = useState<boolean | undefined>(
    undefined
  );
  const [downloadsSectionProps, setDownloadsSectionProps] = useState<
    DownloadsSectionProps | undefined
  >(undefined);

  const { deckId, categoryId } = useParams<{
    deckId: string;
    categoryId: string;
  }>();

  const getCardDetailsRoute = CardDeckRoutes.cardDeckTacticCard.resolve;

  useIonViewWillEnter(() => {
    // console.log(`Loading deck with id ${deckId}`);
    const deck = getCardDeckByIdString(deckId);
    const isExpansionDeck = deck?.type === 'expansion';

    // sections are going to be used to render 404 if they are null
    let sections = getCardDeckCategoriesWithCards(deckId) ?? null;
    setSections(sections);

    // the rest can be ingored if the deck does not exist
    if (!deck) {
      return;
    }
    setIsExpansionDeck(isExpansionDeck);
    setDocumentTitle(deckId, isExpansionDeck);
    setDownloadsSectionProps({
      deckTitle: deck!.title,
      deckPdfUrl: deck!.pdfDownloadUrl ?? '',
      deckImagesArchiveUrl: deck!.imagesArchiveDownloadUrl ?? '',
    });
  }, [deckId, categoryId]);

  // useIonViewDidEnter(() => {
  //   console.log('Tactics list did enter');
  // });

  // useIonViewDidLeave(() => {
  //   console.log('Tactics list did leave');
  // });

  const isBreadcrumbALink =
    isExpansionDeck !== undefined ? !isExpansionDeck : true;

  const downloadsSection =
    isExpansionDeck && downloadsSectionProps ? (
      <DownloadsSection {...downloadsSectionProps} />
    ) : undefined;

  return (
    <CardDeckCardsGridPage
      deckId={deckId}
      breadcrumbs={
        <Breadcrumbs>
          <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
          <TextBreadcrumb
            {...provideDeckBreadcrumbProps(deckId, isBreadcrumbALink)}
          />
          {!isExpansionDeck ? <Breadcrumb>Tactics</Breadcrumb> : null}
        </Breadcrumbs>
      }
      cardListSections={sections}
      shouldShowCategorySectionTitle={true}
      getCardDetailsRoute={getCardDetailsRoute}
      bottomSection={downloadsSection}
    />
  );
};

export default CardDeckTacticsGridPage;
