import React from 'react';
import { IonPage, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { PostHog } from 'posthog-js';

import { CardDeckRoutes } from '../../cardDecksRoutes';
import AppContentContainer from '../AppContentContainer';
import { once } from '../Functions/functions';

import './NotFoundPage.css';
import '../Style/card.css';
import '../Style/empty-card.css';
import EmptyContentComp from '../ContentLoading/EmptyContentComp';

const getTrackPageVisitOnce = (posthog: PostHog) => {
  return once(() => {
    posthog.capture('page not found');
  });
};

const NotFoundPage: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('navigation');
  const postHog = usePostHog();
  let trackPageVisit: (() => void) | null = getTrackPageVisitOnce(postHog);

  useIonViewWillEnter(() => {
    trackPageVisit && trackPageVisit();
  });

  useIonViewDidLeave(() => {
    trackPageVisit = null;
  });

  return isTReady ? (
    <IonPage id="page-not-found">
      <AppContentContainer>
        <EmptyContentComp
          illustrationPath="images/blob-sweat.png"
          title={t('NotFoundPage_title')}
          descriptionHtml={t('NotFoundPage_description')}
          buttonText={t('NotFoundPage_buttonTitle')}
          onButtonClick={() => {
            window.location.href = CardDeckRoutes.root.resolve();
          }}
        />
      </AppContentContainer>
    </IonPage>
  ) : null;
};

export default NotFoundPage;
