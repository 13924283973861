import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IonInput,
  IonLabel,
  IonItem,
  IonList,
  IonIcon,
  IonNote,
} from '@ionic/react';
import { alertCircleOutline, keyOutline } from 'ionicons/icons';

import ButtonWithSpinner from '../../Common/UI/Buttons/ButtonWithSpinner';

import { validateEmail } from './validateEmailString';
import { getInvalidOnlyIonInputClasses } from '../../Common/UI/Ionic/IonInputExtensions';

interface EmailInputFormCompProps {
  formTitle: string;
  isFormTitleIconVisible: boolean;
  formInstructions: string;
  emailInputValue?: string;
  submitButtonLabel: string;
  formErrorMessage?: string;
  onEmailSubmitClicked: () => Promise<void>;
  onInputChanged: (email: string) => void;
}

const EmailInputFormComp: React.FC<EmailInputFormCompProps> = ({
  formTitle,
  isFormTitleIconVisible,
  formInstructions,
  emailInputValue,
  submitButtonLabel,
  formErrorMessage,
  onEmailSubmitClicked,
  onInputChanged,
}) => {
  const [email, setEmail] = useState<string>(emailInputValue ?? '');
  // there needs to be a default value set,
  // otherwise the input doesn't draw a bottom border
  const [emailInputErrorMessage, setEmailInputErrorMessage] =
    useState<string>('error');
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
  const { t, ready: isTReady } = useTranslation('authentication');
  const submitButtonRef = useRef<HTMLIonButtonElement>(null);

  const validateNonEmptyEmail = (): boolean => {
    const updatedIsValid = validateEmail(email);

    if (!updatedIsValid) {
      setEmailInputErrorMessage(
        t('EmailInputFormComp_inputInvalidErrorMessage')
      );
    }

    setIsValid(updatedIsValid);
    return updatedIsValid;
  };

  const validateInputOnValueChanged = () => {
    if (!email) return;

    validateNonEmptyEmail();
  };

  const validateInputOnSubmit = () => {
    if (!email) {
      setEmailInputErrorMessage(t('EmailInputFormComp_inputEmptyErrorMessage'));
      const updatedIsValid = false;
      setIsValid(updatedIsValid);
      return updatedIsValid;
    }

    return validateNonEmptyEmail();
  };

  const markInputTouched = () => {
    setIsTouched(true);
  };

  const submitButtonClicked = async () => {
    markInputTouched();
    const isValid = validateInputOnSubmit();
    if (!isValid) {
      return;
    }
    // console.log('sending that email');
    await onEmailSubmitClicked();
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitButtonRef.current?.click();
  };

  return isTReady ? (
    <>
      <form onSubmit={handleSubmit}>
        <IonList>
          <IonItem lines="none" className="header-item">
            {isFormTitleIconVisible && (
              <IonIcon icon={keyOutline} slot="start"></IonIcon>
            )}
            <IonLabel className="ion-text-wrap">
              <h1>{formTitle}</h1>
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonNote color="dark" className="ion-text-wrap">
              {formInstructions}
            </IonNote>
          </IonItem>
          <IonItem lines="none" className="gap">
            <IonInput
              type="email"
              clearInput={true}
              className={getInvalidOnlyIonInputClasses(isValid, isTouched)}
              label={t('EmailInputFormComp_inputLabel')}
              labelPlacement="stacked"
              placeholder={t('EmailInputFormComp_inputPlaceholder')}
              errorText={emailInputErrorMessage}
              value={email}
              onIonInput={e => {
                // console.log('onIonInput');
                const email = e.detail.value;
                setEmail(email!);
                onInputChanged(email!);
              }}
              onFocus={() => {
                // console.log('onFocus');
                setIsValid(undefined);
              }}
              onIonBlur={() => {
                // console.log('onIonBlur');
                validateInputOnValueChanged();
                markInputTouched();
              }}
            />
          </IonItem>
          {formErrorMessage && (
            <IonItem lines="none">
              <IonNote color="danger ion-text-wrap" className="message">
                {formErrorMessage}
              </IonNote>
            </IonItem>
          )}
          <ButtonWithSpinner
            expand="block"
            ref={submitButtonRef}
            title={submitButtonLabel}
            className="action gap"
            onClick={submitButtonClicked}
          />
        </IonList>
      </form>
    </>
  ) : null;
};

export default EmailInputFormComp;
