import { IonItem, IonList, IonNote, IonSpinner } from '@ionic/react';
import HtmlToReactComp from '../../Common/UI/HTML/HtmlToReactComp';
import CenteredContentContainer from '../../Common/UI/Buttons/CenteredContentContainer';

type CardDeckEmptyCardsSearchProps = {
  message: string;
  isLoading?: boolean;
};

const CardDeckSearchResultsLoadingOrEmptyComp: React.FC<
  CardDeckEmptyCardsSearchProps
> = ({ message, isLoading }) => {
  // isLoading = true;
  return (
    <div className="page-content-container ion-padding">
      <div className="page-content">
        <IonList>
          {isLoading && (
            <IonItem lines="none">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                <IonSpinner />
              </div>
            </IonItem>
          )}
          {!isLoading && (
            <>
              <IonItem lines="none">
                <CenteredContentContainer>
                  <IonNote
                    id="cards-search-message"
                    color="dark"
                    className="ion-text-wrap ion-text-center">
                    <HtmlToReactComp html={message} />
                  </IonNote>
                </CenteredContentContainer>
              </IonItem>
              <IonItem lines="none" className="image-item">
                <img
                  src="images/blob-search.png"
                  style={{ margin: '0 auto', width: '100px' }}
                />
              </IonItem>
            </>
          )}
        </IonList>
      </div>
    </div>
  );
};

export default CardDeckSearchResultsLoadingOrEmptyComp;
