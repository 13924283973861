import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AlertOptions,
  IonButton,
  IonButtons,
  IonFooter,
  IonPage,
  IonToolbar,
  useIonAlert,
} from '@ionic/react';
import { HookOverlayOptions } from '@ionic/react/dist/types/hooks/HookOverlayOptions';
import { usePostHog } from 'posthog-js/react';

import { Auth, isSignInWithEmailLink } from 'firebase/auth';
import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';

import './EmailLinkLogInPage.css';
import '../../Common/Pages/page.css';

import AppContentContainer from '../../Common/AppContentContainer';
import ContentLoadingComp, {
  ContentLoadingState,
} from '../../Common/ContentLoading/ContentLoadingComp';
import { once } from '../../Common/Functions/functions';
import { getQueryParam } from '../../Common/WebContext/queryParams';
import composeTrackUserActionWithPostHog from '../../External/PostHog/trackUserActionWithPostHog';
import composeTrackUserIdentityWithPostHog from '../../External/PostHog/trackUserIdentity';

import { useAuthenticatedUser } from '../AuthenticatedUserContext';
import EmailLogInForm from './EmailLinkLoginForm';
import EmailLinkSentComp from './EmailLinkSent/EmailLinkSentComp';
import EmailLinkTroubleshootComp from './EmailLinkTroubleshootComp';
import EmailLinkLoginSuccessComp from './EmailLinkLoginSuccessComp';
import { releaseAuthBroadcast } from './authStateChangeObserverWithManualRelease';
import ConfirmEmailLoginForm from './ConfirmEmailLoginForm';
import signInWithAuthBroadcastHeld from './signInWithEmailLink';
import { emailLinkLoginFormLocalStorage } from './emailLinkLoginFormLocalStorage';
import {
  isLoginLinkWithStandaloneFlow,
  removeLoginLinkParamsFromAddressBar,
} from './loginLink';
import LoginLinkExpiredRequestNewInAppComp from './LoginLinkExpiredRequestNewInAppComp';
import StartOverRequestNewLoginLinkInAppComp from './StartOverRequestNewLoginLinkInAppComp';

export enum EmailLinkLogInPageState {
  EmailForm = 'EmailForm',
  LinkSent = 'LinkSent',
  Troubleshooting = 'Troubleshooting',
  ConfirmEmailBrowserFlow = 'ConfirmEmailBrowserFlow',
  ConfirmEmailStandaloneFlow = 'ConfirmEmailStandaloneFlow',
  SuccessStandaloneFlow = 'SuccessStandaloneFlow',
  AlreadyLoggedinBrowserFlow = 'AlreadyLoggedinBrowserFlow',
  AlreadyLoggedinStandaloneFlow = 'AlreadyLoggedinStandaloneFlow',
  LoginLinkExpiredRequestNewInAppComp = 'LoginLinkExpiredRequestNewInAppComp',
  StartOverRequestNewLoginLinkInAppComp = 'StartOverRequestNewLoginLinkInAppComp',
}

const getCurrentUrl = () => {
  return window.location.href;
};

const getInvalidEmailAlertOptions = (
  t: (i18nKey: string) => string,
  onDidDismiss?: () => void
): AlertOptions & HookOverlayOptions => {
  return {
    header: t('EmailLinkLogInPage_InvalidEmailAlert_Title'),
    message: t('EmailLinkLogInPage_InvalidEmailAlert_Message'),
    buttons: [
      {
        text: t('EmailLinkLogInPage_InvalidEmailAlert_OkButton'),
        handler: onDidDismiss,
      },
    ],
  };
};

const getExpiredLoginLinkAlertOptions = (
  t: (i18nKey: string) => string,
  onDidDismiss?: () => void
): AlertOptions & HookOverlayOptions => {
  return {
    header: t('EmailLinkLogInPage_ExpiredLoginLinkAlert_Title'),
    message: t('EmailLinkLogInPage_ExpiredLoginLinkAlert_Message'),
    buttons: [
      {
        text: t('EmailLinkLogInPage_ExpiredLoginLinkAlert_OkButton'),
        handler: onDidDismiss,
      },
    ],
  };
};

const getUnknownLoginLinkErrorAlertOptions = (
  t: (i18nKey: string) => string,
  onDidDismiss?: () => void
): AlertOptions & HookOverlayOptions => {
  return {
    header: t('EmailLinkLogInPage_UnknownLoginLinkAlert_Title'),
    message: t('EmailLinkLogInPage_UnknownLoginLinkAlert_Message'),
    buttons: [
      {
        text: t('EmailLinkLogInPage_UnknownLoginLinkAlert_OkButton'),
        handler: onDidDismiss,
      },
    ],
  };
};

const setLastKnownLoginEmailIfPresentInQueryParams = () => {
  const email = getQueryParam('email');
  if (email) {
    emailLinkLoginFormLocalStorage.setLastKnownLoginEmail(email);
  }
};

const EmailLinkLogInPage: React.FC<{
  onContinueToApp: () => void;
  initialState?: EmailLinkLogInPageState;
  shouldShowStatesPreviewNavigation?: boolean;
}> = ({
  onContinueToApp,
  initialState = EmailLinkLogInPageState.EmailForm,
  shouldShowStatesPreviewNavigation = false,
}) => {
  const [isReadyToShowLoginPageContents, setIsReadyToShowLoginPageContents] =
    useState<boolean>(false);
  const [pageState, setPageState] =
    useState<EmailLinkLogInPageState>(initialState);
  const user = useAuthenticatedUser();
  const [presentAlert] = useIonAlert();
  const { t, ready: isTReady } = useTranslation('authentication');
  const auth = getFirebaseAuth();
  const posthog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(posthog);
  const trackIdentity = composeTrackUserIdentityWithPostHog(posthog);

  const trackIdentityIfEmailFromInvitePresent = (
    emailFromInvite: string | null
  ) => {
    if (emailFromInvite) {
      trackIdentity({ id: emailFromInvite, email: emailFromInvite });
    }
  };

  const trackLoginFormVisit = (invitedEmail: string | null) => {
    const visitEventName = 'visited login form';
    const eventParams = !!invitedEmail ? { email: invitedEmail } : {};
    trackAction(visitEventName, eventParams);
  };

  const trackVisitAndIdentityIfAvailable = () => {
    const email = getQueryParam('email');
    trackIdentityIfEmailFromInvitePresent(email);
    trackLoginFormVisit(email);
  };

  const onPageLoad = () => {
    const currentUrlString = getCurrentUrl();
    if (isSignInWithEmailLink(auth, currentUrlString)) {
      handleLoginLinkOnPageLoad(auth, currentUrlString);
    } else {
      setLastKnownLoginEmailIfPresentInQueryParams();
      trackVisitAndIdentityIfAvailable();
      showLoginFormOnPageLoad();
    }
  };

  const showLoginFormOnPageLoad = () => {
    setIsReadyToShowLoginPageContents(true);
  };

  const handleLoginLinkOnPageLoad = (auth: Auth, currentUrlString: string) => {
    // console.log('auth.currentUser', auth.currentUser);

    if (auth.currentUser) {
      showAlreadyLoggedInStateOnLoginLinkLoad(currentUrlString);
      return;
    }

    let email = emailLinkLoginFormLocalStorage.getRequestedLoginLinkEmail();
    if (!email) {
      showEmailConfirmationForm(getCurrentUrl());
    } else {
      signIn(auth, email, getCurrentUrl());
    }
  };

  const showAlreadyLoggedInStateOnLoginLinkLoad = (
    currentUrlString: string
  ) => {
    removeLoginLinkParamsFromAddressBar();
    const didLoginFlowStartFromStandaloneMode =
      isLoginLinkWithStandaloneFlow(currentUrlString);
    const nextPageState = didLoginFlowStartFromStandaloneMode
      ? EmailLinkLogInPageState.AlreadyLoggedinStandaloneFlow
      : EmailLinkLogInPageState.AlreadyLoggedinBrowserFlow;
    setPageState(nextPageState);
    setIsReadyToShowLoginPageContents(true);
  };

  const showEmailConfirmationForm = (currentUrlString: string) => {
    const didLoginFlowStartFromStandaloneMode =
      isLoginLinkWithStandaloneFlow(currentUrlString);
    const nextPageState = didLoginFlowStartFromStandaloneMode
      ? EmailLinkLogInPageState.ConfirmEmailStandaloneFlow
      : EmailLinkLogInPageState.ConfirmEmailBrowserFlow;
    setPageState(nextPageState);
    setIsReadyToShowLoginPageContents(true);
  };

  const signIn = async (auth: any, email: string, link: string) => {
    return signInWithAuthBroadcastHeld(auth, email, link)
      .then(onSignInSuccess)
      .catch(onSignInError);
  };

  const onSignInSuccess = () => {
    const currentUrlString = getCurrentUrl();
    let didLoginFlowStartFromStandaloneMode =
      isLoginLinkWithStandaloneFlow(currentUrlString);
    removeLoginLinkParamsFromAddressBar();
    if (didLoginFlowStartFromStandaloneMode) {
      setPageState(EmailLinkLogInPageState.SuccessStandaloneFlow);
      setIsReadyToShowLoginPageContents(true);
    } else {
      releaseAuthBroadcast(); // and it will reveal the underlying app page
    }
  };

  const onSignInError = (error: any) => {
    // console.log(error);
    switch (error.code) {
      case 'auth/invalid-email':
        showEmailConfirmationForm(getCurrentUrl());
        presentAlert(getInvalidEmailAlertOptions(t));
        return;
      case 'auth/invalid-action-code':
        onLoginLinkInvalid();
        return;
      default:
        onUnknownLoginLinkError();
        return;
    }
  };

  const onLoginLinkInvalid = () => {
    const currentUrlString = getCurrentUrl();
    let didLoginFlowStartFromStandaloneMode =
      isLoginLinkWithStandaloneFlow(currentUrlString);
    if (didLoginFlowStartFromStandaloneMode) {
      showLoginLinkExpiredRequestNewInAppMessage();
    } else {
      showEmailFormOnLoginLinkErrorOrAbandonedEmailConfirmation();
      presentAlert(getExpiredLoginLinkAlertOptions(t));
    }
  };

  const showLoginLinkExpiredRequestNewInAppMessage = () => {
    removeLoginLinkParamsFromAddressBar();
    setPageState(EmailLinkLogInPageState.LoginLinkExpiredRequestNewInAppComp);
    setIsReadyToShowLoginPageContents(true);
  };

  const showStartOverRequestNewLoginLinkInAppMessage = () => {
    removeLoginLinkParamsFromAddressBar();
    setPageState(EmailLinkLogInPageState.StartOverRequestNewLoginLinkInAppComp);
    setIsReadyToShowLoginPageContents(true);
  };

  const showEmailFormOnLoginLinkErrorOrAbandonedEmailConfirmation = () => {
    removeLoginLinkParamsFromAddressBar();
    setPageState(EmailLinkLogInPageState.EmailForm);
    setIsReadyToShowLoginPageContents(true);
  };

  const onUnknownLoginLinkError = () => {
    const currentUrlString = getCurrentUrl();
    let didLoginFlowStartFromStandaloneMode =
      isLoginLinkWithStandaloneFlow(currentUrlString);
    if (didLoginFlowStartFromStandaloneMode) {
      showStartOverRequestNewLoginLinkInAppMessage();
    } else {
      showEmailFormOnLoginLinkErrorOrAbandonedEmailConfirmation();
    }
    presentAlert(getUnknownLoginLinkErrorAlertOptions(t));
  };

  const exitAuthContext = () => {
    removeLoginLinkParamsFromAddressBar();
    releaseAuthBroadcast();
    onContinueToApp();
  };

  useEffect(once(onPageLoad), []);

  // user object changes on this page only when an already logged in user
  // logs out in the "already logged-in state"
  // in which case we need to show the login form
  useEffect(() => {
    if (!user && isReadyToShowLoginPageContents) {
      // user logged out in the success state
      setPageState(initialState);
    }
  }, [user]);

  return (
    <IonPage id="email-link-login">
      <AppContentContainer>
        <div className="page-content-container">
          <div className="page-content bottom-buttons-margin-adjust ion-padding">
            <ContentLoadingComp
              loadingState={
                isReadyToShowLoginPageContents && isTReady
                  ? ContentLoadingState.Loaded
                  : ContentLoadingState.Loading
              }
              loadedContent={(() => {
                switch (pageState) {
                  case EmailLinkLogInPageState.EmailForm:
                    return (
                      <EmailLogInForm
                        onEmailSent={() =>
                          setPageState(EmailLinkLogInPageState.LinkSent)
                        }
                      />
                    );
                  case EmailLinkLogInPageState.LinkSent:
                    return (
                      <EmailLinkSentComp
                        onTroubleshootClicked={() =>
                          setPageState(EmailLinkLogInPageState.Troubleshooting)
                        }
                        onResendClicked={() =>
                          setPageState(EmailLinkLogInPageState.EmailForm)
                        }
                      />
                    );
                  case EmailLinkLogInPageState.Troubleshooting:
                    return (
                      <EmailLinkTroubleshootComp
                        onResendClicked={() => {
                          setPageState(EmailLinkLogInPageState.EmailForm);
                        }}
                        onBackClicked={() =>
                          setPageState(EmailLinkLogInPageState.LinkSent)
                        }
                      />
                    );
                  case EmailLinkLogInPageState.ConfirmEmailBrowserFlow:
                    return (
                      <ConfirmEmailLoginForm
                        onLoggedIn={onSignInSuccess}
                        onLoginLinkInvalid={onLoginLinkInvalid}
                        onUnknownLoginLinkError={onUnknownLoginLinkError}
                        onStartOver={
                          showEmailFormOnLoginLinkErrorOrAbandonedEmailConfirmation
                        }
                      />
                    );
                  case EmailLinkLogInPageState.ConfirmEmailStandaloneFlow:
                    return (
                      <ConfirmEmailLoginForm
                        onLoggedIn={onSignInSuccess}
                        onLoginLinkInvalid={onLoginLinkInvalid}
                        onUnknownLoginLinkError={onUnknownLoginLinkError}
                        onStartOver={
                          showStartOverRequestNewLoginLinkInAppMessage
                        }
                      />
                    );
                  case EmailLinkLogInPageState.SuccessStandaloneFlow:
                    return (
                      <EmailLinkLoginSuccessComp
                        email={auth.currentUser?.email || 'pip@pipdecks.com'}
                        isNewLogin={true}
                        isStandaloneLoginFlow={true}
                        onContinueInBrowserClicked={exitAuthContext}
                      />
                    );
                  case EmailLinkLogInPageState.AlreadyLoggedinBrowserFlow:
                    return (
                      <EmailLinkLoginSuccessComp
                        email={auth.currentUser?.email || 'pip@pipdecks.com'}
                        isNewLogin={false}
                        isStandaloneLoginFlow={false}
                        onContinueInBrowserClicked={exitAuthContext}
                      />
                    );
                  case EmailLinkLogInPageState.AlreadyLoggedinStandaloneFlow:
                    return (
                      <EmailLinkLoginSuccessComp
                        email={auth.currentUser?.email || 'pip@pipdecks.com'}
                        isNewLogin={false}
                        isStandaloneLoginFlow={true}
                        onContinueInBrowserClicked={exitAuthContext}
                      />
                    );
                  case EmailLinkLogInPageState.LoginLinkExpiredRequestNewInAppComp:
                    return <LoginLinkExpiredRequestNewInAppComp />;
                  case EmailLinkLogInPageState.StartOverRequestNewLoginLinkInAppComp:
                    return <StartOverRequestNewLoginLinkInAppComp />;
                }
              })()}
            />
          </div>
        </div>
      </AppContentContainer>
      {shouldShowStatesPreviewNavigation && (
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton
                onClick={() => {
                  if (currentPreviewStateIndex > 0) {
                    currentPreviewStateIndex -= 1;
                    setPageState(previewStates[currentPreviewStateIndex]);
                  }
                }}>
                Previous
              </IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  if (currentPreviewStateIndex < previewStates.length - 1) {
                    currentPreviewStateIndex += 1;
                    setPageState(previewStates[currentPreviewStateIndex]);
                  }
                }}>
                Next
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default EmailLinkLogInPage;

const previewStates = Object.values(EmailLinkLogInPageState);
let currentPreviewStateIndex = 0;
