import { FirebaseError } from 'firebase/app';
import {
  ListenCustomer,
  ListenCustomerError,
} from '../../CardDecks/Common/AuthorizedAccess/customerStorage';
import { composeListenWithErrorLogging } from '../../ErrorHandling/composeWithErrorLogging';
import {
  FirestoreCustomer,
  isHandledListenCustomerFirebaseError,
  listenFirebaseCustomer,
  mapFirebaseErrorToListenCustomerError,
  mapFirestoreCustomerToCustomer,
} from '../../External/Firebase/FirebaseCustomerStorage';
import logErrorWithSentry from '../../External/Sentry/logErrorWithSentry';
import { mapResult, Result } from '../../Common/Types/Result';
import { Customer } from '../../CardDecks/Common/AuthorizedAccess/Customer';

const mapFirebaseResultToCustomerResult = (
  result: Result<FirestoreCustomer | null, unknown>
): Result<Customer | null, ListenCustomerError> => {
  return mapResult(
    result,
    mapFirestoreCustomerToCustomer,
    mapFirebaseErrorToListenCustomerError
  );
};

export const composeListenCustomerWithRemoteErrorLogging =
  (): ListenCustomer => {
    return composeListenWithErrorLogging(
      listenFirebaseCustomer,
      logErrorWithSentry,
      isHandledListenCustomerFirebaseError,
      mapFirebaseResultToCustomerResult
      //   new FirebaseError('auth/network-request-failed', 'Network request failed')
    );
  };
