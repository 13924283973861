import React from 'react';
import { IonButton } from '@ionic/react';

import './CardDeckCategoryFinderPromptComp.css';

import { classForVisible } from '../../../Common/UI/IonicCSSClasses';

interface CardDeckCategoryFinderPromptAnswerButtonProps {
  title: string;
  action: () => void;
  classNames: string;
}

const CardDeckCategoryFinderPromptAnswerButton: React.FC<
  CardDeckCategoryFinderPromptAnswerButtonProps
> = ({ title, action, classNames }) => {
  return (
    <IonButton
      fill="outline"
      onClick={action}
      className={`secondary ${classNames}`}>
      {title}
    </IonButton>
  );
};

interface CardDeckCategoryFinderPromptCompProps {
  index: number;
  text: string;
  isItemVisible: () => boolean;
  areButtonsVisible: () => boolean;
  isYesButtonSelected: () => boolean;
  onYesButtonTap: (index: number) => void;
  isNoButtonSelected: () => boolean;
  onNoButtonTap: (index: number) => void;
  setListItemElementRef: (element: HTMLElement | null) => void;
}

const CardDeckCategoryFinderPromptComp: React.FC<
  CardDeckCategoryFinderPromptCompProps
> = ({
  index,
  text,
  isItemVisible,
  areButtonsVisible,
  isYesButtonSelected,
  onYesButtonTap,
  isNoButtonSelected,
  onNoButtonTap,
  setListItemElementRef,
}) => {
  const classForVisibleItem = classForVisible(isItemVisible());
  const classForVisibleButtons = classForVisible(areButtonsVisible());
  const classForActive = (isActive: boolean) => (isActive ? 'selected' : '');

  return (
    <div
      className={`${classForVisibleItem} prompt-container`}
      ref={setListItemElementRef}>
      <div className="prompt-label">{text}</div>
      <div className={`prompt-button-group ${classForVisibleButtons}`}>
        <CardDeckCategoryFinderPromptAnswerButton
          title="Yes"
          action={() => {
            onYesButtonTap(index);
          }}
          classNames={`${classForActive(isYesButtonSelected())}`}
        />
        <CardDeckCategoryFinderPromptAnswerButton
          title="No"
          action={() => {
            onNoButtonTap(index);
          }}
          classNames={`${classForActive(isNoButtonSelected())}`}
        />
      </div>
    </div>
  );
};

export default CardDeckCategoryFinderPromptComp;

export { CardDeckCategoryFinderPromptAnswerButton as CardDeckSystemItemButton };
