import { IonButton } from '@ionic/react';

import { classForVisible } from '../../../Common/UI/IonicCSSClasses';
import PipCategoryLabel from '../../Common/PipCategoryLabel';

export interface CardDeckCategoryFinderCategoryAnswerCompProps {
  title: string;
  iconCode: string;
  iconColor: string;
  isVisible: boolean;
  onTap: () => void;
  setRef: (element: HTMLElement | null) => void;
}

const CardDeckCategoryFinderCategoryAnswerComp: React.FC<
  CardDeckCategoryFinderCategoryAnswerCompProps
> = ({ title, iconCode, iconColor, isVisible, onTap, setRef }) => {
  return (
    <>
      <div
        ref={setRef}
        className={`prompt-container ${classForVisible(isVisible)}`}>
        <div className="prompt-label">
          {`Use the '${title}' category to figure out your problem`}
        </div>
        <div className="prompt-button-group">
          <IonButton fill="outline" onClick={onTap} className="secondary">
            <PipCategoryLabel
              title={title}
              iconCode={iconCode}
              iconColor={iconColor}
            />
          </IonButton>
        </div>
      </div>
    </>
  );
};

export default CardDeckCategoryFinderCategoryAnswerComp;
