import { isSignInWithEmailLink } from 'firebase/auth';
import getFirebaseAuth from '../../External/Firebase/FirebaseAuth';

const isEmailLoginLink = (url: string) => {
  const auth = getFirebaseAuth();
  return isSignInWithEmailLink(auth, url);
};

const isLoginLinkWithStandaloneFlow = (urlString: string) => {
  const currentUrl = new URL(urlString);
  return currentUrl.searchParams.has('standaloneLoginFlow');
};

const appendStandaloneLoginFlowQueryParamTo = (url: URL) => {
  url.searchParams.append('standaloneLoginFlow', 'true');
};

const removeLoginLinkParamsFromAddressBar = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

export {
  isEmailLoginLink,
  isLoginLinkWithStandaloneFlow,
  appendStandaloneLoginFlowQueryParamTo,
  removeLoginLinkParamsFromAddressBar,
};
