import React from 'react';
import { useIonRouter } from '@ionic/react';

import './PipLogoHeader.css';

import BackButton from '../Common/Navigation/BackButton/BackButton';
import SearchButton from '../CardDecks/CardDeckCardsSearch/SearchButton';
import LogOutButton from '../Authentication/Logout/LogOutButton';
import BookmarksListNavigationButton from '../CardDecks/CardDeckBookmarks/BookmarksList/BookmarksListNavigationButton';
import ShopButton from './ShopButton';

import { CardDeckRoutes } from '../cardDecksRoutes';

const PipLogoHeader: React.FC = () => {
  const router = useIonRouter();

  return (
    <header>
      <div id="logo-header">
        <div className="header-container">
          <div className="top-left-buttons">
            <BackButton className="header-button" />
          </div>
          <div className="logo">
            <a
              href="/"
              className="logo"
              onClick={e => {
                e.preventDefault();
                router.push(CardDeckRoutes.root.resolve());
              }}>
              <img
                src="/images/pip-decks-logo.png"
                className="full"
                alt="Logo full"
              />
              <img
                src="/images/pip-logo.png"
                className="small"
                alt="Logo small"
              />
            </a>
          </div>
          <div className="top-right-buttons">
            <ShopButton />
            <BookmarksListNavigationButton />
            <SearchButton />
            <LogOutButton />
          </div>
        </div>
      </div>
    </header>
  );
};

export default PipLogoHeader;
