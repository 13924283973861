import { SolveMyProblemRoutes } from '../getSolveMyProblemRoutes';

const provideFocusBreadcrumbProps = (
  localize: (key: string) => string,
  isLink: boolean = true,
  challengeId: string
) => {
  const focusRoute = isLink
    ? SolveMyProblemRoutes.focus.resolve(challengeId)
    : undefined;
  return {
    route: focusRoute,
    text: localize('SolveMyProblemFocusPage_breadcrumb'),
  };
};

export default provideFocusBreadcrumbProps;
