import {
  getDecksUserCanAccess,
  getDecksUserCannotAccess,
} from '../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import { getCardDecks } from './cardDecks';

export const getUnlockedCardDecks = (deckIdsUserCanAcccess: Set<number>) => {
  const decks = getCardDecks();
  const decksUserCanAccess = getDecksUserCanAccess(
    deckIdsUserCanAcccess,
    decks
  );
  return decksUserCanAccess;
};

export const getLockedCardDecks = (deckIdsUserCanAcccess: Set<number>) => {
  const decks = getCardDecks();
  const decksUserCannotAccess = getDecksUserCannotAccess(
    deckIdsUserCanAcccess,
    decks
  );
  return decksUserCannotAccess;
};
