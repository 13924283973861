import React from 'react';

interface FontIconCompProps {
  className: string;
  color: string | undefined;
  iconCode: string;
}
const FontIconComp: React.FC<FontIconCompProps> = ({
  className,
  color,
  iconCode,
}) => {
  return (
    <span
      className={className}
      style={{ color: color }}
      dangerouslySetInnerHTML={{ __html: iconCode }}
      aria-hidden="true"
    />
  );
};

export default FontIconComp;
