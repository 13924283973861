import React from 'react';
import { IonPage } from '@ionic/react';

import './ContentLoadingPage.css';

import AppContentContainer from '../AppContentContainer';
import ContentLoadingIndicatorComp from './ContentLoadingIndicatorComp';

const ContentLoadingPage: React.FC = () => {
  return (
    <IonPage id="loading-content">
      <AppContentContainer>
        <ContentLoadingIndicatorComp />
      </AppContentContainer>
    </IonPage>
  );
};

export default ContentLoadingPage;
