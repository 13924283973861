import React from 'react';
import { useIonRouter } from '@ionic/react';
import { IonicReactProps } from '@ionic/react/dist/types/components/IonicReactProps';

interface RouterLinkProps extends IonicReactProps {
  href: string;
  children: React.ReactNode;
}

const RouterLink: React.FC<RouterLinkProps> = ({
  href,
  children,
  className,
  style,
}) => {
  const ionRouter = useIonRouter();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (event.metaKey || event.ctrlKey) {
      return;
    }

    event.preventDefault();
    ionRouter.push(href);
  };

  return (
    <span
      className={`routerLinkContainer${className ? ` ${className}` : ''}`}
      style={style}>
      <a href={href} className="routerLink" onClick={handleClick}>
        {children}
      </a>
    </span>
  );
};

export default RouterLink;
