import Fuse from 'fuse.js';
import { getCardDeckRecipeCardSearchItems } from '../CardDeckCard/RecipeCard/cardDeckRecipeCard';
import { getCardDeckTacticCardSearchItems } from '../CardDeckCard/TacticCard/cardDeckTacticCard';

let cachedFuseInstance: Fuse<any> | null;
let deckIdsForCachedFuseInstance: Set<number> = new Set();
export const getFuseSearchEngine = (deckIds: Set<number>): Fuse<any> => {
  if (
    !cachedFuseInstance ||
    !areSetsEqual(deckIds, deckIdsForCachedFuseInstance)
  ) {
    cachedFuseInstance = initFuse(deckIds);
    deckIdsForCachedFuseInstance = deckIds;
  }
  return cachedFuseInstance!;
};

const initFuse = (deckIds: Set<number>): Fuse<any> => {
  const deckIdsArray = Array.from(deckIds);
  const filteredTacticCards = getCardDeckTacticCardSearchItems(deckIdsArray);
  const filteredRecipeCards = getCardDeckRecipeCardSearchItems(deckIdsArray);
  const allCards = (filteredTacticCards as any[]).concat(
    filteredRecipeCards as any[]
  );
  const fuseOptions = {
    keys: [
      { name: 'title', weight: 1.0 },
      { name: 'summary', weight: 0.5 },
      { name: 'description', weight: 0.5 },
      { name: 'origin', weight: 0.05 },
      { name: 'items.title', weight: 0.2 },
      { name: 'items.summary', weight: 0.1 },
    ],
    includeScore: true,
    threshold: 0.0,
    includeMatches: true,
    ignoreLocation: true,
  };
  const fuse = new Fuse(allCards, fuseOptions);
  return fuse;
};

function areSetsEqual(firstSet: Set<number>, secondSet: Set<number>): boolean {
  if (firstSet.size !== secondSet.size) return false;
  for (let elementInFirstSet of firstSet) {
    if (!secondSet.has(elementInFirstSet)) {
      return false;
    }
  }
  return true;
}
