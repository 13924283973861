import { replaceHtmlTagsInObjectProperties } from '../../../Common/Object/object';
import { tacticCards } from '../../../Content/tacticCardsContent';
import { GuideLink } from '../Common/GuideLink';

export interface CardDeckTacticCard {
  id: number;
  categoryId: number;
  title: string;
  summary: string;
  description: string;
  imageUrl: string;
  origin: string;
  walkthroughVideo: string;
  whiteboardTemplatesSubheading: string;
  miroBoardId: string;
  muralTemplateId: string;
  color: string;
  guidesLinks: GuideLink[];
}

export const emptyTacticCard = {
  id: 0,
  categoryId: 0,
  title: '',
  summary: '',
  description: '',
  imageUrl: '',
  origin: '',
  walkthroughVideo: '',
  whiteboardTemplatesSubheading: '',
  miroBoardId: '',
  muralTemplateId: '',
  color: '',
  guidesLinks: [],
};

const typedCards: { [key: string]: CardDeckTacticCard[] } =
  tacticCards as unknown as { [key: string]: CardDeckTacticCard[] };

export const getCardDeckTacticCard = (deckId: string, cardId: number) => {
  return typedCards[deckId]?.find(card => card.id == cardId);
};

export interface CardDeckTacticCardSearchItem extends CardDeckTacticCard {
  deckId: number;
}

export const getCardDeckTacticCardSearchItems = (deckIds: number[]) => {
  const tacticCardArraysFromRequestedDecks = deckIds.map(deckId => {
    return typedCards[deckId];
  });
  const rawSearchItems =
    tacticCardArraysFromRequestedDecks.flat() as unknown as CardDeckTacticCardSearchItem[];
  const cleanedUpSearchItems = rawSearchItems.map(searchItem =>
    replaceHtmlTagsInObjectProperties(
      searchItem,
      ['title', 'summary', 'description'],
      ' '
    )
  );
  return cleanedUpSearchItems;
};
