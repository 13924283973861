import { useRef, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import {
  useIonViewWillEnter,
  IonSearchbar,
  IonList,
  IonPage,
  useIonViewDidEnter,
  ScrollDetail,
  IonButton,
  useIonViewDidLeave,
  useIonRouter,
} from '@ionic/react';

import './CardDeckCardsSearchPage.css';

import Fuse from 'fuse.js';
import { getFuseSearchEngine } from './initFuse';

import AppContentContainer from '../../Common/AppContentContainer';
import Breadcrumbs from '../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import HomeBreadcrumb from '../Common/Breadcrumbs/PipLogoBreadcrumb';
import Breadcrumb from '../../Common/Navigation/Breadcrumbs/Breadcrumb';
import ContentLoadingComp, {
  ContentLoadingState,
} from '../../Common/ContentLoading/ContentLoadingComp';
import provideHomeBreadcrumbProps from '../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import { getDeckIdsUserCanAccess } from '../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import AuthorizedDecksContentAccessContainer from '../Common/AuthorizedAccess/AuthorizedDecksContentAccessContainer';
import { useAuthenticatedCustomer } from '../Common/AuthorizedAccess/AuthenticatedCustomerContext';

import { CardDeckCardSearchResult } from './CardDeckCardSearchModels';
import SearchResultListItem from './SearchResultListItem';
import { consumeResetSearchPageArgument } from './searchPageArguments';

import {
  CardDeckSearchResultsViewModel,
  getCardDeckSearchResults,
} from './getCardDeckSearchResults';
import { cardSearchStrings } from './cardSearchStrings';
import CardDeckSearchResultsLoadingOrEmptyComp from './CardDeckSearchResultsLoadingOrEmptyComp';

const setDocumentTitle = () => {
  document.title = 'Search Pip Decks Cards';
};

const CardDeckCardsSearchPage: React.FC = () => {
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const [readyToInitSearchEngine, setReadyToInitSearchEngine] =
    useState<boolean>(false);
  const [isViewVisible, setIsViewVisible] = useState<boolean>(false);

  const location = useLocation();
  const [searchEngine, setSearchEngine] = useState<Fuse<any> | null>(null);

  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);
  const listRef = useRef<HTMLIonListElement>(null);

  const [searchResultsMessage, setSearchResultsMessage] = useState<
    string | null
  >(null);
  const [isSpinnerHidden, setIsSpinnerHidden] = useState<boolean>(true);

  const [allSearchResults, setAllSearchResults] = useState<
    [CardDeckCardSearchResult[], string]
  >([[], '']);
  const [renderedSearchResults, setRenderedSearchResults] = useState<
    [CardDeckCardSearchResult[], string]
  >([[], '']);
  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // used to prevent triggering multiple page loads
  // while the previous one is being rendered
  const lastLoadedItemsContentHeightRef = useRef(0);

  const updateSearchResultsMessageUI = (message: string | null) => {
    setSearchResultsMessage(message);
  };

  const updateRenderedResults = () => {
    const updatedRenderedSearchResults = allSearchResults[0].slice(
      0,
      currentPage * pageSize
    );
    setRenderedSearchResults([
      updatedRenderedSearchResults,
      allSearchResults[1],
    ]);
  };

  useEffect(() => {
    if (currentPage == 1) {
      // allSearchResults triggers the load of the first page
      return;
    }

    updateRenderedResults();
  }, [currentPage]);

  useEffect(() => {
    resetPagingProperties();
    updateRenderedResults();
  }, [allSearchResults]);

  const resetPagingProperties = () => {
    setTotalPages(Math.ceil(allSearchResults[0].length / pageSize));
    setCurrentPage(1);
    lastLoadedItemsContentHeightRef.current = 0;
  };

  function clearSearchBar() {
    if (searchBarRef.current != null) {
      searchBarRef.current.value = '';
    }
  }
  const resetSearchState = () => {
    clearSearchBar();
    resetDisplayedResults();
    setIsSpinnerHidden(true);
  };

  const resetStateIfResetArgumentSet = () => {
    const shouldReset = consumeResetSearchPageArgument(location.pathname);
    if (shouldReset) {
      resetSearchState();
    }
  };

  const router = useIonRouter();
  const customer = useAuthenticatedCustomer();

  useEffect(() => {
    if (readyToInitSearchEngine) {
      const deckIds = getDeckIdsUserCanAccess(customer);

      if (deckIds) {
        setSearchEngine(getFuseSearchEngine(deckIds));
        setLoadingState(ContentLoadingState.Loaded);
      }
      // else still loading or an error will be handled by AuthorizedDecksContentAccessContainer
    }
  }, [readyToInitSearchEngine, customer]);

  useIonViewWillEnter(() => {
    resetStateIfResetArgumentSet();
    setReadyToInitSearchEngine(true);
    setDocumentTitle();
  });

  useIonViewDidEnter(() => {
    setIsViewVisible(true);
  });

  useIonViewDidLeave(() => {
    setIsViewVisible(false);
  });

  useEffect(() => {
    // this can result in an unexpected search bar focus when search engine
    // is reinitialized due decks access change, but that should happen
    // rarely enough that it doesn't make sense to add complexity to address it
    if (searchEngine && isViewVisible) {
      setTimeout(focusSearchBar, 300);
    }
  }, [searchEngine, isViewVisible]);

  const focusSearchBar = () => {
    if (searchBarRef.current != null) {
      searchBarRef.current.setFocus();
    }
  };

  const displayResults = (searchResults: CardDeckSearchResultsViewModel) => {
    setAllSearchResults([searchResults.results, searchResults.searchValue]);
    updateSearchResultsMessageUI(searchResults.message);
  };

  const handleSearch = (value: string) => {
    // console.log('searching');
    const searchResults = getCardDeckSearchResults(value, searchEngine);
    // console.log('displaying results');
    setIsSpinnerHidden(true);
    displayResults(searchResults);
  };

  const resetDisplayedResults = () => {
    // console.log('reseting results');
    displayResults({
      results: [],
      message: null,
      searchValue: '',
    });
  };

  const showSpinner = () => {
    resetDisplayedResults();
    setIsSpinnerHidden(false);
  };

  // need to cache the function across renders so that
  // it can be removed as a listener by reference
  const cachedShowSpinner = useCallback(showSpinner, []);

  // IonInput event is debounced and we want to show
  // the spinner when the user starts typing, so we need
  // to add a listener to the underlying input element
  const addResetResultsOnSourceInputHandler = () => {
    withUnderlyingInputElement(searchBarRef, input => {
      input?.addEventListener('input', cachedShowSpinner);
    });
  };
  const removeResetResultsOnSourceInputHandler = () => {
    withUnderlyingInputElement(searchBarRef, input => {
      input?.removeEventListener('input', cachedShowSpinner);
    });
  };

  const onContentScroll = (
    ev: CustomEvent<ScrollDetail>,
    contentRef: React.RefObject<HTMLIonContentElement>
  ) => {
    if (!contentRef.current || !listRef.current) {
      return;
    }
    const scrollFromTop = ev.detail.scrollTop;
    const containerHeight = contentRef.current.clientHeight;
    const contentHeight = listRef.current?.clientHeight;
    if (contentHeight == lastLoadedItemsContentHeightRef.current) {
      return;
    }
    if (
      scrollFromTop + containerHeight >= contentHeight - 50 &&
      currentPage < totalPages
    ) {
      lastLoadedItemsContentHeightRef.current = contentHeight;
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <IonPage id="search">
      <AppContentContainer onContentScroll={onContentScroll}>
        <AuthorizedDecksContentAccessContainer>
          <ContentLoadingComp
            loadingState={loadingState}
            loadedContent={
              <>
                <Breadcrumbs>
                  <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
                  <Breadcrumb>Search</Breadcrumb>
                </Breadcrumbs>
                <IonSearchbar
                  ref={searchBarRef}
                  placeholder={cardSearchStrings.searchBarPlaceholder}
                  showCancelButton="never"
                  className="search-bar"
                  debounce={300}
                  onIonClear={() => {
                    // instead of waiting for debounce to kick the IonInput event
                    // clear the search term immediately
                    resetDisplayedResults();
                    setIsSpinnerHidden(true);
                  }}
                  onIonFocus={addResetResultsOnSourceInputHandler}
                  onIonBlur={removeResetResultsOnSourceInputHandler}
                  onIonInput={event => {
                    //console.log(`input triggered: ${event.target.value}`);
                    const searchValue = event.target.value ?? '';
                    handleSearch(searchValue);
                  }}
                />
                {renderedSearchResults[0].length === 0 && (
                  <CardDeckSearchResultsLoadingOrEmptyComp
                    message={
                      searchResultsMessage ??
                      'Enter a card title, keyword or phrase...'
                    }
                    isLoading={!isSpinnerHidden}
                  />
                )}
                {renderedSearchResults.length > 0 && isSpinnerHidden && (
                  <IonList ref={listRef} className="hotjar-adjust">
                    {renderedSearchResults[0].map((searchResult, index) => (
                      <SearchResultListItem
                        key={index}
                        searchResult={searchResult}
                        searchTerm={renderedSearchResults[1]}
                      />
                    ))}
                    {currentPage < totalPages && (
                      <IonButton
                        expand="full"
                        onClick={() => setCurrentPage(currentPage + 1)}
                      >
                        Load More
                      </IonButton>
                    )}
                  </IonList>
                )}
              </>
            }
          />
        </AuthorizedDecksContentAccessContainer>
      </AppContentContainer>
    </IonPage>
  );
};

export default CardDeckCardsSearchPage;

const withUnderlyingInputElement = (
  searchBarRef: React.RefObject<HTMLIonSearchbarElement>,
  handleInput: (input: HTMLInputElement) => void
) => {
  const searchBar = searchBarRef.current;
  if (searchBar) {
    searchBar.getInputElement().then(input => {
      handleInput(input);
    });
  }
};
