import { IonButton, IonIcon } from '@ionic/react';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface IconButtonProps extends IonButtonProps {
  iconSrc: string;
  onClick: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  iconSrc,
  onClick,
  ...rest
}) => {
  const { className, ...restProps } = rest;

  return (
    <IonButton
      onClick={onClick}
      className={`${className} icon-button`}
      {...restProps}>
      <IonIcon src={iconSrc} />
    </IonButton>
  );
};

export default IconButton;
