import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';

import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import PipCategoryLabel from '../../Common/PipCategoryLabel';

import { CardDeckRoutes } from '../../../cardDecksRoutes';
import provideDeckBreadcrumbProps from '../../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';

import {
  CardDeckCardListSection,
  getCardDeckRecipes,
  recipeCategoryAttr,
} from '../cardDeckCardListSections';

import CardDeckCardListPage, {
  getCardListDocumentTitle,
} from '../CardDeckCardListPage';
import CardDeckCardsGridPage from '../CardDeckCardsGridPage';

const setDocumentTitle = (deckId: string) => {
  document.title = getCardListDocumentTitle(deckId, 'Recipes');
};

const CardDeckRecipesGridPage: React.FC = () => {
  // console.log(`Loading card list`);
  const [sections, setSections] = useState<
    CardDeckCardListSection[] | null | undefined
  >(undefined);
  const { deckId, categoryId } = useParams<{
    deckId: string;
    categoryId: string;
  }>();

  const lastBreadcrumb = (
    <PipCategoryLabel
      title={recipeCategoryAttr.title}
      iconCode={recipeCategoryAttr.iconCode}
    />
  );
  const getCardDetailsRoute = CardDeckRoutes.cardDeckRecipeCard.resolve;

  useIonViewWillEnter(() => {
    // console.log(`Loading deck with id ${deckId}`);
    let sections = getCardDeckRecipes(deckId) ?? null;
    setSections(sections);

    setDocumentTitle(deckId);
  }, [deckId, categoryId]);

  return (
    <CardDeckCardsGridPage
      deckId={deckId}
      breadcrumbs={
        <Breadcrumbs>
          <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
          <TextBreadcrumb {...provideDeckBreadcrumbProps(deckId)} />
          <Breadcrumb>{lastBreadcrumb}</Breadcrumb>
        </Breadcrumbs>
      }
      cardListSections={sections}
      shouldShowCategorySectionTitle={false}
      getCardDetailsRoute={getCardDetailsRoute}
    />
  );
};

export default CardDeckRecipesGridPage;
