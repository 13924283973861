import React from 'react';
import { IonIcon } from '@ionic/react';
import { lockClosed } from 'ionicons/icons';

import './UnlockButton.css';

interface UnlockButtonProps {
  onClick: () => void;
}

import { useTranslation } from 'react-i18next';

const UnlockButton: React.FC<UnlockButtonProps> = ({ onClick }) => {
  const { t, ready: isTReady } = useTranslation('content-access');

  return isTReady ? (
    <button className="unlock" onClick={onClick}>
      <IonIcon icon={lockClosed} className="icon" />
      <span className="title">{t('HomeLockedDeckCell_unlockButtonTitle')}</span>
    </button>
  ) : null;
};

export default UnlockButton;
