import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';

import { useAuthenticatedUser } from '../../../Authentication/AuthenticatedUserContext';
import { tagToDeckIdMap } from '../../../External/Firebase/FirebaseCustomerStorage';
import {
  DataLoadingResult,
  errorDataLoadingResult,
  loadingDataLoadingResult,
  successDataLoadingResult,
} from '../../../Common/Types/DataLoadingResult';
import { isResultError, isResultOk } from '../../../Common/Types/Result';
import { composeListenCustomerWithRemoteErrorLogging } from '../../../CompositionRoot/AuthorizedAccess/composeCustomerStorageWithErrorLogging';

import { Customer } from './Customer';
import { ListenCustomerError } from './customerStorage';

export type CustomerResult = DataLoadingResult<
  Customer | null,
  ListenCustomerError
>;

const AuthenticatedCustomerContext = createContext<CustomerResult>(
  loadingDataLoadingResult()
);

export const useAuthenticatedCustomer = () =>
  useContext(AuthenticatedCustomerContext);

interface AuthenticatedCustomerProviderProps {
  children: React.ReactNode;
}

export const AuthenticatedCustomerProvider: React.FC<
  AuthenticatedCustomerProviderProps
> = ({ children }) => {
  const [customer, setCustomer] = useState<CustomerResult>(
    loadingDataLoadingResult()
  );
  const user = useAuthenticatedUser();
  const unsubscribeRef = useRef(() => {});
  const [loadRetryCount, setLoadRetryCount] = useState(-1);
  const listenCustomerWithLogging =
    composeListenCustomerWithRemoteErrorLogging();

  const startCustomerListener = (userEmail: string) => {
    return listenCustomerWithLogging(userEmail, customerResult => {
      if (isResultError(customerResult)) {
        setCustomer(
          errorDataLoadingResult(customerResult.error, () => {
            setLoadRetryCount(count => count + 1);
          })
        );
      } else if (isResultOk(customerResult)) {
        setCustomer(successDataLoadingResult(customerResult.data));
      }
    });
  };

  useEffect(() => {
    if (user) {
      // console.log('fetching customer data');
      const userEmail = user.email;
      if (!userEmail) {
        // unexpected since we require email for login
        setCustomer(successDataLoadingResult(null));
        // console.log('email not found');
        return;
      }

      // allows token based authorization on internal builds
      if (import.meta.env.VITE_INTERNAL_BUILD_AUTH_CHECK === 'true') {
        const token = localStorage.getItem('token');
        if (
          user.email === 'internal@pipdecks.com' &&
          token === import.meta.env.VITE_INTERNAL_ACCESS_TOKEN
        ) {
          setCustomer(
            successDataLoadingResult({
              email: 'internal@pipdecks.com',
              deckIdsUserCanAccess: new Set(
                Object.values(tagToDeckIdMap).filter(id => !!id)
              ) as Set<number>,
              isProMember: true,
            })
          );
          return;
        }
      }

      const timeoutId = setTimeout(() => {
        // console.log(`Retrying customer load: iteration ${loadRetryCount}`);
        unsubscribeRef.current();
        unsubscribeRef.current = startCustomerListener(userEmail);
      }, Math.pow(2, loadRetryCount) * 1000);

      return () => {
        clearTimeout(timeoutId);
        unsubscribeRef.current();
      };
    } else if (user === undefined) {
      // user is undefined
      // keep loading
    } else {
      // user is null, the content should blocked by the login from
      // console.log('no user');
    }
  }, [user, loadRetryCount]);

  return (
    <AuthenticatedCustomerContext.Provider value={customer}>
      {children}
    </AuthenticatedCustomerContext.Provider>
  );
};
