import { useEffect, useMemo } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { usePostHog } from 'posthog-js/react';

import './i18n';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/ionic-light.css';
import './theme/pip-light.css';
import './theme/custom.css';
import './theme/content-link.css';
import './theme/fonts.css';

import { CardDeckRoutes } from './cardDecksRoutes';
import HomePage from './CardDecks/Home/HomePage';
import CardDeckTacticCardPage from './CardDecks/CardDeckCard/TacticCard/CardDeckTacticCardPage';
import CardDeckRecipeCardPage from './CardDecks/CardDeckCard/RecipeCard/CardDeckRecipeCardPage';
import CardDeckCardsSearchPage from './CardDecks/CardDeckCardsSearch/CardDeckCardsSearchPage';
import CardDeckHomePage from './CardDecks/CardDeckHome/CardDeckHomePage';
import CardDeckCategoryTacticsGridPage from './CardDecks/CardDeckCardList/Pages/CardDeckCategoryTacticsGridPage';
import CardDeckTacticsGridPage from './CardDecks/CardDeckCardList/Pages/CardDeckTacticsGridPage';
import CardDeckRecipesGridPage from './CardDecks/CardDeckCardList/Pages/CardDeckRecipesGridPage';
import CadDeckCategoryFinderPage from './CardDecks/CadDeckCategoryFinder/CadDeckCategoryFinderPage';

import ProtectedPageContainer from './Authentication/ProtectedPageContainer';
import getSolveMyProblemRoutes from './CardDecks/SolveMyProblem/Common/getSolveMyProblemRoutes';
import { AuthenticatedCustomerProvider } from './CardDecks/Common/AuthorizedAccess/AuthenticatedCustomerContext';
import { AuthenticatedUserProvider } from './Authentication/AuthenticatedUserContext';
import TrackCustomerComp from './Analytics/Auth/TrackCustomerComp';
import PageViewTracker from './Analytics/PageViewTracker';
import CardDeckCardBookmarksPage from './CardDecks/CardDeckBookmarks/BookmarksList/CardDeckCardBookmarksPage';
import NetworkStatusModal from './Common/NetworkStatus/NetworkStatusModal';
import { NetworkStatusProvider } from './Common/NetworkStatus/NetworkStatusContext';
import NotFoundPage from './Common/Pages/NotFoundPage';
import {
  addOnlineStatusListeners,
  getInitialOnlineStatus,
} from './Common/NetworkStatus/networkStatusUtils';
import EmailLinkLogInPage, {
  EmailLinkLogInPageState,
} from './Authentication/EmailLinkLogin/EmailLinkLogInPage';
import { checkIfStandaloneMode } from './Common/Platform/StandaloneMode';
import trackAppModeWithEachEvent from './Analytics/Platform/trackAppMode';
import { addAuthStateChangeObserverWithManualRelease } from './Authentication/EmailLinkLogin/authStateChangeObserverWithManualRelease';
import composeSubscribeToUserChangedEventWithIdentityTracking from './CompositionRoot/Analytics/getSubscribeToUserChangedEventWithIdentityTracking';
import composeTrackUserIdentityWithPostHog from './External/PostHog/trackUserIdentity';
import composeAddPropertiesToAllTrackedEventsInSessionWithPostHog from './External/PostHog/trackUserPropertiesInSessionWithPostHog';
import { User } from './Authentication/User';

import getFirebasePerformance from './External/Firebase/FirebasePerformance';
import './External/Sentry/initSentry';

setupIonicReact({
  swipeBackEnabled: false,
  animated: false,
  mode: 'ios',
});

const RedirectToWithQueryParams = ({ to }: { to: string }) => {
  const location = useLocation();
  const toPathWithQuery = `${to}${location.search}`;
  return <Redirect to={toPathWithQuery} />;
};

const App: React.FC = () => {
  const postHog = usePostHog();

  useEffect(() => {
    if (postHog) {
      const addPropertiesToAllTrackedEventsInSession =
        composeAddPropertiesToAllTrackedEventsInSessionWithPostHog(postHog);
      trackAppModeWithEachEvent(
        addPropertiesToAllTrackedEventsInSession,
        checkIfStandaloneMode
      );
    }
  }, [postHog]);

  const subscribeToUserChanged = useMemo(() => {
    if (!postHog) {
      return undefined;
    }
    const trackUserIdentity = composeTrackUserIdentityWithPostHog(postHog);
    return (onUserChanged: (user: User | null) => void): (() => void) => {
      return composeSubscribeToUserChangedEventWithIdentityTracking(
        addAuthStateChangeObserverWithManualRelease,
        onUserChanged,
        trackUserIdentity
      )();
    };
  }, [postHog]);

  // Initialize Performance Monitoring
  const perf = getFirebasePerformance();

  return (
    <NetworkStatusProvider
      getInitialOnlineStatus={getInitialOnlineStatus}
      addOnlineStatusListeners={addOnlineStatusListeners}
    >
      <AuthenticatedUserProvider listenUser={subscribeToUserChanged}>
        <AuthenticatedCustomerProvider>
          <IonApp>
            <IonReactRouter>
              <PageViewTracker />
              <IonRouterOutlet>
                <Route path={CardDeckRoutes.cardDecks.path} exact={true}>
                  <RedirectToWithQueryParams to={CardDeckRoutes.home.path} />
                </Route>
                <Route path={CardDeckRoutes.root.path} exact={true}>
                  <RedirectToWithQueryParams to={CardDeckRoutes.home.path} />
                </Route>
                <Route path={CardDeckRoutes.home.path} exact={true}>
                  <ProtectedPageContainer>
                    <HomePage />
                  </ProtectedPageContainer>
                </Route>
                <Route path={CardDeckRoutes.cardDeck.path} exact={true}>
                  <ProtectedPageContainer>
                    <CardDeckHomePage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckCategoryFinder.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CadDeckCategoryFinderPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckTacticCardList.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckTacticsGridPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckRecipeCardList.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckRecipesGridPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckCategoryTacticCardList.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckCategoryTacticsGridPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckTacticCard.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckTacticCardPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDeckRecipeCard.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckRecipeCardPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDecksCardSearch.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckCardsSearchPage />
                  </ProtectedPageContainer>
                </Route>
                <Route
                  path={CardDeckRoutes.cardDecksSavedCards.path}
                  exact={true}
                >
                  <ProtectedPageContainer>
                    <CardDeckCardBookmarksPage />
                  </ProtectedPageContainer>
                </Route>
                {getSolveMyProblemRoutes()}
                {/* for debugging */}
                {/* <Route path="/login-pages" exact={true}>
                  <EmailLinkLogInPage
                    onContinueToApp={function (): void {
                      console.log('continue to app');
                    }}
                    initialState={EmailLinkLogInPageState.EmailForm}
                    shouldShowStatesPreviewNavigation={true}
                  />
                </Route> */}
                <Route component={NotFoundPage}></Route>
              </IonRouterOutlet>
            </IonReactRouter>
            <NetworkStatusModal />
          </IonApp>
          <TrackCustomerComp />
        </AuthenticatedCustomerProvider>
      </AuthenticatedUserProvider>
    </NetworkStatusProvider>
  );
};

export default App;
