import { IonIcon, IonItem, IonLabel } from '@ionic/react';

interface EmailSentHeaderItemProps {
  title: string;
  icon: string;
}

const EmailSentHeaderItem: React.FC<EmailSentHeaderItemProps> = ({
  title,
  icon,
}) => {
  return (
    <IonItem lines="none" className="header-item">
      <IonIcon icon={icon} slot="start"></IonIcon>
      <IonLabel className="ion-text-wrap">
        <h1>{title}</h1>
      </IonLabel>
    </IonItem>
  );
};

export default EmailSentHeaderItem;
