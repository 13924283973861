import React from 'react';
import { IonIcon } from '@ionic/react';
import BackArrow from '/public/images/icons/back-arrow.svg';

interface LeftArrowIconProps {
  rotationDegree?: number;
}

const LeftArrowIcon: React.FC<LeftArrowIconProps> = ({
  rotationDegree = 0,
}) => (
  // TODO: replace with an icon from Figma
  // the issue is that it scales down and doesn't look
  // as good as the hardcoded svg so it needs more tweaking
  // <IonIcon
  //   icon={BackArrow}
  //   className="button-icon"
  //   style={{
  //     transform: `rotate(${rotationDegree}deg)`,
  //     transformOrigin: 'center',
  //   }}
  // />
  //
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${rotationDegree}deg)`,
      transformOrigin: 'center',
    }}>
    <path
      d="M20 12H4"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18L4 12L10 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeftArrowIcon;
