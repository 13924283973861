import React, { useEffect, useState } from 'react';

import ContentLoadingComp, {
  ContentLoadingState,
} from '../../../Common/ContentLoading/ContentLoadingComp';
import {
  isDataLoadingResultSuccess,
  isDataLoadingResultError,
} from '../../../Common/Types/DataLoadingResult';
import { useAuthenticatedCustomer } from './AuthenticatedCustomerContext';
import { getDeckIdsUserCanAccessResult } from './cardDeckAuthorizedAccessFunctions';
import PurchaseDeckAccessCallToAction from './UnauthorizedAccess/PurchaseDeckAccessCallToAction';
import AuthenticatedCustomerLoadingErrorComp from './UnauthorizedAccess/AuthenticatedCustomerLoadingErrorComp';

interface AuthorizedDeckContentAccessContainerProps {
  deckId: string;
  children: React.ReactNode;
}

const AuthorizedDeckContentAccessContainer: React.FC<
  AuthorizedDeckContentAccessContainerProps
> = ({ deckId, children }) => {
  const [loadingState, setLoadingState] = useState<ContentLoadingState>(
    ContentLoadingState.Loading
  );
  const [errorContent, setErrorContent] = useState<React.ReactNode>(null);
  const customer = useAuthenticatedCustomer();
  const deckIdNumber = Number(deckId);

  useEffect(() => {
    const deckIdsResult = getDeckIdsUserCanAccessResult(customer);

    if (isDataLoadingResultSuccess(deckIdsResult)) {
      if (deckIdsResult.data && deckIdsResult.data.has(deckIdNumber)) {
        setLoadingState(ContentLoadingState.Loaded);
      } else {
        setErrorContent(<PurchaseDeckAccessCallToAction deckId={deckId} />);
        setLoadingState(ContentLoadingState.Error);
      }
    } else if (isDataLoadingResultError(deckIdsResult)) {
      setErrorContent(
        <AuthenticatedCustomerLoadingErrorComp
          onRetryButtonClick={() => {
            setLoadingState(ContentLoadingState.Loading);
            deckIdsResult.error!.retry();
          }}
        />
      );
      setLoadingState(ContentLoadingState.Error);
    }
    // else still loading
  }, [customer, deckId]);

  return (
    <ContentLoadingComp
      loadingState={loadingState}
      loadedContent={children}
      errorContent={errorContent}
    />
  );
};

export default AuthorizedDeckContentAccessContainer;
