import React from 'react';
import './HomeDeckCell.css';
import ClickableDiv from '../../../Common/UI/Buttons/ClickableDiv';
import UnlockButton from './UnlockButton';

interface HomeDeckCellProps {
  imageSource: string;
  title: string;
  description: string;
  deckRoute: string;
  onUnlockClick?: () => void;
}

const HomeDeckCell: React.FC<HomeDeckCellProps> = ({
  imageSource,
  title,
  description,
  deckRoute,
  onUnlockClick,
}) => {
  const isLocked = !!onUnlockClick;
  return (
    <ClickableDiv
      className={`home-deck-cell${isLocked ? ' locked' : ''}`}
      onClickRouteTo={isLocked ? undefined : deckRoute}
      onClick={!isLocked ? undefined : onUnlockClick}
      tabIndex={isLocked ? -1 : 0}>
      <div>
        <img src={imageSource} className="image" aria-hidden="true" />
        <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: description }}></p>
      </div>
      {onUnlockClick && <UnlockButton onClick={onUnlockClick} />}
    </ClickableDiv>
  );
};

export default HomeDeckCell;
