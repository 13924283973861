import React, { useEffect, useRef } from 'react';
import { loadVimeoScript } from './loadVimeoScript';
import { useLocation } from 'react-router';

interface VimeoVideoPlayerProps {
  videoId: string;
  videoPrivacyHash?: string;
}

const VimeoVideoPlayer: React.FC<VimeoVideoPlayerProps> = ({
  videoId,
  videoPrivacyHash,
}) => {
  const playerIFrameRef = useRef<HTMLIFrameElement>(null);
  const location = useLocation();

  const videoFrameUrl = getVideoFrameUrl(videoId, videoPrivacyHash);

  // stops and resets the player if user leaves the page
  useEffect(() => {
    if (window.Vimeo && window.Vimeo.Player && playerIFrameRef.current) {
      const player = new Vimeo.Player(playerIFrameRef.current!);
      player.unload();
    }
  }, [location]);

  loadVimeoScript();

  return (
    <div
      style={{
        padding: '62.5% 0 0 0',
        position: 'relative',
        margin: '1em 0 1em 0',
      }}>
      <iframe
        ref={playerIFrameRef}
        src={videoFrameUrl}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}></iframe>
    </div>
  );
};

export default VimeoVideoPlayer;

const getVideoFrameUrl = (videoId: string, videoPrivacyHash?: string) => {
  const url = new URL('https://player.vimeo.com/video');
  url.pathname += `/${videoId}/`;
  const searchParams = url.searchParams;
  searchParams.set('badge', '0');
  searchParams.set('autopause', '0');
  searchParams.set('player_id', '0');
  searchParams.set('app_id', '58479');
  if (videoPrivacyHash) {
    searchParams.set('h', videoPrivacyHash);
  }
  return url.toString();
};
