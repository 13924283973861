import React, { useState } from 'react';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import './AlreadyCustomerComp.css';
import { useAuthenticatedUser } from '../../../../Authentication/AuthenticatedUserContext';
import { useLogout } from '../../../../Authentication/Logout/useLogout';
import HtmlToReactComp from '../../../../Common/UI/HTML/HtmlToReactComp';
import composeTrackUserActionWithPostHog from '../../../../External/PostHog/trackUserActionWithPostHog';

const AlreadyCustomerComp: React.FC = () => {
  const user = useAuthenticatedUser();
  const email = user?.email;
  const handleLogout = useLogout();
  const [showClarification, setShowClarification] = useState(false);
  const { t, ready: isTReady } = useTranslation('content-access');
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);

  const handleAlreadyPurchasedClick = () => {
    setShowClarification(true);
    trackAction('claims already purchased');
  };

  return isTReady && email ? (
    <div className="already-customer">
      {!showClarification && (
        <IonButton
          onClick={handleAlreadyPurchasedClick}
          fill="clear"
          size="default">
          {t('NewCustomerCallToAction_AlreadyPurchasedButton')}
        </IonButton>
      )}
      {showClarification && (
        <>
          <p className="already-purchased-message">
            <HtmlToReactComp
              html={t('NewCustomerCallToAction_AlreadyPurchasedMessage1', {
                email: email,
              })}
            />
          </p>
          <p className="already-purchased-message">
            <HtmlToReactComp
              html={t('NewCustomerCallToAction_AlreadyPurchasedMessage2')}
            />
          </p>
          <IonButton size="default" fill="clear" onClick={handleLogout}>
            {t('NewCustomerCallToAction_AlreadyPurchasedLogoutButton')}
          </IonButton>
        </>
      )}
    </div>
  ) : null;
};

export default AlreadyCustomerComp;
