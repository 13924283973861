import React from 'react';
import { IonSpinner } from '@ionic/react';

import './ContentLoadingIndicatorComp.css';

interface ContentLoadingIndicatorCompProps {
  className?: string;
}

const ContentLoadingIndicatorComp: React.FC<
  ContentLoadingIndicatorCompProps
> = ({ className }) => {
  return (
    <div className={`spinner-container ion-text-center ${className || ''}`}>
      <IonSpinner />
    </div>
  );
};

export default ContentLoadingIndicatorComp;
