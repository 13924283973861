import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import PostHogAnalyticsProvider from './Analytics/PostHogAnalyticsProvider';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // PostHogProvider doesn't handle re-renders properly in StrictMode
  // so we need to keep it out of StrictMode.
  <PostHogAnalyticsProvider>
    <StrictMode>
      <App />
    </StrictMode>
  </PostHogAnalyticsProvider>
);
