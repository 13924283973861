import React from 'react';
import Breadcrumb from './Breadcrumb';

interface TextBreadcrumbProps {
  route?: string;
  text: string;
}

const TextBreadcrumb: React.FC<TextBreadcrumbProps> = ({ route, text }) => {
  return <Breadcrumb route={route}>{text}</Breadcrumb>;
};

export default TextBreadcrumb;
