// LegalNoteComp.tsx

import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

const LegalNoteComp: React.FC = () => {
  const { t, ready: isTReady } = useTranslation('authentication');

  return isTReady ? (
    <div style={{ fontSize: '0.8em', marginTop: '0.75em' }}>
      <Trans
        i18nKey="EmailLogInForm_legalNoteLabel"
        t={t}
        values={{
          termsOfService: t('EmailLogInForm_TermsOfServiceLinkText'),
          privacyPolicy: t('EmailLogInForm_privacyPolicyLinkText'),
        }}
        components={[
          <a
            href={t('EmailLogInForm_TermsOfServiceLinkUrl')}
            target="_blank"
            className="content-link thin-link"
          />,
          <a
            href={t('EmailLogInForm_privacyPolicyLinkUrl')}
            target="_blank"
            className="content-link thin-link"
          />,
        ]}
      />
    </div>
  ) : null;
};

export default LegalNoteComp;
