// scriptLoader.ts
let scriptLoaded = false;

export const loadVimeoScript = () => {
  if (!scriptLoaded) {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.head.appendChild(script);
    scriptLoaded = true;
  }
};
