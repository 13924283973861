import AddPropertiesToAllTrackedEventsInSession from '../UserInfo/AddPropertiesToAllTrackedEventsInSession';

const trackAppModeWithEachEvent = (
  addPropertiesToAllTrackedEventsInSession: AddPropertiesToAllTrackedEventsInSession,
  checkIfStandaloneMode: () => boolean
) => {
  const appMode = checkIfStandaloneMode() ? 'standalone' : 'browser';
  addPropertiesToAllTrackedEventsInSession({
    appMode: appMode,
  });
};

export default trackAppModeWithEachEvent;
