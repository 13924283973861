import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useIonViewWillEnter } from '@ionic/react';

import { CardDeckRoutes } from '../../../cardDecksRoutes';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';

import { getCardDeckCategory } from '../../CardDeckCategories/cardDeckCategories';
import PipCategoryLabel from '../../Common/PipCategoryLabel';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import provideDeckBreadcrumbProps from '../../Common/Breadcrumbs/provideDeckBreadcrumbProps';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';

import {
  CardDeckCardListSection,
  getCardDeckCategoryWithCards,
} from '../cardDeckCardListSections';

import { getCardListDocumentTitle } from '../CardDeckCardListPage';
import CardDeckCardsGridPage from '../CardDeckCardsGridPage';

const getCategory = (deckId: string, categoryId: string | undefined) => {
  if (categoryId != undefined) {
    const category = getCardDeckCategory(deckId, Number(categoryId));
    return category;
  }
  return undefined;
};

const getLastBreadcrumb = (deckId: string, categoryId: string | undefined) => {
  const category = getCategory(deckId, categoryId);
  if (category != undefined) {
    return (
      <PipCategoryLabel
        title={category!.title}
        iconCode={category!.iconCode}
        iconColor={category!.color}
      />
    );
  }
  return 'Tactics';
};

const getDocumentTitle = (deckId: string, categoryId: string | undefined) => {
  let categoryTitle;
  const category = getCategory(deckId, categoryId);
  if (category != undefined) {
    categoryTitle = category!.title;
  } else {
    categoryTitle = 'Tactics';
  }
  return getCardListDocumentTitle(deckId, categoryTitle);
};

const setDocumentTitle = (deckId: string, categoryId: string | undefined) => {
  document.title = getDocumentTitle(deckId, categoryId);
};

const getSections = (deckId: string, categoryId: string | undefined) => {
  if (!categoryId) {
    return null;
  }

  const categoryIdNumber = Number(categoryId);
  if (!categoryIdNumber) {
    return null;
  }

  const singleCategorySection = getCardDeckCategoryWithCards(
    deckId,
    categoryIdNumber
  );
  if (!singleCategorySection) {
    return null;
  }

  return [singleCategorySection];
};

const CardDeckCategoryTacticsGridPage: React.FC = () => {
  // console.log(`Loading card list`);
  const [sections, setSections] = useState<
    CardDeckCardListSection[] | null | undefined
  >(undefined);
  const { deckId, categoryId } = useParams<{
    deckId: string;
    categoryId: string;
  }>();

  const lastBreadcrumb = getLastBreadcrumb(deckId, categoryId);
  const getCardDetailsRoute = CardDeckRoutes.cardDeckTacticCard.resolve;

  useIonViewWillEnter(() => {
    // console.log(`Loading deck with id ${deckId}`);

    let sections = getSections(deckId, categoryId);
    setSections(sections);

    setDocumentTitle(deckId, categoryId);
  }, [deckId, categoryId]);

  return (
    <CardDeckCardsGridPage
      deckId={deckId}
      breadcrumbs={
        <Breadcrumbs>
          <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
          <TextBreadcrumb {...provideDeckBreadcrumbProps(deckId)} />
          <Breadcrumb>{lastBreadcrumb}</Breadcrumb>
        </Breadcrumbs>
      }
      cardListSections={sections}
      shouldShowCategorySectionTitle={false}
      getCardDetailsRoute={getCardDetailsRoute}
    />
  );
};

export default CardDeckCategoryTacticsGridPage;
