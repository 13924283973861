import React, { useEffect, useState } from 'react';

import { isDataLoadingResultSuccess } from '../../../Common/Types/DataLoadingResult';
import { useAuthenticatedCustomer } from './AuthenticatedCustomerContext';
import { getDeckIdsUserCanAccessResult } from './cardDeckAuthorizedAccessFunctions';

interface AuthorizedDecksAccessContainerProps {
  children: React.ReactNode;
}

const AuthorizedDecksAccessContainer: React.FC<
  AuthorizedDecksAccessContainerProps
> = ({ children }) => {
  const [canAccessDecks, setCanAccessDecks] = useState<boolean>(false);
  const customer = useAuthenticatedCustomer();

  useEffect(() => {
    const deckIdsResult = getDeckIdsUserCanAccessResult(customer);

    if (isDataLoadingResultSuccess(deckIdsResult)) {
      setCanAccessDecks(!!deckIdsResult.data);
    }
  }, [customer]);

  return <>{canAccessDecks ? children : null}</>;
};

export default AuthorizedDecksAccessContainer;
