import { getCardDeck } from '../../Home/cardDecks';
import { resolveCardDeckLink } from '../resolveCardDeckLink';

const provideDeckBreadcrumbProps = (deckId: string, isLink: boolean = true) => {
  const deckIdNumber = Number(deckId);
  const deck = getCardDeck(deckIdNumber);
  const deckRoute = deck && isLink ? resolveCardDeckLink(deck) : undefined;
  return {
    route: deckRoute,
    text: getCardDeck(deckIdNumber)?.title ?? '',
  };
};

export default provideDeckBreadcrumbProps;
