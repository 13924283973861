import { CardDeckRoutes } from '../../cardDecksRoutes';
import { CardDeck } from '../Home/cardDecks';

export const resolveCardDeckLink = (cardDeck: CardDeck) => {
  if (cardDeck.type === 'full') {
    return CardDeckRoutes.cardDeck.resolve(cardDeck.id);
  } else {
    return CardDeckRoutes.cardDeckTacticCardList.resolve(cardDeck.id);
  }
};
