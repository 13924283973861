// requested login link email is sent to Firebase auth to verify
// that the requesting user and the consuming user are the same person
const requestedLoginLinkEmailStorageKey = 'requestedLoginLinkEmail';
// last known email is used to prepoluate the "request login link" email form
const lastKnownLoginEmailStorageKey = 'lastKnownLoginEmail';

export const emailLinkLoginFormLocalStorage = {
  getRequestedLoginLinkEmail: () => {
    return window.localStorage.getItem(requestedLoginLinkEmailStorageKey);
  },
  setRequestedLoginLinkEmail: (email: string) => {
    window.localStorage.setItem(requestedLoginLinkEmailStorageKey, email);
  },
  removeRequestedLoginLinkEmail: () => {
    window.localStorage.removeItem(requestedLoginLinkEmailStorageKey);
  },

  getLastKnownLoginEmail: () => {
    return window.localStorage.getItem(lastKnownLoginEmailStorageKey);
  },
  setLastKnownLoginEmail: (email: string) => {
    window.localStorage.setItem(lastKnownLoginEmailStorageKey, email);
  },
  removeLastKnownLoginEmail: () => {
    window.localStorage.removeItem(lastKnownLoginEmailStorageKey);
  },
};
