import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import {
  IonCol,
  IonGrid,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRow,
  useIonRouter,
  useIonViewWillEnter,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';

import '../Common/SolveMyProblemFlow.css';
import './SolveMyProblemTacticsPage.css';

import composeTrackUserActionWithPostHog from '../../../External/PostHog/trackUserActionWithPostHog';
import logErrorWithSentry from '../../../External/Sentry/logErrorWithSentry';
import { CardDeckRoutes } from '../../../cardDecksRoutes';
import AppContentContainer from '../../../Common/AppContentContainer';
import ButtonWithSpinner from '../../../Common/UI/Buttons/ButtonWithSpinner';
import Breadcrumbs from '../../../Common/Navigation/Breadcrumbs/Breadcrumbs';
import Breadcrumb from '../../../Common/Navigation/Breadcrumbs/Breadcrumb';
import NotFoundPage from '../../../Common/Pages/NotFoundPage';
import TextBreadcrumb from '../../../Common/Navigation/Breadcrumbs/TextBreadcrumb';
import renderPageIfModelDefinedOrNotFoundPage from '../../../Common/Pages/pageIfModelDefinedOrNotFoundPage';
import HomeBreadcrumb from '../../Common/Breadcrumbs/PipLogoBreadcrumb';
import { useAuthenticatedCustomer } from '../../Common/AuthorizedAccess/AuthenticatedCustomerContext';
import { getDeckIdsUserCanAccess } from '../../Common/AuthorizedAccess/cardDeckAuthorizedAccessFunctions';
import CardCell, {
  cardGridColumnSizeSm,
  cardGridColumnSizeXs,
} from '../../Common/CardCell/CardCell';
import provideHomeBreadcrumbProps from '../../Common/Breadcrumbs/providePipLogoBreadcrumbProps';
import {
  CardDeckCardListItem,
  CardDeckCardListSection,
  getTacticCardListItemAndSectionByCardTitleAndDeckId,
} from '../../CardDeckCardList/cardDeckCardListSections';
import AuthorizedDeckContentAccessContainer from '../../Common/AuthorizedAccess/AuthorizedDeckContentAccessContainer';
import { getCardDeck } from '../../Home/cardDecks';
import SolveMyProblemContent from '../Content/SolveMyProblemContent';
import provideFocusBreadcrumbProps from '../Common/Breadcrumbs/provideFocusBreadcrumbProps';
import provideChallengeBreadcrumbProps from '../Common/Breadcrumbs/provideChallengeBreadcrumbProps';

type ProblemSolutionItem = {
  deckId: number;
  section: CardDeckCardListSection;
  card: CardDeckCardListItem;
};

const getMissingCardErrorMessage = (
  cardTitle: string,
  challenge: string,
  focus: string
) => {
  return `Card not found for title: "${cardTitle}", challenge: "${challenge}", focus: "${focus}"`;
};

let loggedErrors: string[] = [];
const logMissingCardErrorOnce = (error: Error) => {
  if (!loggedErrors.includes(error.message)) {
    logErrorWithSentry(error);
    loggedErrors.push(error.message);
  }
};

const SolveMyProblemTacticsPage: React.FC = () => {
  const { challengeIndex, focusIndex } = useParams<{
    challengeIndex: string;
    focusIndex: string;
  }>();
  const challengeIndexNumber = Number(challengeIndex);
  const focusIndexNumber = Number(focusIndex);
  const router = useIonRouter();
  const customer = useAuthenticatedCustomer();
  const { t, ready: isTReady } = useTranslation('solve-my-problem');
  const postHog = usePostHog();
  const trackAction = composeTrackUserActionWithPostHog(postHog);

  const getRoute = CardDeckRoutes.cardDeckTacticCard.resolve;

  const deckDetails = SolveMyProblemContent.deckOptions[challengeIndexNumber];
  if (!deckDetails) {
    return <NotFoundPage />;
  }
  const deckId = deckDetails.deckId;

  const focus = deckDetails.focusOptions[focusIndexNumber];
  if (!focus) {
    return <NotFoundPage />;
  }

  const title = focus.title;
  const [cardItems, setCardItems] = useState<
    ProblemSolutionItem[] | null | undefined
  >(undefined);
  const [visibleCardsCount, setVisibleCardsCount] = useState<number>(0);

  function loadAndDisplayData() {
    const cardTitles = focus.cards;
    const cardItemsOrErrors: (ProblemSolutionItem | Error)[] = cardTitles.map(
      cardTitle => {
        const cardListItemSectionItem =
          getTacticCardListItemAndSectionByCardTitleAndDeckId(
            cardTitle,
            deckId
          );
        if (!cardListItemSectionItem) {
          return new Error(
            getMissingCardErrorMessage(
              cardTitle,
              deckDetails.deckLink,
              focus.title
            )
          );
        }

        return {
          deckId: Number(deckId),
          section: cardListItemSectionItem.cardListSection,
          card: cardListItemSectionItem.cardListItem,
        };
      }
    );

    const errors = cardItemsOrErrors.filter(itemOrError => {
      return itemOrError instanceof Error;
    }) as Error[];
    errors.forEach(error => {
      logMissingCardErrorOnce(error);
    });

    const cardItems = cardItemsOrErrors.filter(itemOrError => {
      return !(itemOrError instanceof Error);
    }) as ProblemSolutionItem[];
    const initiallyVisibleCardsCount = cardItems.length / 2;
    setVisibleCardsCount(initiallyVisibleCardsCount);
    setCardItems(cardItems);
  }

  const setDocumentTitle = () => {
    document.title = t('SolveMyProblemTacticsPage_documentTitle');
  };

  useIonViewWillEnter(() => {
    setDocumentTitle();
  });

  useEffect(() => {
    if (!isTReady) {
      return;
    }

    const deckIdsUserCanAccess = getDeckIdsUserCanAccess(customer);
    if (deckIdsUserCanAccess?.has(Number(deckDetails.deckId))) {
      loadAndDisplayData();
    } // else still loading or error handled by AuthorizedDeckContentAccessContainer
  }, [customer, isTReady, challengeIndex, focusIndex]);

  return renderPageIfModelDefinedOrNotFoundPage(
    cardItems,
    isTReady ? (
      <IonPage
        id="solve-my-problem-tactics"
        className="solve-my-problem-flow-container"
      >
        <AppContentContainer>
          <AuthorizedDeckContentAccessContainer deckId={deckDetails.deckId}>
            <Breadcrumbs>
              <HomeBreadcrumb {...provideHomeBreadcrumbProps()} />
              <TextBreadcrumb {...provideChallengeBreadcrumbProps(t, true)} />
              <TextBreadcrumb
                {...provideFocusBreadcrumbProps(t, true, challengeIndex)}
              />
              <Breadcrumb>
                {t('SolveMyProblemTacticsPage_breadcrumb')}
              </Breadcrumb>
            </Breadcrumbs>
            <div className="bottom-buttons-margin-adjust">
              <IonList className="cards-list">
                <IonListHeader>
                  <IonLabel>{title}</IonLabel>
                </IonListHeader>
              </IonList>
              <IonGrid className="cards-grid">
                <IonRow>
                  {cardItems
                    ?.slice(0, visibleCardsCount)
                    .map((cardItem, index) => (
                      <IonCol
                        sizeSm={cardGridColumnSizeSm}
                        sizeXs={cardGridColumnSizeXs}
                        offsetSm={index % 2 == 0 ? '2' : '0'}
                        offsetXs="2"
                        key={cardItem.card.id}
                      >
                        <CardCell
                          title={cardItem.card.title}
                          imageSource={cardItem.card.imageUrl}
                          description={cardItem.card.description}
                          onClick={() => {
                            trackAction(
                              'navigate to tactic in Solve My Problem',
                              {
                                challengeTitle: deckDetails.deckLink,
                                focusTitle: focus.title,
                                pathDescription: `${deckDetails.deckLink} / ${focus.title} / ${cardItem.card.title}`,
                                cardTitle: cardItem.card.title,
                              }
                            );

                            router.push(
                              getRoute(cardItem.deckId, cardItem.card.id)
                            );
                          }}
                          categoryColor={cardItem.card.style.borderColor}
                          deckTitle={getCardDeck(cardItem.deckId)?.title}
                          categoryTitle={cardItem.section.title}
                          categoryIcon={cardItem.section.iconCode}
                        />
                      </IonCol>
                    ))}
                  {visibleCardsCount != cardItems?.length && (
                    <IonCol
                      sizeSm="8"
                      sizeXs={cardGridColumnSizeXs}
                      offsetSm="2"
                      offsetXs="2"
                    >
                      <ButtonWithSpinner
                        title={t(
                          'SolveMyProblemTacticsPage_showMoreButtonTitle'
                        )}
                        size="default"
                        fill="outline"
                        expand="block"
                        className="secondary show-more-button"
                        containerClassName="show-more-button-container"
                        onClick={async () => {
                          trackAction('show more tactics in Solve My Problem', {
                            challengeTitle: deckDetails.deckLink,
                            focusTitle: focus.title,
                            routeDescription: `${deckDetails.deckLink} / ${focus.title}`,
                          });
                          cardItems && setVisibleCardsCount(cardItems.length);
                        }}
                      />
                    </IonCol>
                  )}
                </IonRow>
              </IonGrid>
            </div>
          </AuthorizedDeckContentAccessContainer>
        </AppContentContainer>
      </IonPage>
    ) : (
      <></>
    )
  );
};

export default SolveMyProblemTacticsPage;
