import FontIconComp from '../../Common/UI/Icons/FontIconComp';
import './PipCategoryLabel.css';

interface PipCategoryLabelProps {
  title: string;
  iconColor?: string;
  iconCode: string;
}
const PipCategoryLabel: React.FC<PipCategoryLabelProps> = ({
  title,
  iconColor,
  iconCode,
}) => {
  return (
    <div className="pipCategoryLabel">
      <FontIconComp
        className="pip-icon"
        color={iconColor}
        iconCode={iconCode}
      />
      <span style={{ display: 'block', marginLeft: '8px' }}>{title}</span>
    </div>
  );
};

export default PipCategoryLabel;
