import Fuse from 'fuse.js';
import { CardDeckCardSearchResult } from './CardDeckCardSearchModels';

export type CardDeckSearchResultsViewModel = {
  results: CardDeckCardSearchResult[];
  message: string | null;
  searchValue: string;
};

// TODO: localize strings

/**
 * Searches for card decks based on the provided search value.
 *
 * @param {string} searchValue - The value to search for.
 * @param {Fuse<any>} searchEngine - The search engine to use for searching.
 * @param {number} [limit=100] - The maximum number of results to return.
 * @return {CardDeckSearchResultsViewModel} - An object containing the search results, message, and search value.
 * The search value represents the string used to execute the search, which might be different
 * than the one passed into the function, e.g. it could be trimmed.
 */
export const getCardDeckSearchResults = (
  searchValue: string,
  searchEngine: Fuse<any> | null,
  limit: number = 100
) => {
  if (!searchValue || searchValue.length == 0) {
    return {
      results: [],
      message: null,
      searchValue: searchValue,
    };
  }

  if (!searchEngine) {
    return {
      results: [],
      message:
        'Uh-oh, search hit a snag!<br/>Try refreshing the page to get back on track.',
      searchValue: searchValue,
    };
  }

  if (searchValue.trim().length == 0) {
    return {
      results: [],
      message: 'Try entering a word or two.',
      searchValue: searchValue,
    };
  }

  const trimmedSearchValue = searchValue.trim();

  if (trimmedSearchValue.length == 1) {
    return {
      results: [],
      message: 'Just a single character?<br/>Double up for better results!',
      searchValue: trimmedSearchValue,
    };
  }

  const results = searchEngine.search(trimmedSearchValue, { limit: limit });
  if (results.length == 0) {
    return {
      results: [],
      message: 'Nope, can’t find that one!<br/>Try something else?',
      searchValue: trimmedSearchValue,
    };
  }

  const typedResults = results as unknown as CardDeckCardSearchResult[];
  //console.log(typedResults.length); //.map(result => result.item.title));
  return {
    results: typedResults,
    message: null,
    searchValue: trimmedSearchValue,
  };
};
